import { ColumnHelper } from "@tanstack/react-table";
import { MbjTableCheckbox } from "./parts/MbjTableCheckbox";
import * as React from "react";

export function createSelectColumn<T>(columnHelper: ColumnHelper<T>) {
  return columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <MbjTableCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row, table }) => (
      <MbjTableCheckbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  });
}
