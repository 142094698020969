import styled from "styled-components";
import {
  components,
  Operation, useGetAllianceMembers, useGetFormationPlanningAlliances,
  useGetFormationPlanningMember, useGetMemberAlliances, useGetMemberMe,
  useModifyFormationPlanning
} from "@mbj-front-monorepo/data-access";
import React, {useEffect, useMemo, useState} from "react";
import {MbjCardClassic, MbjLoading, MbjModal2, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {FormNoPlanCard} from "./Components/FormNoPlanCard";
import {OneMonthPl} from "./Components/OneMonthPl";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {FormModifyNoPl} from "./Components/FormModifyNoPl";
import {BlockPlannif, OneMonth, OneMonthForIn, Plannif} from "./FormationPlannification";

interface FormationPlannificationAllianceCtrlProps{
  className?:string;
}


export const TabMois:OneMonth[] = [
  {num:0, numIso:1, libelle:'Janvier', libShort:'Janv.'},
  {num:1, numIso:2, libelle:'Février', libShort:'Fev.'},
  {num:2, numIso:3, libelle:'Mars', libShort:'Mars'},
  {num:3, numIso:4, libelle:'Avril', libShort:'Avr.'},
  {num:4, numIso:5, libelle:'Mai', libShort:'Mai'},
  {num:5, numIso:6, libelle:'Juin', libShort:'Juin.'},
  {num:6, numIso:7, libelle:'Juillet', libShort:'Juill.'},
  {num:7, numIso:8, libelle:'Août', libShort:'Août'},
  {num:8, numIso:9, libelle:'Septembre', libShort:'Sept.'},
  {num:9, numIso:10, libelle:'Octobre', libShort:'Oct.'},
  {num:10, numIso:11, libelle:'Novembre', libShort:'Nov.'},
  {num:11, numIso:12, libelle:'Décembre', libShort:'Dec.'},
]

const FormationPlannificationAllianceCtrl = (props:FormationPlannificationAllianceCtrlProps)=>{
  const memberQuery = useGetMemberMe();
  const AlliancesQuery = useGetMemberAlliances();
  const [idAlliance, setIdAlliance] = useState<number>(3)
  const MembersAllianceQuery = useGetAllianceMembers(idAlliance);
  const [idsMember, setIdsMember] = useState<number[]>([])
  const PlannQuery = useGetFormationPlanningAlliances(idsMember);
  const TabMoisCpnt:OneMonthForIn[] = [];
  const today = new Date();
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const [plannifs, setPlannifs] = useState<Plannif[]>([])
  const [formationPlCurrent, setFormationPlCurrent] = useState<components["schemas"]["FormationPlanning.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"]|null>(null)
  const [openEditNoPlannif, setOpenEditNoPlannif] = useState<boolean>(false)
  for(let i=0; i<12; i++) {
    const DateDecale = new Date(firstDate.getTime() + 86400000 * i * 32);
    const ThisDate = new Date(DateDecale.getFullYear(), DateDecale.getMonth(), 1);
    const monthThis = DateDecale.getMonth();
    const MyMonth = TabMois.find(m=>m.num === monthThis);
    if(MyMonth){
      TabMoisCpnt.push({
        month:MyMonth,
        date:ThisDate
      })
    }
  }
  useEffect(()=>{
    if(MembersAllianceQuery.data){
      setIdsMember(MembersAllianceQuery.data.map(ma=>ma.Member?.id||0))
    }
  }, [MembersAllianceQuery.data])
  const SaveFormationPlDate = (id:number, date:Date)=>{
    console.log('dd');
  }
  useEffect(()=>{
    if(PlannQuery.data && memberQuery){
      const PQC = PlannQuery.data.filter(p=>idsMember.indexOf(p.Employee?.MemberMaster?.id||0)!==-1 &&  p.Employee?.MemberMaster?.id!==memberQuery.data?.id)
      const today = new Date();
      const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const MyTab:Plannif[]=[]
      for(let i=0; i<12; i++){
        const DateDecale = new Date(firstDate.getTime() + 86400000*i*32);
        const ThisDate = new Date(DateDecale.getFullYear(), DateDecale.getMonth(), 1);
        const monthThis = DateDecale.getMonth();
        const MyMonth = TabMois.find(m=>m.num === monthThis);
        if(MyMonth) {
          const PlannQueryConcerned = PQC.filter(p => p.souhaitAt && new Date(p.souhaitAt).getMonth() === monthThis && new Date(p.souhaitAt).getFullYear() === ThisDate.getFullYear())
          const ActivitesConcerned = PlannQueryConcerned.map(pl => pl.Activite);
          const ActivitesConcernedSSD: components["schemas"]["Activite-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"][] = [];
          ActivitesConcerned.map(ac => {
            if (ac) {
              if (ActivitesConcernedSSD.map(acd => acd.id).indexOf(ac.id) === -1) {
                ActivitesConcernedSSD.push(ac)
              }
            }
          })
          const ListForActi: BlockPlannif[] = [];
          //mois:MyMonth ? MyMonth+" "+DateDecale.getFullYear() : '',
          ActivitesConcernedSSD.map(a => {
            ListForActi.push({
              Activite: a,
              list: PlannQueryConcerned.filter(p => p.Activite && p.Activite.id === a.id),
              mois: `${MyMonth.libelle} ${ThisDate.getFullYear()}`,
              id:ThisDate.getFullYear()+MyMonth.num
            })
            return a;
          })
          MyTab.push({
            mois: `${MyMonth.libelle} ${ThisDate.getFullYear()}`,
            list: ListForActi,
            date:ThisDate,
          })
        }
      }
      setPlannifs(MyTab);
    }
  }, [PlannQuery.data, idsMember, memberQuery.data])
  const handleClickOnNoPlannif = (fp:components["schemas"]["FormationPlanning.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"])=>{
    setFormationPlCurrent(fp)
    setOpenEditNoPlannif(o=>!o);
  }
  const handleClickSelectAlliance = (a:number)=>{
    setIdAlliance(a)
  }
  return (
    <div className={`formation_plannification ${props.className}`}>
      <div className={"Select_alliance"}>
        {AlliancesQuery.data?.map(alliance=>(
          <div className={`oneChoice_alliance ${alliance.Alliance && alliance.Alliance.id === idAlliance ? 'current' : ''}`} key={`Oca${alliance.Alliance?.id}`} onClick={()=>handleClickSelectAlliance(alliance.Alliance?.id||0)}>
            {alliance.Alliance?.name}
          </div>
        ))}
      </div>
      <MbjWidgetClassic title={"Plannification de l'alliance"} basis={"100%"}>
        <DndProvider backend={HTML5Backend}>
          <div className={`wrap_plann`}>
            {TabMoisCpnt.map((item, idx:number)=>(
              <OneMonthPl readonly key={`MT${idx}`} date={item.date} Month={item.month} list={plannifs} setPlannifs={setPlannifs} SaveFormationPlDate={SaveFormationPlDate}/>
            ))}
          </div>
        </DndProvider>
      </MbjWidgetClassic>
      <MbjModal2 open={openEditNoPlannif} closeMe={()=>setOpenEditNoPlannif(false)} titre={"Gestion formation"} Wd={"50%"}>
        {formationPlCurrent ?
          <FormModifyNoPl FormationPl={formationPlCurrent} CloseMe={()=>setOpenEditNoPlannif(false)}/>:<p>Bad</p>
        }
      </MbjModal2>
    </div>
  )
}

export const FormationPlannificationAlliance = styled(FormationPlannificationAllianceCtrl)`
  padding: 1% 2%;
  .Select_alliance{
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    gap:10px;
    .oneChoice_alliance{
      padding: 0.25rem 0.5rem;
      border-radius: 14px;
      border:solid 1px ${props => props.theme.primary};
      &:hover, &.current{
        cursor: pointer;
        background: ${props => props.theme.primary};
        color: white;
      }
    }
  }
  .wrap_list_formation_no_planif{
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
  }
  .wrappy_list_formation_no_planif{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .wrap_plann{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`
