import styled from "styled-components";
import React from "react";

interface ItemSaveCardCtrlProps{
  className?:string;
  statutLib:string;
  satutID:number;
  infos:React.ReactNode;
  Pc:number;
  Reaction:()=>void;
}

const TabStatut = ['', '', 'in_save', 'exclude', 'relation', 'over', 'waiting'];

const ItemSaveCardCtrl = (props:ItemSaveCardCtrlProps)=>{
  return (
    <div className={`item_save_card ${props.className}`}>
      <div className={`content_card`} onClick={()=>props.Reaction()}>
        <div className={"statut_place"}>
          <div className={`statut_libelle ${TabStatut[props.satutID]}`}>{props.statutLib}</div>
        </div>
        <div className={"infos_place"}>{props.infos}</div>
        <div className={`pc_place ${(props.Pc||0) > 0.8 ? 'good' : (props.Pc||0) > 0.7 ? 'medium' : 'bad'}`}>{Math.round(props.Pc*100)}%</div>
      </div>
    </div>
  )
}

export const ItemSaveCard = styled (ItemSaveCardCtrl)`
  padding: 1rem 0.5rem;

  .content_card {
    border: solid ${props => props.theme.primary} 1px;
    border-radius: 8px;
    transition: all 0.3s;
    &:hover{
      border: solid transparent 1px;
      transition: all 0.3s;
      cursor: pointer;
      transform: translateY(-10px);
      box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    }
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    .pc_place{
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 24px;
      &.good{
        color: ${props => props.theme.thirdDarken};
      }
      &.medium{
        color: ${props => props.theme.warningPastel};
      }
      &.bad{
        color: ${props => props.theme.warningDarken};
      }
    }
    .infos_place{
      flex-grow: 1;
    }
    .statut_place {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .statut_libelle {
        padding: 0.25rem;
        border-radius: 4px;

        &.in_save {
          background: #9df19d;
        }

        &.exclude {
          background: #8f155f;
          color: white;
        }

        &.relation {
          background: #9df19d;
        }

        &.over {
          background: #35ef81;
        }

        &.waiting {
          background: #ea9f5a;
        }
      }
    }
  }

`
