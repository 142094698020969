import React from "react";
import styled from "styled-components";
import { FormationCourteCard } from "./FormationCourteCard/FormationCourteCard";
import { FormationContinuMetierCard } from "./FormationsMetierCard/FormationsMetierCard";
import { MbjWidgetClassic } from "@mbj-front-monorepo/ui";

interface FormationsToWidgetCtrlProps {
  className?: string;
  employeeId?: number;
  metierId?: number;
}

const FormationsToWidgetCtrl = ({ className, employeeId, metierId }: FormationsToWidgetCtrlProps) => {
  return (
    <MbjWidgetClassic title="Formations">
      <FormationContinuMetierCard employeeId={employeeId} metierId={metierId} basis={"48%"} />
      <FormationCourteCard employeeId={employeeId} metierId={metierId} basis={"48%"} />
    </MbjWidgetClassic>
  );
};

export const FormationsToWidget = styled(FormationsToWidgetCtrl)((props) => ({}));
