import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { MbjButton, MbjFlex, MbjLoading, MbjModal, MbjNavPath, useModal } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useAddEmployeeActivite, useGetEmployee } from "@mbj-front-monorepo/data-access";
import { MbjEmployeeActiviteAccordion, MbjFindActivite } from "@mbj-front-monorepo/data-ui";
import { GoPlus } from "react-icons/go";

interface CompetencesCtrlProps {
  className?: string;
}

function CompetencesCtrl(props: CompetencesCtrlProps) {
  const location = useLocation();
  const { id } = useParams();
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));
  const { isShowing, toggle } = useModal();
  const mutationAdd = useAddEmployeeActivite(employeeQuery.data?.id || -1);
  const HandleEndFindActi = (data: { idActivite: number; niveau: number }) => {
    mutationAdd.mutate(
      {
        Employee: "/api/employees/" + employeeQuery.data?.id,
        niveau: data.niveau,
        Activite: "/api/activites/" + data.idActivite,
      },
      {
        onSuccess: () => {
          toggle();
        },
      }
    );
  };
  return (
    <div className={"Competences " + props.className}>
      {employeeQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom,
              },
              { index: 3, label: "Compétences" },
            ]}
          />
          <MbjFlex direction={"column"} gap={"10px"} grow={1}>
            <MbjButton leftIcon={<GoPlus />} onClick={toggle} alignSelf={"flex-end"}>
              Ajouter une compétence
            </MbjButton>
            <MbjEmployeeActiviteAccordion employeeId={id ? parseInt(id) : undefined} themeColor={"grey"} />
          </MbjFlex>
          <MbjModal hide={toggle} isShowing={isShowing} title="Ajout d'une compétence" fitContent={true}>
            <MbjFindActivite CallBackFx={HandleEndFindActi} isPending={mutationAdd.isLoading} />
          </MbjModal>
        </>
      )}
    </div>
  );
}
export const Competences = styled(CompetencesCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
  display: "flex !important",
  flexDirection: "column",
}));
