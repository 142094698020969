import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {
  MbjAccordion,
  MbjAccordionContent,
  MbjAccordionItem, MbjAccordionTrigger, MbjChoixMenu,
  MbjLoading,
  MbjNavPath,
  MbjScrollArea,
  MbjTableV2,
  MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {Operation, useGetBesoinsFormationEntreprise} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useRef, useState} from "react";
import {ModalFormationActivite} from "./Components/ModalFormationActivite";
import {ExpertOne} from "../Collaborateurs/CollaborateurDetail/Suivi/ExpertSuivi/ExpertSuiviOneCompetenceWidget";
import {OneBlockCompetence} from "./Components/OneBlockCompetence";
import {GrClose} from "react-icons/gr";
import {ItemMenuResult} from "../Recrutements/ProfilsRetenus/ProfilsRetenus2";
import {BesoinsParCompetences} from "./BesoinsParCompetences";
import {BesoinsParServices} from "./BesoinsParServices";
import {BesoinsParUserMetier} from "./BesoinsParUserMetier";
import {BesoinsParNiveau} from "./BesoinsParNiveau";

interface FormationEntrepriseCtrlProps{
  className?:string;
}

const MesMenus:ItemMenuResult[]=[
  {id:1, libelle:"Par Compétences"},
  {id:2, libelle:"Par Service"},
  {id:3, libelle:"Par Métier de l'entreprise"},
  {id:4, libelle:"Par Niveau d'écart"},
]

interface NodeMenu{
  id:number,
  Nody:React.ReactNode
}



const FormationEntrepriseCtrl = (props:FormationEntrepriseCtrlProps)=>{
  const location = useLocation();
  const [menuCurrent, setMenuCurrent] = useState<ItemMenuResult|null>(MesMenus.find(m=>m.id === 1)||null)
  const [nodyCurrent, setNodyCurrent] = useState<React.ReactNode|null>(null)
  const TabNode:NodeMenu[] = [
    {id:1, Nody:<BesoinsParCompetences/>},
    {id:2, Nody:<BesoinsParServices/>},
    {id:3, Nody:<BesoinsParUserMetier/>},
    {id:4, Nody:<BesoinsParNiveau/>}
  ]
  useEffect(()=>{
    if(menuCurrent){
      const myNody = TabNode.find(t=>t.id === menuCurrent?.id||0)
      if(myNody){
        setNodyCurrent(myNody.Nody);
      }
    }
  }, [menuCurrent])
  return (
    <div className={`formation_entreprise ${props.className}`}>
      <MbjNavPath
        routePath={location.pathname}
        changeLabelTab={[{
          index: 1,
          label: "Gestion des Besoins en compétences de l'entreprise",
        },
          {
            index: 2,
            label: "Recherche des besoins en compétences",
          }
        ]}
      />
      <MbjChoixMenu current={menuCurrent} setChoice={setMenuCurrent} choices={MesMenus}/>
      <div className={"content_page"}>
        {nodyCurrent && nodyCurrent}
      </div>
    </div>
  )
}

export const FormationEntreprise = styled(FormationEntrepriseCtrl)`
  padding: 1% 2%;
  height: 100%;
`
