import styled from "styled-components";
import {Operation, useReadNotification} from "@mbj-front-monorepo/data-access";
import {useState} from "react";

interface NotificationCardCtrlProps{
  className?:string;
  Notification:Operation<"getNotificationsItem">
}

const NotificationCardCtrl = (props:NotificationCardCtrlProps)=>{
  const mutation = useReadNotification(props.Notification.id||0)
  const [isnew, setIsNew] = useState(!props.Notification.readAt)
  const readThis = ()=>{
    const now = new Date(new Date().getTime() - new Date().getTimezoneOffset()*60000)
    console.log(new Date().getTimezoneOffset())
    if(isnew) {
      const data: Operation<"putNotificationsItem", "requestBody"> = {
        readAt: now.toISOString()
      }
      setIsNew(false)
      mutation.mutate((data));
    } else{
      const data: Operation<"putNotificationsItem", "requestBody"> = {
        readAt: null
      }
      setIsNew(true)
      mutation.mutate((data));
    }
  }
  const getDateStr = ()=>{
    const today = new Date();
    today.setHours(12,0);
    const year = new Date(today.getTime() - 86400000)
    const dateNotif = props.Notification.creationAt ?  new Date(props.Notification.creationAt.slice(0,10)) : new Date("1970-01-01")
    if(dateNotif.toISOString().slice(0,10) === today.toISOString().slice(0,10)){
      return `Aujourd'hui ${props.Notification.creationAt ? new Date(props.Notification.creationAt).toLocaleTimeString() : ''}`
    } else if(dateNotif.toISOString().slice(0,10) === year.toISOString().slice(0,10)){
      return `Hier ${props.Notification.creationAt ? new Date(props.Notification.creationAt).toLocaleTimeString() : ''}`
    } else{
      console.log(dateNotif.toISOString().slice(0,10),year.toISOString().slice(0,10))
      return `${props.Notification.creationAt ? new Date(props.Notification.creationAt).toLocaleDateString() : ''} ${props.Notification.creationAt ? new Date(props.Notification.creationAt).toLocaleTimeString() : ''}`
    }
  }
  return (
    <div className={`notification ${props.className} ${isnew ? 'new' : 'readed'}`}>
      <div className={"date_place"}>{getDateStr()}</div>
      <div className={"content_place"}>
        {props.Notification.content}
      </div>
      <div className={`wrap_is`}>
        <div className={`btn_read ${isnew ? 'toLu' : 'toUnlu'}`} onClick={readThis}>
          {isnew ? "marquer lu" : "marquer non lu"}
        </div>
      </div>
    </div>
  )
}
export const NotificationCard = styled(NotificationCardCtrl)`
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  background: #eff4f5;
  border:solid 1px transparent;
  margin-bottom: 10px;
  color: ${props => props.theme.greyDarken};
  .wrap_is{
    display: flex;
    justify-content: flex-end;
    .btn_read{
      background: ${props => props.theme.secondary};
      padding: 0 0.5rem;
      color:white;
      border-radius: 6px;
      &:hover{
        cursor: pointer;
      }
      &.toUnlu{
        background: ${props=>props.theme.third};
      }
    }
  }
  &.new{
    background: #FDEFF0;
    //box-shadow: 0px 0px 5px rgba(0,0,0,.2);
    border:solid 1px #FAD5D5;
    color: ${props => props.theme.warning};
  }
  .date_place{
    font-size: 14px;
    font-weight: bold;
  }
  .content_place{
    padding: 0.5rem 0;
    font-style: italic;
  }
`
