import * as React from "react";
import styled from "styled-components";

export interface MbjRangeStaticCtrlProps {
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance  :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary" | "dark";
  /**
   * Couleur custom :
   */
  customColor?: string;
  /**
   * Nombre de points à afficher :
   */
  nbPoints: number;
  /**
   * valeur actuelle :
   */
  currentPoint: number;
  /**
   * Le niveau va-il être diminué ?
   */
  pointDecrease?: boolean;
  /**
   * Le niveau va-il être augmenté ?
   */
  pointIncrease?: boolean;
  height?:string;
}

function MbjRangeStaticCtrl(props: MbjRangeStaticCtrlProps) {
  const points = [];
  for (let i = 0; i < props.nbPoints; i++) {
    points.push(
      <div
        key={i}
        className={`point ${i < props.currentPoint ? "active" : ""} ${
          i === props.currentPoint - 1 && props.pointDecrease ? "preview" : ""
        } ${i === props.currentPoint && props.pointIncrease ? "preview" : ""}`}
      />
    );
  }

  return <div className={props.className}>{points}</div>;
}
/**
 * Composant layout card :
 */
export const MbjRangeStatic = styled(MbjRangeStaticCtrl)((props) => ({
  display: "flex",
  flexDirection: "row",
  gap: "2.5px",
  height: props.height ? props.height : "15px",

  "& .point": {
    width: "35px",
    backgroundColor: props.theme.greyPastel,

    "&:first-child": {
      borderRadius: "50px 0px 0px 50px",
    },

    "&:last-child": {
      borderRadius: "0px 50px 50px 0px",
    },

    "&.active": {
      backgroundColor: props.customColor
        ? props.customColor
        : props.themeColor === "primary"
        ? props.theme.primary
        : props.themeColor === "secondary"
        ? props.theme.secondary
        : props.themeColor === "third"
        ? props.theme.third
        : props.themeColor === "complementary"
        ? props.theme.complementary
        : "none",
    },
    "&.preview": {
      backgroundColor:
        props.themeColor === "primary"
          ? props.theme.primaryPastel
          : props.themeColor === "secondary"
          ? props.theme.secondaryPastel
          : props.themeColor === "third"
          ? props.theme.thirdPastel
          : props.themeColor === "complementary"
          ? props.theme.complementaryPastel
          : "none",
    },
  },
}));
MbjRangeStatic.defaultProps = {
  themeColor: "primary",
};
