import styled from "styled-components";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import React from "react";
import {useParams} from "react-router-dom";

interface DetailComparaisonCtrlprops{
  className?:string;
}

const DetailComparaisonCtrl = (props:DetailComparaisonCtrlprops)=>{
  const {id, idUserMetier} = useParams();
  return (
    <div className={`details_comparaison ${props.className}`}>
      {!!id && !!idUserMetier && idUserMetier!=="0" &&
        <MbjCompareReferentiel
          idToCompare1={parseInt(id) || -1}
          idToCompare2={parseInt(idUserMetier) || -1}
          typeCompared={"PUM"}
        />
      }
    </div>
  )
}
export const DetailComparaison = styled(DetailComparaisonCtrl)``
