import { fetchApi, Operation } from "../../services/fetchApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetConversation = (roomId: string) => {
  const promise = () => fetchApi("/api/conversations", { method: "get", query: { Room: roomId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["conversation", { roomId }], promise, {});
};

export const useAddConversation = (roomId: string) => {
  //const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"postConversationCollection", "requestBody">) => {
      const dataWithRoom = { ...data, Room: "/api/rooms/" + roomId };
      return fetchApi("/api/conversations", { method: "post", json: dataWithRoom });
    },
    {
      onSuccess: (newData) => {
        toast.success("Message envoyé !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
