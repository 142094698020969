import styled from "styled-components";
import {useGetUserMetiers} from "@mbj-front-monorepo/data-access";
import {MbjAutocomplete, MbjButton, MbjLoading, MbjSimpleInputContainer, useAutocomplete} from "@mbj-front-monorepo/ui";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import * as React from "react";

interface FormChoiceUserMetierCtrlProps{
  className?:string;
  idUserMetierCurrent?:number;
}

const FormChoiceUserMetierCtrl = (props:FormChoiceUserMetierCtrlProps)=>{
  const navigate = useNavigate();
  const UserMetiersListQuery = useGetUserMetiers();
  const useAutoCompleteHook = useAutocomplete<GetElementType<typeof UserMetiersListQuery.data>>();
  const methods = useForm({
    mode:'onChange',
  })
  const onSuby = (data:any)=>{
    if (useAutoCompleteHook.selectedValue) {
      console.log(data);
      navigate('../comparateur/'+useAutoCompleteHook.selectedValue.id)
    }
  }
  return (
    <div className={`form_choice_user_metier ${props.className}`}>
      {UserMetiersListQuery.isLoading ? (
        <MbjLoading/>): UserMetiersListQuery.isError ? (<p>Error</p>) : (
        <form onSubmit={methods.handleSubmit(onSuby)}>
          <div className={`wrap_inputs`}>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={""}>
              <MbjAutocomplete
                placeholder={"Choisissez un métier parmi vos fiches"}
                suggestionsQuery={UserMetiersListQuery}
                autocompleteHooks={useAutoCompleteHook}
                foldAfterChoose={true}
                name={"userMetier"}
                id={"userMetier"}
                register={methods.register}
                setValue={methods.setValue}
                trigger={methods.trigger}
                labelKey={"namePerso" as keyof unknown}
                required={true}
              />
            </MbjSimpleInputContainer>
            <MbjButton isPending={false}>
              Comparer le métier
            </MbjButton>
          </div>
        </form>
      )}
    </div>
  )
}

export const FormChoiceMetier = styled(FormChoiceUserMetierCtrl)`
  .wrap_inputs{
    display: flex;
    gap: 10px;
    align-items: end;
  }
`
