import React from "react";
import "./EntrepriseApp.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "../../../pages/Dashboard/Dashboard";
import { NotFound } from "../../../pages/NotFound/NotFound";
import { Entreprise } from "../../../pages/Entreprise/Entreprise";
import { Collaborateurs } from "../../../pages/Collaborateurs/Collaborateurs";
import { Alliances } from "../../../pages/Alliances/Alliances";
import { Recrutements } from "../../../pages/Recrutements/Recrutements";
import { Reclassements } from "../../../pages/Reclassements/Reclassements";
import { Messagerie } from "../../../pages/Messagerie/Messagerie";
import { Formations } from "../../../pages/Formations/Formations";
import { LegalMentions } from "../../../pages/LegalMentions/LegalMentions";
import { MdGroups, MdOutlineDashboard } from "react-icons/md";
import { BsBank2, BsCloudUpload, BsFillDiagram3Fill } from "react-icons/bs";
import { RiInboxArchiveFill, RiInboxUnarchiveFill } from "react-icons/ri";
import { IoMdMail } from "react-icons/io";
import { MainDesktopLayout, MbjLoading, MbjMenuLinks } from "@mbj-front-monorepo/ui";
import { logout, useGetMemberMe } from "@mbj-front-monorepo/data-access";
import { FaChalkboardTeacher, FaUserCircle } from "react-icons/fa";
import { useQueryClient } from "@tanstack/react-query";
import { AjoutCV } from "../../../pages/AjoutCV/AjoutCV";
import { EffectifRoot } from "../../../pages/Effectif/EffectifRoot";
import { CvTheque } from "../../../pages/CvTheque/CvTheque";
import { Recrutements2 } from "../../../pages/Recrutements/Recrutements2";
import { Messagerie2 } from "../../../pages/Messagerie2/Messagerie2";
import {FormationEntrepriseRoot} from "../../../pages/FormationEntreprise/FormationEntrepriseRoot";

export function EntrepriseApp() {
  const memberQuery = useGetMemberMe();

  const menuLinks: MbjMenuLinks[] = [
    {
      label: "Tableau de bord",
      to: "accueil",
      icon: <MdOutlineDashboard title="Tableau de bord icône" />,
    },
    {
      label: "Entreprise",
      to: "entreprise",
      icon: <BsBank2 title="Entreprise icône" />,
    },
    { label: "Collaborateurs", to: "collaborateurs", icon: <MdGroups title="Collaborateurs icône" /> },
    { label: "Gestion Effectifs", to: "effectif", icon: <MdGroups title="Effectif icône" /> },
    { label: "Formation", to: "formations_entreprise", icon: <FaChalkboardTeacher title="Formations icône" /> },
    // { label: "Ajout CV", to: "ajout_cv", icon: <BsCloudUpload title="Ajout cv icône" /> },
    { label: "Alliances", to: "alliances", icon: <BsFillDiagram3Fill title="Alliances icône" /> },
    { label: "Cvthèque", to: "cv_theque", icon: <MdGroups title="cvtheque icône" /> },
    { label: "Recrutements", to: "recrutements2", icon: <RiInboxArchiveFill title="Recrutements icône" /> },
    { label: "Reclassements", to: "reclassements", icon: <RiInboxUnarchiveFill title="Reclassements icône" /> },
    // { label: "Messagerie", to: "messagerie", icon: <IoMdMail title="Messagerie icône" /> },
    { label: "Messagerie", to: "messagerie2", icon: <IoMdMail title="Messagerie icône" /> },

    //{ label: "Formations", to: "formations", icon: <IoMdSchool title="Formations icône" /> },
    //{ label: "Actualités économiques", to: "actualitesEconomiques", icon: <BsJournalText title="Actualités économiques icône" /> },
  ];

  const queryClient = useQueryClient();

  return (
    <div className="EntrepriseApp">
      <Routes>
        <Route
          element={
            <MainDesktopLayout
              type="Entreprise"
              menuLinks={menuLinks}
              disconnectionCallback={() => logout(queryClient)}
              userLogo={
                memberQuery.isLoading ? (
                  <MbjLoading height={"50%"} />
                ) : memberQuery.isError ? (
                  <p>Error !</p>
                ) : memberQuery.data?.logo !== null ? (
                  <img src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${memberQuery.data.logo}`} />
                ) : (
                  <FaUserCircle className={"icon-profile-menu"} />
                )
              }
            />
          }
        >
          <Route path="" element={<Navigate to="accueil" />} />
          <Route path="/accueil" element={<Dashboard />} />
          <Route path="/entreprise/*" element={<Entreprise />} />
          <Route path="/collaborateurs/*" element={<Collaborateurs />} />
          <Route path="/effectif/*" element={<EffectifRoot />} />
          {/*<Route path="/ajout_cv" element={<AjoutCV />} />*/}
          <Route path="/formations_entreprise/*" element={<FormationEntrepriseRoot />} />
          <Route path="/formations/*" element={<Formations />} />
          <Route path="/alliances/*" element={<Alliances />} />
          <Route path={"/cv_theque/*"} element={<CvTheque />} />
          <Route path="/recrutements/*" element={<Recrutements />} />
          <Route path="/recrutements2/*" element={<Recrutements2 />} />
          <Route path="/reclassements/*" element={<Reclassements />} />
          {/*<Route path="/actualitesEconomiques" element={<ActualitesEconomiques />} />*/}
          <Route path="/messagerie/*" element={<Messagerie />} />
          <Route path="/messagerie2/*" element={<Messagerie2 />} />
          <Route path="/mentions" element={<LegalMentions />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
