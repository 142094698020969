import {
  MbjAvatar,
  MbjButton,
  MbjFlex,
  MbjHeading,
  MbjLoading,
  MbjModal,
  MbjScrollArea,
  MbjSearchBar,
  MbjSimpleInput,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useAddRoom, useGetAllMember } from "@mbj-front-monorepo/data-access";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface NewConversationModalProps {
  className?: string;
  isShowing: boolean;
  hide: () => void;
}

const NewConversationModalCtrl = (props: NewConversationModalProps) => {
  const membersQuery = useGetAllMember();
  const mutationAddRoom = useAddRoom();
  const [search, setSearch] = useState<string>("");

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  function atLeastOneMemberChecked(selectedMembers: string[]) {
    return selectedMembers.length > 0;
  }

  return (
    <MbjModal
      isShowing={props.isShowing}
      hide={props.hide}
      title={"Nouvelle conversation"}
      fitContent
      className={props.className}
    >
      {membersQuery.isLoading ? (
        <MbjLoading />
      ) : membersQuery.isError ? (
        <div>{membersQuery.error.message}</div>
      ) : (
        <MbjFlex direction={"column"} gap={"10px"}>
          <MbjSearchBar placeholder={"Rechercher un membre"} onChange={(e) => setSearch(e.target.value)} />
          <form
            onSubmit={handleSubmit((data) => {
              const selectedMembers = data.selectedMembers.map((userId: string) => parseInt(userId));
              mutationAddRoom.mutate(selectedMembers);
            })}
          >
            <MbjScrollArea maxHeight={"400px"}>
              <MbjFlex direction={"column"} gap={"10px"}>
                {membersQuery.data
                  ?.filter((member) => member.name?.toLowerCase().includes(search.toLowerCase()))
                  .map((member) => (
                    <MbjFlex align={"center"} key={member.id} className={"to_add_container"}>
                      <MbjAvatar
                        name={member.name}
                        bg={"grey"}
                        src={`${process.env.REACT_APP_API_URL_ROOT}/logos/members/${member.logo}`}
                        size={"md"}
                      />
                      <MbjHeading>{member.name}</MbjHeading>
                      <MbjSimpleInput
                        name={"selectedMembers"}
                        value={member.userId + ""}
                        register={register}
                        registerOptions={{
                          validate: atLeastOneMemberChecked,
                        }}
                        type={"checkbox"}
                        height={"20px"}
                        width={"20px"}
                      />
                    </MbjFlex>
                  ))}
              </MbjFlex>
            </MbjScrollArea>
            {errors.selectedMembers && <div className={"error"}>⚠︎ Vous devez sélectionner au moins un membre</div>}
            <MbjButton isPending={mutationAddRoom.isLoading}>Créer</MbjButton>
          </form>
        </MbjFlex>
      )}
    </MbjModal>
  );
};

export const NewConversationModal = styled(NewConversationModalCtrl)((props) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    button: {
      alignSelf: "flex-end",
    },

    ".error": {
      color: "red",
    },
  },

  ".to_add_container": {
    borderBottom: "1px solid black",
    paddingBottom: "5px",

    input: {
      marginLeft: "auto",
    },
  },
}));
