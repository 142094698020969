import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DefaultTheme } from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { getDateFR } from "@mbj-front-monorepo/utils";

interface EmployeeInfoPdfProps {
  employeeData?: Operation<"getEmployeeItem">;
  theme: DefaultTheme;
}
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
// Create styles
const styles = StyleSheet.create({
  employeeInfoContainer: {
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  title: {
    fontSize: 12,
    fontWeight: 900,
    marginBottom: 7,
    borderBottom: "1px solid grey",
  },

  employeeFieldContainer: {
    display: "flex",
    flexDirection: "row",
    width: "35%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  data: {
    fontSize: 10,
    fontWeight: "bold",
    maxLines: 4,
  },
  label: {
    fontSize: 10,
  },
});

// Create Document Component
export const EmployeeInfoPdf = (props: EmployeeInfoPdfProps) => {
  return (
    <View style={styles.employeeInfoContainer}>
      <Text style={[styles.title, styles.employeeFieldContainer]}>Collaborateur concerné :</Text>
      {props.employeeData?.nom && (
        <View style={styles.employeeFieldContainer}>
          <Text style={styles.label}>Nom : </Text>
          <Text style={styles.data}>{props.employeeData?.nom}</Text>
        </View>
      )}
      {props.employeeData?.prenom && (
        <View style={styles.employeeFieldContainer}>
          <Text style={styles.label}>Prénom : </Text>
          <Text style={styles.data}>{props.employeeData?.prenom}</Text>
        </View>
      )}
      {props.employeeData?.Service && (
        <View style={styles.employeeFieldContainer}>
          <Text style={styles.label}>Service : </Text>
          <Text style={styles.data}>{props.employeeData?.Service.libelle}</Text>
        </View>
      )}
      {props.employeeData?.UserMetier && (
        <View style={styles.employeeFieldContainer}>
          <Text style={styles.label}>Poste : </Text>
          <Text style={styles.data}>{props.employeeData?.UserMetier.namePerso}</Text>
        </View>
      )}
      {props.employeeData?.entryAt && (
        <View style={styles.employeeFieldContainer}>
          <Text style={styles.label}>Entrée le : </Text>
          <Text style={styles.data}>{getDateFR(new Date(props.employeeData.entryAt))}</Text>
        </View>
      )}
      {props.employeeData?.email && (
        <View style={styles.employeeFieldContainer}>
          <Text style={styles.label}>Email : </Text>
          <Text style={styles.data}>{props.employeeData?.email}</Text>
        </View>
      )}
      {props.employeeData?.adresse && (
        <View style={styles.employeeFieldContainer}>
          <Text style={styles.label}>Adresse : </Text>
          <Text style={styles.data}>
            {props.employeeData?.adresse} {props.employeeData?.codePostal} {props.employeeData?.ville}
          </Text>
        </View>
      )}
      {props.employeeData?.birthAt && (
        <View style={styles.employeeFieldContainer}>
          <Text style={styles.label}>Né le : </Text>
          <Text style={styles.data}>{new Date(props.employeeData.birthAt).toLocaleDateString()}</Text>
        </View>
      )}
    </View>
  );
};
