import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  MbjAccordion,
  MbjAccordionContent,
  MbjAccordionItem,
  MbjAccordionTrigger,
  MbjCardClassic,
  MbjLoading,
  MbjNavPath,
  MbjNoResult,
  MbjScrollArea,
  MbjWidgetClassic,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { CollabProfilItem } from "../MesRecherches/CollabProfilItem/CollabProfilItem";
import { useGetRecrutementSaveds } from "@mbj-front-monorepo/data-access";

interface ProfilsRetenusCtrlProps {
  className?: string;
}

function ProfilsRetenusCtrl(props: ProfilsRetenusCtrlProps) {
  const location = useLocation();
  const recrutementSavedQuery = useGetRecrutementSaveds(true);

  return (
    <div className={"ProfilsRetenus" + props.className}>
      <MbjNavPath routePath={location.pathname} />
      <MbjWidgetClassic title={"Profils retenus"}>
        {recrutementSavedQuery.isLoading ? (
          <MbjLoading />
        ) : recrutementSavedQuery.isError ? (
          <p>Erreur ...</p>
        ) : recrutementSavedQuery.data && recrutementSavedQuery.data.length > 0 ? (
          <MbjAccordion type="single" defaultValue="item-0" collapsible>
            {recrutementSavedQuery.data.map((recrutementSaved, idx: number) => (
              <MbjAccordionItem value={`item-${idx}`} key={recrutementSaved.id}>
                <MbjAccordionTrigger>
                  {recrutementSaved.Metier
                    ? recrutementSaved.Metier.libelle
                    : recrutementSaved.UserMetier
                    ? recrutementSaved.UserMetier.namePerso
                    : ""}
                </MbjAccordionTrigger>
                <MbjAccordionContent>
                  <MbjScrollArea maxHeight={"400px"}>
                    {recrutementSaved.MatchEmployees?.map((MatchEmployee, idx: number) =>
                      MatchEmployee.isSaved ? (
                        <CollabProfilItem
                          key={MatchEmployee.id}
                          MatchEmployee={MatchEmployee}
                          type={recrutementSaved.Metier ? "Metier" : "UserMetier"}
                          refMet={
                            recrutementSaved.Metier
                              ? recrutementSaved.Metier.id
                              : recrutementSaved.UserMetier
                              ? recrutementSaved.UserMetier.id
                              : null
                          }
                        />
                      ) : (
                        <React.Fragment key={MatchEmployee.id} />
                      )
                    )}
                  </MbjScrollArea>
                </MbjAccordionContent>
              </MbjAccordionItem>
            ))}
          </MbjAccordion>
        ) : (
          <MbjCardClassic>
            <MbjNoResult text={"Aucun profils retenus"} />
          </MbjCardClassic>
        )}
      </MbjWidgetClassic>
    </div>
  );
}
export const ProfilsRetenus = styled(ProfilsRetenusCtrl)((props) => ({
  padding: "1% 2%",
}));
