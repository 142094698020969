import React from "react";
import styled from "styled-components";
import { Column, Table } from "@tanstack/react-table";
import { MbjDebouncedInput } from "./MbjDeboucedInput";
import { MbjFlex } from "../../../atoms/MbjFlex/MbjFlex";

interface MbjTableFilterCtrlProps {
  className?: string;

  column: Column<any, unknown>;
  table: Table<any>;
}

export const MbjTableFilterCtrl = (props: MbjTableFilterCtrlProps) => {
  const firstValue = props.table.getPreFilteredRowModel().flatRows[0]?.getValue(props.column.id);

  const columnFilterValue = props.column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () => (typeof firstValue === "number" ? [] : Array.from(props.column.getFacetedUniqueValues().keys()).sort()),
    [props.column.getFacetedUniqueValues()]
  );

  return typeof firstValue === "number" ? (
    <MbjFlex direction={"row"} wrap={"nowrap"} className={props.className} justify={"center"} gap={"3px"}>
      <MbjDebouncedInput
        type="number"
        min={Number(props.column.getFacetedMinMaxValues()?.[0] ?? "")}
        max={Number(props.column.getFacetedMinMaxValues()?.[1] ?? "")}
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(value) => props.column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
        placeholder={`Min ${
          props.column.getFacetedMinMaxValues()?.[0] ? `(${props.column.getFacetedMinMaxValues()?.[0]})` : ""
        }`}
      />
      <MbjDebouncedInput
        type="number"
        min={Number(props.column.getFacetedMinMaxValues()?.[0] ?? "")}
        max={Number(props.column.getFacetedMinMaxValues()?.[1] ?? "")}
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(value) => props.column.setFilterValue((old: [number, number]) => [old?.[0], value])}
        placeholder={`Max ${
          props.column.getFacetedMinMaxValues()?.[1] ? `(${props.column.getFacetedMinMaxValues()?.[1]})` : ""
        }`}
      />
    </MbjFlex>
  ) : (
    <MbjFlex className={props.className} justify={"center"}>
      <datalist id={props.column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <MbjDebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => props.column.setFilterValue(value)}
        placeholder={`Chercher (${props.column.getFacetedUniqueValues().size})`}
        list={props.column.id + "list"}
      />
    </MbjFlex>
  );
};

export const MbjTableFilter = styled(MbjTableFilterCtrl)((props) => ({
  marginTop:"7px",
  'input[type="text"]': {
    width: "8rem",
    flexGrow: 1,
  },
  'input[type="number"]': {
    flexGrow: 1,
  },
}));

MbjTableFilter.defaultProps = {};
