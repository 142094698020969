import * as React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  createSelectColumn,
  MbjButton,
  MbjFlex,
  MbjLink,
  MbjLoading,
  MbjNavPath,
  MbjScrollArea,
  MbjTableV2,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import { AjoutMetierModal } from "./AjoutMetierModal/AjoutMetierModal";
import {useDuplicateUserMetier, useGetUserMetierStatistiques} from "@mbj-front-monorepo/data-access";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { GoPlus } from "react-icons/go";
import {AjoutMetierByScanModal} from "./AjoutMetierByScanModal";

const Titles = [
  { libelle: "Nom du métier", Align: "isLeft" },
  { libelle: "Effectif", Align: "isCenter" },
  { libelle: "Le plus ancien", Align: "isCenter" },
  { libelle: "Le plus récent", Align: "isCenter" },
  { libelle: "Age médian", Align: "isCenter" },
  { libelle: "Salaire moyen", Align: "isCenter" },
  //{ libelle: 'Format Cible', Align: 'isCenter' },
];
interface EntrepriseMetiersCtrlProps {
  className?: string;
}

function EntrepriseMetiersCtrl(props: EntrepriseMetiersCtrlProps) {
  const location = useLocation();
  const mutationDuplicate = useDuplicateUserMetier();
  const userMetierSatistiquesQuery = useGetUserMetierStatistiques();
  const { isShowing: ajoutIsShowing, toggle: ajoutToggle } = useModal();
  const { isShowing: isShowingFDP, toggle: toggleFDP } = useModal();

  const columnHelper = createColumnHelper<GetElementType<typeof userMetierSatistiquesQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.accessor((row) => row.UserMetier?.namePerso || "--", {
      header: "Nom",
      cell: (info) => <MbjLink to={info.row.original.UserMetier?.id?.toString() || "0"}>{info.getValue()}</MbjLink>,
    }),
    columnHelper.accessor((row) => row.effectif, {
      header: "Effectif",
    }),
    columnHelper.accessor((row) => row.older, {
      header: "Le plus ancien",
      cell: (info) => {
        const value = info.getValue();
        return value ? getDateFR(new Date(value)) : "--";
      },
    }),
    columnHelper.accessor((row) => row.younger, {
      header: "Le plus récent",
      cell: (info) => {
        const value = info.getValue();
        return value ? getDateFR(new Date(value)) : "--";
      },
    }),
    columnHelper.accessor((row) => row.ageMedian, {
      header: "Age médian",
      cell: (info) => info.getValue() || "--",
    }),
    columnHelper.accessor((row) => row.avgSalary, {
      header: "Salaire moyen",
      cell: (info) => info.getValue() || "--",
    }),
  ];

  const table = useReactTable({
    data: userMetierSatistiquesQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    initialState: {
      sorting: [{ id: "Effectif", desc: true }],
    },
    debugTable: false,
  });
  const DuplicateMetier = ()=>{
    const lineSelected = table.getSelectedRowModel().rows[0];
    if(lineSelected){
      const UserMetier = lineSelected.original.UserMetier;
      if(UserMetier) {
        mutationDuplicate.mutate({UserMetier: {id: UserMetier.id}})
      }
    }

  }
  return (
    <div className={"EntrepriseMetiers" + props.className}>
      <MbjNavPath
        routePath={location.pathname}
        noMargin
        changeLabel={{
          index: 2,
          label: "Métiers",
        }}
      />
      <AjoutMetierModal isShowing={ajoutIsShowing} hide={ajoutToggle} />
      <MbjWidgetClassic title="Fiches métiers" bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {userMetierSatistiquesQuery.isLoading ? (
          <MbjLoading />
        ) : userMetierSatistiquesQuery.isError ? (
          <p>Error: {userMetierSatistiquesQuery.error.message}</p>
        ) : (
          <>
            <div className={"space-between"}>
              <div className={"ResultNumber"}>{table.getRowModel().rows.length} résultats</div>
              <div style={{display:"flex", justifyContent:"flex-end", gap:"10px"}}>
                {(table.getIsSomeRowsSelected() && table.getSelectedRowModel().rows.length === 1) && (
                  <MbjButton themeColor={"warning"} onClick={DuplicateMetier} isPending={false}>
                    Dupliquer le métier
                  </MbjButton>
                )}
                <MbjButton onClick={ajoutToggle} leftIcon={<GoPlus />}>
                  Ajouter un métier
                </MbjButton>
                <MbjButton onClick={toggleFDP} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
                  Scanner une fiche de poste
                </MbjButton>
              </div>
            </div>
            <MbjScrollArea overflowX={"auto"}>
              <MbjTableV2 table={table} Multiple={true}/>
            </MbjScrollArea>
          </>
        )}
      </MbjWidgetClassic>
      <AjoutMetierByScanModal isShowing={isShowingFDP} hide={toggleFDP}/>
    </div>
  );
}
export const EntrepriseMetiers = styled(EntrepriseMetiersCtrl)((props) => ({
  padding: "1% 2%",
  display: "flex !important",
  flexDirection: "column",
  height: "100%",
  ".ResultNumber":{
    fontSize:"20px"
  },
  ".space-between":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  }
}));
