import styled from "styled-components";
import {
  MbjAvatar,
  MbjFlex,
  MbjHeading,
  MbjIconButton,
  MbjLinkBox,
  MbjLinkOverlay,
  MbjModal,
  useModal,
} from "@mbj-front-monorepo/ui";
import { IoSettings } from "react-icons/io5";
import React from "react";
import { Operation } from "@mbj-front-monorepo/data-access";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";

interface ConversationsPreviewCtrlProps {
  Room: GetElementType<Operation<"getRoomCollection">>;
  className?: string;
  active?: boolean;
}

const ConversationsPreviewCtrl = (props: ConversationsPreviewCtrlProps) => {
  const { isShowing: showConfigConversation, toggle: toggleConfigConversation } = useModal();

  return (
    <MbjLinkBox className={props.className}>
      <MbjFlex gap={"8px"} wrap={"nowrap"}>
        <MbjAvatar
          name={props.Room.Members?.at(0)?.name}
          bg={"grey"}
          src={`${process.env.REACT_APP_API_URL_ROOT}/logos/members/${props.Room.Members?.at(0)?.logo}`}
          size={"md"}
        />
        <MbjFlex direction={"column"} gap={"10px"} width={"80%"}>
          <MbjFlex justify={"space-between"} gap={0}>
            <MbjLinkOverlay className={`name-place`} to={"?id=" + props.Room.id}>
              <MbjHeading level={"h3"} size={"xs"}>
                {props.Room.Members?.map((member) => member.name).join(", ")}
              </MbjHeading>
            </MbjLinkOverlay>
            <div>
              {props.Room.lastConversation
                ? getDateFR(new Date(props.Room.lastConversation.created_at || ""))
                : getDateFR(new Date(props.Room.created_at || ""))}
            </div>
          </MbjFlex>
          <MbjFlex justify={"space-between"} gap={0} wrap={"nowrap"}>
            <span className={"last-mesage-preview"}>{props.Room.lastConversation?.content || "Aucun message"}</span>
            <MbjIconButton
              icon={<IoSettings />}
              ariaLabel={"option conversations"}
              size={"xs"}
              isRound
              themeColor={"grey"}
              onClick={toggleConfigConversation}
            />
          </MbjFlex>
        </MbjFlex>
      </MbjFlex>
      <MbjModal
        isShowing={showConfigConversation}
        hide={toggleConfigConversation}
        title={"Options de la conversation"}
        fitContent
      ></MbjModal>
    </MbjLinkBox>
  );
};

export const ConversationsPreview = styled(ConversationsPreviewCtrl)((props) => ({
  backgroundColor: props.theme.background2,
  padding: "10px 5px",
  borderRadius: !props.active ? "16px" : "0px",
  cursor: "pointer",
  width: "100%",

  borderLeft: `8px solid ${props.active ? props.theme.primary : "transparent"}`,

  "&:hover": {
    outline: "2px solid " + props.theme.primary,
    outlineOffset: "-2px",
  },

  ".last-mesage-preview": {
    fontStyle: "italic",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));
