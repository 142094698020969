import * as React from "react";
import styled from "styled-components";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { InternDesktopLayout, MbjLink, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { NotFound } from "../NotFound/NotFound";
import { BoiteReception } from "./BoiteReception/BoiteReception";
import { MessageEnvoyes } from "./MessagesEnvoyes/MessagesEnvoyes";
import { Corbeille } from "./Corbeille/Corbeille";

interface MessagerieCtrlProps {}

function MessagerieCtrl(props: MessagerieCtrlProps) {
  const location = useLocation();
  const state = location.state ? location.state : null;
  return (
    <div className="Messagerie">
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              mbjSectionMenu={
                <MbjSectionMenu>
                  <MbjLink to="boite-reception">Boite de réception</MbjLink>
                  <MbjLink to="message-envoyes">Message envoyés</MbjLink>
                  <MbjLink to="corbeille">Corbeille</MbjLink>
                </MbjSectionMenu>
              }
            />
          }
        >
          <Route path="" element={<Navigate to="boite-reception" state={state} />} />
          <Route path="/boite-reception" element={<BoiteReception />} />
          <Route path="/message-envoyes" element={<MessageEnvoyes />} />
          <Route path="/corbeille" element={<Corbeille />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
export const Messagerie = styled(MessagerieCtrl)((props) => ({}));
