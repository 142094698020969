import { useQuery } from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { fetchApi } from "../../services/fetchApi";

export const useGetRepartitionMetiers = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/member_repartition_metiers", { method: "get", query: { Member: memberId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member", memberId, "repartitionMetiers"], promise, {
    enabled: !!memberId,
  });
};
