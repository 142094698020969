import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useGetMemberMe } from "../member";
import { fetchApi } from "../../services/fetchApi";

export const useGetRecrutementSaveds = (matchEmployeesSavedFilter?: boolean) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/recrutement_saveds", {
      method: "get",
      query: { Member: memberId + "", "MatchEmployees.isSaved": matchEmployeesSavedFilter },
    });
  return useQuery(["recrutement_saveds", { memberId, matchEmployeesSavedFilter }], promise, {
    enabled: !!memberId,
  });
};

export const useGetRecrutementSaved = (recrutementSavedId: number) => {
  const promise = () =>
    fetchApi("/api/recrutement_saveds/{id}", { method: "get", params: { id: recrutementSavedId + "" } });
  return useQuery(["recrutement_saveds", { recrutementSavedId }], promise, {});
};

export const usePostRecrutementSaved = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();

  return useMutation(
    (data: { Metier: string } | { UserMetier: string }) => {
      return fetchApi("/api/recrutement_saveds", {
        method: "post",
        json: { ...data, Member: "/api/members/" + memberQuery.data?.id },
      });
    },
    {
      onSuccess: (newSaved) => {
        queryClient.invalidateQueries(["recrutement_saveds", { memberId: memberQuery.data?.id }]);
        queryClient.setQueryData(["recrutement_saveds", { recrutementSavedId: newSaved.id }], newSaved);
        toast.success("Recherche enregistrée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteRecrutementSaved = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  return useMutation(
    (recrutementSavedId: number | number[]) => {
      if (Array.isArray(recrutementSavedId)) {
        return Promise.all(
          recrutementSavedId.map((id) =>
            fetchApi("/api/recrutement_saveds/{id}", { method: "delete", params: { id: id + "" } })
          )
        );
      }
      return fetchApi("/api/recrutement_saveds/{id}", { method: "delete", params: { id: recrutementSavedId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["recrutement_saveds", { memberId: memberQuery.data?.id }]);
        toast.success("Recherche supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
