import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DefaultTheme } from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { EntretienAnnuelFormData } from "../../EntretienAnnuelNewForm";

interface EvenementsMarquantsPdfProps {
  formData?: EntretienAnnuelFormData;
  eventsPossibles?: Operation<"getTypeEvtsMarquantCollection">;
  theme: DefaultTheme;
}

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create Document Component
export const EvenementsMarquantsPdf = (props: EvenementsMarquantsPdfProps) => {
  function getEventLibelle(eventId: number): string | undefined {
    if (!props.eventsPossibles) {
      console.log("not sdfsd", props.eventsPossibles);
      return;
    }
    const event = props.eventsPossibles.find((event) => event.id === eventId);
    return event?.libelle;
  }

  // Create styles
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      borderWidth: 1,
      borderColor: props.theme.primary,
    },
    headerContainer: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      backgroundColor: props.theme.primaryLighten,
      borderBottomWidth: 1,
      alignItems: "stretch",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
      fontSize: 10,
    },

    type: {
      width: "40%",
      padding: "0px 8px",
    },
    precision: {
      width: "60%",
      padding: "0px 8px",
    },
    row: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      fontSize: 10,
      fontStyle: "bold",
    },
    cell: {
      borderRightColor: props.theme.primary,
      borderRightWidth: 1,
      alignItems: "center",
      minHeight: 24,
    },
  });

  if (!props.formData || !props.formData.Evts) {
    return <React.Fragment />;
  }
  return (
    <View style={styles.tableContainer}>
      <View style={styles.headerContainer}>
        <View style={[styles.type, styles.cell, { justifyContent: "center" }]}>
          <Text>Type d'évènement</Text>
        </View>
        <View style={[styles.precision, styles.cell, { justifyContent: "center" }]}>
          <Text>Précision</Text>
        </View>
      </View>
      {props.formData.Evts.map((event, index) => (
        <View style={styles.row} key={index} wrap={false}>
          <View style={[styles.type, styles.cell]}>
            <Text>{getEventLibelle(event.type || -1)}</Text>
          </View>
          <View style={[styles.precision, styles.cell]}>
            <Text>{event.coment}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};
