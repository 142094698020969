import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {MbjCirclePercent} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface ProximitePartUserMetierCardCtrlProps{
  className?:string;
  Proximite: components["schemas"]["ParticulierProxiUserMetier-read.partproxiusermetier_read.UserMetier_read.Metier"]
  ClickOnIt:(id:number)=>void;
}

const ProximitePartUserMetierCardCtrl = (props:ProximitePartUserMetierCardCtrlProps)=>{
  if(!props.Proximite || !props.Proximite.score){
    return null;
  } else {
  return (
      <div className={`proxiCard ${props.className}`} onClick={()=>props.ClickOnIt(props.Proximite.UserMetier && props.Proximite.UserMetier.id ? props.Proximite.UserMetier.id : 0)}>
        <div className={"content_proxi"}>
          <div className={`score`}>
            <MbjCirclePercent
              percent={Math.round((props.Proximite.score)*100)}
            />
          </div>
          <div className={`name_metier`}>{props.Proximite.UserMetier?.namePerso}</div>
        </div>
      </div>
    )
  }
}

export const ProximitePartUserMetierCard = styled(ProximitePartUserMetierCardCtrl)`
  width: 50%;
  padding: 1rem;
  .content_proxi{
    background: white;
    padding: 0.5rem;
    display: flex;
    border-radius: 8px;
    justify-content: flex-start;
    align-items: center;
  }
  .name_metier{
    font-size: 19px;
    flex-grow: 1;
  }
  .score{
    width: 70px;
    height: 70px;
    flex-shrink: 0;
  }
`
