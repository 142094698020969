import styled, { keyframes } from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { BsFillBellFill } from "react-icons/bs";
import { MbjNotifRubbon } from "./MbjNotifRubbon";
import { Operation, useGetAllNotifications, useMe } from "@mbj-front-monorepo/data-access";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

interface AlertNotificationsCtrlProps {
  className?: string;
}
//process.env["REACT_APP_DEFAULT_THEME"]
const url = `${process.env["REACT_APP_MERCURE_URL"]}?topic=mbj/notifications`;

interface NotificationMBJ {
  idNotif: number;
  type: string;
  content: string;
  idUserTo: number;
}
const AlertNotificationsCtrl = (props: AlertNotificationsCtrlProps) => {
  const [newNotification, setNewNotification] = useState(false);
  const [haveNew, setHaveNew] = useState(false);
  const queryClient = useQueryClient();
  const MeQuery = useMe();
  const notificationsQuery = useGetAllNotifications();
  const [myNotification, setMyNotification] = useState<Operation<"getNotificationsItem">[]>([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (notificationsQuery.data) {
      setMyNotification(notificationsQuery.data);
      setHaveNew(notificationsQuery.data.filter((n) => !n.readAt).length > 0);
    }
  }, [notificationsQuery.data]);
  const Switch = () => {
    setNewNotification(false);
    setOpen((o) => !o);
  };
  const handleNotification = useCallback(
    (notif: NotificationMBJ) => {
      if (MeQuery.data) {
        console.log(MeQuery.data);
        if (notif.idUserTo === MeQuery.data[0].id) {
          const Exist = myNotification.find((n) => n.id === notif.idNotif);
          if (!Exist) {
            queryClient.invalidateQueries(["notifications"]);
            setNewNotification(true);
            toast.info("Nouvelle notification", {
              position: toast.POSITION.BOTTOM_RIGHT,
              icon: false,
            });
          }
        }
      }
    },
    [myNotification, queryClient, MeQuery.data]
  );

  useEffect(() => {
    const eventSource = new EventSource(url);
    eventSource.onmessage = (event) => {
      const myNotification: NotificationMBJ = JSON.parse(event.data);
      handleNotification(myNotification);
    };
  }, [handleNotification]);
  return (
    <div className={`alert_notif ${props.className} ${haveNew ? "alerte" : ""} ${newNotification ? "ring" : ""}`}>
      <div className={`wrap-bell`} onClick={Switch}>
        <BsFillBellFill />
      </div>
      <MbjNotifRubbon open={open} closeMe={() => setOpen(false)} />
    </div>
  );
};

const Ring = keyframes`
  0% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(-30deg);
  }
`;

export const AlertNotifications = styled(AlertNotificationsCtrl)`
  width: 42px;
  height: 42px;
  background: white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  &.ring {
    .wrap-bell {
      animation: ${Ring} 0.3s infinite;
    }
  }
  &.alerte {
    .wrap-bell {
      svg {
        color: ${(props) => props.theme.warning};
      }
    }
  }
  .wrap-bell {
    margin: auto;
    transform-origin: 50% 0;
    width: 20px;
    svg {
      font-size: 20px;
    }
  }
`;
