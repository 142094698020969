import React from 'react';
import styled from 'styled-components';

interface MbjLabelCtrlProps {
  /**
   * Classe HTML du badge ?
   */
  className?: string;
  /**
   * The HTMLLabelElement.htmlFor property reflects the value of the for content property. That means that this script-accessible property is used to set and read the value of the content property for, which is the ID of the label's associated control element.
   */
  htmlFor?: string;
  /**
   * Contenu du label
   */
  text?: string;
  children?: React.ReactNode;
  /**
   * Taille du label
   */
  size?: 'small' | 'medium' | 'big' | 'very-small';
  /**
   * Couleur
   */
  themeColor?: 'white' | 'light' | 'dark' | 'primary';
}
function MbjLabelCtrl({ ...props }: MbjLabelCtrlProps) {
  return (
    <label className={props.className} htmlFor={props.htmlFor}>
      {props.text || props.children}
    </label>
  );
}

/**
 * Composant label :
 */
export const MbjLabel = styled(MbjLabelCtrl)((props) => ({
  fontSize:
    props.size === 'big'
      ? 'calc(14px + 1.2vmin)'
      : props.size === 'medium'
      ? 'calc(11px + 1vmin)'
      : props.size === 'small'
      ? 'calc(9px + 0.9vmin)'
      : props.size === 'very-small'
      ? 'calc(7.5px + 0.6vmin)'
      : '16px',
  color:
    props.themeColor === 'white'
      ? props.theme.background2
      : props.themeColor === 'light'
      ? props.theme.primaryPastel
      : props.themeColor === 'dark'
      ? props.theme.dark
      : props.themeColor === 'primary'
      ? props.theme.primaryDarken
      : 'none',
  fontWeight: 600,
}));
MbjLabel.defaultProps = {
  size: 'small',
  themeColor: 'light',
};
