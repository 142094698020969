import * as React from "react";
import styled from "styled-components";
import { Navigate, Route, Routes } from "react-router-dom";
import { InternDesktopLayout, MbjLink, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { NotFound } from "../NotFound/NotFound";
import { Resume } from "./Resume/Resume";
import { Recherche } from "./Recherche/Recherche";
import { MesRecherches } from "./MesRecherches/MesRecherches";
import { ProfilsRetenus } from "./ProfilsRetenus/ProfilsRetenus";

interface RecrutementsCtrlProps {}

function RecrutementsCtrl(props: RecrutementsCtrlProps) {
  return (
    <div className="Recrutements">
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              mbjSectionMenu={
                <MbjSectionMenu>
                  <MbjLink to="resume">Résumé</MbjLink>
                  <MbjLink to="recherche">Recherche</MbjLink>
                  <MbjLink to="profils-retenus">Profils retenus</MbjLink>
                </MbjSectionMenu>
              }
            />
          }
        >
          <Route path="" element={<Navigate to="resume" />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/recherche" element={<Recherche />} />
          <Route path="/resume/:id" element={<MesRecherches />} />
          <Route path="/profils-retenus" element={<ProfilsRetenus />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
export const Recrutements = styled(RecrutementsCtrl)((props) => ({}));
