import * as React from "react";
import styled from "styled-components";
import { Navigate, Route, Routes } from "react-router-dom";
import { InternDesktopLayout, MbjLink, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { NotFound } from "../NotFound/NotFound";
import { FormationsFind } from "./Find/FormationsFind";
import { FormationsIoga } from "./Ioga/FormationsIoga";
import { FormationsSessions } from "./Sessions/FormationsSessions";

interface FormationsCtrlProps {}

function FormationsCtrl(props: FormationsCtrlProps) {
  return (
    <div className="Formations">
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              mbjSectionMenu={
                <MbjSectionMenu>
                  <MbjLink to="recherche">Touver une formation</MbjLink>
                  <MbjLink to="iogas">Vidéos IOGA</MbjLink>
                  <MbjLink to="sessions">Sessions de formation</MbjLink>
                </MbjSectionMenu>
              }
            />
          }
        >
          <Route path="" element={<Navigate to="recherche" />} />
          <Route path="/recherche" element={<FormationsFind />} />
          <Route path="/iogas" element={<FormationsIoga />} />
          <Route path="/sessions" element={<FormationsSessions />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
export const Formations = styled(FormationsCtrl)((props) => ({}));
