import * as React from "react";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjListItem,
  MbjLoading,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useGetUserMetiers } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos sur les métiers de l'entreprise :
 */
interface MetiersCardCtrlProps extends MbjCardClassicCtrlProps {}

function MetiersCardCtrl(props: MetiersCardCtrlProps) {
  const userMetiersQuery = useGetUserMetiers();

  return (
    <MbjCardClassic {...props} className={props.className} noPadding title="Métiers">
      {userMetiersQuery.isLoading ? (
        <MbjLoading />
      ) : userMetiersQuery.isError ? (
        <p>Error !</p>
      ) : (
        <MbjScrollArea maxHeight={"250px"}>
          {userMetiersQuery.data?.map((userMetier, i: number) => {
            return (
              <MbjListItem key={userMetier.id}>
                <span>{userMetier.namePerso}</span>
              </MbjListItem>
            );
          })}
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
}
export const MetiersCard = styled(MetiersCardCtrl)((props) => ({

}));
