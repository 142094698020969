import * as React from "react";
import styled from "styled-components";
import { RepartitionN } from "./RepartitionChart/RepartitionN";
import { MbjCardClassic, MbjCardClassicCtrlProps } from "@mbj-front-monorepo/ui";

/**
 * Carte contenant des infos sur l'expertise de l'entreprise :
 */
interface RepartitionMetiersCardCtrlProps extends MbjCardClassicCtrlProps {}

function RepartitionMetiersCardCtrl(props: RepartitionMetiersCardCtrlProps) {
  return (
    <MbjCardClassic
      {...props}
      title="Répartition des métiers de l'entreprise"
      className={props.className}
      grow={props.grow}
    >
      <RepartitionN />
    </MbjCardClassic>
  );
}
export const RepartitionMetiersCard = styled(RepartitionMetiersCardCtrl)((props) => ({}));
