import * as React from "react";
import styled from "styled-components";

interface MbjWidgetBodyCtrlProps {
  /**
   * Classe HTML de l'élément?
   */
  className?: string;
  /**
   * Corps de la card ?
   */
  children?: React.ReactNode;
}

function MbjWidgetBodyCtrl(props: MbjWidgetBodyCtrlProps) {
  return <div className={`MbjWidgetBody ${props.className}`}>{props.children}</div>;
}

export const MbjWidgetBody = styled(MbjWidgetBodyCtrl)((props) => ({
  paddingTop:"8px",
  flexGrow:1,
}));
MbjWidgetBody.defaultProps = {};
