import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useGetEntretienAnnuels = (employeeId?: number) => {
  const promise = () => fetchApi("/api/entretien_annuels", { method: "get", query: { Employee: employeeId + "" } });
  return useQuery(["entretien_annuels", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};

export const useAddNewEntretien = (employeeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postEntretienAnnuelCollection", "requestBody">) => {
      return fetchApi("/api/entretien_annuels", {
        method: "post",
        json: { ...data, Employee: "/api/employees/" + employeeId },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["entretien_annuels", { employeeId }]);
        toast.success("Entretien créé !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
