import * as React from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import { MesCollaborateurs } from "./MesCollaborateurs/MesCollaborateurs";
import { NotFound } from "../NotFound/NotFound";
import { CollaborateurDetail } from "./CollaborateurDetail/CollaborateurDetail";
import { InternDesktopLayout } from "@mbj-front-monorepo/ui";

interface CollaborateursCtrlProps {}

function CollaborateursCtrl(props: CollaborateursCtrlProps) {
  return (
    <div className="Collaborateurs">
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              /*
                            mbjSectionMenu={
                                <MbjSectionMenu
                                    otherContent={
                                        <React.Fragment>
                                            <MbjButton themeColor="secondary" size="small">
                                                Ajouter à une session de formation
                                            </MbjButton>
                                        </React.Fragment>
                                    }
                                />
                            }
                            */
            />
          }
        >
          <Route path="" element={<MesCollaborateurs />} />
        </Route>
        <Route path="/:id/*" element={<CollaborateurDetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
export const Collaborateurs = styled(CollaborateursCtrl)((props) => ({}));
