import styled from "styled-components";
import {
  components,
  useGetAllStatutResultsSearch,
  useGetSearchOne, useModifyStatutSearchEmp, useModifyStatutSearchPart,
  useModifyStatutSearchRecl
} from "@mbj-front-monorepo/data-access";
import {InformationsParticulier} from "../../../Result/Comparaison/InformationsParticulier";
import * as React from "react";
import {InformationsReclassement} from "../../../Result/Comparaison/InformationsReclassement";
import {InformationsEmployee} from "../../../Result/Comparaison/InformationsEmployee";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {GestionNoteForPart} from "../NotesFor/GestionNoteForPart";
import {GestionNoteForRecl} from "../NotesFor/GestionNoteForRecl";
import {GestionNoteForEmp} from "../NotesFor/GestionNoteForEmp";
import {useEffect, useState} from "react";
import {SendMessageRetenu} from "../NotesFor/SendMessageRetenu";

interface GereSaveProfilCtrlProps{
  className?:string;
  ResultEmp:components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"]|null;
  ResultPart:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|null;
  ResultRecl:components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|null;
}

const GereSaveProfilCtrl = (props:GereSaveProfilCtrlProps)=>{
  const mutationStatutRecl = useModifyStatutSearchRecl(props.ResultRecl ? props.ResultRecl.id||0 : 0)
  const mutationStatutPart = useModifyStatutSearchPart(props.ResultPart ? props.ResultPart.id||0 : 0)
  const mutationStatutEmp = useModifyStatutSearchEmp(props.ResultEmp ? props.ResultEmp.id||0 : 0)
  const StatutQuery = useGetAllStatutResultsSearch();
  const [idStatutCurrent, setIdStatutCurrent] = useState<number>(0);
  const SearchQuery = useGetSearchOne(props.ResultRecl ? props.ResultRecl.RecrutementSaved?.id||0 : props.ResultPart ? props.ResultPart.RecrutementSaved?.id||0 :  props.ResultEmp ? props.ResultEmp.RecrutementSaved?.id||0 : 0)
  useEffect(()=>{
    if(props.ResultEmp){
      setIdStatutCurrent(props.ResultEmp.statut?.id||0)
    } else if(props.ResultPart){
      setIdStatutCurrent(props.ResultPart.Statut?.id||0)
    } else if(props.ResultRecl){
      setIdStatutCurrent(props.ResultRecl.statut?.id||0)
    }
  }, [props.ResultEmp, props.ResultPart, props.ResultRecl])
  const ClickChoiceStatut = (statut:components["schemas"]["StatutResultsSearch-statutresult.read"])=>{
    setIdStatutCurrent(statut.id||0);
    if(props.ResultEmp){
      mutationStatutEmp.mutate({statut:`/api/statut_results_searches/${statut.id||0}`})
    } else if(props.ResultPart){
      mutationStatutPart.mutate({Statut:`/api/statut_results_searches/${statut.id||0}`})
    } else if(props.ResultRecl){
      mutationStatutRecl.mutate({statut:`/api/statut_results_searches/${statut.id||0}`})
    }
  }
  return (
    <div className={`gere_save_profil ${props.className}`}>
      <div className={"profil_place block_infos"}>
        <h3>Gestion du statut</h3>
        <div className={"content_block"}>
          <div className={"wrap_choice_statut"}>
            {StatutQuery.isLoading ? <MbjLoading/> : StatutQuery.isError ? 'Error':
              StatutQuery.data?.map(item=>(
                <div onClick={()=>ClickChoiceStatut(item)} className={`chips_statut ${item.id === idStatutCurrent ? 'is_current' : ''}`} key={"StatutChoice"+item.id}>{item.libelle}</div>
              ))
            }
          </div>
        </div>
      </div>
      <div className={"profil_place block_infos"}>
        <h3>Envoyer un message</h3>
        <SendMessageRetenu
          idUser={props.ResultPart ? props.ResultPart.Particulier?.userId : props.ResultRecl ? props.ResultRecl?.Reclassement?.UserMetier?.Member?.userId : props.ResultEmp?.Employee?.userId}
          email={props.ResultPart ? props.ResultPart.Particulier?.email : props.ResultRecl ? props.ResultRecl?.Reclassement?.UserMetier?.Member?.email : props.ResultEmp?.Employee?.email}
        />
      </div>
        <div className={"profil_place block_infos"}>
          <h3>Informations sur le résultats</h3>
          <div className={"content_block"}>
            {props.ResultPart ?
              <InformationsParticulier idPart={props.ResultPart.Particulier?.id||-1}/>:
              props.ResultRecl?
                <InformationsReclassement idRecl={props.ResultRecl?.Reclassement?.id||-1}/>:
                <InformationsEmployee idEmpl={props.ResultEmp?.Employee?.id||-1} isShort/>
            }
          </div>
        </div>

      <div className={"infos_search_place block_infos"}>
        <h3>Informations sur la rechecher</h3>
        <div className={"content_block"}>
          {SearchQuery.isLoading ? <MbjLoading/> : SearchQuery.isError ? 'error' :
            <>
              <div className={"one_infos"}>
                <span className={"lib_infos"}>Création de la recherche</span>
                <span className={"infos"}>{new Date(SearchQuery.data && SearchQuery.data.creationAt ? SearchQuery.data.creationAt : "1970-01-01").toLocaleDateString()}</span>
              </div>
              <div className={"one_infos"}>
                <span className={"lib_infos"}>Dernière mise à jour</span>
                <span className={"infos"}>{new Date(SearchQuery.data && SearchQuery.data.updateAt ? SearchQuery.data.updateAt : "1970-01-01").toLocaleDateString()} {new Date(SearchQuery.data && SearchQuery.data.updateAt ? SearchQuery.data.updateAt : "1970-01-01").toLocaleTimeString()}</span>
              </div>
            </>
          }
        </div>
      </div>
      <div className={"notes_place block_infos"}>
        <h3>Vos notes pour ce profil</h3>
        <div className={`content_block`}>
          {props.ResultPart ?
            <GestionNoteForPart idResult={props.ResultPart?.id||0}/>:
            props.ResultRecl?
              <GestionNoteForRecl idResult={props.ResultRecl.id||0}/>:
              <GestionNoteForEmp idResult={props.ResultEmp?.id||0}/>
          }
        </div>
      </div>
    </div>
  )
}

export const GereSaveProfil = styled(GereSaveProfilCtrl)`
  padding: 1% 2%;
  max-height: 75vh;
  overflow-y: auto;
  .wrap_choice_statut{
    display: flex;
    justify-content: flex-start;
    gap:10px;
    .chips_statut{
      width: max-content;
      padding: 0.25rem 0.5rem;
      border:solid 1px ${props => props.theme.primary};
      border-radius: 3px;
      &.is_current{
        background: ${props => props.theme.third};
        border:solid 1px ${props => props.theme.third};
        color:white;
      }
      &:not(.is_current):hover{
        cursor: pointer;
        background: ${props => props.theme.thirdPastel};
        border:solid 1px ${props => props.theme.thirdPastel};
      }
    }
  }
  .block_infos{
    width: 100%;
    margin-bottom: 20px;
    background: white;
    padding: 1rem;
    .content_block{
      margin-top: 15px;
    }
    .one_infos{
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 8px;
      .lib_infos{
        font-weight: bold;
      }
    }
  }
`
