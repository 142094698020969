import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {MbjButton, MbjFlex, MbjNavPath, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {GoPlus} from "react-icons/go";
import {AlliancesTable} from "../MesAlliances/AlliancesTable/AlliancesTable";
import {AlliancesGroup} from "../MesAlliances/AlliancesTable/AlliancesGroup";

interface GestionAllianceCtrlProps{
  className?:string;
}

const GestionAllianceCtrl = (props:GestionAllianceCtrlProps)=>{
  const location = useLocation();
  const navigate = useNavigate();
  const clickNew = ()=>{
    navigate("./nouvelle_alliance")
  }
  return (
    <div className={`gestion_alliances ${props.className}`}>
      <MbjNavPath routePath={location.pathname} />
        <div className={"space-between"}>
          <MbjButton onClick={clickNew} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
            Créer une nouvelle alliance
          </MbjButton>
        </div>
      <MbjFlex gap={"1.5rem"} basis={"100%"}>
        <MbjWidgetClassic
          title={"Alliances Actives"}
          textInfo={
            "Les alliances actives représentent les alliances dont vous faites parti et dans lesquelles vous souhaitez partager des données."
          } basis={"100%"}
        >
          <AlliancesGroup />
        </MbjWidgetClassic>
        <MbjWidgetClassic
          title={"Alliances Inactives"}
          textInfo={
            "Les alliances inactives représentent les alliances dont vous faites parti mais dans lesquelles vous ne souhaitez pas partager de données."
          }
        >
          <AlliancesTable allianceState={"inactive"} />
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  )
}

export const GestionAlliance = styled(GestionAllianceCtrl)`
  padding: 1% 2%;
  .space-between{
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }
`
