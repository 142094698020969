import React, { useMemo } from "react";
import styled from "styled-components";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjListItem,
  MbjLoading,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { useGetMobilites, useGetMobiliteSaved } from "@mbj-front-monorepo/data-access";
import { MbjMobiliteListItem } from "@mbj-front-monorepo/data-ui";

interface MobilitesSavedCardCtrlProps extends MbjCardClassicCtrlProps {
  id: number;
  className?: string;
}

const MobilitesSavedCardCtrl = (props: MobilitesSavedCardCtrlProps) => {
  const { isLoading, isError, data: mobiliteSaves } = useGetMobiliteSaved(props.id);
  const mobiliteQuery = useGetMobilites(props.id);

  const mobiliteFiltered = useMemo(() => {
    if (!mobiliteSaves || !mobiliteQuery.data) {
      return [];
    }
    const resultTab = [];
    for (const mobiliteSave of mobiliteSaves) {
      resultTab.push(mobiliteQuery.data.find((mobilite) => mobilite.Metier?.id === mobiliteSave.Metier?.id));
    }
    return resultTab;
  }, [mobiliteQuery.data, mobiliteSaves]);

  return (
    <MbjCardClassic
      className={`MobiliteSaved ${props.className}`}
      title="Dernières mobilités consultées"
      noPadding
      {...props}
    >
      {isLoading ? (
        <MbjLoading />
      ) : isError ? (
        "Errors"
      ) : mobiliteSaves?.length === 0 ? (
        <p className={`no_result`}>Aucune mobilité consultée</p>
      ) : (
        <MbjScrollArea>
          {mobiliteFiltered?.map(
            (mobilite, index: number) =>
              mobilite && (
                <MbjListItem key={mobilite.id} to={`${mobilite.Metier?.id}`}>
                  <MbjMobiliteListItem mobilite={mobilite} />
                </MbjListItem>
              )
          )}
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
};

export const MobilitesSavedCard = styled(MobilitesSavedCardCtrl)((props) => ({
  ".no_result":{
    fontSize:"18px",
    color:props.theme.grey,
    padding:"48px",
    textAlign:"center"
  }

}));
