import styled from "styled-components";
import {useLocation, useParams} from "react-router-dom";
import {useGetFiPart, useGetParticulier, useGetXpPart} from "@mbj-front-monorepo/data-access";
import {
    MbjButton,
    MbjLoading,
    MbjModal,
    MbjNavPath,
    MbjNoResult,
    MbjWidgetClassic,
    useModal
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {GoPlus} from "react-icons/go";
import {
    MbjParticulierFiCard,
    MbjParticulierFiNewForm,
    MbjParticulierXpCard,
    MbjParticulierXpNewForm
} from "@mbj-front-monorepo/data-ui";

interface CvCvThequeCtrlProps{
    className?:string;
}

const CvCvThequeCtrl= (props:CvCvThequeCtrlProps)=>{
    const { isShowing: isShowingAddXp, toggle: toggleAddXp } = useModal();
    const { isShowing: isShowingAddFi, toggle: toggleAddFi } = useModal();
    const { id } = useParams();
    const particulierQuery = useGetParticulier(parseInt(id || "-1"));
    const xpQuery = useGetXpPart(id ? parseInt(id) : undefined);
    const FisQuery = useGetFiPart(id ? parseInt(id) : undefined);
    const location = useLocation()
    return (
        <div className={"cv_cv_theque " + props.className}>
            <MbjNavPath
                routePath={location.pathname}
                changeLabelTab={[
                    {
                        index: 2,
                        label: particulierQuery.data?.nom?.toUpperCase() + " " + particulierQuery.data?.prenom,
                    },
                    { index: 3, label: "Cv" },
                ]}
            />
            {xpQuery.isLoading ? (
                <MbjLoading />
            ) : xpQuery.isError ? (
                <p>Erreur ...</p>
            ) : (
                <MbjWidgetClassic title={"Expériences pros"} justify={"center"} bodyDirection={"column"}>
                    <MbjButton leftIcon={<GoPlus />} onClick={toggleAddXp} alignSelf={"flex-end"}>
                        Ajouter une expérience
                    </MbjButton>
                    {xpQuery.data && xpQuery.data.length > 0 ? (
                        xpQuery.data?.map((xp, index) => <MbjParticulierXpCard ParticulierXp={xp} key={xp.id} />)
                    ) : (
                        <MbjNoResult text={"Aucune expérience trouvée"} />
                    )}
                    <MbjModal
                        isShowing={isShowingAddXp}
                        hide={toggleAddXp}
                        fitContent
                        title="Nouvelle expérience professionnelle"
                    >
                        <MbjParticulierXpNewForm idParticulier={particulierQuery.data?.id || -1} hideModal={toggleAddXp} />
                    </MbjModal>
                </MbjWidgetClassic>
            )}
            {FisQuery.isLoading ? (
                <MbjLoading />
            ) : FisQuery.isError ? (
                <p>Erreur ...</p>
            ) : (
                <MbjWidgetClassic title={"Formations initiales"} bodyDirection={"column"}>
                    <MbjButton leftIcon={<GoPlus />} onClick={toggleAddFi} alignSelf={"flex-end"}>
                        Ajouter une formation
                    </MbjButton>
                    {FisQuery.data && FisQuery.data.length > 0 ? (
                        FisQuery.data?.map((fi, index) => <MbjParticulierFiCard ParticulierFi={fi} key={fi.id} />)
                    ) : (
                        <MbjNoResult text={"Aucune formations initiales trouvés"} />
                    )}
                    <MbjModal isShowing={isShowingAddFi} hide={toggleAddFi} fitContent title="Nouvelle formation initiale">
                        <MbjParticulierFiNewForm idParticulier={particulierQuery.data?.id || -1} hideModal={toggleAddFi} />
                    </MbjModal>
                </MbjWidgetClassic>
            )}
        </div>
    )
}

export const CvCvTheque = styled(CvCvThequeCtrl)`
    padding: 1% 2%;
`

