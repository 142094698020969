import styled from "styled-components";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {useGetUserMetier} from "@mbj-front-monorepo/data-access";
import {InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu} from "@mbj-front-monorepo/ui";
import {FaUserCircle} from "react-icons/fa";
import {NotFound} from "../NotFound/NotFound";
import * as React from "react";
import {EffectifRecrutement} from "./EffectifRecrutement";
import {EffectifDashboard} from "./EffectifDashboard";

interface FormatCibleDetailCtrlProps{
  className?:string;
}

const FormatCibleDetailCtrl = (props:FormatCibleDetailCtrlProps)=>{
  const { id } = useParams();
  const UserMetierQuery = useGetUserMetier(id ? parseInt(id) : undefined)
  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="Entreprise"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + props.className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {UserMetierQuery.data ? (
                      <>
                        <FaUserCircle className={"icon-profile-menu"} />
                        <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                          {UserMetierQuery.data?.namePerso?.toUpperCase()}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                <MbjLink to="dashboard">Tableau de bord</MbjLink>
                <MbjLink to="recrutement">Recrutement</MbjLink>
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path="" element={<Navigate to="dashboard" />} />
        <Route path="/dashboard" element={<EffectifDashboard />} />
        <Route path="/recrutement" element={<EffectifRecrutement />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export const FormatCibleDetail = styled(FormatCibleDetailCtrl)((props) => ({
  minHeight: "fit-content",
  justifyContent: "space-between",

  ".custom-content-menu": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5em",

    ".icon-profile-menu": {
      fill: props.theme.primaryDarken,
      height: "25px",
      width: "25px",
    },
  },
}));
