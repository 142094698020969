import styled from "styled-components";
import {components, useGetAllSearchByMember} from "@mbj-front-monorepo/data-access";
import {useMemo, useState} from "react";
import {MbjChoixMenu, MbjLoading, MbjModal2} from "@mbj-front-monorepo/ui";
import {ProfilRetenusList} from "./ProfilRetenusList";
import {Compare} from "../Result/Comparaison/Compare";

interface ProfilsRetenus2CtrlProps{
  className?:string;
}

export interface ItemMenuResult{
  id:number;
  libelle:string;
}

const MesMenusResult:ItemMenuResult[]=[
  {id:1, libelle:"Metiers internes"},
  {id:2, libelle:"Autres Metiers"},
]

const ProfilsRetenus2Ctrl = (props:ProfilsRetenus2CtrlProps)=>{
  const SearchQuery = useGetAllSearchByMember();
  const [menuCurrent, setMenuCurrent] = useState<ItemMenuResult|null>(MesMenusResult.find(m=>m.id === 1)||null)
  const [openCompare, setOpenCompare] = useState(false);
  const [resultEmpCurr, setResultEmpCurr] = useState<components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"]|null>(null);
  const [resultPartCurr, setResultPartCurr] = useState<components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|null>(null)
  const [resultReclCurr, setResultReclCurr] = useState<components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|null>(null)
  const listSearchUserMetier = useMemo(() => {
    if (!SearchQuery.data) {
      return [];
    } else {
      return SearchQuery.data.filter((s) => !!s.UserMetier);
    }
  }, [SearchQuery.data]);
  const listSearchMetier = useMemo(() => {
    if (!SearchQuery.data) {
      return [];
    } else {
      return SearchQuery.data.filter((s) => s.Metier);
    }
  }, [SearchQuery.data]);
  const getNbResult = (id:number)=>{
    if(id === 1){
      return listSearchUserMetier.length;
    }
    return listSearchMetier.length;
  }
  const switchCompare = ()=>{
    setOpenCompare(o=>!o);
  }
  const ClickCompare = (type:"Emp"|"Part"|"Recl", result:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>{
    console.log('CLICK comp')
    if(type === 'Emp'){
      setResultEmpCurr(result);
      setResultPartCurr(null);
      setResultReclCurr(null);
      switchCompare();
    } else if(type === 'Part'){
      setResultPartCurr(result);
      setResultEmpCurr(null);
      setResultReclCurr(null);
      switchCompare();
    } else {
      setResultPartCurr(null);
      setResultEmpCurr(null);
      setResultReclCurr(result);
      switchCompare();
    }
  }
  return (
    <div className={`profils_retenus ${props.className}`}>
      <div className={"content"}>
        {SearchQuery.isLoading ? (<MbjLoading/>) : SearchQuery.isError ? 'Error data' :
          (
            <>
              <div className={"MenuChoix"}>
                <MbjChoixMenu current={menuCurrent} getNbResult={getNbResult} setChoice={setMenuCurrent} choices={MesMenusResult}/>
              </div>
              <div className={"wrap_result"}>
                {menuCurrent?.id === 1 ?
                  <ProfilRetenusList searchs={listSearchUserMetier} clickCompare={ClickCompare}/>:
                  <ProfilRetenusList searchs={listSearchMetier} clickCompare={ClickCompare}/>
                }
              </div>
            </>
          )
        }
      </div>
      <MbjModal2 open={openCompare} closeMe={switchCompare} titre={"Comparaison"}>
        <Compare
          SearchEmp={resultEmpCurr||undefined}
          SearchPart={resultPartCurr||undefined}
          SearchRecl={resultReclCurr||undefined}
          isToGere={true}
        />
      </MbjModal2>
    </div>
  )
}
export const ProfilsRetenus2 = styled(ProfilsRetenus2Ctrl)`
  padding: 1% 2%;
  height: 100%;
  .content{
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  height: 100%;
  }
  .wrap_result{
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
  }
`
