import styled from "styled-components";

import {MbjLoading} from "@mbj-front-monorepo/ui";
import {useGetAllNoteResReclBySearch} from "@mbj-front-monorepo/data-access";
import {FormNoteReclassement} from "./FormNoteReclassement";


interface GestionNoteForReclCtrlProps{
  className?:string;
  idResult:number;
}

const GestionNoteForReclCtrl = (props:GestionNoteForReclCtrlProps)=>{
  const NotesQuery = useGetAllNoteResReclBySearch(props.idResult);
  return (
    <div className={"gestion_notes_emp "+ props.className}>
      {NotesQuery.isLoading ? <MbjLoading/> : NotesQuery.isError ? 'Erreur BD' :
        NotesQuery.data?.length === 0 ?
          <div className={"no_notes"}>Pas encore de note pour ce profil</div>:
          NotesQuery.data.map(item=>(
            <FormNoteReclassement Note={item||null} key={`one_note_edit${props.idResult}${item.id}`} idResult={props.idResult||0}/>
          ))
      }
      <div className={"nouvelle_note"}>
        <h4>Nouvelle note</h4>
        <div className={"wrap_new_note"}>
          <FormNoteReclassement Note={null} idResult={props.idResult||0}/>
        </div>
      </div>
    </div>
  )
}

export const GestionNoteForRecl = styled(GestionNoteForReclCtrl)`

`
