import * as React from "react";
import { MbjButton, MbjModal, MbjModalCtrlProps } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { components, useDeleteMessage } from "@mbj-front-monorepo/data-access";

interface ConfirmSuppModalCtrlProps extends MbjModalCtrlProps {
  className?: string;
  type: "inbox" | "outbox";
  message: components["schemas"]["Messagerie-read.Messagerie_read.Member"];
}

function ConfirmSuppModalCtrl(props: ConfirmSuppModalCtrlProps) {
  const trashMutation = useDeleteMessage();

  function handleTrashClick() {
    trashMutation.mutate(
      { message: props.message, type: props.type },
      {
        onSuccess: () => {
          console.log("succeess");
          props.hide();
        },
      }
    );
  }

  const isTrashMessage =
    (props.type === "inbox" && props.message.isTrashTo) || (props.type === "outbox" && props.message.isTrashFrom);

  return (
    <MbjModal title="Confirmation suppression" {...props}>
      <p className="">
        {!isTrashMessage
          ? "Etes-vous sûr de vouloir supprimer ce message ? Il sera placé dans la corbeille"
          : "Etes-vous sûr de vouloir supprimer ce message ? Il sera définitivement supprimé"}
      </p>
      <div className="content">
        <MbjButton onClick={props.hide} size="sm" themeColor="primary">
          Je vais réfléchir
        </MbjButton>
        <MbjButton onClick={handleTrashClick} isPending={trashMutation.isLoading} size="sm" themeColor="warning">
          Oui, je suis sûr
        </MbjButton>
      </div>
    </MbjModal>
  );
}
export const ConfirmSuppModal = styled(ConfirmSuppModalCtrl)((props) => ({
  p: {
    textAlign: "center",
  },
  ".content": {
    display: "flex",
    justifyContent: "space-evenly",
    //gap: "20px",
    alignItems: "center",
  },
}));
