import * as React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { NotFound } from "../../NotFound/NotFound";
import { InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { Informations } from "./Informations/Informations";
import { Competences } from "./Competences/Competences";
import { CV } from "./CV/CV";
import { Mobilites } from "./Mobilites/Mobilites";
import styled from "styled-components";
import { FaUserCircle } from "react-icons/fa";
import { Dashboard } from "./Dashboard/Dashboard";
import { MobiliteDetails } from "./Mobilites/MobiliteDetails/MobiliteDetails";
import { Suivi } from "./Suivi/Suivi";
import { EntretienAnnuel } from "./EntretienAnnuel/EntretienAnnuel";
import { EntretienAnnuelNew } from "./EntretienAnnuel/EntretienAnnuelNew/EntretienAnnuelNew";
import { useGetEmployee } from "@mbj-front-monorepo/data-access";
import {MobilitesInterne} from "./Mobilites/MobilitesInterne/MobilitesInterne";

interface CollaborateurDetailCtrlProps {
  className?: string;
}

function CollaborateurDetailCtrl(props: CollaborateurDetailCtrlProps) {
  const { id } = useParams();
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));

  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="Entreprise"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + props.className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {employeeQuery.data ? (
                      <>
                        <FaUserCircle className={"icon-profile-menu"} />
                        <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                          {employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                <MbjLink to="dashboard">Tableau de bord</MbjLink>
                <MbjLink to="informations">Informations</MbjLink>
                <MbjLink to="cv">CV</MbjLink>
                <MbjLink to="competences">Compétences</MbjLink>
                <MbjLink to="mobilites">Mobilités</MbjLink>
                <MbjLink to="mobilites_internes">Mobilités Internes</MbjLink>
                {/*<MbjLink to="formation">Formation</MbjLink>*/}
                {/*<MbjLink to="badges">Badges</MbjLink>*/}
                <MbjLink to="suivi">Suivi</MbjLink>
                <MbjLink to="entretien">Entretiens périodiques</MbjLink>
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path="" element={<Navigate to="dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/informations" element={<Informations />} />
        <Route path="/competences" element={<Competences />} />
        <Route path="/cv" element={<CV />} />
        <Route path="/mobilites" element={<Mobilites />} />
        <Route path="/mobilites_internes" element={<MobilitesInterne />} />
        <Route path="/mobilites/:idMetier" element={<MobiliteDetails />} />
        {/*<Route path="/formation" element={<Formation />} />*/}
        {/*<Route path="/badges" element={<Badges />} />*/}
        {<Route path="/suivi" element={<Suivi />} />}
        {<Route path="/entretien" element={<EntretienAnnuel />} />}
        {<Route path="/entretien/new" element={<EntretienAnnuelNew />} />}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
export const CollaborateurDetail = styled(CollaborateurDetailCtrl)((props) => ({
  minHeight: "fit-content",
  justifyContent: "space-between",

  ".custom-content-menu": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5em",

    ".icon-profile-menu": {
      fill: props.theme.primaryDarken,
      height: "25px",
      width: "25px",
    },
  },
}));
