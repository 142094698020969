import * as React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import {
  MbjButton,
  MbjCardClassic,
  MbjFlex,
  MbjLoading,
  MbjModal,
  MbjNavPath,
  MbjNoResult,
  MbjScrollArea,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import { useGetEmployee, useGetFI, useGetXp } from "@mbj-front-monorepo/data-access";
import {
  MbjEmployeeFiCard,
  MbjEmployeeFiNewForm,
  MbjEmployeeXpCard,
  MbjEmployeeXpNewForm,
} from "@mbj-front-monorepo/data-ui";
import { GoPlus } from "react-icons/go";
import { ScanCVModal } from "./ScanCVModal";

interface CVCtrlProps {
  className?: string;
}

function CVCtrl(props: CVCtrlProps) {
  const location = useLocation();
  const { id } = useParams();
  const { isShowing: showScanCv, toggle: toggleScanCV } = useModal();
  const { isShowing: showNewXp, toggle: toggleNewXp } = useModal();
  const { isShowing: showNewFi, toggle: toggleNewFi } = useModal();
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));

  const xpQuery = useGetXp(parseInt(id as string));
  const FisQuery = useGetFI(parseInt(id as string));
  return (
    <div className={"CV " + props.className}>
      {employeeQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <MbjFlex direction={"column"}>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom,
            }}
          />

          <ScanCVModal isShowing={showScanCv} hide={toggleScanCV} />
          <MbjWidgetClassic title="Expériences professionnelles" bodyDirection={"column"} basis={"45%"}>
            <div style={{display:"flex", justifyContent:"flex-end", gap:"10px"}}>
              <MbjButton onClick={toggleNewXp} leftIcon={<GoPlus />}>
                Ajouter une expérience professionnelle
              </MbjButton>
            </div>
            {xpQuery.data && xpQuery.data?.length > 0 ? (
              <MbjFlex direction={"column"}>
                {xpQuery.isLoading ? (
                  <MbjLoading />
                ) : xpQuery.isError ? (
                  <p>Error...</p>
                ) : (
                  xpQuery.data?.map((xp) => <MbjEmployeeXpCard EmployeeXp={xp} key={xp.id} />)
                )}
              </MbjFlex>
            ) : (
              <MbjCardClassic>
                <div style={{padding:"20px"}}>
                <MbjNoResult text={"Aucune expérience professionnelle"} />
                </div>
              </MbjCardClassic>
            )}
          </MbjWidgetClassic>
          <MbjWidgetClassic title="Formations initiales" bodyDirection={"column"} basis={"45%"}>
            <div style={{display:"flex", justifyContent:"flex-end", gap:"10px"}}>

              <MbjButton onClick={toggleNewFi} leftIcon={<GoPlus />}>
                Ajouter une formation initiale
              </MbjButton>
            </div>
            {FisQuery.data && FisQuery.data?.length > 0 ? (
              <MbjScrollArea maxHeight={"325px"}>
                <MbjFlex direction={"column"} gap={"20px"}>
                  {FisQuery.isLoading ? (
                    <MbjLoading />
                  ) : FisQuery.isError ? (
                    <p>Error...</p>
                  ) : (
                    FisQuery.data?.map((fi, idx: number) => <MbjEmployeeFiCard EmployeeFi={fi} key={fi.id} />)
                  )}
                </MbjFlex>
              </MbjScrollArea>
            ) : (
              <MbjCardClassic>
                <div style={{padding:"20px"}}>
                  <MbjNoResult text={"Aucune formation initiale"} />
                </div>
              </MbjCardClassic>
            )}
          </MbjWidgetClassic>
        </MbjFlex>
      )}
      {id && (
        <>
          <MbjModal isShowing={showNewXp} hide={toggleNewXp} fitContent title="Nouvelle expérience professionnelle">
            <MbjEmployeeXpNewForm idEmployee={parseInt(id.toString())} hideModal={toggleNewXp} />
          </MbjModal>
          <MbjModal isShowing={showNewFi} hide={toggleNewFi} fitContent title="Nouvelle formation initiale">
            <MbjEmployeeFiNewForm idEmployee={parseInt(id.toString())} hideModal={toggleNewFi} />
          </MbjModal>
        </>
      )}
    </div>
  );
}
export const CV = styled(CVCtrl)((props) => ({
  padding: "1% 2%",
}));
