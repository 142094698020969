import * as React from "react";
import { MbjListItem, MbjRank } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

/**
 * Carte contenant des infos sur l'effectif de l'entreprise :
 */
interface CompetenceItemProps {
  /**
   * Classe html de la carte :
   */
  className?: string;
  /**
   * Numéro de l'item :
   */

  /**
   * données à utiliser :
   */
  competence: GetElementType<Operation<"getCompetencesTopFlopCollection">>;
}

function CompetenceItemCtrl(props: CompetenceItemProps) {
  return (
    <MbjListItem className={props.className}>
      <div className={"name"}>{props.competence.Activite?.libelle}</div>
      <MbjRank maxRank={5} rank={props.competence.niveau} themeColor={"complementary"} isStar />
    </MbjListItem>
  );
}
export const CompetenceItem = styled(CompetenceItemCtrl)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
}));
