import * as React from "react";
import styled from "styled-components";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { NotFound } from "../NotFound/NotFound";
import { EntrepriseResume } from "./Résumé/EntrepriseRésumé";
import { EntrepriseInfos } from "./Informations/EntrepriseInfos";
import { EntrepriseMetiers } from "./Métiers/EntrepriseMetiers";
import { EntrepriseStats } from "./Statistiques/EntrepriseStats";
import { InternDesktopLayout, MbjLink, MbjSectionMenu, useModal } from "@mbj-front-monorepo/ui";
import { EntrepriseService } from "./Services/EntrepriseServices";
import { ModifierMetierModal } from "./Métiers/ModifierMetierModal/ModifierMetierModal";

interface EntrepriseCtrlProps {}

function EntrepriseCtrl(props: EntrepriseCtrlProps) {
  const { isShowing: modifyIsShowing, toggle: modifyToggle } = useModal();

  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  return (
    <div className="Entreprise">
      <Routes location={state?.backgroundLocation || location}>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              mbjSectionMenu={
                <MbjSectionMenu>
                  <MbjLink to="resume">Résumé</MbjLink>
                  <MbjLink to="informations">Informations</MbjLink>
                  <MbjLink to="metiers">Métiers de l'entreprise</MbjLink>

                  <MbjLink to="services">Services dans l'entreprise</MbjLink>
                  {/*<MbjLink to="statistiques">Statistiques</MbjLink>*/}
                </MbjSectionMenu>
              }
            />
          }
        >
          <Route path="" element={<Navigate to="resume" />} />
          <Route path="/resume" element={<EntrepriseResume />} />
          <Route path="/informations" element={<EntrepriseInfos />} />
          <Route path="/metiers" element={<EntrepriseMetiers />} />
          <Route path="/metiers/:id" element={<ModifierMetierModal isShowing={true} hide={modifyToggle} />} />
          <Route path="/services" element={<EntrepriseService />} />
          <Route path="/statistiques" element={<EntrepriseStats />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>

      {
        /* Show the modal when a `backgroundLocation` is set */
        state?.backgroundLocation && (
          <Routes>
            <Route path="/metiers/:id" element={<ModifierMetierModal isShowing={true} hide={modifyToggle} />} />
          </Routes>
        )
      }
    </div>
  );
}
export const Entreprise = styled(EntrepriseCtrl)((props) => ({}));
