import styled from "styled-components";
import {MbjModal, MbjModalCtrlProps} from "@mbj-front-monorepo/ui";
import {AjoutCV} from "../../../AjoutCV/AjoutCV";

interface AjoutCollaborateurByCVModalCtrlProps extends MbjModalCtrlProps {}

const AjoutCollaborateurByCVModalCtrl = (props:AjoutCollaborateurByCVModalCtrlProps)=>{
  return (
    <MbjModal {...props} title="Ajout d'un collaborateur par cv" fitContent maxWidth={"80%"}>
      <AjoutCV CloseMe={props.hide}/>
    </MbjModal>
  )
}
export const AjoutCollaborateurByCVModal = styled(AjoutCollaborateurByCVModalCtrl)``
