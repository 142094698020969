import * as React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  MbjButton,
  MbjFlex,
  MbjLoading,
  MbjNavPath,
  MbjScrollArea,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import { NouveauMessageModal } from "../NouveauMessageModal/NouveauMessageModal";
import { MessageItemCard } from "../MessageItemCard/MessageItemCard";
import { useGetMessagesInbox, useGetMessagesOutbox } from "@mbj-front-monorepo/data-access";
import { GoPlus } from "react-icons/go";

interface CorbeilleCtrlProps {
  className?: string;
}

function CorbeilleCtrl(props: CorbeilleCtrlProps) {
  const location = useLocation();
  const messagesOutboxQuery = useGetMessagesOutbox();
  const messagesInboxQuery = useGetMessagesInbox();

  const messagesInboxTrash = useMemo(() => {
    if (messagesInboxQuery === undefined || messagesInboxQuery.data === null || messagesInboxQuery.data === undefined) {
      return [];
    }
    return messagesInboxQuery.data?.filter((message) => message.isTrashTo && !message.isVeryTrashTo);
  }, [messagesInboxQuery.data]);

  const { isShowing: isShowing, toggle: toggle } = useModal();

  const messagesOutboxTrash = useMemo(() => {
    if (
      messagesOutboxQuery === undefined ||
      messagesOutboxQuery.data === null ||
      messagesOutboxQuery.data === undefined
    ) {
      return [];
    }
    return messagesOutboxQuery.data?.filter((message) => message.isTrashFrom && !message.isVeryTrashFrom);
  }, [messagesOutboxQuery.data]);

  const nbMessageTrash = messagesOutboxTrash.length + messagesInboxTrash.length;

  return (
    <div className={"Corbeille " + props.className}>
      <div className={"top-container"}>
        <MbjNavPath routePath={location.pathname} />
        <MbjButton onClick={toggle} leftIcon={<GoPlus />}>
          Nouveau message
        </MbjButton>
        <NouveauMessageModal isShowing={isShowing} hide={toggle} />
      </div>
      <MbjWidgetClassic bodyDirection={"column"} title={"Corbeille"}>
        {messagesOutboxQuery.isLoading || messagesInboxQuery.isLoading ? (
          <MbjLoading />
        ) : messagesOutboxQuery.isError || messagesInboxQuery.isError ? (
          <p>Erreur ...</p>
        ) : nbMessageTrash >= 0 ? (
          <>
            <div>
              {nbMessageTrash +
                " " +
                (nbMessageTrash === 1 ? "message dans la corbeille" : "messages dans la corbeille")}
            </div>
            <MbjScrollArea maxHeight={"550px"}>
              <MbjFlex direction={"column"} gap={"12px"}>
                {messagesInboxTrash.map((message, idx) => (
                  <MessageItemCard type={"inbox"} message={message} key={message.id} />
                ))}
                {messagesOutboxTrash.map((message, idx) => (
                  <MessageItemCard type={"outbox"} message={message} key={message.id} />
                ))}
              </MbjFlex>
            </MbjScrollArea>
          </>
        ) : (
          <div>Aucun message dans la corbeille...</div>
        )}
      </MbjWidgetClassic>
    </div>
  );
}
export const Corbeille = styled(CorbeilleCtrl)((props) => ({
  padding: "1% 2%",

  ".top-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));
