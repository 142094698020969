import styled from "styled-components";
import {components, useGetAllianceMembers, useGetMemberMe} from "@mbj-front-monorepo/data-access";
import React, {useEffect, useState} from "react";
import {AvatarFact, MbjButton, MbjLoading} from "@mbj-front-monorepo/ui";
import {BiChevronDown} from "react-icons/bi";

interface AllianceCardCtrlProps{
  className?:string;
  Alliance:components["schemas"]["AllianceMember-read.Member.Alliances_read.Member"];
}

const AllianceCardCtrl = (props:AllianceCardCtrlProps)=>{
  const memberQuery = useGetMemberMe();
  const MembersQuery = useGetAllianceMembers(props.Alliance.Alliance ? props.Alliance.Alliance?.id||0 : 0)
  const [isOwner, setIsOwner] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if(memberQuery.data && props.Alliance.Alliance){
      const idAllianceOwner = props.Alliance.Alliance.Owner?.id;
      const idMe = memberQuery.data.id;
      setIsOwner(idAllianceOwner === idMe);
    }
  }, [memberQuery.data, props.Alliance.Alliance]);
  const handleError = (e:any)=>{
    e.target.src = AvatarFact;
  }
  if(!props.Alliance){
    return null;
  } else{
    return (
      <div className={`allianceCard ${props.className}`}>
        <div className={"content_card_alliance"}>
          <div className={"alliance_name"}>
            {props.Alliance.Alliance?.name}
          </div>
          {props.Alliance.Alliance && props.Alliance.Alliance?.creationAt &&
          <div className={"date_creation is_info"}>
            <span className={`prefinfo`}>Créateur de l'alliance</span>
            <span className={`info`}>{new Date(props.Alliance.Alliance.creationAt).toLocaleDateString()}</span>
          </div>
          }
          <div className={"owner is_info"}>
            <span className={`prefinfo`}>Créateur de l'alliance</span>
            <span className={`info`}>{isOwner ? "Vous même" : props.Alliance.Alliance?.Owner?.name}</span>
          </div>

          <div className={`members_alliance`}>
            <h4 onClick={()=>setOpen(o=>!o)}>Membres de l'alliance ({MembersQuery.data?.length}) <span className={open ? 'chevopen' : 'chevclosed'}><BiChevronDown /></span></h4>
            <div className={`wrappy_members`}  aria-expanded={!open}>
            {MembersQuery.isLoading ? <MbjLoading/> :
              MembersQuery.data?.map(item=>(
                <div key={`one_member_alliance${props.Alliance.Alliance?.id||0}${item.Member?.id||0}`} className={`one_member_alliance`}>
                  <div className={"Avatar_place"}>
                    <div className={`wrap_logo`}>
                      <img
                        src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${item.Member?.logo}`}
                        alt={"avatar_logo"}
                        onError={handleError}
                      />
                    </div>
                  </div>
                  <div className={`ent_name`}>{item.Member?.name}</div>
                </div>
              ))
            }
            </div>
          </div>
          <div className={"action_card_alliance"}>
            {isOwner ?
                <MbjButton size={"xs"} themeColor={"third"}>Gestion des membres</MbjButton>:
              <MbjButton size={"xs"} themeColor={"warning"}>Sortir de l'alliance</MbjButton>
            }
          </div>
        </div>

      </div>
    )
  }
}

export const AllianceCard = styled(AllianceCardCtrl)`
  padding: 0.5rem;
  width: 100%;
  .action_card_alliance{
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 0;
  }
  .chevopen{
    display: inline-block;
    transform: rotate(180deg);
  }
  .chevclosed{
    display: inline-block;
    transform: rotate(0deg);
  }
  .is_info{
    display: flex;
    gap:8px;
    span{
      display: block;
      &:first-child{
        color:${props=>props.theme.primary};
        font-weight: bold;
      }
    }
  }
  .alliance_name{
    font-size: 18px;
    color:${props=>props.theme.primary};
    font-weight: bold;
    margin-bottom: 15px;
  }
  .content_card_alliance{
    padding: 0.5rem;
    background: white;
    border-radius: 4px;
  }
  h4{
    margin: 12px 0;
    &:hover{
      cursor:pointer;
      text-decoration: underline;
    }
  }
  .wrappy_members{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    gap:12px;
    max-height: 1000px;
    overflow-y: auto;
    transition: max-height 1s ease-in-out;
    position: relative;
    padding-bottom: 0.5rem;
  }
  .wrappy_members[aria-expanded="true"]{
    max-height: 0;
    padding: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
  .one_member_alliance{
    display: flex;
    gap: 12px;
    align-items: center;
    width: 30%;
    .ent_name{
      font-weight: bold;
    }
    .Avatar_place{
      .wrap_logo{
        width: 60px;
        height: 60px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 50%;
        box-shadow: 0px 0px 3px rgba(0,0,0,.2);
        img{
          width: 40px;
          height: auto;
          margin: auto;
        }
      }
    }

  }

`
