import React from "react";
import styled from "styled-components";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjLoading,
  MbjNoResult,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { LineFormationContinuMetier } from "./LineFormationContinuMetier";
import { useGetEmployee, useGetFormationToMetier } from "@mbj-front-monorepo/data-access";

interface FormationsContinuMetierCardCtrlProps extends MbjCardClassicCtrlProps {
  className?: string;
  metierId?: number;
  employeeId?: number;
}

const FormationContinuMetierCardCtrl = (props: FormationsContinuMetierCardCtrlProps) => {
  const employeeQuery = useGetEmployee(props.employeeId);
  const formationToQuery = useGetFormationToMetier(props.employeeId, props.metierId);

  if (employeeQuery.data && !employeeQuery.data.codePostal) {
    return (
      <MbjCardClassic className={`cardFC ${props.className}`} title={"Formations pour ce métier"} {...props}>
        <p>Pas de code postal renseigné</p>
      </MbjCardClassic>
    );
  }

  return (
    <MbjCardClassic className={`cardFC ${props.className}`} title={"Formations pour ce métier"} {...props}>
      {formationToQuery.isLoading ? (
        <MbjLoading />
      ) : formationToQuery.isError ? (
        <p>Informations non disponible</p>
      ) : (
        <MbjScrollArea maxHeight={"450px"}>
          {formationToQuery.data.FormationContinusMetier?.length === 0 && (
            <MbjNoResult text="Aucune formation métier" />
          )}
          {formationToQuery.data.FormationContinusMetier &&
            formationToQuery.data.FormationContinusMetier?.map((FM, idx: number) => (
              <LineFormationContinuMetier key={FM.id} FormationContinuMetier={FM} />
            ))}
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
};

export const FormationContinuMetierCard = styled(FormationContinuMetierCardCtrl)((props) => ({}));
