import * as React from "react";
import styled from "styled-components";
import {
  MbjButton,
  MbjHeading,
  MbjModal,
  MbjModalCtrlProps,
  MbjOption,
  MbjSearchBar,
  useSearchBar,
} from "@mbj-front-monorepo/ui";
import { Operation, useAddUserMetier, useGetMemberMe, useGetMetiers } from "@mbj-front-monorepo/data-access";

interface AjoutMetierModalCtrlProps extends MbjModalCtrlProps {}

function AjoutMetierModalCtrl(props: AjoutMetierModalCtrlProps) {
  const useSearchBarHook = useSearchBar<Operation<"getMetierCollection">[0]>(10, "id");
  const metiersQuery = useGetMetiers();
  const memberQuery = useGetMemberMe();
  const mutation = useAddUserMetier();

  function handleClick() {
    useSearchBarHook.options.forEach((option, index) => {
      mutation.mutate(
        { Member: "/api/members/" + memberQuery.data?.id, Metier: "api/metiers/" + option.id },
        {
          onSettled: (data, error, variables, context) => {
            props.hide();
            useSearchBarHook.clearOptions();
          },
        }
      );
    });
  }

  return (
    <MbjModal {...props} title="Ajout d'un ou plusieurs métiers" fitContent>
      <div style={{paddingBottom:"15px"}}>
        <div style={{marginTop:"15px"}}>
          <MbjSearchBar
            placeholder={"Choisissez un métier parmi cette liste"}
            className={"SearchBar"}
            suggestionsQuery={metiersQuery}
            selectsHook={useSearchBarHook}
            labelKey={"libelle"}
          />
        </div>
        {useSearchBarHook.options.length > 0 ? (
          <div className={"to-add-container"}>
            <div className={"top"}>
              <MbjHeading withLine={false} level={"h2"}>
                Métier{useSearchBarHook.options.length > 1 ? "s" : ""} à ajouter :
              </MbjHeading>
            </div>
            <div className={"options-container"}>
              {useSearchBarHook.options.map((option, i: number) => (
                <MbjOption key={option.id} onClick={() => useSearchBarHook.removeOption(option)}>
                  {option.libelle}
                </MbjOption>
              ))}
            </div>
            <div style={{margin:"20px 0", display:"flex", justifyContent:"flex-end"}}>
              <MbjButton onClick={handleClick} isPending={mutation.isLoading}>
                Confirmer
              </MbjButton>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>

    </MbjModal>
  );
}
export const AjoutMetierModal = styled(AjoutMetierModalCtrl)((props) => ({
  ".to-add-container": {
    margin: "15px 0",
    maxWidth: "600px",

    ".top": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px",
    },

    ".options-container": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "5px",
    },
  },
}));
