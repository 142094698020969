import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DefaultTheme } from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { EntretienAnnuelFormData } from "../../EntretienAnnuelNewForm";

interface PlanDevCompetencesPdfProps {
  formData?: EntretienAnnuelFormData;
  user_metiers?: Operation<"getUserMetierCollection">;
  metiers?: Operation<"getMetierCollection">;
  theme: DefaultTheme;
}

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create Document Component
export const PlanDevCompetencesPdf = (props: PlanDevCompetencesPdfProps) => {
  function getUserMetier(idUM?: number) {
    if (!props.user_metiers || !idUM) {
      console.log("no user_metiers", props.user_metiers);
      return;
    }
    return props.user_metiers.find((UM) => UM.id === idUM);
  }
  function getMetier(id?: number) {
    if (!props.metiers || !id) {
      console.log("no metiers", props.metiers);
      return;
    }
    return props.metiers.find((metier) => metier.id === id);
  }

  // Create styles
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      borderWidth: 1,
      borderColor: props.theme.primary,
    },
    headerContainer: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      backgroundColor: props.theme.primaryLighten,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
      fontSize: 10,
      //verticalAlign: "middle",
    },

    libelle: {
      width: "50%",
    },
    avisResp: {
      width: "50%",
    },
    blank: {
      width: "25%",
    },
    courtTerme: {
      width: "25%",
    },
    moyenTerme: {
      width: "25%",
    },
    longTerme: {
      width: "25%",
    },

    label: {
      marginLeft: 8,
      marginBottom: 8,
      fontSize: 14,
      color: props.theme.lightText,
    },
    row: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      fontSize: 10,
      fontStyle: "bold",
      textAlign: "center",
    },
    cell: {
      borderRightColor: props.theme.primary,
      borderRightWidth: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    listItem: {
      fontSize: 10,
    },
  });

  if (!props.formData?.NewForm && !props.formData) {
    return <React.Fragment />;
  }
  return (
    <>
      {props.formData?.NewForm && (
        <>
          <Text style={styles.label}>Vos besoins de formation</Text>
          <View style={styles.tableContainer}>
            <View style={styles.headerContainer}>
              <View style={[styles.cell, styles.libelle]}>
                <Text>Libellé</Text>
              </View>
              <View style={[styles.cell, styles.avisResp]}>
                <Text>Avis du responsable</Text>
              </View>
            </View>
            {props.formData.NewForm.map((NewForm, index) => {
              return (
                <View style={styles.row} key={index} wrap={false}>
                  <View style={[styles.cell, styles.libelle]}>
                    <Text>{NewForm.libelle}</Text>
                  </View>
                  <View style={[styles.cell, styles.avisResp]}>
                    <Text>{NewForm.avis}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </>
      )}
      {(props.formData?.EvoInterneCrtTerme ||
        props.formData?.EvoInterneMoyenTerme ||
        props.formData?.EvoInterneLgTerme ||
        props.formData?.EvoExterneCrtTerme ||
        props.formData?.EvoExterneMoyenTerme ||
        props.formData?.EvoExterneLgTerme) && (
        <>
          <Text style={styles.label}>Souhait évolutions professionelles</Text>
          <View style={styles.tableContainer}>
            <View style={styles.headerContainer}>
              <View style={[styles.cell, styles.blank]}>
                <Text></Text>
              </View>
              <View style={[styles.cell, styles.courtTerme]}>
                <Text>Court terme (1 an)</Text>
              </View>
              <View style={[styles.cell, styles.moyenTerme]}>
                <Text>Moyen terme (3 ans)</Text>
              </View>
              <View style={[styles.cell, styles.longTerme]}>
                <Text>Long terme (plus de 3 ans)</Text>
              </View>
            </View>

            <View style={styles.row} wrap={false}>
              <View style={[styles.cell, styles.blank]}>
                <Text>Évolution interne</Text>
              </View>
              <View style={[styles.cell, styles.courtTerme]}>
                <Text>{getUserMetier(props.formData?.EvoInterneCrtTerme)?.namePerso}</Text>
              </View>
              <View style={[styles.cell, styles.moyenTerme]}>
                <Text>{getUserMetier(props.formData?.EvoInterneMoyenTerme)?.namePerso}</Text>
              </View>
              <View style={[styles.cell, styles.longTerme]}>
                <Text>{getUserMetier(props.formData?.EvoInterneLgTerme)?.namePerso}</Text>
              </View>
            </View>
            <View style={styles.row} wrap={false}>
              <View style={[styles.cell, styles.blank]}>
                <Text>Évolution externe</Text>
              </View>
              <View style={[styles.cell, styles.courtTerme]}>
                <Text>{getMetier(props.formData?.EvoExterneCrtTerme)?.libelle}</Text>
              </View>
              <View style={[styles.cell, styles.moyenTerme]}>
                <Text>{getMetier(props.formData?.EvoExterneMoyenTerme)?.libelle}</Text>
              </View>
              <View style={[styles.cell, styles.longTerme]}>
                <Text>{getMetier(props.formData?.EvoExterneLgTerme)?.libelle}</Text>
              </View>
            </View>
          </View>
        </>
      )}
    </>
  );
};
