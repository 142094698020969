import styled from "styled-components";
import {
  Operation,
  useAddFormationPlanning,
  useGetFormationPlanningOne
} from "@mbj-front-monorepo/data-access";
import {MbjButton} from "@mbj-front-monorepo/ui";
import React from "react";

interface WidgetInscriptionCtrlProps{
  className?:string;
  idEmployee:number;
  idActivite:number;
}

const WidgetInscriptionCtrl = (props:WidgetInscriptionCtrlProps)=>{
  const FormationQuery = useGetFormationPlanningOne(props.idEmployee, props.idActivite);
  const mutation = useAddFormationPlanning();
  const handleClickAdd = ()=>{
      const data:Operation<"postFormationPlanningCollection", "requestBody"> = {
        Employee:`api/employees/${props.idEmployee}`,
        Activite:`api/activites/${props.idActivite}`,
      }
      mutation.mutate(data)
  }

  return (
    <div className={`widget_inscription`}>
      {FormationQuery.isLoading ? 'chargement' : FormationQuery.isError ? 'Error data':
        <>
          {FormationQuery.data.length ===0 ?
            <MbjButton size={"sm"} type="button" onClick={handleClickAdd} themeColor={"primary"} isPending={mutation.isLoading}>
              Programmer une formation
            </MbjButton>:
            <p>Une ou plusieurs formation sont déjà programmées pour cette activité/Collaborateur</p>
          }
        </>
      }
    </div>
  )
}

export const WidgetInscription = styled(WidgetInscriptionCtrl)``
