import * as React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { MbjLoading, MbjNoResult, MbjTable } from "@mbj-front-monorepo/ui";
import { useNavigate } from "react-router-dom";
import { getDateFR } from "@mbj-front-monorepo/utils";
import { useGetMemberAlliances, useGetMemberMe } from "@mbj-front-monorepo/data-access";

const Titles = [
  { libelle: "Nom", Align: "isLeft" },
  { libelle: "Créée par", Align: "isCenter" },
  { libelle: "Créée le", Align: "isCenter" },
  //{ libelle: 'Membres', Align: 'isCenter' },
];

/**
 * Carte contenant des infos sur les à développer :
 */
interface AlliancesTableCtrlProps {
  /**
   * les données sont-elles en train de se charger ?
   */
  isLoading?: boolean;
  /**
   * On veut les alliances actives ou incatives ?
   */
  allianceState?: "active" | "inactive";
  /**
   * Lien de redirection pour le bouton
   */
  redirectBaseUrl?: string;
  className?: string;
}

function AlliancesTableCtrl(props: AlliancesTableCtrlProps) {
  const memberQuery = useGetMemberMe();
  const alliancesQuery = useGetMemberAlliances();
  const navigate = useNavigate();

  const redirect = (id: number) => {
    if (props.redirectBaseUrl) navigate(props.redirectBaseUrl + "/" + id.toString());
    else navigate(id.toString());
  };

  const alliancesFiltered = useMemo(() => {
    if (!alliancesQuery.data) {
      return [];
    }
    return alliancesQuery.data.filter((alliance) => {
      if (props.allianceState === "active") {
        return alliance.isActive;
      } else if (props.allianceState === "inactive") {
        return !alliance.isActive;
      }
      return true;
    });
  }, [alliancesQuery.data]);

  return (
    <MbjTable titles={Titles} className={`tableAlliancesActive ${props.className}`} scrollable maxHeight={"300px"}>
      {alliancesQuery?.isLoading ? (
        <tr>
          <td colSpan={Titles.length}>
            <MbjLoading />
          </td>
        </tr>
      ) : alliancesQuery?.isError ? (
        <tr>
          <td colSpan={Titles.length}>
            <p>Error: {alliancesQuery.error.message}</p>
          </td>
        </tr>
      ) : alliancesFiltered.length > 0 ? (
        alliancesFiltered.map((alliance, i: number) => {
          return (
            <tr
              key={alliance.Alliance?.id}
              className="selectable"
              onClick={() => (alliance.Alliance?.id ? redirect(alliance.Alliance.id) : undefined)}
            >
              <td>{alliance.Alliance?.name}</td>
              <td className={"isCenter"}>{alliance.Alliance?.Owner?.name}</td>
              <td className="isCenter">
                {alliance.Alliance?.creationAt ? getDateFR(new Date(alliance.Alliance.creationAt)) : ""}
              </td>
            </tr>
          );
        })
      ) : (
        <MbjNoResult text={"Aucune alliances " + props.allianceState + "s"} />
      )}
    </MbjTable>
  );
}

export const AlliancesTable = styled(AlliancesTableCtrl)((props) => ({
  width: "100%",
}));
AlliancesTable.defaultProps = {
  allianceState: "active",
};
