import React from "react";
import styled from "styled-components";
import { MbjLoading } from "@mbj-front-monorepo/ui";
import { useGetRepartitionMetiers } from "@mbj-front-monorepo/data-access";
import { randomColorFromNb } from "@mbj-front-monorepo/utils";

interface RepartitionNCtrlProps {
  className?: string;
  height?: string;
}

const RepartitionNCtrl = ({ className, ...props }: RepartitionNCtrlProps) => {
  const { isLoading, isError, data: repartitions } = useGetRepartitionMetiers();
  function colorTable() {
    const obj: any = {};
    if (repartitions) {
      Object.keys(repartitions).forEach((key: any, i: number) => {
        obj[key] = randomColorFromNb(i);
      });
    }

    return obj;
  }
  if (isLoading) {
    return <MbjLoading />;
  }
  if (isError) {
    return <p>Erreurs</p>;
  } else {
    const Colors = colorTable();
    return (
      <div className={`RepartitionMetier ${className}`}>
        <div className="wrapper">
          {repartitions.map((item, idx: number) => (
            <div
              className={"one-repart"}
              key={`R${idx}`}
              style={{ width: `${(item.taux || 0) * 100}%`, background: `${Colors[idx]}` }}
            >
              <div className={"number"}>{item.nbOccurence}</div>
            </div>
          ))}
        </div>
        <div className={"legende"}>
          {repartitions.map((item, idx: number) => (
            <div className={"one-legende"} key={`Leg${idx}`}>
              <div className={"wrapper-legende"}>
                <div className={"square-legende"} style={{ background: `${Colors[idx]}` }} />
                <div className={"libelle"}>{item.champPro}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export const RepartitionN = styled(RepartitionNCtrl)((props) => ({
  width: "100%",
  margin: "0.5rem auto 1rem auto",
  padding: "0.5rem",
  ".legende": {
    display: "flex",
    jutifyContent: "start",
    flexWrap: "wrap",
    ".one-legende": {
      padding: "0.25rem",
      ".wrapper-legende": {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        ".square-legende": {
          width: "20px",
          height: "20px",
          marginRight: "5px",
        },
        ".name": {
          fontSize: "14px",
        },
      },
    },
  },
  ".wrapper": {
    height: props.height ? props.height : "38px",
    marginBottom: "10px",
    width: "100%",
    display: "flex",
    jutifyContent: "start",
    ".one-repart": {
      height: "100%",
      position: "relative",
      ".number": {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        display: "none",
      },
      "&:hover": {
        ".number": {
          display: "block",
        },
      },
    },
  },
}));
