import { useQuery } from "@tanstack/react-query";

export const useEventSourceQuery = (
  url: URL,
  topics: string[],
  eventCallback: {
    eventName: "message" | "open" | "error";
    callback: (event: MessageEvent) => any;
  },
  queryKey?: any
) => {
  for (const topic of topics) {
    url.searchParams.append("topic", topic);
  }

  const fetchData = () => {
    const evtSource = new EventSource(url);
    let data = {
      data: "Aucune donnée n'a été renvoyée par la callback function",
    };

    evtSource.addEventListener(eventCallback.eventName as string, (event) => {
      data = eventCallback.callback(event);
    });

    return data;
  };

  return useQuery(queryKey, fetchData);
};
