import * as React from "react";
import { useEffect } from "react";
import {
  MbjAccordion,
  MbjAccordionContent,
  MbjAccordionItem,
  MbjAccordionTrigger,
  MbjButton,
  MbjLabel,
  MbjLoading,
  MbjModal,
  MbjModalCtrlProps,
  MbjScrollArea,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  useModal,
} from "@mbj-front-monorepo/ui";

import styled from "styled-components";
import { ActiviteItem } from "./ActiviteItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getDateEN } from "@mbj-front-monorepo/utils";
import {
  useAddActiviteUserMetiers,
  useDeleteUserMetier,
  useGetCompetencesUserMetiers,
  useGetUserMetier,
  useMutateUserMetier,
} from "@mbj-front-monorepo/data-access";
import { MbjFindActivite } from "@mbj-front-monorepo/data-ui";
import { GoPlus } from "react-icons/go";

interface ModifierMetierModalCtrlProps extends MbjModalCtrlProps {}

function ModifierMetierModalCtrl(props: ModifierMetierModalCtrlProps) {
  const { id } = useParams();
  const userMetierQuery = useGetUserMetier(parseInt(id || "-1"));
  const queryCompetencesUserMetiers = useGetCompetencesUserMetiers(parseInt(id || "-1"));
  const UMMutation = useMutateUserMetier(parseInt(id || "-1"));
  const mutation = useDeleteUserMetier(parseInt(id || "-1"));
  const mutationAddActivite = useAddActiviteUserMetiers(parseInt(id || "-1"));

  const navigate = useNavigate();
  const location = useLocation();
  const { isShowing, toggle } = useModal();

  //Suppresion du métier :
  function handleDelete() {
    mutation.mutate(undefined, {
      onSettled: () => {
        navigate(-1);
      },
    });
  }

  //Ajout d'une compétence :
  const handleAddActivite = (data: { idActivite: number; niveau: number }) => {
    mutationAddActivite.mutate(
      {
        UserMetier: "/api/user_metiers/" + userMetierQuery.data?.id,
        niveau: data.niveau,
        Activite: "/api/activites/" + data.idActivite,
      },
      {
        onSuccess: () => {
          toggle();
        },
      }
    );
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const defaultValues = {
      namePerso: userMetierQuery.data?.namePerso,
      formatCible: userMetierQuery.data?.formatCible ? userMetierQuery.data?.formatCible : "",
      formatCibleAt: userMetierQuery.data?.formatCibleAt
        ? getDateEN(new Date(userMetierQuery.data?.formatCibleAt))
        : "",
    };
    reset(defaultValues);
  }, [reset, userMetierQuery.data]);

  const onSubmit = (data: any) => {
    const myData = {
      namePerso: data.namePerso,
      formatCible: data.formatCible ? parseInt(data.formatCible) : null,
      formatCibleAt: data.formatCibleAt ? getDateEN(new Date(data.formatCibleAt)) : null,
    };
    //console.log(myData);
    UMMutation.mutate(myData);
  };

  return (
    <MbjModal {...props} isSubRoute={true} title="Modification du métier" maxWidth={"700px"}>
      {userMetierQuery.isLoading ? (
        <MbjLoading />
      ) : userMetierQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <div className={"infosMetier-container"}>
            <form onSubmit={handleSubmit(onSubmit)} className="namePersoForm">
              <MbjSimpleInputContainer errors={errors} label={"Libellé"}>
                <MbjSimpleInput
                  name={"namePerso"}
                  id={"namePerso"}
                  noPadding
                  register={register}
                  registerOptions={{
                    required: {
                      value: true,
                      message: "Libellé requis",
                    },
                    minLength: {
                      value: 4,
                      message: "Nécessite au moins 4 caractères",
                    },
                  }}
                />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={errors} label={"Format Cible"}>
                <MbjSimpleInput name={"formatCible"} type={"number"} id={"formatCible"} noPadding register={register} />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={errors} label={"Date du format Cible"}>
                <MbjSimpleInput
                  name={"formatCibleAt"}
                  type={"date"}
                  id={"formatCibleAt"}
                  noPadding
                  register={register}
                />
              </MbjSimpleInputContainer>
              <MbjButton isPending={UMMutation.isLoading}>Enregistrer</MbjButton>
            </form>
            <div className="LibelleContainer">
              <MbjLabel>Depuis la fiche MBJ :</MbjLabel>
              <div className="data">{userMetierQuery.data?.Metier?.libelle}</div>
            </div>
            <div className="DomaineContainer">
              <MbjLabel>Domaine :</MbjLabel>
              <div className="data">{userMetierQuery.data?.Metier?.champPro}</div>
            </div>
            <div className="CompetencesContainer">
              <div>
                <MbjLabel>Compétences :</MbjLabel>
                <MbjButton
                  leftIcon={<GoPlus />}
                  size={"sm"}
                  themeColor={"secondary"}
                  onClick={toggle}
                  alignSelf={"flex-end"}
                >
                  Ajouter une compétence
                </MbjButton>
                <MbjModal hide={toggle} isShowing={isShowing} title="Ajout d'une compétence">
                  <MbjFindActivite CallBackFx={handleAddActivite} isPending={mutationAddActivite.isLoading} />
                </MbjModal>
              </div>
              <MbjScrollArea maxHeight={"350px"}>
                {queryCompetencesUserMetiers.isLoading ? (
                  <MbjLoading />
                ) : queryCompetencesUserMetiers.isError ? (
                  <p>Erreur ...</p>
                ) : (
                  <MbjAccordion type="multiple" defaultValue={["item-0", "item-1"]} className={"accordéon"}>
                    {queryCompetencesUserMetiers.data?.map((compUserMetier, idx: number) => (
                      <MbjAccordionItem value={`item-${idx}`} key={compUserMetier.Competence?.id}>
                        <MbjAccordionTrigger>
                          <div className="accordion-trigger-content">
                            <span>{compUserMetier.Competence?.libelle}</span>
                          </div>
                        </MbjAccordionTrigger>
                        <MbjAccordionContent>
                          <ActiviteItem competenceId={compUserMetier.Competence?.id || -1} />
                        </MbjAccordionContent>
                      </MbjAccordionItem>
                    ))}
                  </MbjAccordion>
                )}
              </MbjScrollArea>
            </div>
          </div>
          <MbjButton
            onClick={handleDelete}
            isPending={mutation.isLoading}
            themeColor={"warning"}
            size={"sm"}
            className={"deleteMetier"}
          >
            Supprimer
          </MbjButton>
        </>
      )}
    </MbjModal>
  );
}
export const ModifierMetierModal = styled(ModifierMetierModalCtrl)((props) => ({
  ".infosMetier-container": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",

    ".namePersoForm": {
      marginBottom: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "9px",

      button: {
        alignSelf: "flex-end",
      },
    },

    ".label": {
      color: props.theme.lightText,
    },
    ".data": {
      color: props.theme.primary,
      fontWeight: 600,
    },
  },
  ".CompetencesContainer": {
    "& > div:first-child": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
  },
  ".deleteMetier": {
    marginLeft: "auto",
    marginTop: "0.6em",
  },
}));
