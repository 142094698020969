import * as React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createSelectColumn,
  MbjButton,
  MbjCardClassic,
  MbjConfirmModal,
  MbjFlex,
  MbjLink,
  MbjLoading,
  MbjNavPath,
  MbjNoResult,
  MbjScrollArea,
  MbjTableV2,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import { useDeleteRecrutementSaved, useGetRecrutementSaveds } from "@mbj-front-monorepo/data-access";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";

interface ResumeCtrlProps {
  className?: string;
}

function ResumeCtrl(props: ResumeCtrlProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const RecherchesSavedQuery = useGetRecrutementSaveds();
  const mutation = useDeleteRecrutementSaved();
  const { isShowing, toggle } = useModal();

  const columnHelper = createColumnHelper<GetElementType<typeof RecherchesSavedQuery.data>>();

  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.accessor((row) => row.Metier?.libelle || row.UserMetier?.namePerso, {
      header: "Libellé",
      cell: (info) => <MbjLink to={`../resume/${info.row.original?.id}`}>{info.getValue()}</MbjLink>,
    }),
    columnHelper.accessor((row) => row.nbRecl, {
      header: "Nombre de recrutements",
    }),

    columnHelper.accessor((row) => row.nbProfils, {
      header: "Nombre de profils",
    }),
    columnHelper.accessor((row) => (row.Metier ? "Référentiel my-better-job" : "Référentiel de l'entreprise"), {
      header: "Type de référentiel",
    }),
    columnHelper.accessor((row) => row.creationAt, {
      header: "Date de création",
      cell: (info) => {
        const value = info.getValue();
        return value ? getDateFR(new Date(value)) : "--";
      },
    }),
  ];

  const table = useReactTable({
    data: RecherchesSavedQuery?.data ?? [],
    columns,
    enableRowSelection: true, //enable row selection for all rows
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Date", desc: true }],
    },
  });

  return (
    <div className={"Resume" + props.className}>
      <MbjNavPath routePath={location.pathname} />

      <MbjWidgetClassic title={"Recrutements en cours"} grow={1} bodyGrow={1} bodyDirection={"column"}>
        {RecherchesSavedQuery.isLoading ? (
          <MbjLoading />
        ) : RecherchesSavedQuery.isError ? (
          "Errors"
        ) : RecherchesSavedQuery.data && RecherchesSavedQuery.data.length > 0 ? (
          <>
            <MbjFlex justify={"flex-end"} gap={"10px"}>
              {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
                <MbjButton themeColor={"warning"} onClick={toggle} isPending={false}>
                  Supprimer {table.getSelectedRowModel().rows.length} recrutement(s)
                </MbjButton>
              )}
              <MbjButton onClick={() => navigate("../recherche")}>Nouveau recrutement</MbjButton>
            </MbjFlex>
            <MbjScrollArea grow={1} overflowX={"auto"}>
              <MbjTableV2 table={table} />
              {/*<MbjFlex direction={"column"}>*/}
              {/*  {RecherchesSavedQuery.data?.map((search, i: number) => (*/}
              {/*    <SavedSearchCard key={search.id} search={search} />*/}
              {/*  ))}*/}
              {/*</MbjFlex>*/}
            </MbjScrollArea>
            <MbjConfirmModal
              isShowing={isShowing}
              hide={toggle}
              title="Confirmation de suppression de recherche"
              confirmText={`Etes-vous sûr de vouloir supprimer ${
                table.getSelectedRowModel().rows.length
              } recrutement(s) ?`}
              confirmFunction={() => {
                mutation.mutate(
                  table.getSelectedRowModel().rows.map((row) => row.original.id || 0),
                  {
                    onSuccess: () => {
                      toggle();
                      table.toggleAllRowsSelected(false);
                    },
                  }
                );
              }}
              isLoading={mutation.isLoading}
            />
          </>
        ) : (
          <MbjCardClassic>
            <MbjNoResult text={"Aucun recrutements en cours"} />
          </MbjCardClassic>
        )}
      </MbjWidgetClassic>
    </div>
  );
}
export const Resume = styled(ResumeCtrl)((props) => ({
  padding: "1% 2%",
  display: "flex !important",
  flexDirection: "column",
  height: "100%",

  ".searchCard": {
    overflow: "visible",

    ".searchHeader": {
      borderRadius: "0.4em 0.4em 0 0",
    },
    ".searchContent": {
      padding: "0.5em 1em",
      button: {
        marginLeft: "auto",
        marginTop: "10px",
        width: "fit-content",
      },
    },
  },
}));
