import * as React from "react";
import styled from "styled-components";
import { MbjLoading, MbjModal, MbjModalCtrlProps } from "@mbj-front-monorepo/ui";
import { useGetCompareRecl } from "@mbj-front-monorepo/data-access";

interface ReclassementModalCompareCtrlProps extends MbjModalCtrlProps {
  className?: string;
  idR: number;
}

const ReclassementModalCompareCtrl = (props: ReclassementModalCompareCtrlProps) => {
  const { isLoading, isError, data } = useGetCompareRecl(props.idR);
  return (
    <MbjModal title={"Comparaison du métier reclassé avec vos métiers"} {...props} maxWidth={"65%"} fitContent>
      {isLoading ? (
        <MbjLoading />
      ) : isError ? (
        "erreurs"
      ) : (
        data?.map((item: typeof data[0], idx: number) => (
          <div className={"OneMetierCompare"} key={item.id}>
            <div className={"libMet"}>{item.UserMetier?.namePerso}</div>
            <div className={"Pc"}>{Math.round((item.score||0)*100)}%</div>
          </div>
        ))
      )}
    </MbjModal>
  );
};

export const ReclassementModalCompare = styled(ReclassementModalCompareCtrl)`
  .OneMetierCompare {
    padding: 0.5rem;
    border-bottom: solid #ccc 1px;
    display: flex;
    justify-content: start;
    .libMet {
      font-weight: bold;
      margin-right: 10px;
    }
  }
`;
