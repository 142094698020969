import styled from "styled-components";
import {Operation, useGetResultsSearchReclassementBySearch} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjScrollArea, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {CardReclassementFind} from "./CardReclassementFind";
import * as React from "react";
import {useMemo} from "react";

interface BlockResultReclassementCtrlProps{
  className?:string;
  Search:Operation<"gETSearchsItem">
}

const BlockResultReclassementCtrl = (props:BlockResultReclassementCtrlProps)=>{
  const ResultsQuery = useGetResultsSearchReclassementBySearch(props.Search.id||null)
  const ProfilsCompatible = useMemo(()=>{
    if(ResultsQuery.data){
      return ResultsQuery.data?.sort((a, b) => (a.score || 0) > (b.score || 0) ? -1 : 1).filter(r => r.statut?.id === 1)
    } else {
      return []
    }
  }, [ResultsQuery.data])
  const ProfilsSauvegard = useMemo(()=>{
    if(ResultsQuery.data){
      return ResultsQuery.data?.sort((a, b) => (a.score || 0) > (b.score || 0) ? -1 : 1).filter(r => r.statut?.id === 2)
    } else {
      return []
    }
  }, [ResultsQuery.data])
  const ProfilsRejeteBut = useMemo(()=>{
    if(ResultsQuery.data){
      return ResultsQuery.data?.sort((a, b) => (a.score || 0) > (b.score || 0) ? -1 : 1).filter(r => r.statut?.id === 3 && ((r.scoreWhenStatut||0) > (r.score||0)))
    } else {
      return []
    }
  }, [ResultsQuery.data])
  return (
    <MbjWidgetClassic title={"Résultat de la recherche par Reclassement"} basis={"100%"} className={`bl ${props.className}`}>

      {ResultsQuery.isLoading || ResultsQuery.isLoading ? (
        <MbjLoading />
      ) : ResultsQuery.isError || ResultsQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          {ResultsQuery.data.length === 0 ?
            <p className={"no-result"}>Malheureusement à l'heure actuelle, aucun reclassement ne correspond à votre recherche</p>:
            <>
              <div className={`one_block_result`}>
                <div className={`nb_resultat`}>Nombre de Reclassements compatibles : {ProfilsCompatible.length}</div>
                <MbjScrollArea maxHeight={"300px"} basis={"100%"}>
                  {ProfilsCompatible.map((item, idx: number) => (
                    <CardReclassementFind key={`one_result${idx}`} MemberMetierReclassement={item.Reclassement} score={item.score} idSearch={item.id||0} idStatut={1} scoreWhenUp={item.scoreWhenStatut}/>
                  ))}
                </MbjScrollArea>
              </div>
              <div className={`one_block_result`}>
                {ProfilsSauvegard.length === 0 ?
                  <p className={"no-result"}>Vous n'avez pas encore retenu de profil</p>:
                  <>
                    <div className={`nb_resultat`}>Nombre de Reclassements retenus : {ProfilsSauvegard.length}</div>
                    <MbjScrollArea maxHeight={"300px"}>
                      {ProfilsSauvegard.map((item, idx: number) => (
                        <CardReclassementFind key={`one_result${idx}`} MemberMetierReclassement={item.Reclassement} score={item.score} idSearch={item.id||0} idStatut={2} scoreWhenUp={item.scoreWhenStatut}/>
                      ))}
                    </MbjScrollArea>
                  </>
                }
              </div>
              {ProfilsRejeteBut.length > 0 &&
                <div className={`one_block_result`}>
                  <div className={`nb_resultat`}>Reclassements rejetés mais qui ont progressé : {ProfilsRejeteBut.length}</div>
                  <MbjScrollArea maxHeight={"300px"}>
                    {ProfilsRejeteBut.map((item, idx: number) => (
                      <CardReclassementFind key={`one_result${idx}`} MemberMetierReclassement={item.Reclassement} score={item.score} idSearch={item.id||0} idStatut={3} scoreWhenUp={item.scoreWhenStatut}/>
                    ))}
                  </MbjScrollArea>
                </div>
              }
            </>
          }
        </>
      )
      }

    </MbjWidgetClassic>
  )
}
export const BlockResultReclassement = styled(BlockResultReclassementCtrl)`
  .one_block_result{
    width: 100%;
  }
  .nb_resultat{
    font-size: 18px;
    margin-bottom: 15px;
  }
`
