import * as React from "react";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjLabel, MbjLoading, MbjStatsNumber } from "@mbj-front-monorepo/ui";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled, { useTheme } from "styled-components";
import { useGetMemberMe, useGetStatsEffectifs } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos générales de l'entreprise :
 */
interface EffectifsCardCtrlProps extends MbjCardClassicCtrlProps {}

function EffectifsCardCtrl(props: EffectifsCardCtrlProps) {
  const theme = useTheme();
  const memberQuery = useGetMemberMe();
  const statsEffectifQuery = useGetStatsEffectifs();

  return (
    <MbjCardClassic {...props} title="Effectifs" className={props.className} grow={props.grow}>
      {statsEffectifQuery.isLoading ? (
        <MbjLoading />
      ) : statsEffectifQuery.error ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <div className="bigNumbers">
            <div className={"lineNumberContainer"}>
              <MbjStatsNumber
                label={"Effectif actuel de la société"}
                value={statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].effectif : 0}
                themeColor={"primary"}
              />
              <MbjStatsNumber label={"Evolution sur 1 an"} />
            </div>
            <div className={"lineNumberContainer"}>
              <MbjStatsNumber label={"Effectif prévu à 12 mois"} />
              <MbjStatsNumber label={"Evolution sur 5 ans"} />
            </div>
          </div>


            <div className="graphContainer">
              <MbjLabel>Evolution des effectifs de {memberQuery.data?.name}</MbjLabel>
              {statsEffectifQuery.data.length ===1 &&
                <div className={"no_graph"}>
                  <p>L'ancienneté de l'entreprise ne permet pas d'afficher une information pertinente</p>
                </div>
              }
              <ResponsiveContainer width="94%" height={250}>
                <LineChart data={statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].evolutions : []}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip />
                  {/*
                                      <Legend />
                                      */}
                  <Line type="monotone" dataKey="effectif" stroke={theme.complementary} />
                </LineChart>
              </ResponsiveContainer>
            </div>

        </>
      )}
    </MbjCardClassic>
  );
}
export const EffectifsCard = styled(EffectifsCardCtrl)((props) => ({
  ".bigNumbers": {
    padding: "0 2rem",

    ".lineNumberContainer": {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "space-between",
      gap: "25px",
      flexWrap: "wrap",
    },
  },
  ".graphContainer": {
    marginLeft: "-40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
    minWidth: "150px",
    marginTop: "60px",
    position:"relative",
    ".no_graph":{
      width:"calc(100% - 36px)",
      height:"100%",
      position:"absolute",
      left:"36px",
      top:0,
      zIndex:"3",
      background:"rgba(0,0,0,.6)",
      display:"flex",
      justifyContent:"center",
      flexDirection:"column",
      "p":{
        textAlign:'center',
        color:"white",
        fontSize:"18px"
      }
    }
  },
}));
