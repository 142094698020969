import styled from "styled-components";
import {useGetAllNoteResPartBySearch} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {FormNotePart} from "./FormNotePart";

interface GestionNoteForPartCtrlProps{
  className?:string;
  idResult:number;
}

const GestionNoteForPartCtrl = (props:GestionNoteForPartCtrlProps)=>{
  const NotesQuery = useGetAllNoteResPartBySearch(props.idResult)
  return (
    <div className={"gestion_notes_part "+ props.className}>
      {NotesQuery.isLoading ? <MbjLoading/> : NotesQuery.isError ? 'Erreur BD' :
        NotesQuery.data?.length === 0 ?
          <div className={"no_notes"}>Pas encore de note pour ce profil</div>:
          NotesQuery.data.map(item=>(
            <FormNotePart Note={item||null} key={`one_note_edit${props.idResult}${item.id}`} idResult={props.idResult||0}/>
          ))
      }
      <div className={"nouvelle_note"}>
        <h4>Nouvelle note</h4>
        <div className={"wrap_new_note"}>
          <FormNotePart Note={null} idResult={props.idResult||0}/>
        </div>
      </div>
    </div>
  )
}

export const GestionNoteForPart = styled(GestionNoteForPartCtrl)`

`
