import styled from "styled-components";
import React from "react";
import {orgaFormation} from "../AutoFormation/EmployeeAutoFormation";

interface CoordOF{
  className?:string,
  titre:string;
  infos:React.ReactNode;
}

const CoordOFCtrl = (props:CoordOF)=>{
  return (
    <div className={`line_coord ${props.className}`}>
      <div className={`titre`}>{props.titre}</div>
      <div className={`infos`}>{props.infos}</div>
    </div>
  )
}

export const CoordOF = styled(CoordOFCtrl)`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  .link_mail{
    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }
`
