import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NotFound } from "../NotFound/NotFound";
import { MesReclassements } from "./MesReclassements/MesReclassements";
import { NouveauReclassement } from "./NouveauReclassement/NouveauReclassement";
import styled from "styled-components";
import { InternDesktopLayout, MbjLink, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { ReclassementStatistiques } from "./ReclassementStatistiques/ReclassementStatistiques";

interface ReclassementsCtrlProps {}

function ReclassementsCtrl(props: ReclassementsCtrlProps) {
  return (
    <div className="Reclassements">
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              mbjSectionMenu={
                <MbjSectionMenu>
                  <MbjLink to="mes-reclassements">Mes reclassements</MbjLink>
                  <MbjLink to="statistiques">Statistiques</MbjLink>
                </MbjSectionMenu>
              }
            />
          }
        >
          <Route path="" element={<Navigate to="mes-reclassements" />} />
          <Route path="/mes-reclassements" element={<MesReclassements />} />
          <Route path="/nouveau-reclassement" element={<NouveauReclassement />} />
          <Route path="/statistiques" element={<ReclassementStatistiques />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
export const Reclassements = styled(ReclassementsCtrl)((props) => ({}));
