import * as React from "react";
import { useEffect } from "react";
import {
  MbjButton,
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjHeading,
  MbjLoading,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { useGetEmployee, useMutateEmployee } from "@mbj-front-monorepo/data-access";
import { FormProvider, useForm } from "react-hook-form";
import { getDateEN } from "@mbj-front-monorepo/utils";
import styled from "styled-components";
import { MbjEmployeeEditEntrepriseInfos } from "./MbjEmployeeEditEntrepriseInfos";

interface MbjEmployeeEditInformationsCardCtrlProps extends MbjCardClassicCtrlProps {
  /**
   * Classe html de la carte :
   */
  className?: string;

  employeeId?: number;

  memberId?: number;
}

const MbjEmployeeEditInformationsCardCtrl = (props: MbjEmployeeEditInformationsCardCtrlProps) => {
  const employeeQuery = useGetEmployee(props.employeeId);
  const mutation = useMutateEmployee(props.employeeId || -1);

  const defaultValues = {
    nom: employeeQuery.data?.nom,
    prenom: employeeQuery.data?.prenom,
    email: employeeQuery.data?.email,
    telephone1: employeeQuery.data?.telephone1,
    telephone2: employeeQuery.data?.telephone2,
    adresse: employeeQuery.data?.adresse,
    codePostal: employeeQuery.data?.codePostal,
    ville: employeeQuery.data?.ville,
    birthAt: employeeQuery.data?.birthAt ? getDateEN(new Date(employeeQuery.data.birthAt)) : null,
    UserMetier: employeeQuery.data?.UserMetier,
    Service: employeeQuery.data?.Service || null,
    salary: employeeQuery.data?.salary,
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const resetValues = (defaultValues: any) => {
    methods.reset(defaultValues);
  };

  useEffect(() => {
    resetValues(defaultValues);
  }, [methods.reset, employeeQuery.data]);

  return (
    <MbjCardClassic title="Informations générales" className={props.className} {...props}>
      {employeeQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.error ? (
        <p>Erreur ...</p>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((data) => {
              const datas = {
                ...data,
                UserMetier: `/api/user_metiers/${data.UserMetier?.id}`,
                Service: data.Service && data.Service.id !== 0 ? `/api/services/${data.Service.id}` : null,
                birthAt: data.birthAt ? data.birthAt.toString() : null,
              };
              console.log(datas);
              mutation.mutate(datas);
            })}
            className="Infos"
          >
            <MbjHeading>Coordonnées</MbjHeading>
            <div className={"rowContainer"}>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom *"}>
                <MbjSimpleInput
                  name={"nom"}
                  id={"nom"}
                  noPadding
                  register={methods.register}
                  registerOptions={{
                    required: {
                      value: true,
                      message: "Nom requis",
                    },
                  }}
                />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Prénom *"}>
                <MbjSimpleInput
                  name={"prenom"}
                  id={"prenom"}
                  noPadding
                  register={methods.register}
                  registerOptions={{
                    required: {
                      value: true,
                      message: "Prénom requis",
                    },
                  }}
                />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Date d'intégration"}>
                <MbjSimpleInput type={"date"} name={"birthAt"} id={"birthAt"} noPadding register={methods.register} />
              </MbjSimpleInputContainer>
            </div>
            <div className={"rowContainer"}>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Email *"}>
                <MbjSimpleInput
                  name={"email"}
                  id={"email"}
                  noPadding
                  register={methods.register}
                  registerOptions={{
                    required: {
                      value: true,
                      message: "Email requis",
                    },
                  }}
                />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Téléphone fixe"}>
                <MbjSimpleInput name={"telephone1"} id={"telephone1"} noPadding register={methods.register} />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Téléphone portable"}>
                <MbjSimpleInput name={"telephone2"} id={"telephone2"} noPadding register={methods.register} />
              </MbjSimpleInputContainer>
            </div>
            <div className={"rowContainer"}>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Adresse"}>
                <MbjSimpleInput name={"adresse"} id={"adresse"} noPadding register={methods.register} />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Code postal"}>
                <MbjSimpleInput name={"codePostal"} id={"codePostal"} noPadding register={methods.register} />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={methods.formState.errors} label={"Ville"}>
                <MbjSimpleInput name={"ville"} id={"ville"} noPadding register={methods.register} />
              </MbjSimpleInputContainer>
            </div>
            {props.memberId && (
              <MbjEmployeeEditEntrepriseInfos employee={employeeQuery.data} memberId={props.memberId} />
            )}
            <div className={"interactContainer"}>
              {methods.formState.isDirty && (
                <MbjButton type="button" onClick={() => resetValues(defaultValues)} themeColor={"warning"}>
                  Réinitialiser
                </MbjButton>
              )}
              <MbjButton isPending={mutation.isLoading}>Confirmer</MbjButton>
            </div>
          </form>
        </FormProvider>
      )}
    </MbjCardClassic>
  );
};
export const MbjEmployeeEditInformationsCard = styled(MbjEmployeeEditInformationsCardCtrl)((props) => ({
  form: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    padding:"12px",
    ".rowContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "start",
      gap: "20px",
    },
    ".title": {
      marginTop: "20px",
    },
    ".interactContainer": {
      display: "flex",
      flexDirection: "row",
      gap: "15px",
      alignSelf: "end",
    },
  },
}));
