import * as React from "react";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  MbjButton,
  MbjCardClassic,
  MbjLoading,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import { useForm } from "react-hook-form";
import { useGetMemberMe, useMutateMember } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos générales de l'entreprise :
 */
interface ResumeCardCtrlProps {
  /**
   * Classe html de la carte :
   */
  className?: string;
  /**
   * Largeur que doit prendre cette carte :
   */
  grow?: number;
  /**
   * les données sont-elles en train de se charger ?
   */
  isLoading?: boolean;
}

function ResumeCardCtrl(props: ResumeCardCtrlProps) {
  const mutation = useMutateMember();
  const memberQuery = useGetMemberMe();

  const methods = useForm({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return memberQuery.data;
    }, [memberQuery.data]),
  });
  useEffect(() => {
    methods.reset(memberQuery.data);
  }, [memberQuery.data]);

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  return (
    <MbjCardClassic title="Présentation de l’entreprise pour l’extérieur" className={props.className} grow={props.grow}>
      {memberQuery.isLoading ? (
        <MbjLoading />
      ) : memberQuery.error ? (
        <p>Erreur ...</p>
      ) : (
        <form onSubmit={methods.handleSubmit(onSubmit)} id="description-form">
          <MbjSimpleInputContainer errors={methods.formState.errors}>
            <MbjSimpleTextArea
              name={"description"}
              id={"description"}
              height={"80px"}
              noPadding
              register={methods.register}
              resize={"none"}
              registerOptions={{
                maxLength: {
                  value: 750,
                  message: "Description trop longue",
                },
              }}
            />
          </MbjSimpleInputContainer>
          {methods.formState.isDirty &&
            <MbjButton isPending={mutation.isLoading}>Sauvegarder</MbjButton>
          }
        </form>
      )}
    </MbjCardClassic>
  );
}
export const ResumeCard = styled(ResumeCardCtrl)((props) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
    button: {
      alignSelf: "end",
      marginTop: "15px",
    },
  },
}));
