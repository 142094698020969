import styled from "styled-components";
import {
  components, useDeleteNewNotEmployess,
  useDeleteNewNotReclassement, usePostNewNotEmployee,
  usePostNewNotReclassement, usePutNewNotEmployess,
  usePutNewNotReclassement
} from "@mbj-front-monorepo/data-access";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {MbjButton, MbjSimpleInputContainer, MbjSimpleTextArea} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {BsPencil} from "react-icons/bs";

interface FormNoteEmpCtrlProps{
  className?:string;
  Note:components["schemas"]["NotesResultsEmployee.jsonld-notesresultsemp.read"]|null;
  idResult:number,
}

const FormNoteEmpCtrl = (props:FormNoteEmpCtrlProps)=>{
  const [edition, setEdition] = useState(props.Note ? false : true);
  const mutationAdd = usePostNewNotEmployee();
  const mutationTrash = useDeleteNewNotEmployess();
  const mutationPut = usePutNewNotEmployess();
  const methods = useForm({
    mode:'onChange', defaultValues:{comment:''}
  })
  useEffect(()=>{
    if(props.Note){
      methods.reset({comment:props.Note.comment || ''})
    }
  }, [props.Note])
  const onSubmit = (data:any)=>{
    console.log(data);
    if(!props.Note) {
      mutationAdd.mutate({
        comment: data.comment,
        ResultsSearch: "/api/results_search_employees/" + props.idResult
      }, {
        onSuccess: () => {
          methods.reset();
        }
      })
    } else {
      mutationPut.mutate({id:props.Note && props.Note.id ? props.Note.id : 0, datas:{comment: data.comment}})
    }
  }

  const TrashMe = (e:any)=>{
    e.preventDefault();
    if(props.Note){
      mutationTrash.mutate(props.Note.id||0)
    }
  }

  return (
    <div className={"Form_note_emp" + props.className}>
      {props.Note && props.Note.creationAt &&
        <div className={`date_note`} style={{marginBottom:"6px", fontWeight:"bold"}}>{new Date(props.Note.creationAt).toLocaleDateString()}</div>
      }
      {!edition ?
        <div className={`one_note_before_edition`}>
          <div className={`in_comment`}>{props.Note?.comment}</div>
          <button className={`btn_edit`} onClick={()=>setEdition(o=>!o)}><BsPencil/></button>
        </div>:
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MbjSimpleInputContainer errors={methods.formState.errors}>
            <MbjSimpleTextArea
              name={"comment"}
              id={"comment"}
              height={"80px"}
              noPadding
              register={methods.register}
              resize={"none"}
              registerOptions={{
                maxLength: {
                  value: 750,
                  message: "Note trop longue",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <div className={"wrap_sender"}>
            <MbjButton size={"xs"} themeColor={"primary"} onClick={methods.handleSubmit(onSubmit)} disabled={mutationAdd.isLoading || mutationTrash.isLoading} isPending={mutationAdd.isLoading || mutationTrash.isLoading}>
              Enregistrer
            </MbjButton>
            {props.Note &&
              <MbjButton size={"xs"} themeColor={"warning"} onClick={TrashMe} disabled={mutationAdd.isLoading || mutationTrash.isLoading} isPending={mutationAdd.isLoading || mutationTrash.isLoading}>
                Supprimer
              </MbjButton>
            }
            {edition &&
              <MbjButton size={"xs"} themeColor={"primary"} onClick={()=>setEdition(o=>!o)} disabled={mutationAdd.isLoading || mutationTrash.isLoading} isPending={mutationAdd.isLoading || mutationTrash.isLoading}>
                Annuler
              </MbjButton>
            }
          </div>
        </form>
      }

    </div>
  )
}

export const FormNoteEmp = styled(FormNoteEmpCtrl)`
  .one_note_before_edition{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    margin-bottom: 25px;
    border-bottom: solid #ccc 1px;
    .btn_edit{
      visibility: hidden;
      border:none;
      background: ${props => props.theme.primaryPastel};
      width: 28px;
      height: 28px;
      border-radius: 50%;
      &:hover{
        cursor: pointer;
      }
    }
    &:hover{
      .btn_edit{
        visibility: visible;
      }
    }
  }
  .wrap_sender{
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    gap: 6px;
  }
`
