import * as React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { MbjLoading, MbjNavPath } from "@mbj-front-monorepo/ui";
import { useGetEmployee, useGetMemberMe } from "@mbj-front-monorepo/data-access";
import { MbjEmployeeEditInformationsCard } from "@mbj-front-monorepo/data-ui";

interface InformationsCtrlProps {
  className?: string;
}

function InformationsCtrl(props: InformationsCtrlProps) {
  const location = useLocation();
  const { id } = useParams();
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));
  const memberQuery = useGetMemberMe();

  return (
    <div className={"Informations " + props.className}>
      {employeeQuery.isLoading || memberQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.isError || memberQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom,
            }}
          />
          <MbjEmployeeEditInformationsCard employeeId={id ? parseInt(id) : -1} memberId={memberQuery.data.id} />
        </>
      )}
    </div>
  );
}
export const Informations = styled(InformationsCtrl)((props) => ({
  padding: "1% 2%",
}));
