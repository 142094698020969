import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {ProfilRetenusOne} from "./ProfilRetenusOne";


interface ProfilRetenusListCtrlProps{
  className?:string;
  searchs:(components["schemas"]["Searchs-searchs.read_read.Member_read.UserMetier_read.Metier"])[]
  clickCompare:(type:"Emp"|"Part"|"Recl", result:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>void;
}

const ProfilRetenusListCtrl = (props:ProfilRetenusListCtrlProps)=>{
  return (
    <div className={`profils_retenus_um ${props.className}`}>
      {props.searchs.map(item=>(
        <ProfilRetenusOne
          key={`PR${item.id}`}
          MetierId={item.Metier ? item.Metier.id||0 : 0}
          UserMetierId={item.UserMetier ? item.UserMetier.id||0 : 0}
          libelle={item.UserMetier ? item.UserMetier.namePerso||'' : item.Metier?.libelle||''}
          id={item.id||0}
          clickCompare={props.clickCompare}
        />
      ))}
    </div>
  )
}
export const ProfilRetenusList = styled(ProfilRetenusListCtrl)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
