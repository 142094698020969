import styled from "styled-components";
import React from "react";
import { FlexItemOptions, omitFlexProps, useFlexItem } from "../../flex-utils";
import { BaseColorsTheme } from "../../../assets/styles/ThemeType";
import { BaseButonOptions } from "./button-types";
import { MbjLoading } from "../MbjLoading/MbjLoading";

interface MbjIconButtonCtrlProps extends FlexItemOptions, BaseButonOptions {
  /**
   * Couleur utilisée pour représenter l'ordre d'importance du bouton sur la page :
   */
  themeColor?: BaseColorsTheme;
  /**
   * Couleur  de base de l'élément
   */
  basicColor?: "white" | "black";

  size?: "xs" | "sm" | "md" | "lg";
  /**
   * En train de travailler ?
   */
  isPending?: boolean;
  /**
   * Icone personnalisée :
   */
  icon: React.ReactElement;

  ariaLabel: string;

  isRound?: boolean;
  margin?:string;
}

function MbjIconButtonCtrl(props: MbjIconButtonCtrlProps) {
  const { themeColor, basicColor, size, isPending, icon, ariaLabel, isRound, ...rest } = omitFlexProps(props);
  return (
    <button {...rest} disabled={props.isPending ? props.isPending : props.disabled} aria-label={ariaLabel}>
      {props.icon}
      {props.isPending && <MbjLoading isSpinner height={"100%"} className={"spinner"} />}
    </button>
  );
}
/**
 * Composant représentant un icone censé être cliqué
 */
export const MbjIconButton = styled(MbjIconButtonCtrl)((props) => ({
  display: "inline-flex",
  cursor: props.disabled ? "default" : "pointer",
  padding: props.size === "xs" ? "0.35rem" : props.size === "sm" ? "0.5rem" : props.size === "md" ? "0.7rem" : "0.9rem",
  backgroundColor: props.themeColor ? props.theme[props.themeColor] : "none",
  fontSize: props.size ? props.theme.fontSize[props.size] : "none",
  color: props.theme.background2,
  border: "1px solid transparent",
  borderRadius: !props.isRound ? "0.3em" : "50%",
  height: "fit-content",
  width: "fit-content",
  position: "relative",
  margin:props.margin ? props.margin : "0",

  "& > :not(.spinner)": {
    visibility: props.isPending ? "hidden" : "visible",
  },
  "& > .spinner": {
    position: "absolute",
    padding: "inherit",
    top: 0,
    left: 0,
  },

  "&:focus-visible": {
    outline: "2px solid blue",
  },

  "&:hover, &:focus": {
    backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Darken`] : "none",
    border: "1px solid " + (props.themeColor ? props.theme[props.themeColor] : "none"),
  },

  "&:active": {
    border: "1px solid transparent",
    boxShadow: "0px 0px 4px 4px " + (props.themeColor ? props.theme[`${props.themeColor}Darken`] : "none"),
    backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Lighten`] : "none",
  },

  ".icon:hover": {
    fill: props.themeColor ? props.theme[props.themeColor] : "none",
  },
  "&:disabled": {
    transform: "none",
    border: "1px solid transparent",
    boxShadow: "none",
    backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Pastel`] : "none",
  },
  ...useFlexItem(props),
}));
MbjIconButton.defaultProps = {
  themeColor: "primary",
  disabled: false,
  basicColor: "black",
  type: "button",
  size: "md",
};
