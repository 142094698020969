import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { MbjFlex, MbjLoading, MbjNavPath } from "@mbj-front-monorepo/ui";
import { MetrixMetier } from "./MetrixMetier";
import { FormationsToWidget } from "./FormationsToWidget/FormationsToWidget";
import { useGetEmployee, useGetMetier, useMutateMobiliteSaved } from "@mbj-front-monorepo/data-access";
import { MbjCompareReferentiel } from "@mbj-front-monorepo/data-ui";

interface MobiliteDetailsCtrlProps {
  className?: string;
}

const MobiliteDetailsCtrl = (props: MobiliteDetailsCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const { idMetier } = useParams();
  if (!id || !idMetier) {
    throw new Error("id or idMetier is undefined");
  }
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));
  const metierQuery = useGetMetier(parseInt(idMetier || "-1"));

  const savedMobiliteMutation = useMutateMobiliteSaved(parseInt(id));

  useEffect(() => {
    if (!savedMobiliteMutation.isSuccess && !savedMobiliteMutation.isLoading) {
      console.log("mutateMobiliteSaved");
      savedMobiliteMutation.mutate(parseInt(idMetier));
    }
  }, []);

  return (
    <div className={"Mobilites " + props.className}>
      {employeeQuery.isLoading || metierQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.isError || metierQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom,
              },
              { index: 3, label: "Mobilités" },
              { index: 4, label: metierQuery.data?.libelle?.toUpperCase() },
            ]}
          />
          <MbjFlex direction={"column"} basis={"100%"}>
            <h1>Mobilité vers : {metierQuery.data?.libelle}</h1>
            <MbjCompareReferentiel
              idToCompare1={employeeQuery.data.id || -1}
              idToCompare2={metierQuery.data.id || -1}
              typeCompared={"EM"}
            />
            <MetrixMetier Metier={metierQuery.data} />
            <FormationsToWidget employeeId={employeeQuery.data.id} metierId={metierQuery.data.id} />
          </MbjFlex>
        </>
      )}
    </div>
  );
};

export const MobiliteDetails = styled(MobiliteDetailsCtrl)((props) => ({
  padding: "1% 2%",
  h1: {
    fontSize: "24px",
    paddingLeft: "0.5rem",
    margin: "15px auto 20px 0",
  },
}));
