import { fetchApi } from "../../services/fetchApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetRooms = () => {
  const promise = () => fetchApi("/api/rooms", { method: "get" });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["rooms"], promise, {});
};

export const useAddRoom = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (users: number[]) => {
      const userRooms = users.map((user) => ({ User: "/api/users/" + user }));
      const data = { userRooms: userRooms };
      return fetchApi("/api/rooms", { method: "post", json: data });
    },
    {
      onSuccess: (newData) => {
        queryClient.setQueryData(["room", newData.id], newData);
        queryClient.invalidateQueries(["rooms"]);
        toast.success("Conversation crée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
