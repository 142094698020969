import React, { useEffect } from "react";
import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DefaultTheme } from "styled-components";
import { EntrepriseInfoPdf } from "./parts/EntrepriseInfoPdf";
import { EmployeeInfoPdf } from "./parts/EmployeeInfoPdf";
import { EvenementsMarquantsPdf } from "./parts/EvenementsMarquantsPdf";
import { BilanCompetencesPdf } from "./parts/BilanCompetencesPdf";
import { AnalyseCompetencesPdf } from "./parts/AnalyseCompetencePdf";
import { RealisedAndFixedObjectifsPdf } from "./parts/RealisedAndFixedObjectifsPdf";
import { PlanDevCompetencesPdf } from "./parts/PlanDevCompetencesPdf";
import { Operation } from "@mbj-front-monorepo/data-access";
import { EntretienAnnuelFormData } from "../EntretienAnnuelNewForm";

interface EntretienPdfProps {
  text?: string;
  formData?: EntretienAnnuelFormData;
  employeeData?: Operation<"getEmployeeItem">;
  entrepriseData?: Operation<"meMemberCollection">;

  eventsPossibles?: Operation<"getTypeEvtsMarquantCollection">;
  activitesEntretiens?: Operation<"getEmployeeEntretienActiviteCollection">;
  formPasts?: Operation<"getFormationEmployeeCollection">;
  objectifsEntretien?: Operation<"getObjectifsEntretienCollection">;
  user_metiers?: Operation<"getUserMetierCollection">;
  metiers?: Operation<"getMetierCollection">;

  theme: DefaultTheme;
}
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create Document Component
export const EntretienPdf = (props: EntretienPdfProps) => {
  // Create styles
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Oswald",
      color: props.theme.primary,
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: "Oswald",
      color: props.theme.primary,
    },
    label: {
      marginLeft: 8,
      marginBottom: 8,
      fontSize: 14,
      color: props.theme.lightText,
    },
    data: {
      fontSize: 12,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  useEffect(() => {
    console.log("pdf props1 ", props);
  }, [props]);
  console.log("pdf props2", props);

  const date = new Date();

  return (
    <Document>
      <Page style={styles.body}>
        <Text
          style={styles.header}
          render={({ pageNumber }) =>
            pageNumber !== 1
              ? `Entretien périodique ${date.getFullYear()} de ${props.employeeData?.nom} ${props.employeeData?.prenom}`
              : ""
          }
          fixed
        />
        <Text style={styles.title}>
          Entretien périodique de {props.employeeData?.nom} {props.employeeData?.prenom}
        </Text>
        <Text style={styles.author}>réalisé le {date.toLocaleDateString()}</Text>
        <EntrepriseInfoPdf theme={props.theme} entrepriseData={props.entrepriseData} />
        <EmployeeInfoPdf theme={props.theme} employeeData={props.employeeData} />
        {props.formData?.Evts && props.formData?.Evts.length > 0 && (
          <>
            <Text style={styles.subtitle}>Évènements marquant durant l'année</Text>
            <EvenementsMarquantsPdf
              theme={props.theme}
              formData={props.formData}
              eventsPossibles={props.eventsPossibles}
            />
          </>
        )}
        <Text style={styles.subtitle}>Bilan de l'année (ce que j’ai pensé de mon année de travail)</Text>
        <Text style={styles.label}>Ce qui m'a plu</Text>
        <Text style={styles.data}>{props.formData?.liked}</Text>
        <Text style={styles.label}>Ce qui m'a déplu</Text>
        <Text style={styles.data}>{props.formData?.noliked}</Text>
        {props.formData?.Acti && props.formData?.Acti.length > 0 && (
          <>
            <Text style={styles.subtitle}>Évaluation des compétences</Text>
            <BilanCompetencesPdf
              theme={props.theme}
              formData={props.formData}
              activitesEntretiens={props.activitesEntretiens}
            />
          </>
        )}
        {((props.formData?.FormPast && props.formData?.FormPast.length > 0) ||
          (props.formData?.ToRise && props.formData?.ToRise.length > 0) ||
          (props.formData?.Skill && props.formData?.Skill.length > 0)) && (
          <>
            <Text style={styles.subtitle}>Développement des compétences</Text>
            <AnalyseCompetencesPdf theme={props.theme} formData={props.formData} formPasts={props.formPasts} />
          </>
        )}
        {((props.formData?.ObjPast && props.formData?.ObjPast.length > 0) ||
          (props.formData?.NewObj && props.formData?.NewObj.length > 0)) && (
          <>
            <Text style={styles.subtitle}>Réalisation et fixation des objectifs</Text>
            <RealisedAndFixedObjectifsPdf
              theme={props.theme}
              formData={props.formData}
              objectifsEntretien={props.objectifsEntretien}
            />
          </>
        )}
        {((props.formData?.ObjPast && props.formData?.ObjPast.length > 0) ||
          props.formData?.EvoInterneCrtTerme ||
          props.formData?.EvoInterneMoyenTerme ||
          props.formData?.EvoInterneLgTerme ||
          props.formData?.EvoExterneCrtTerme ||
          props.formData?.EvoExterneMoyenTerme ||
          props.formData?.EvoExterneLgTerme) && (
          <>
            <Text style={styles.subtitle}>Réalisation et fixation des objectifs</Text>
            <PlanDevCompetencesPdf
              theme={props.theme}
              formData={props.formData}
              user_metiers={props.user_metiers}
              metiers={props.metiers}
            />
          </>
        )}
        {(props.formData?.comentGeneResp !== "" || props.formData?.comentGeneSalarie !== "") && (
          <View wrap={false}>
            <Text style={styles.subtitle}>Commentaires généraux</Text>
            {props.formData?.comentGeneResp && (
              <>
                <Text style={styles.label}>Commentaires du responsable</Text>
                <Text style={styles.data}>{props.formData?.comentGeneResp}</Text>
              </>
            )}
            {props.formData?.comentGeneSalarie && (
              <>
                <Text style={styles.label}>Commentaires du salarié</Text>
                <Text style={styles.data}>{props.formData?.comentGeneSalarie}</Text>
              </>
            )}
          </View>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};
