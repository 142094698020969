import React from "react";
import styled from "styled-components";
import {
  MbjCard,
  MbjCardBody,
  MbjCardCtrlProps,
  MbjCardFooter,
  MbjCardHeader,
  MbjConfirmModal,
  MbjFlex,
  MbjHeading,
  MbjIconButton,
  MbjModal,
  MbjStaticInfo,
  useModal,
} from "@mbj-front-monorepo/ui";
import { Operation, useDeleteXpPro } from "@mbj-front-monorepo/data-access";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { MbjEmployeeXpEditForm } from "./MbjEmployeeXpEditForm";
import { BiEdit } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";

interface MbjEmployeeXpCardCtrlProps extends MbjCardCtrlProps {
  EmployeeXp: GetElementType<Operation<"getEmployeeXpCollection">>;
  isReadOnly?:boolean;
}

const MbjEmployeeXpCardCtrl = (props: MbjEmployeeXpCardCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const mutationDelete = useDeleteXpPro();
  const HandleConfirmDelete = () => {
    mutationDelete.mutate(props.EmployeeXp.id || -1, {
      onSettled: () => {
        toggleConfirm();
      },
    });
  };
  return (
    <>
      <MbjCard {...props}>
        <MbjCardHeader>
          <MbjHeading>
            Du {props.EmployeeXp.startAt ? getDateFR(new Date(props.EmployeeXp.startAt)) : "inconnu"} à{" "}
            {props.EmployeeXp.endAt ? getDateFR(new Date(props.EmployeeXp.endAt)) : "maintenant"} - {" "}
            <span style={{marginLeft:"7px",textDecoration:"underline"}}>{props.EmployeeXp.Metier?.libelle}</span>
          </MbjHeading>
        </MbjCardHeader>
        <MbjCardBody>
          <MbjFlex direction={"column"}>
            <MbjFlex gap={"15px"}>
              <MbjStaticInfo
                label="Type de contrat : "
                info={props.EmployeeXp.TypeContrat ? props.EmployeeXp.TypeContrat.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
              <MbjStaticInfo
                label="Salaire : "
                info={props.EmployeeXp.salary ? props.EmployeeXp.salary + "€" : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
            </MbjFlex>
            {props.EmployeeXp.description && (
              <MbjStaticInfo label="Description : " info={props.EmployeeXp.description} gap={"5px"} />
            )}
          </MbjFlex>
        </MbjCardBody>
        {!props.isReadOnly &&
          <MbjCardFooter>
            <MbjFlex direction={"row"} gap={"7px"}>
              <MbjIconButton
                size={"sm"}
                onClick={toggle}
                ariaLabel={"Editer l'expérience"}
                icon={<BiEdit />}
                themeColor={"secondary"}
                isRound
              />
              <MbjIconButton
                size={"sm"}
                onClick={toggleConfirm}
                ariaLabel={"Supprimer l'expérience"}
                icon={<BsTrash />}
                themeColor={"warning"}
                isRound
              />
            </MbjFlex>
          </MbjCardFooter>
        }
      </MbjCard>
      <MbjModal isShowing={isShowing} hide={toggle} title="Modification d'une expérience professionnelle">
        <MbjEmployeeXpEditForm EmployeeXp={props.EmployeeXp} hideModal={toggle} />
      </MbjModal>
      <MbjConfirmModal
        isShowing={ShowConfirm}
        hide={toggleConfirm}
        title="Confirmation suppression expérience"
        confirmText={"Voulez-vous vraiment supprimer cette expérience ?"}
        confirmFunction={HandleConfirmDelete}
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
};

export const MbjEmployeeXpCard = styled(MbjEmployeeXpCardCtrl)((props) => ({}));
