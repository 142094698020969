import React from "react";
import styled from "styled-components";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjFlex, MbjLoading, MbjStaticInfo } from "@mbj-front-monorepo/ui";
import { FcManager } from "react-icons/fc";
import { getDateFR } from "@mbj-front-monorepo/utils";
import { useNavigate } from "react-router-dom";
import { useGetEmployee } from "@mbj-front-monorepo/data-access";
import {AvatarAnonyme} from "@mbj-front-monorepo/ui"

interface MbjEmployeeInformationsCardCtrlProps extends MbjCardClassicCtrlProps {
  /**
   * Classe html de la carte :
   */
  className?: string;
  /**
   * Données à utiliser :
   */
  employeeId: string;
}

const MbjEmployeeInformationsCardCtrl = (props: MbjEmployeeInformationsCardCtrlProps) => {
  const navigate = useNavigate();
  const employeeQuery = useGetEmployee(parseInt(props.employeeId));
  return (
    <MbjCardClassic title={props.title} className={props.className} {...props}>
      {employeeQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.error ? (
        <p>Erreur ...</p>
      ) : (
        <div>
          <div className="Avatar-place">
            <img style={{width:"80px", marginTop:"15px"}} src={AvatarAnonyme} />
          </div>
          <MbjFlex direction={"column"} gap={"12px"}>
            <MbjStaticInfo label="Prénom et Nom" info={`${employeeQuery.data.prenom} ${employeeQuery.data.nom}`} />
            <MbjStaticInfo
              label="Date d'intégration"
              info={employeeQuery.data.birthAt ? getDateFR(new Date(employeeQuery.data.birthAt)) : "inconnue"}
            />
            <MbjStaticInfo label="Email" info={employeeQuery.data.email ? employeeQuery.data.email : "inconnu"} />
            <MbjStaticInfo
              label="Tel1"
              info={employeeQuery.data.telephone1 ? employeeQuery.data.telephone1 : "inconnu"}
            />
            <MbjStaticInfo
              label="Tel2"
              info={employeeQuery.data.telephone2 ? employeeQuery.data.telephone2 : "inconnu"}
            />
            <MbjStaticInfo
              label="adresse"
              info={employeeQuery.data.adresse ? employeeQuery.data.adresse : "inconnue"}
            />
            <MbjStaticInfo info={`${employeeQuery.data.codePostal} ${employeeQuery.data.ville}`} />
            <MbjStaticInfo
              label="Poste occupé"
              info={`${employeeQuery.data.UserMetier ? employeeQuery.data.UserMetier.namePerso : "inconnu"}`}
            />
            <MbjStaticInfo
              label="Service"
              info={`${employeeQuery.data.Service ? employeeQuery.data.Service.libelle : "inconnu"}`}
            />
            <MbjStaticInfo
              label="Depuis"
              info={`${employeeQuery.data.entryAt ? getDateFR(new Date(employeeQuery.data.entryAt)) : "inconnu"}`}
            />
          </MbjFlex>
        </div>
      )}
    </MbjCardClassic>
  );
};

export const MbjEmployeeInformationsCard = styled(MbjEmployeeInformationsCardCtrl)((props) => ({
  position: "relative",
  ".go-infos": {
    position: "absolute",
    right: "10px",
    top: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  ".Avatar-place": {
    marginBottom: "20px",
    fontSize: "50px",
    textAlign: "center",
  },
}));
