import styled from "styled-components";
import {MaCvTheque} from "./MaCvTheque/MaCvTheque";
import {AllianceCvTheque} from "./AllianceCvTheque/AllianceCvTheque";
import {MbjButton, MbjFlex, MbjNavPath, useModal} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {useLocation} from "react-router-dom";
import {AjoutParticulierByCVModal} from "./AjoutParticulierByCVModal";
import {GoPlus} from "react-icons/go";


interface CvThequeRootCtrlProps{
  className?:string;
}

const CvThequeRootCtrl = (props:CvThequeRootCtrlProps)=>{
  const { isShowing: isShowingNewCVThequeCV, toggle: toggleCVThequeCV } = useModal();

  const location = useLocation();
  return (
    <div className={`root_cv_theque ${props.className}`}>
      <MbjFlex justify={"space-between"}>
        <MbjNavPath routePath={location.pathname} noMargin />
      </MbjFlex>
      <MbjFlex justify={"flex-end"}>
        <MbjButton onClick={toggleCVThequeCV} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
          Ajouter un Cv
        </MbjButton>
      </MbjFlex>
      <MaCvTheque/>
      <AllianceCvTheque/>
      <AjoutParticulierByCVModal isShowing={isShowingNewCVThequeCV} hide={()=>toggleCVThequeCV()}/>
    </div>
  )
}
export const CvThequeRoot =styled(CvThequeRootCtrl)`
  padding: 1% 2%;
`
