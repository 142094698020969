import styled from "styled-components";
import {Operation, useGetOFAutoFormation} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import React, {useMemo} from "react";

interface EmployeeAutoFormationOneCompetenceCtrlProps{
  className?:string;
  activite:{id:number, libelle:string, niveau:number}
  employee: Operation<"getEmployeeItem">;
}

export interface OneResultOF{
  name:string;
  distance:string;
  formation:string[];
  OF?:any;
}

const EmployeeAutoFormationOneCompetenceCtrl = (props:EmployeeAutoFormationOneCompetenceCtrlProps)=>{
  if (!props.employee.id) throw new TypeError("employee.id is undefined");
  const OfQuery = useGetOFAutoFormation(props.employee.id)
  const myList = useMemo(()=>{
    const list:OneResultOF[] = [];
    if(OfQuery.data){
      OfQuery.data.OFActivites?.map(item=>{
        const Formation:string[] = []
        item.formationsCourtesForm?.map(fcc=>{
          const ActivitesDispense = fcc.activites?.map(a=>a.id);
          if(ActivitesDispense && ActivitesDispense.indexOf(props.activite.id)!==-1){
            Formation.push(fcc.formationContinuCourtes?.libelle || 'nom inconnu')
          }
        })
        if(Formation.length>0){
          list.push({
            name:item.organismeFormation?.rs||'nom inconnu',
            distance:`${item.distance||'nc'}`,
            formation:Formation
          })
        }
      })
    }
    return list;
  }, [OfQuery.data, props.activite.id])
  return (
    <MbjWidgetClassic title={`Organismes de formation pouvant former à cette compétence`} className={props.className}>
      {OfQuery.isLoading ? (
        <MbjLoading />
      ) : OfQuery.isError || !OfQuery.data ? (
        <p>Erreur ...</p>
      ) : (
          myList.length === 0 ?
            <div className={`no_result`}>Aucune formation trouvée pour cette compétence</div>:
            myList.map((item, idx:number)=>(
              <div className={`one_of_competence`} key={`of_one_${idx}`}>
                <div className={"titre_of"}>{item.name}</div>
                <div className={`list_formation`}>
                  {item.formation.map((f, idxF:number)=>(
                    <div className={`one_form_of`} key={`O_f${idx}${idxF}`}>{f}</div>
                  ))}
                </div>
              </div>
            ))
      )}
    </MbjWidgetClassic>
  )
}

export const EmployeeAutoFormationOneCompetence = styled(EmployeeAutoFormationOneCompetenceCtrl)`
  width: 100%;
  margin-top: 20px;
  .one_of_competence{
    width: 100%;
    background: #f5f1f1;
    padding: 1rem;
    margin-bottom: 10px;
    border-radius: 6px;
    .titre_of{
      font-weight: bold;
      margin-bottom: 10px;
      border-bottom: solid black 1px;
    }
  }
`
