import React from "react";
import styled from "styled-components";
import { Operation, useAddXpPro, useGetMetiers, useGetTypesContracts } from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import {
  CustomSelect,
  MbjButton,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";

interface MbjEmployeeXpNewFormCtrlProps {
  className?: string;
  idEmployee: number;
  hideModal?: () => void;
}

const MbjEmployeeXpNewFormCtrl = (props: MbjEmployeeXpNewFormCtrlProps) => {
  const metiersQuery = useGetMetiers();
  const typeContractsQuery = useGetTypesContracts();
  const mutationAdd = useAddXpPro();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<Operation<"postEmployeeXpCollection", "requestBody">>({
    mode: "onChange",
  });

  const onSubmit = (data: Operation<"postEmployeeXpCollection", "requestBody">) => {
    mutationAdd.mutate(
      {
        ...data,
        Employee: "/api/employees/" + props.idEmployee,
        Metier: "/api/metiers/" + data.Metier,
        TypeContrat: "/api/type_contrats/" + data.TypeContrat,
        endAt: data.endAt ? data.endAt : undefined,
      },
      {
        onSettled: () => {
          if (props.hideModal !== undefined) {
            props.hideModal();
          }
        },
      }
    );
    return;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={props.className}>
      <MbjSimpleInputContainer errors={errors} label={"Date de début *"}>
        <MbjSimpleInput
          name={"startAt"}
          id={"startAt"}
          type={"date"}
          noPadding
          register={register}
          registerOptions={{
            required: {
              value: true,
              message: "Date d'obtention requise",
            },
          }}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Date de fin"}>
        <MbjSimpleInput name={"endAt"} id={"endAt"} type={"date"} noPadding register={register} />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Métier exercé *"} htmlFor={"idMetier"}>
        <Controller
          name={"Metier"}
          control={control}
          rules={{ required: { value: true, message: "Métier requis" } }}
          render={({ field }) => (
            <CustomSelect
              inputId={"Metier"}
              isLoading={metiersQuery.isLoading}
              options={metiersQuery.data}
              getOptionLabel={(item) => item.libelle || ""}
              getOptionValue={(item) => item.id + ""}
              isClearable={true}
              onChange={(selectedOption, triggeredAction) => {
                if (selectedOption) {
                  field.onChange(selectedOption.id);
                }
                if (triggeredAction.action === "clear") {
                  field.onChange(null);
                }
              }}
            />
          )}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Type du contrat *"} htmlFor={"idContrat"}>
        <Controller
          name={"TypeContrat"}
          control={control}
          rules={{ required: { value: true, message: "Contrat requis" } }}
          render={({ field }) => (
            <CustomSelect
              inputId={"TypeContrat"}
              isLoading={typeContractsQuery.isLoading}
              options={typeContractsQuery.data}
              getOptionLabel={(option) => option?.libelle || ""}
              getOptionValue={(option) => option?.id + ""}
              isClearable={true}
              onChange={(selectedOption, triggeredAction) => {
                if (selectedOption) {
                  field.onChange(selectedOption.id);
                }
                if (triggeredAction.action === "clear") {
                  field.onChange(null);
                }
              }}
            />
          )}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Salaire"}>
        <MbjSimpleInput
          name={"salary"}
          id={"salary"}
          type={"number"}
          noPadding
          register={register}
          registerOptions={{
            min: {
              value: 0,
              message: "Ne peut pas être négatif",
            },
            valueAsNumber: true,
          }}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Description"}>
        <MbjSimpleTextArea name={`description`} id={`description`} noPadding register={register} />
      </MbjSimpleInputContainer>
      <MbjButton isPending={mutationAdd.isLoading}>Ajouter l'expérience</MbjButton>
    </form>
  );
};

export const MbjEmployeeXpNewForm = styled(MbjEmployeeXpNewFormCtrl)((props) => ({
  gap: "8px",
  display: "flex",
  flexDirection: "column",

  button: {
    alignSelf: "flex-end",
  },
}));
