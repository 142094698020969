import styled from "styled-components";
import {Operation} from "@mbj-front-monorepo/data-access";
import {useState} from "react";
import {MbjAccordion, MbjAccordionContent, MbjAccordionItem, MbjAccordionTrigger} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {WidgetInscription} from "./WidgetInscription";

interface OneBlockCompetenceCtrlProps{
  titre:string;
  className?:string;
  list:Operation<"getBesoinFormationEntrepriseCollection">
  ClickActi:(item:{id:number, libelle:string, niveau:number})=>void;
}

const OneBlockCompetenceCtrl = (props:OneBlockCompetenceCtrlProps)=>{
  const [open, setOpen] = useState(false);
  return (
    <div className={`block_one_comp ${props.className} ${open ? 'opened' : 'closed'}`}>
      <div className={`titre_block`} onClick={()=>setOpen(o=>!o)}>{props.titre}</div>
      <div className={"content_block_comp"}>
        <MbjAccordion type="multiple" defaultValue={[]} className={"accordéon"}>
          {props.list.sort((a, b)=>(a.activite?.libelle||'') > (b.activite?.libelle||'') ? 1 : -1).map((item, idx)=>(
            <MbjAccordionItem value={`item-${idx}`} key={idx}>
              <MbjAccordionTrigger>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", alignContent:"center"}}>
                  <div className="accordion-trigger-content">
                    <span>{item.activite?.libelle} ({item.EmployeeNeedFormation?.length})</span>
                  </div>
                  <div className={"details"} onClick={()=>props.ClickActi({id:(item.activite?.id||0), libelle:(item.activite?.libelle||''), niveau:0})}>Comment former sur cette compétence ?</div>
                </div>
              </MbjAccordionTrigger>

              <MbjAccordionContent>
                {item.EmployeeNeedFormation?.map((itemE:any, idx2:number)=>(
                  <div className={"Employee_need"} key={`Employee_need${idx2}`}>
                    <div className={`employee_name`}>
                      {itemE.Employees?.prenom} {itemE.Employees?.nom} {itemE.niveau}/{itemE.cible}
                    </div>
                    <div className={"employee_action"}>
                      <WidgetInscription idActivite={(item.activite && item.activite.id) ? item.activite.id : 0} idEmployee={itemE.Employees ? itemE.Employees.id : 0}/>
                    </div>
                  </div>
                ))}
              </MbjAccordionContent>
            </MbjAccordionItem>

          ))}
        </MbjAccordion>
      </div>
    </div>
  )
}

export const OneBlockCompetence = styled(OneBlockCompetenceCtrl)`
  margin-bottom: 10px;
  background: white;
  padding: 0.5rem;
  border-radius: 6px;
  .employee_action{
    height: 30px;
  }
  .Employee_need{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .titre_block{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .content_block_comp{
    display: none;
    padding-left: 1rem;
  }
  &.opened{
    .content_block_comp{
      display: block;
    }
  }
`
