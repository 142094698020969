import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi } from "../../services/fetchApi";
import { useGetMemberMe } from "../member";

export const useSavingMachEmployee = (matchEmployeeId: number) => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;

  return useMutation(
    (isSaved: boolean) => {
      return fetchApi("/api/match_employees/{id}", {
        method: "put",
        params: { id: matchEmployeeId + "" },
        json: { isSaved },
      });
    },
    {
      onSuccess: (matchEmployee) => {
        queryClient.invalidateQueries(["recrutement_saveds", { memberId, matchEmployeesSavedFilter: true }]);
        //AJOUTER UNE INVALIDATE QUERY POUR LE RECRUTEMENT SAVED DETAIL
        toast.success(matchEmployee.isSaved ? "Profil retenu !" : "Profil supprimé de la sélection !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
