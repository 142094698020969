import styled from "styled-components";
import {Operation} from "@mbj-front-monorepo/data-access";
import React, {useEffect, useState} from "react";
import {GrClose} from "react-icons/gr";
import {BsFillArrowLeftSquareFill} from "react-icons/bs";
import {ExpertSuiviOneCompetenceWidget} from "../ExpertSuivi/ExpertSuiviOneCompetenceWidget";
import {EmployeeAutoFormationOneCompetence} from "../AutoFormation/EmployeeAutoFormationOneCompetence";
import {IogaWidget} from "../../../../FormationEntreprise/Components/IogaWidget";
import {MbjWidgetClassic} from "@mbj-front-monorepo/ui";

interface ModalSuiviOneCompetenceCtrlProps{
  className?:string;
  activite:{id:number, libelle:string, niveau:number}
  employee: Operation<"getEmployeeItem">;
  open:boolean;
  closeMe:()=>void;
}

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const ModalSuiviOneCompetenceCtrl = (props:ModalSuiviOneCompetenceCtrlProps)=>{
  const [state, setState] = useState(props.open ? VISIBLE : HIDDEN);
  useEffect(()=>{
    if(!props.open){
      setState(LEAVING)
    } else{
      setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
    }
  }, [props.open])
  useEffect(()=>{
    if(state === LEAVING){
      const timer = setTimeout(()=>{
        setState(HIDDEN)
      }, 300)
      return ()=>{
        clearTimeout(timer);
      }
    } else if (state === ENTERING){
      const sf = document.body.offsetHeight
      setState(VISIBLE);
    }
  }, [state])


  const styleModal = {transitionDuration: `300ms`, transitionProperty:"width", width:"100%"}
  if(state!==VISIBLE){
    styleModal.width = "0";
  }

  if(state === HIDDEN){
    return null;
  } else {
    return (
      <div className={`Modal_suivi_competence ${props.className}`} style={styleModal}>
        <div className={`header`}>
          <div className={"Close"} onClick={()=>props.closeMe()}>
            <div className={`wrap_close`}><BsFillArrowLeftSquareFill/></div>
          </div>
          <div className={"lib"}>Former {props.employee?.prenom} à {props.activite.libelle}</div>
        </div>
        <div className={`body`}>
          <ExpertSuiviOneCompetenceWidget employee={props.employee} activite={props.activite}/>
          <EmployeeAutoFormationOneCompetence activite={props.activite} employee={props.employee}/>
          <MbjWidgetClassic title={"formation vidéos IOGA"}>
            <IogaWidget/>
          </MbjWidgetClassic>
        </div>
      </div>
    )
  }
}

export const ModalSuiviOneCompetence = styled(ModalSuiviOneCompetenceCtrl)`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .header{
    width: 100%;
    display: flex;
    padding: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .Close, .wrap_close{
      font-size: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .wrap_close{
      &:hover{
        cursor: pointer;
      }
    }
    .lib{
      flex-grow: 1;
      font-size: 22px;
      color:${props=>props.theme.primary};
    }
  }
  .body{
    flex-grow: 1;
    padding: 1rem;
    height: 0;
    overflow-y: auto;
  }
`
