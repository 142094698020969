import * as React from "react";
import { useState } from "react";
import {
  Operation,
  useGetEmployeesCompetences,
  useGetFI,
  useGetMemberMe,
  useGetXp,
} from "@mbj-front-monorepo/data-access";
import {
  MbjAccordion,
  MbjAccordionItem,
  MbjButton,
  MbjCardClassic,
  MbjFlex,
  MbjLabel,
  MbjLoading,
  MbjModal,
  MbjModalCtrlProps,
  MbjNoResult,
  MbjScrollArea,
  MbjTable,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { NouveauMessageModal } from "../../../../Messagerie/NouveauMessageModal/NouveauMessageModal";
import { MbjCompareReferentiel, MbjEmployeeLineCompetence } from "@mbj-front-monorepo/data-ui";

const xpTitles = [
  { libelle: "Métier", Align: "isLeft" },
  { libelle: "Période", Align: "isCenter" },
  { libelle: "Contrat", Align: "isCenter" },
];
const fiTitles = [
  { libelle: "Intitulé", Align: "isLeft" },
  { libelle: "Discipline", Align: "isCenter" },
  { libelle: "Niveau", Align: "isCenter" },
  { libelle: "Obtenu le", Align: "isCenter" },
];

interface ExternalProfilConsultModalCtrlProps extends MbjModalCtrlProps {
  externalProfil: GetElementType<
    GetElementType<Operation<"getRecrutementSavedCollection">>["MatchEmployees"]
  >["Employee"];
  externalMember: NonNullable<GetElementType<Operation<"getRecrutementSavedCollection">>["UserMetier"]>["Member"];
  type?: string;
  refMet?: number | null;
}
function ExternalProfilConsultModalCtrl(props: ExternalProfilConsultModalCtrlProps) {
  const [pageCurrent, setPageCurrent] = useState(0);

  const queryCompetencesEmployee = useGetEmployeesCompetences(props.externalProfil?.id);
  const xpQuery = useGetXp(props.externalProfil?.id);
  const FisQuery = useGetFI(props.externalProfil?.id);
  const memberQuery = useGetMemberMe();

  const { isShowing: isShowingContact, toggle: toggleContact } = useModal();
  const [userToContact, setUserToContact] = useState<{ id: number; name: string } | null>(null);
  const [subjectDefault, setSubjectDefault] = useState("");
  const [textDefault, setTextDefault] = useState("");

  const handleContact = () => {
    const Emp = props.externalProfil?.prenom + " " + props.externalProfil?.nom;
    const concernedName = props.externalMember?.name;
    const MyName = memberQuery.data ? memberQuery.data.name : "";

    const subjectText = `Demande de contact à propos du profil ${Emp} de ${concernedName}`;
    setSubjectDefault(subjectText);
    const TextDefault = `<p>Bonjour je suis recruteur/recruteuse chez ${MyName}</p></br><p>J'ai identifié le profil <strong>${Emp}</strong> de ${concernedName}, à qui nous pourrions proposer des opportunités de recrutement intéressantes.</p><p>Pouvons-nous échanger à ce sujet prochainement ?</p></br><p>Bien cordialement</p>`;
    setTextDefault(TextDefault);
    setUserToContact({
      id: props.externalMember?.id || -1,
      name: props.externalMember?.name || "",
    });
    toggleContact();
  };

  return (
    <MbjModal
      {...props}
      title={`${props.externalProfil?.nom?.toUpperCase()} ${props.externalProfil?.prenom}`}
      maxWidth={"80%"}
    >
      <div className={`wrap-pages`}>
        <div className={"navigation"}>
          <div className={`ItemNavig ${pageCurrent === 0 ? "isActive" : ""}`} onClick={() => setPageCurrent(0)}>
            Cv & Compétences
          </div>
          {props.type && (
            <div className={`ItemNavig ${pageCurrent === 1 ? "isActive" : ""}`} onClick={() => setPageCurrent(1)}>
              Comparaison
            </div>
          )}
        </div>
        <div className={`onePage ${pageCurrent === 0 ? "isActive" : ""}`}>
          <MbjFlex direction={"column"}>
            <MbjWidgetClassic title={"CV"}>
              <MbjCardClassic title={"Informations générales"}>
                <div className={"infosContent"}>
                  <div className="Nom">
                    <MbjLabel>Nom</MbjLabel>
                    <div className="data">{props.externalProfil?.nom}</div>
                  </div>
                  <div className="Prénom">
                    <MbjLabel>Prénom</MbjLabel>
                    <div className="data">{props.externalProfil?.prenom}</div>
                  </div>
                  <div className="poste">
                    <MbjLabel>Poste occupé</MbjLabel>
                    <div className="data">
                      {props.externalProfil?.UserMetier ? props.externalProfil?.UserMetier.namePerso : "inconnu"}
                    </div>
                  </div>
                  {props.externalMember && (
                    <div className="entreprise">
                      <MbjLabel>Entreprise</MbjLabel>
                      <div className="data">
                        <span>{props.externalMember?.name}</span>
                        <MbjButton size={"xs"} onClick={() => handleContact()}>
                          Contacter
                        </MbjButton>
                        {userToContact && (
                          <NouveauMessageModal
                            isShowing={isShowingContact}
                            hide={toggleContact}
                            defaultDest={userToContact}
                            defaultText={textDefault}
                            defaultSubject={subjectDefault}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </MbjCardClassic>
              <MbjFlex basis={"55%"} direction={"column"}>
                <MbjCardClassic title={"Expériences professionnelles"}>
                  {xpQuery.isLoading ? (
                    <MbjLoading />
                  ) : xpQuery.isError ? (
                    <p>Erreur ...</p>
                  ) : xpQuery.data.length > 0 ? (
                    <MbjTable titles={xpTitles} scrollable maxHeight={"150px"} themeColor={"white"} noBorderRadius>
                      {xpQuery.data.map((xp, idx) => (
                        <tr key={xp.id}>
                          <td>{xp.Metier?.libelle}</td>
                          <td className="isCenter">
                            {xp.endAt
                              ? `du ${xp.startAt ? getDateFR(new Date(xp.startAt)) : "--"} au
                                                ${getDateFR(new Date(xp.endAt))}`
                              : `depuis le ${xp.startAt ? getDateFR(new Date(xp.startAt)) : "--"}`}
                          </td>
                          <td className="isCenter">{xp.TypeContrat ? xp.TypeContrat.libelle : "Non renseigné"}</td>
                        </tr>
                      ))}
                    </MbjTable>
                  ) : (
                    <MbjNoResult text={"Aucune expérience professionnelle"} />
                  )}
                </MbjCardClassic>
                <MbjCardClassic title={"Formations initiales"}>
                  {FisQuery.isLoading ? (
                    <MbjLoading />
                  ) : FisQuery.isError ? (
                    <p>Erreur ...</p>
                  ) : FisQuery.data.length > 0 ? (
                    <MbjTable titles={fiTitles} scrollable maxHeight={"150px"} themeColor={"white"} noBorderRadius>
                      {FisQuery.data.map((fi, idx) => (
                        <tr key={fi.id}>
                          <td>{fi.nom}</td>
                          <td className="isCenter">{fi.Discipline?.libelle}</td>
                          <td className="isCenter">{fi.NiveauEtude?.libelle}</td>
                          <td className="isCenter">{fi.obtentionAt ? getDateFR(new Date(fi.obtentionAt)) : "--"}</td>
                        </tr>
                      ))}
                    </MbjTable>
                  ) : (
                    <MbjNoResult text={"Aucune formation initiale"} />
                  )}
                </MbjCardClassic>
              </MbjFlex>
            </MbjWidgetClassic>
            <MbjWidgetClassic title={"Compétences"}>
              {queryCompetencesEmployee.isLoading ? (
                <MbjLoading />
              ) : queryCompetencesEmployee.isError ? (
                <p>Erreur ...</p>
              ) : (
                <MbjScrollArea maxHeight={"350px"}>
                  <MbjAccordion type="multiple" defaultValue={["item-1"]} className={"accordéon"}>
                    {queryCompetencesEmployee.data?.map((CE, idx) => (
                      <MbjAccordionItem value={`item-${idx}`} key={CE?.Competence?.id}>
                        <MbjEmployeeLineCompetence
                          CompetencesEmployee={CE}
                          idEmployee={props.externalProfil?.id || -1}
                          isLoading={queryCompetencesEmployee.isLoading}
                          isError={queryCompetencesEmployee.isError}
                          EmployeeActivites={CE.EmployeeActivites}
                        />
                      </MbjAccordionItem>
                    ))}
                  </MbjAccordion>
                </MbjScrollArea>
              )}
            </MbjWidgetClassic>
          </MbjFlex>
        </div>
        {props.type && props.refMet && (
          <div className={`onePage ${pageCurrent === 1 ? "isActive" : ""}`}>
            <MbjCompareReferentiel
              idToCompare1={props.externalProfil?.id || -1}
              idToCompare2={props.refMet}
              typeCompared={props.type === "Metier" ? "EM" : "EUM"}
            />
          </div>
        )}
      </div>
    </MbjModal>
  );
}
export const ExternalProfilConsultModal = styled(ExternalProfilConsultModalCtrl)((props) => ({
  ".navigation": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    marginBottom: "10px",
    ".ItemNavig": {
      padding: "0.5rem 0.75rem",
      borderWidth: "0px 0px 2px 0px",
      borderStyle: "solid",
      borderColor: "transparent",
      "&.isActive": {
        fontWeight: "bold",
        borderColor: props.theme.primary,
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  ".onePage": {
    display: "none",
    "&.isActive": {
      display: "block",
    },
  },
  ".infosContent": {
    padding: "12px",

    ".entreprise": {
      ".data": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
      },
    },
  },
}));
