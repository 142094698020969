import React from "react";
import styled from "styled-components";
import { FlexContainerOptions, useFlexContainer } from "../../flex-utils";

interface MbjStaticInfoCtrlProps extends FlexContainerOptions {
  label?: string;
  info?: string;
  className?: string;
}

export const MbjStaticInfoCtrl = (props: MbjStaticInfoCtrlProps) => {
  return (
    <div className={`LineInfos ${props.className}`}>
      {props.label && <div className="label-infos">{props.label}</div>}
      <div className="info-infos">{props.info}</div>
    </div>
  );
};
export const MbjStaticInfo = styled(MbjStaticInfoCtrl)((props) => ({
  display: "flex",
  ...useFlexContainer(props),

  ".label-infos": {
    fontSize: "15px",
    fontWeight: "bold",
    color: props.theme.primaryLighten,
  },

  ".info-infos": {
    fontSize: "15px",
  },
}));
MbjStaticInfo.defaultProps = {
  direction: "column",
};
