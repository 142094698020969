import styled from "styled-components";
import {Operation} from "@mbj-front-monorepo/data-access";
import {BlockResultEmployee} from "./BlockResultEmployee";
import {BlockResultReclassement} from "./BlockResultReclassement";
import {useState} from "react";

interface SearchDidCtrlProps{
  className?:string;
  Search:Operation<"gETSearchsItem">
}

const SearchDidCtrl = (props:SearchDidCtrlProps)=>{
  const [type, setType] = useState("profil")
  return (
    <div className={`searchDid ${props.className}`}>
      <div className={`informations_search`}>
        <div className={`date_is`}>Date de la recherche:  <strong>{new Date(props.Search.creationAt as string).toLocaleDateString()}</strong></div>
        <div className={`date_is`}>Dernière mise à jour:  <strong>{new Date(props.Search.updateAt as string).toLocaleDateString()} {new Date(props.Search.updateAt as string).toLocaleTimeString()}</strong></div>
      </div>
      <div className={`switcher`}>
        <div className={`one_switch ${type === "profil" ? "selected" : ""}`} onClick={()=>setType("profil")}>Profils individuels</div>
        <div className={`one_switch ${type === "profil" ? "" : "selected"}`} onClick={()=>setType("reclassement")}>Métiers en reclassement et formations</div>
      </div>
      <div className={`wrap_resultats`}>
        {type === "profil" ?
          <BlockResultEmployee Search={props.Search}/>:
          <BlockResultReclassement Search={props.Search}/>
        }
      </div>
    </div>
  )
}

export const SearchDid = styled(SearchDidCtrl)`
  .switcher{
    margin: 15px auto;
    display: flex;
    gap:0.25rem;
    justify-content: flex-start;
    padding-bottom: 0.25rem;
    border-bottom: solid ${props => props.theme.primary};
    .one_switch{
      width : calc(50% - 0.175rem);
      padding: 0.25rem 0.5rem;
      text-align: center;
      font-size: 18px;
      color: ${props=>props.theme.primary};
      &:hover{
        cursor: pointer;
        background: ${props=>props.theme.primaryPastel};
      }
      &.selected{
        background: ${props=>props.theme.primary};
        color:white;
      }

    }
  }
`
