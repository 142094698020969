import styled from "styled-components";
import React from "react";

interface ItemSearchRetenuCtrlProps{
  className?:string;
  children:React.ReactNode;
}

const ItemSearchRetenuCtrl = (props:ItemSearchRetenuCtrlProps)=>{
  return (
    <div className={`item_search_retenu ${props.className}`}>
      {props.children}
    </div>
  )
}

export const ItemSearchRetenu = styled(ItemSearchRetenuCtrl)`
  background: white;
  padding: 1rem;
  border-radius: 6px;
`
