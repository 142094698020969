import styled from "styled-components";
import {useParams} from "react-router-dom";
import {components, useGetSearchOne} from "@mbj-front-monorepo/data-access";
import {useState} from "react";
import {MbjLoading, MbjModal2} from "@mbj-front-monorepo/ui";
import {ResultEmployee} from "./ResultEmployee";
import {ResultReclassement} from "./ResultReclassement";
import {ResultParticulier} from "./ResultParticulier";
import {Compare} from "./Comparaison/Compare";


interface ResultCtrlProps{
  className?:string;
}
export interface ItemMenuResult{
  id:number;
  libelle:string;
}

const MesMenusResult:ItemMenuResult[]=[
  {id:1, libelle:"les salariés en mobilité"},
  {id:2, libelle:"les métiers et les formations de vos alliances"},
  {id:3, libelle:"la cvthèque"},
]

const ResultCtrl = (props:ResultCtrlProps)=>{
  const {id} = useParams();
  const SearchQuery = useGetSearchOne(id ? parseInt(id) : 0);
  const [minPc, setMinPc] = useState<number>(0);
  const [maxPc, setMaxPc] = useState<number>(1);
  const [menuCurrent, setMenuCurrent] = useState<ItemMenuResult>({id:1, libelle:"les salariés en mobilité"})
  const [openCompare, setOpenCompare] = useState(false);
  const [resultEmpCurr, setResultEmpCurr] = useState<components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"]|null>(null);
  const [resultPartCurr, setResultPartCurr] = useState<components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|null>(null)
  const [resultReclCurr, setResultReclCurr] = useState<components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|null>(null)
  const switchCompare = ()=>{
    setOpenCompare(o=>!o);
  }
  const ClickOnCompareEmp = (result:components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>{
    setResultEmpCurr(result);
    setResultPartCurr(null);
    setResultReclCurr(null);
    switchCompare();
  }
  const ClickOnComparePart = (result:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"])=>{
    console.log('click !!');
    setResultPartCurr(result);
    setResultEmpCurr(null);
    setResultReclCurr(null);
    switchCompare();
  }
  const ClickOnCompareRecl = (result:components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"])=>{
    console.log('click !!');
    setResultPartCurr(null);
    setResultEmpCurr(null);
    setResultReclCurr(result);
    switchCompare();
  }
  const getNbResult = (id:number)=>{
    if(!SearchQuery.data){
      return 0;
    } else {
      if(id === 1 && SearchQuery.data){
        return SearchQuery.data?.nbResultEmployee
      } else if(id === 2 && SearchQuery.data){
        return SearchQuery.data?.nbResultReclassement
      } else {
        return SearchQuery.data?.nbResultParticulier
      }
    }
  }
  return (
    <div className={`results_search ${props.className}`}>
      {SearchQuery.isLoading ? (<MbjLoading/>) : SearchQuery.isError ? 'Error data' :
        (
          <>
            <div className={"Title"}>Résultat de la recherche pour <span className={"metier"}>{SearchQuery.data.UserMetier ? SearchQuery.data.UserMetier.namePerso : SearchQuery.data.Metier?.libelle}</span></div>
            <div className={"MenuChoix"}>
              {MesMenusResult.map((item)=>(
                <div onClick={()=>setMenuCurrent(item)} className={`one_menu_choice ${item.id === menuCurrent.id ? 'current' : ''}`}  key={`omc${item.id}`}>{item.libelle} ({getNbResult(item.id)})</div>
              ))}
            </div>
            {menuCurrent.id === 1 ?
              <ResultEmployee min={minPc} max={maxPc} clickCompare={ClickOnCompareEmp}/>:
              menuCurrent.id === 2 ?
                <ResultReclassement min={minPc} max={maxPc} clickCompare={ClickOnCompareRecl}/>:
                  <ResultParticulier min={minPc} max={maxPc} clickCompare={ClickOnComparePart}/>
            }
          </>
        )
      }
      <MbjModal2 open={openCompare} closeMe={switchCompare} titre={"Comparaison"}>
        <Compare
          SearchEmp={resultEmpCurr||undefined}
          SearchPart={resultPartCurr||undefined}
          SearchRecl={resultReclCurr||undefined}
        />
      </MbjModal2>
    </div>
  )
}
export const Result = styled(ResultCtrl)`
  padding: 1% 2%;
  .Title{
    font-size: 16px;
    span.metier{
      color: ${props => props.theme.primary};
      font-weight: bold;
    }
  }
  .MenuChoix{
    margin: 15px auto;
    display: flex;
    justify-content: flex-start;
    .one_menu_choice{
      padding: 0.5rem 1rem;
      &.current{
        border-bottom: solid ${props => props.theme.primary} 4px;
        font-weight: bold;
      }
      &:hover{
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
`
