import * as React from "react";
import { useState } from "react";
import {
  MbjButton,
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjLoading,
  MbjMemberCard,
  MbjModal,
  MbjOpenCloseDetails,
  MbjScrollArea,
  useModal,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { ReclassementModalCompare } from "../ReclassementModalCompare/ReclassementModalCompare";
import { NouveauMessageModal } from "../../../Messagerie/NouveauMessageModal/NouveauMessageModal";
import { Operation, useGetMemberMe, useGetMemberMetierReclassement } from "@mbj-front-monorepo/data-access";
import { MbjCollabProfil } from "../../../../components/organisms/MbjCollabProfil/MbjCollabProfilCard";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { MbjCompareReferentiel } from "@mbj-front-monorepo/data-ui";

/**
 * Carte contenant des infos sur les compétences à rechercher :
 */
interface ReclassementAllianceItemCardCtrlProps extends MbjCardClassicCtrlProps {
  /**
   * données à utiliser :
   */
  memberMetierReclassementId: number;
  metierSearchedId?: number;
  userMetierSearchId?: number;
  score?: number;
  MatchEmployees?: GetElementType<Operation<"getRecrutementSavedItem">["MatchReclassements"]>["MatchEmployees"];
  //Lien vers la page de messagerie :
  contactTo?: string;
}

function ReclassementAllianceItemCardCtrl(props: ReclassementAllianceItemCardCtrlProps) {
  const memberQuery = useGetMemberMe();
  const memberMetierReclassementQuery = useGetMemberMetierReclassement(props.memberMetierReclassementId);

  const { isShowing, toggle } = useModal();
  const { isShowing: isShowingContact, toggle: toggleContact } = useModal();
  const [userToContact, setUserToContact] = useState<{ id: number; name: string } | null>(null);
  const [subjectDefault, setSubjectDefault] = useState("");
  const [textDefault, setTextDefault] = useState("");

  const concernedMember = memberMetierReclassementQuery.data?.UserMetier?.Member;
  const handleContact = () => {
    const MyName = memberQuery.data ? memberQuery.data.name : "";
    const fiche = memberMetierReclassementQuery.data?.UserMetier?.namePerso;

    const subjectText = `Demande de contact à propos de la fiche ${fiche} de ${concernedMember?.name}`;
    setSubjectDefault(subjectText);
    const TextDefault = `<p>Bonjour je suis recruteur/recruteuse chez ${MyName}</p></br><p>J'ai identifié le métier <strong>${fiche}</strong> de ${concernedMember?.name}. Nous pourrions proposer des opportunités de recrutement intéressantes aux profils l'exercant.</p><p>Pouvons-nous échanger à ce sujet prochainement?</p></br><p>Bien cordialement</p>`;
    setTextDefault(TextDefault);
    setUserToContact({
      id: memberMetierReclassementQuery.data?.UserMetier?.Member?.id || -1,
      name: concernedMember?.name || "",
    });
    toggleContact();
  };
  return (
    <MbjCardClassic {...props} className={props.className} grow={props.grow} overflow={"unset"}>
      {memberMetierReclassementQuery.isLoading ? (
        <MbjLoading />
      ) : memberMetierReclassementQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <div className={"content"}>
            <div className={"left"}>
              <MbjMemberCard logo={concernedMember?.logo || ""} name={concernedMember?.name}>
                <div className={"metierReclasse"}>
                  <span className="infos-name me-2">Métier reclassé :</span>
                  {memberMetierReclassementQuery.data?.UserMetier?.namePerso}
                </div>
                <div className={"metierReclasse"}>
                  <span className="infos-name me-2">Nombre :</span>
                  {memberMetierReclassementQuery.data?.volume}
                </div>
              </MbjMemberCard>
            </div>
            {props.score ? <div className={"proxi"}>{Math.round(props.score * 100)}%</div> : <React.Fragment />}
            <div className={"interact-container"}>
              <MbjButton size={"sm"} onClick={() => handleContact()}>
                Contacter
              </MbjButton>
              <MbjButton size={"sm"} themeColor={"grey"} onClick={toggle}>
                Comparer
              </MbjButton>
            </div>
          </div>
          {props.MatchEmployees && props.MatchEmployees.length > 0 && (
            <MbjOpenCloseDetails
              className={"profilsList"}
              text={
                props.MatchEmployees.length === 1
                  ? "le profil diffusé"
                  : `les ${props.MatchEmployees.length} profils diffusés`
              }
              themeColor="primary"
            >
              <MbjScrollArea maxHeight={"250px"}>
                {props.MatchEmployees.map((matchEmployee, index) => (
                  <MbjCollabProfil EmployeeId={matchEmployee.Employee?.id || -1} key={matchEmployee.id} />
                ))}
              </MbjScrollArea>
            </MbjOpenCloseDetails>
          )}

          {userToContact && (
            <NouveauMessageModal
              isShowing={isShowingContact}
              hide={toggleContact}
              defaultDest={userToContact}
              defaultText={textDefault}
              defaultSubject={subjectDefault}
            />
          )}

          {isShowing && !props.score && (
            <ReclassementModalCompare idR={props.memberMetierReclassementId} isShowing={isShowing} hide={toggle} />
          )}
          {isShowing && props.score && (
            <MbjModal isShowing={isShowing} hide={toggle} maxWidth={"65%"} title={"Comparaison des compétences"}>
              <MbjCompareReferentiel
                idToCompare1={memberMetierReclassementQuery.data.UserMetier?.id || -1}
                idToCompare2={props.metierSearchedId || props.userMetierSearchId || -1}
                typeCompared={props.metierSearchedId ? "UMM" : "UMUM"}
              />
            </MbjModal>
          )}
        </>
      )}
    </MbjCardClassic>
  );
}
export const ReclassementAllianceItemCard = styled(ReclassementAllianceItemCardCtrl)((props) => ({
  marginBottom: "15px",
  ".metierReclasse": {
    ".infos-name": {
      color: props.theme.primary,
    },
  },
  "& .content": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    "& .left": {
      flexGrow: 1,
    },
    ".proxi": {
      fontWeight: 500,
      fontSize: "2em",
      color: props.theme.dark,
    },
    "& .interact-container": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: "10px",
    },
  },
  ".profilsList": {
    display: "flex",
    justifyContent: "end",
  },
}));
