import * as React from "react";
import styled from "styled-components";
import { MbjNavPath } from "@mbj-front-monorepo/ui";
import { useLocation } from "react-router-dom";

interface EntrepriseStatsCtrlProps {
  className?: string;
}

function EntrepriseStatsCtrl(props: EntrepriseStatsCtrlProps) {
  const location = useLocation();
  return (
    <div className={"EntrepriseStats" + props.className}>
      <MbjNavPath routePath={location.pathname} />
    </div>
  );
}
export const EntrepriseStats = styled(EntrepriseStatsCtrl)((props) => ({
  padding: "1% 2%",
}));
