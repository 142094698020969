import * as React from "react";
import {
  MbjFlex,
  MbjLoading,
  MbjNoResult,
  MbjScrollArea,
  MbjTabs,
  MbjTabsContent,
  MbjTabsList,
  MbjTabsTrigger,
  MbjWidgetClassic,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { ReclassementAllianceItemCard } from "../../Alliances/ReclassementsAlliances/ReclassementAllianceItemCard/ReclassementAllianceItemCard";
import { CollabProfilItem } from "../MesRecherches/CollabProfilItem/CollabProfilItem";
import { Operation } from "@mbj-front-monorepo/data-access";

interface ResultatRechercheCtrlProps {
  className?: string;

  recrutementSavedResult?: Operation<"postRecrutementSavedCollection">;
  isLoading: boolean;
  isError: boolean;
}

function ResultatRechercheCtrl(props: ResultatRechercheCtrlProps) {
  return (
    <MbjWidgetClassic title={"Résultats de la recherche"} className={props.className}>
      <MbjTabs defaultValue="tab1">
        <MbjTabsList aria-label="Manage your account" isFitted>
          <MbjTabsTrigger value="tab1">Reclassements métier</MbjTabsTrigger>
          <MbjTabsTrigger value="tab2">Profils compatibles</MbjTabsTrigger>
        </MbjTabsList>

        <MbjTabsContent value="tab1" $noPadding>
          {props.isLoading ? (
            <MbjLoading />
          ) : props.isError ? (
            <p>Erreur ...</p>
          ) : (
            props.recrutementSavedResult && (
              <>
                <div>
                  {props.recrutementSavedResult.MatchReclassements?.length} reclassements Métier pour "
                  {props.recrutementSavedResult.Metier
                    ? props.recrutementSavedResult.Metier.libelle
                    : props.recrutementSavedResult.UserMetier
                    ? props.recrutementSavedResult.UserMetier.namePerso
                    : ""}
                  " dans vos alliances
                </div>
                <MbjScrollArea maxHeight={"400px"}>
                  <MbjFlex direction={"column"} gap={"20px"}>
                    {props.recrutementSavedResult.MatchReclassements?.length === 0 ? (
                      <MbjNoResult text="Aucun reclassement trouvé" />
                    ) : (
                      props.recrutementSavedResult.MatchReclassements?.sort((m1, m2) => {
                        if (m1.score && m2.score) return m1.score < m2.score ? 1 : m1.score > m2.score ? -1 : 0;
                        else return 0;
                      }).map((MatchReclassement, idx: number) => (
                        <ReclassementAllianceItemCard
                          memberMetierReclassementId={MatchReclassement.MemberMetierReclassement?.id || -1}
                          score={MatchReclassement.score}
                          metierSearchedId={props.recrutementSavedResult?.Metier?.id}
                          userMetierSearchId={props.recrutementSavedResult?.UserMetier?.id}
                          key={MatchReclassement.id}
                          contactTo={"./../../messagerie"}
                        />
                      ))
                    )}
                  </MbjFlex>
                </MbjScrollArea>
              </>
            )
          )}
        </MbjTabsContent>
        <MbjTabsContent value="tab2">
          {props.isLoading ? (
            <MbjLoading />
          ) : props.isError ? (
            <p>Erreur ...</p>
          ) : (
            props.recrutementSavedResult && (
              <MbjScrollArea maxHeight={"400px"}>
                <MbjFlex direction={"column"} gap={"10px"}>
                  {props.recrutementSavedResult.MatchEmployees?.length === 0 ? (
                    <MbjNoResult text="aucun profil trouvé" />
                  ) : (
                    props.recrutementSavedResult.MatchEmployees?.sort((m1, m2) => {
                      if (m1.score && m2.score) return m1.score < m2.score ? 1 : m1.score > m2.score ? -1 : 0;
                      else return 0;
                    }).map((MatchEmployee, idx: number) => (
                      <CollabProfilItem
                        key={MatchEmployee.id}
                        MatchEmployee={MatchEmployee}
                        type={props.recrutementSavedResult?.Metier ? "Metier" : "UserMetier"}
                        refMet={
                          props.recrutementSavedResult?.Metier
                            ? props.recrutementSavedResult?.Metier.id
                            : props.recrutementSavedResult?.UserMetier
                            ? props.recrutementSavedResult?.UserMetier.id
                            : null
                        }
                      />
                    ))
                  )}
                </MbjFlex>
              </MbjScrollArea>
            )
          )}
        </MbjTabsContent>
      </MbjTabs>
    </MbjWidgetClassic>
  );
}
export const ResultatRecherche = styled(ResultatRechercheCtrl)((props) => ({
  padding: "1% 2%",
}));
