import {components, fetchApi} from "@mbj-front-monorepo/data-access";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";


export const useGetAllNoteResReclBySearch = (id:number)=>{
  const promise = () => fetchApi("/api/notes_results_reclassements", {method:'get', query:{ResultsSearch:id+""}});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["notes_results_recl", { id }], promise, {
    enabled: id!==0,
  });
}

export const usePostNewNotReclassement = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data:components["schemas"]["NotesResultsReclassement-notesresultsrecl.post"]) => {
      return fetchApi("/api/notes_results_reclassements", {
        method: "post",
        json: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notes_results_recl"]);
        toast.success("Note enregistrée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const usePutNewNotReclassement = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: {id:number, datas:components["schemas"]["NotesResultsReclassement-notesresultsrecl.write"] }) => {
      return fetchApi("/api/notes_results_reclassements/{id}", {
        method: "put",
        params:{id:data.id+""},
        json: data.datas,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notes_results_recl"]);
        toast.success("Note modifiée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const useDeleteNewNotReclassement = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (id:number) => {
      return fetchApi("/api/notes_results_reclassements/{id}", {
        method: "delete",
        params: {id:id+""},
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notes_results_recl"]);
        toast.success("Note supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
