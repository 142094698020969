import * as React from "react";
import styled from "styled-components";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjLoading, MbjScrollArea } from "@mbj-front-monorepo/ui";
import { CompetenceItem } from "./competenceItem/competenceItem";
import { useGetFlopCompetences } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos sur les à développer :
 */
interface CompetencesToDevCardCtrlProps extends MbjCardClassicCtrlProps {
  /**
   * les données sont-elles en train de se charger ?
   */
  isLoading?: boolean;
}

function CompetencesToDevCardCtrl(props: CompetencesToDevCardCtrlProps) {
  const flopCompetencesQuery = useGetFlopCompetences();

  return (
    <MbjCardClassic
      {...props}
      className={props.className}
      grow={props.grow}
      noPadding
      title={"Compétences utiles et à développer"}
    >
      <MbjScrollArea maxHeight={"250px"}>
        {flopCompetencesQuery.isLoading ? (
          <MbjLoading />
        ) : flopCompetencesQuery.isError ? (
          <p>Error !</p>
        ) : (
          flopCompetencesQuery.data?.map((competence: typeof flopCompetencesQuery.data[0], i: number) => (
            <CompetenceItem competence={competence} key={competence.id} />
          ))
        )}
      </MbjScrollArea>
    </MbjCardClassic>
  );
}
export const CompetencesToDevCard = styled(CompetencesToDevCardCtrl)((props) => ({}));
