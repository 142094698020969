import * as React from "react";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { useModal } from "@mbj-front-monorepo/ui";
import { ModifierServiceModal } from "./ModifierServiceModal";

interface ServiceItemCtrlProps {
  Service: GetElementType<Operation<"getServicesCollection">>;
}

function ServiceItemCtrl(props: ServiceItemCtrlProps) {
  const { isShowing: modifierServiceIsShowing, toggle: modifierServiceToggle } = useModal();

  return (
    <>
      <tr className="selectable" onClick={modifierServiceToggle}>
        <td>{props.Service.libelle}</td>
        <td className={"isCenter"}>{props.Service.Employees?.length}</td>
        <td className={"isCenter"}>
          {props.Service.creationAt ? getDateFR(new Date(props.Service.creationAt)) : "--"}
        </td>
      </tr>
      <ModifierServiceModal Service={props.Service} isShowing={modifierServiceIsShowing} hide={modifierServiceToggle} />
    </>
  );
}
export const ServiceItem = styled(ServiceItemCtrl)((props) => ({}));
