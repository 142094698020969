import React from "react";
import styled from "styled-components";
import { CgWebsite } from "react-icons/cg";
import { IoLocationSharp, IoSchoolSharp } from "react-icons/io5";
import { MbjOpenCloseDetails, MbjStaticInfo } from "@mbj-front-monorepo/ui";
import { AiTwotonePhone } from "react-icons/ai";
import { GrMail } from "react-icons/gr";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { GiPathDistance } from "react-icons/gi";

interface LineSuggestionFormationCtrlProps {
  suggestion: GetElementType<GetElementType<Operation<"getFormationMetierCollection">>["FormationsCourtesMetier"]>;
  className?: string;
}

const LineSuggestionFormationCtrl = (props: LineSuggestionFormationCtrlProps) => {
  return (
    <div className={props.className}>
      <MbjOpenCloseDetails hidePrefixe defaultOpen text={props.suggestion.libelle || ""}>
        <div className={"suggFormaContent"}>
          {props.suggestion.presentation && (
            <MbjStaticInfo label={"Présentation"} info={props.suggestion.presentation} />
          )}
          {props.suggestion.duree && <MbjStaticInfo label={"Durée"} info={props.suggestion.duree + ""} />}

          <ul className="list-unstyled">
            {props.suggestion.OrganismeFormationDistants?.length === 0
              ? "Aucun organisme de formation"
              : props.suggestion.OrganismeFormationDistants?.map((of, idx: number) => (
                  <li key={`oneOF${idx}`}>
                    <div className="one-OF">
                      <div className={"name-of"}>
                        <IoSchoolSharp style={{marginRight:"8px"}}/>
                        <span className={"ms-2"}>{of.rs}</span>
                      </div>
                      <div className={"infos-of"}>
                        <div className="one-info">
                          <GiPathDistance />
                          <span>{of.distance ? Math.round(of.distance) : ""}km</span>
                        </div>
                        {of.siteWeb !== null && (
                          <div className="one-info">
                            <CgWebsite />
                            <a href={of.siteWeb}>{of.siteWeb}</a>
                          </div>
                        )}
                        <div className="one-info">
                          <IoLocationSharp />
                          <span>{of.adresse ? of.adresse : of.ville}</span>
                        </div>
                        {of.email !== null && (
                          <div className="one-info">
                            <GrMail />
                            <span>{of.email ? of.email : ""}</span>
                          </div>
                        )}
                        {of.telephone !== null && (
                          <div className="one-info">
                            <AiTwotonePhone />
                            <span>{of.telephone ? of.telephone : ""}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </MbjOpenCloseDetails>
    </div>
  );
};
export const LineSuggestionFormation = styled(LineSuggestionFormationCtrl)((props) => ({
  //marginBottom:"10px",
  ul:{
    margin:0,
    padding:0,
    li:{
      listStyle:"none",
      display:"block",
    }
  },
  padding: "0.5rem",
  borderRadius: "0.25rem",

  ".suggFormaContent": {
    paddingLeft: "10px",
  },

  "&.opened": {
    background: "#ccc",
  },
  ".one-OF": {
    margin: "10px 0 10px 15px",
    fontSize: "14px",
    ".name-of": {
      fontWeight: "bold",
      textDecoration: "underline",
      marginBottom: "5px",
      display:"flex",
      alignItems:"center"
    },
    ".one-info": {
      marginLeft: "10px",
      display:"flex",
      alignItems:"center",
      span: {
        marginLeft: "10px",
      },
    },
  },
}));
