import styled from "styled-components";
import {ServiceBlock} from "../BesoinsParServices";
import {useState} from "react";
import * as React from "react";
import {MbjAccordion, MbjAccordionContent, MbjAccordionItem, MbjAccordionTrigger} from "@mbj-front-monorepo/ui";
import {WidgetInscription} from "./WidgetInscription";

interface OneBlockServiceCtrlProps{
  className?:string;
  BlockService:ServiceBlock;
  ClickActi:(item:{id:number, libelle:string, niveau:number})=>void;
}

const OneBlockServiceCtrl = (props:OneBlockServiceCtrlProps)=>{
  const [open, setOpen] = useState(false);
  return (
    <div className={`one_block_service ${props.className} ${open ? 'opened' : 'closed'}`}>
      <div className={`titre_block`} onClick={()=>setOpen(o=>!o)}>{props.BlockService.Service.libelle} ({props.BlockService.nb})</div>
      <div className={"content_block_comp"}>
        <MbjAccordion type="multiple" defaultValue={[]} className={"accordéon"}>
          {props.BlockService.listCompetence.sort((a, b)=>(a.Competence.libelle||'') > (b.Competence.libelle||'') ? 1 : -1).map((item, idx)=>(
            <MbjAccordionItem value={`item-${idx}`} key={idx}>
              <MbjAccordionTrigger>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", alignContent:"center"}}>
                  <div className="accordion-trigger-content">
                    <span>{item.Competence.libelle} ({item.Activites.length})</span>
                  </div>
                </div>
              </MbjAccordionTrigger>

              <MbjAccordionContent>
                {item.Activites?.map((itemE, idx2:number)=>(
                  <div className={"Activite_need"} key={`Activite_need${idx2}`}>
                    <div className={"titre_activite"}>
                      <div className={"lib_acti"}>
                        {itemE.Activite.libelle}
                      </div>
                      <div className={"details"} onClick={()=>props.ClickActi({id:(itemE.Activite?.id||0), libelle:(itemE.Activite?.libelle||''), niveau:0})}>Comment former sur cette compétence ?</div>
                    </div>
                    <div className={"list_employee"}>
                      {itemE.EmployeesInActi.map((itemX, idx3)=>(
                        <div className={`one_employee_need`} key={`one_employee_need${itemX.Employee.id}`}>
                          <div className={`name_pos`}>{itemX.Employee.name}</div>
                          <div className={"compare_niveau"}>
                            <div className={`niveau_pos slider_niveau`}>
                              <div className={`slidy_niveau`} style={{width:`${itemX.niveau/5*100}%`}}/>
                            </div>
                            <div className={`target_pos slider_niveau`}>
                              <div className="slidy_niveau" style={{width:`${itemX.niveauTarget/5*100}%`}}/>
                            </div>
                          </div>
                          <div className={"employee_action"}>
                            <WidgetInscription idActivite={itemE.Activite.id} idEmployee={itemX.Employee.id}/>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </MbjAccordionContent>
            </MbjAccordionItem>

          ))}
        </MbjAccordion>
      </div>
    </div>
  )
}

export const OneBlockService = styled(OneBlockServiceCtrl)`
  margin-bottom: 10px;
  background: white;
  padding: 0.5rem;
  border-radius: 6px;
  .employee_action{
    height: 30px;
  }
  .list_employee {
    margin-left: 15px;

    .one_employee_need {
      .name_pos{
        color: black;
      }
      padding: 0.25rem 0;
      margin-bottom: 6px;
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      align-items: center;
    }

    .niveau_pos {
      .slidy_niveau {
        background: ${props => props.theme.primary};
      }
    }

    .target_pos {
      .slidy_niveau {
        background: ${props => props.theme.third};
      }
    }

    .slider_niveau {
      height: 10px;
      background: #eae9e9;
      position: relative;
      width: 100px;
      border-radius: 6px;
      overflow: hidden;

      &:first-child {
        margin-bottom: 4px;
      }

      .slidy_niveau {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      }
    }
  }

  .titre_activite {
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #ccc;
  }

  .Activite_need {
    margin-left: 20px;
  }

  .titre_block {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .content_block_comp {
    display: none;
    padding-left: 1rem;
  }

  &.opened {
    .content_block_comp {
      display: block;
    }
  }
`
