import React, { useRef, useState } from "react";
import styled from "styled-components";
import { BsFillTriangleFill, BsSearch } from "react-icons/bs";
import { UseQueryResult } from "@tanstack/react-query";
import { MbjListItem } from "../../atoms/MbjListItem/MbjListItem";
import { MbjLoading } from "../../atoms/MbjLoading/MbjLoading";
import { MbjScrollArea } from "../MbjScrollArea/MbjScrollArea";
import {BiChevronDown, BiChevronUp} from "react-icons/bi";

interface MbjSearchBarCtrlProps {
  /**
   * Couleur utilisée pour représenter l'ordre d'importance de l'élément sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Doit-il y avoir des marges intérieures ?
   */
  noPadding?: boolean;
  /**
   * Le texte doit-il être en gras ?
   */
  isBold?: boolean;
  /**
   * Fonction réagissant a un changement
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClickHandle?: any;
  /**
   * Doit-il être désactivé ou non ?
   */
  disabled?: boolean;
  /**
   * Classe HTML de l'élément ?
   */
  className?: string;
  /**
   * Id de l'élément ?
   */
  id?: string;
  /**
   * Placholder
   */
  placeholder?: string;
  /**
   * Valeur par défaut :
   */
  defaultValue?: string;
  /**
   * Requete contenant les suggestions (permettant à l'utilisateur d'autocompléter le résultat)
   */
  suggestionsQuery?: UseQueryResult<any[], Error>;
  labelKey?: string;
  /**
   * Hooks permettant de sélectionner plusieurs options
   */
  selectsHook?: {
    addOption: (optionToAdd: any) => void;
    options: any[];
    removeOption: (optionToDel: any) => void;
  };
  /**
   * On ferme la liste des propositions après en avoir choisit une ?
   */
  foldAfterChoose?: boolean;
}
function MbjSearchBarCtrl(props: MbjSearchBarCtrlProps) {
  const labelKey = props.labelKey || "label";

  const [input, setInput] = useState(props.defaultValue || "");
  const inputEl = useRef<HTMLInputElement>(null);

  const onDivClick = () => {
    if (inputEl && inputEl.current) {
      if (props.foldAfterChoose) {
        return;
      }
      // `current` fait référence au champ textuel monté dans le DOM
      inputEl.current.focus();
    }
  };

  return (
    <div className={props.className} onClick={onDivClick}>
      <BsSearch className={"icon-search"} />
      <input
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        value={input}
        ref={inputEl}
        list="suggestions"
        onInput={(e) => {
          const target = e.target as HTMLInputElement;
          setInput(target.value);
        }}
        id={props.id}
      />
      {props.suggestionsQuery ? <BiChevronUp className={"icon-triangle"} style={{fontSize:"20px"}} /> : <React.Fragment />}
      {props.suggestionsQuery ? (
        <div className={"suggestions-container"}>
          <MbjScrollArea maxHeight={"150px"}>
            {props.suggestionsQuery.isLoading ? (
              <MbjLoading />
            ) : props.suggestionsQuery.isError ? (
              <p>Erreur ...</p>
            ) : (
              props.suggestionsQuery.data
                .filter((suggestion: any) => {
                  if (input === "") {
                    return suggestion;
                  } else if (suggestion[labelKey]?.toLowerCase().includes(input.toLowerCase())) {
                    return suggestion;
                  }
                  return undefined;
                })
                .map((suggestion: any, key: number) => {
                  return (
                    <MbjListItem
                      key={key}
                      onClick={() => {
                        if (props.selectsHook) {
                          props.selectsHook.addOption(suggestion);
                        } else {
                          if (props.onClickHandle) {
                            props.onClickHandle(suggestion);
                          }
                          setInput(suggestion[labelKey]);
                        }
                      }}
                    >
                      {suggestion[labelKey]}
                    </MbjListItem>
                  );
                })
            )}
          </MbjScrollArea>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}

/**
 * Composant principal d'interaction utilisateur :
 */
export const MbjSearchBar = styled(MbjSearchBarCtrl)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.5em",
  border:`${props.theme.greyLighten} 1px solid`,
  backgroundColor: props.theme.background2,
  padding: "7px 10px",
  //boxShadow: "2px 1px 10px 2px #98989821",
  position: "relative",
  cursor: "text",

  "&:focus-within": {
    border: "2px solid",
    borderColor: props.theme.primaryPastel,

    ".icon-triangle": {
      transform: "rotate(0deg)",
    },

    ".suggestions-container": {
      visibility: "visible",
    },
  },

  ".icon-search": {
    marginRight: "15px",
    fill: props.theme.primary,
  },
  ".icon-triangle": {
    marginLeft: "8px",
    fill: props.theme.primary,
    transform: "rotate(180deg)",
    transition: "all 200ms",
  },
  input: {
    fontSize: "16px",
    border: "none",
    background: "none",
    flexGrow: 1,
  },
  ".suggestions-container": {
    visibility: "hidden",
    position: "absolute",
    top: "105%",
    width: "95%",
    backgroundColor: props.theme.background2,
    borderRadius: "0px 0px 7px 7px",
    boxShadow: "0px 7px 10px 2px #a0a0a0d6",
    transition: "all 200ms",
    zIndex: 10,

    ".suggestions-list": {
      fontSize: "calc(8px + 0.85vmin)",
      maxHeight: "0px",
      transition: "all 200ms",
    },

    "&:hover": {
      //visibility: "visible",

      ".suggestions-list": {
        maxHeight: "150px",
      },
    },
  },
}));
MbjSearchBar.defaultProps = {
  themeColor: "primary",
  noPadding: false,
  isBold: false,
  foldAfterChoose: false,
};
