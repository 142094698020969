import styled from "styled-components";
import {Operation, useGetBesoinsFormationEntreprise} from "@mbj-front-monorepo/data-access";
import {useLocation} from "react-router-dom";
import {useMemo, useRef, useState} from "react";
import {ExpertOne} from "../Collaborateurs/CollaborateurDetail/Suivi/ExpertSuivi/ExpertSuiviOneCompetenceWidget";
import {MbjLoading, MbjNavPath, MbjScrollArea, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {GrClose} from "react-icons/gr";
import {OneBlockCompetence} from "./Components/OneBlockCompetence";
import {ModalFormationActivite} from "./Components/ModalFormationActivite";
import * as React from "react";

interface BesoinsParCompetencesCtrlProps{
  className?:string;
}

interface CompetenceBlock{
  Competence:{id:number, libelle:string},
  list:Operation<"getBesoinFormationEntrepriseCollection">
  nb:number
}

const BesoinsParCompetencesCtrl = (props:BesoinsParCompetencesCtrlProps)=>{
  const location = useLocation();
  const refSearch = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>('')
  const BesoinsQuery = useGetBesoinsFormationEntreprise();
  const [openD, setOpenD] = useState(false);
  const [actiCurr, setActiCurr] = useState<{id:number, libelle:string, niveau:number}|null>(null)
  const [ExpertsCurr, setExpertsCurr] = useState<ExpertOne[]>([])
  const myList = useMemo(()=>{
    const list:CompetenceBlock[] = [];
    if(BesoinsQuery.data){
      const regex = new RegExp(""+search+"", 'gi');
      const Competences = BesoinsQuery.data.map(b=>b.activite?.Competence)
      console.log(Competences);
      let CompetencesSSD:any[] = []
      Competences.map(c=>{
        if(CompetencesSSD.map(c=>c.id).indexOf(c?.id) === -1){
          CompetencesSSD.push(c);
        }
        return c;
      })
      console.log(CompetencesSSD);
      CompetencesSSD = CompetencesSSD.sort((a,b)=>a.libelle > b.libelle ? 1 :-1);

      CompetencesSSD.map(c=>{
        const listConcerned = BesoinsQuery.data.filter(b=>b.activite?.Competence?.id === c.id)
        if(search === '' || c.libelle.match(search)) {
          list.push({
            Competence: {id: c.id, libelle: c.libelle},
            list: listConcerned,
            nb: listConcerned.length
          })
        }
        return c;
      })
    }
    return list;
  }, [BesoinsQuery.data, search])
  const SwitchOpen = ()=>{
    setOpenD(o=>!o);
  }
  const ClickActi = (item:{id:number, libelle:string, niveau:number})=>{
    const Besoins = BesoinsQuery.data?.find(b=>b.activite?.id === item.id);
    if(Besoins){
      const ExpertsThis = Besoins.Experts;
      if(ExpertsThis) {
        setExpertsCurr(ExpertsThis.map(e => {
          return {
            employee: {id: (e.employee?.id||0), prenom: (e.employee?.prenom||''), nom:  (e.employee?.nom||''), service: ''},
            niveau: 0
          }
        }))
      }
    }
    setActiCurr(item);
    SwitchOpen();
  }
  const handleChangeSearch = ()=>{
    if(refSearch.current){
      setSearch(refSearch.current.value);
    }
  }
  return (
    <div className={`formation_entreprise ${props.className}`}>
      <MbjWidgetClassic title="Recherche de compétences" className={props.className}>
        {BesoinsQuery.isLoading ? (
          <MbjLoading />
        ) : BesoinsQuery.isError || !BesoinsQuery.data ? (
          <p>Erreur ...</p>
        ) : (
          <>
            <div style={{height:"530px", width:"100%"}}>
              <div className={"Zone_search"}>
                <input value={search} onChange={handleChangeSearch} ref={refSearch} placeholder={"Recherche de compétences"}/>
                {search!=='' &&
                  <div className={`wrap_close_search`} onClick={()=>setSearch('')}>
                    <GrClose/>
                  </div>
                }
              </div>
              <MbjScrollArea overflowX={"auto"}>
                {myList.map((item, idx:number)=>(
                  <OneBlockCompetence key={`BlockComp${idx}`} titre={item.Competence.libelle + " ("+item.nb+")"} list={item.list} ClickActi={ClickActi}/>
                ))}
              </MbjScrollArea>
            </div>
          </>
        )}
      </MbjWidgetClassic>
      <ModalFormationActivite
        open={openD}
        closeMe={SwitchOpen}
        activite={actiCurr ? actiCurr : {id:0, libelle:'nc', niveau:0}}
        Experts={ExpertsCurr}
      />
    </div>
  )
}

export const BesoinsParCompetences = styled(BesoinsParCompetencesCtrl)`
  .Zone_search{
    width: 100%;
    padding: 0.5rem 0;
    position: relative;
    input{
      width: 100%;
      padding: 0 0.5rem;
      border: solid #ccc 1px;
      border-radius: 4px;
      height: 36px;
      font-size: 17px;
    }
    .wrap_close_search{
      position: absolute;
      right: 10px;
      top:50%;
      transform: translateY(-50%);
      background: red;
      color:white;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
      svg{
        margin: auto;
        stroke: white;
        color:white;
        fill:white;
        path{
          stroke: white;
        }
      }
    }
  }
  .Employee_need{
    padding: 0.5rem;
  }
  .details{
    padding: 0.25rem 0.5rem;
    background: ${props=>props.theme.primaryPastel};
    border-radius: 4px;
    &:hover{
      cursor: pointer;
    }
  }
`
