import styled from "styled-components";
import {
  components,
  Operation,
  useGetFormationPlanningMember,
  useModifyFormationPlanning
} from "@mbj-front-monorepo/data-access";
import React, {useEffect, useMemo, useState} from "react";
import {MbjCardClassic, MbjLoading, MbjModal2, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {FormNoPlanCard} from "./Components/FormNoPlanCard";
import {OneMonthPl} from "./Components/OneMonthPl";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {FormModifyNoPl} from "./Components/FormModifyNoPl";

interface FormationPlannificationCtrlProps{
  className?:string;
}

export interface BlockPlannif{
  Activite:components["schemas"]["Activite.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"];
  list:(components["schemas"]["FormationPlanning.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"])[]
  mois:string;
  id:number;
}

export interface Plannif{
  mois:string;
  list:BlockPlannif[];
  date:Date,
}

export interface OneMonth{
  num:number;
  numIso:number;
  libelle:string;
  libShort:string;
}

export interface OneMonthForIn{
  month:OneMonth,
  date:Date,
}

export const TabMois:OneMonth[] = [
  {num:0, numIso:1, libelle:'Janvier', libShort:'Janv.'},
  {num:1, numIso:2, libelle:'Février', libShort:'Fev.'},
  {num:2, numIso:3, libelle:'Mars', libShort:'Mars'},
  {num:3, numIso:4, libelle:'Avril', libShort:'Avr.'},
  {num:4, numIso:5, libelle:'Mai', libShort:'Mai'},
  {num:5, numIso:6, libelle:'Juin', libShort:'Juin.'},
  {num:6, numIso:7, libelle:'Juillet', libShort:'Juill.'},
  {num:7, numIso:8, libelle:'Août', libShort:'Août'},
  {num:8, numIso:9, libelle:'Septembre', libShort:'Sept.'},
  {num:9, numIso:10, libelle:'Octobre', libShort:'Oct.'},
  {num:10, numIso:11, libelle:'Novembre', libShort:'Nov.'},
  {num:11, numIso:12, libelle:'Décembre', libShort:'Dec.'},
]

const FormationPlannificationCtrl = (props:FormationPlannificationCtrlProps)=>{
  const mutationChange = useModifyFormationPlanning();
  const PlannQuery = useGetFormationPlanningMember();
  const TabMoisCpnt:OneMonthForIn[] = [];
  const today = new Date();
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const [plannifs, setPlannifs] = useState<Plannif[]>([])
  const [formationPlCurrent, setFormationPlCurrent] = useState<components["schemas"]["FormationPlanning.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"]|null>(null)
  const [openEditNoPlannif, setOpenEditNoPlannif] = useState<boolean>(false)
  for(let i=0; i<12; i++) {
    const DateDecale = new Date(firstDate.getTime() + 86400000 * i * 32);
    const ThisDate = new Date(DateDecale.getFullYear(), DateDecale.getMonth(), 1);
    const monthThis = DateDecale.getMonth();
    const MyMonth = TabMois.find(m=>m.num === monthThis);
    if(MyMonth){
      TabMoisCpnt.push({
        month:MyMonth,
        date:ThisDate
      })
    }
  }
  const SaveFormationPlDate = (id:number, date:Date)=>{
    const MyNewDate = date.setHours(12,0,0);
    const data:Operation<"putFormationPlanningItem", "requestBody">={
      id:id,
      souhaitAt:date.toISOString().slice(0,10)
    }
    mutationChange.mutate(data)
  }
  const listNoPlannif = useMemo(()=>{
    if(!PlannQuery.data){
      console.log('ici');
      return [];
    } else {
      console.log(PlannQuery.data);
      return PlannQuery.data.filter(p=>!p.souhaitAt);
    }
  }, [PlannQuery.data])
  useEffect(()=>{
    if(PlannQuery.data){
      const today = new Date();
      const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const MyTab:Plannif[]=[]
      for(let i=0; i<12; i++){
        const DateDecale = new Date(firstDate.getTime() + 86400000*i*32);
        const ThisDate = new Date(DateDecale.getFullYear(), DateDecale.getMonth(), 1);
        const monthThis = DateDecale.getMonth();
        const MyMonth = TabMois.find(m=>m.num === monthThis);
        if(MyMonth) {
          const PlannQueryConcerned = PlannQuery.data.filter(p => p.souhaitAt && new Date(p.souhaitAt).getMonth() === monthThis && new Date(p.souhaitAt).getFullYear() === ThisDate.getFullYear())
          const ActivitesConcerned = PlannQueryConcerned.map(pl => pl.Activite);
          const ActivitesConcernedSSD: components["schemas"]["Activite-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"][] = [];
          ActivitesConcerned.map(ac => {
            if (ac) {
              if (ActivitesConcernedSSD.map(acd => acd.id).indexOf(ac.id) === -1) {
                ActivitesConcernedSSD.push(ac)
              }
            }
          })
          const ListForActi: BlockPlannif[] = [];
          //mois:MyMonth ? MyMonth+" "+DateDecale.getFullYear() : '',
          ActivitesConcernedSSD.map(a => {
            ListForActi.push({
              Activite: a,
              list: PlannQueryConcerned.filter(p => p.Activite && p.Activite.id === a.id),
              mois: `${MyMonth.libelle} ${ThisDate.getFullYear()}`,
              id:ThisDate.getFullYear()+MyMonth.num
            })
            return a;
          })
          MyTab.push({
            mois: `${MyMonth.libelle} ${ThisDate.getFullYear()}`,
            list: ListForActi,
            date:ThisDate,
          })
        }
      }
      setPlannifs(MyTab);
    }
  }, [PlannQuery.data])
  const handleClickOnNoPlannif = (fp:components["schemas"]["FormationPlanning.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"])=>{
    setFormationPlCurrent(fp)
    setOpenEditNoPlannif(o=>!o);
  }
  return (
    <div className={`formation_plannification ${props.className}`}>
      <MbjWidgetClassic title={"Les formations non plannifiées"} basis={"100%"}>
        {
          PlannQuery.isLoading ? <MbjLoading/> : PlannQuery.isError ? 'Error Data':
            <div className={`wrap_list_formation_no_planif`}>
              {listNoPlannif.length===0 ? <p>Aucune formation non plannifiée</p>:
                <div className={`wrappy_list_formation_no_planif`}>
                  {listNoPlannif.map((item)=>(
                    <FormNoPlanCard key={`oneFNoPl${item.id}`} FormationPl={item} ClickOnIt={handleClickOnNoPlannif}/>
                  ))}
                </div>
              }
            </div>
        }
      </MbjWidgetClassic>
      <MbjWidgetClassic title={"Plannification"} basis={"100%"}>
        <DndProvider backend={HTML5Backend}>
          <div className={`wrap_plann`}>
            {TabMoisCpnt.map((item, idx:number)=>(
              <OneMonthPl key={`MT${idx}`} date={item.date} Month={item.month} list={plannifs} setPlannifs={setPlannifs} SaveFormationPlDate={SaveFormationPlDate}/>
            ))}
          </div>
        </DndProvider>
      </MbjWidgetClassic>
      <MbjModal2 open={openEditNoPlannif} closeMe={()=>setOpenEditNoPlannif(false)} titre={"Gestion formation"} Wd={"50%"}>
        {formationPlCurrent ?
          <FormModifyNoPl FormationPl={formationPlCurrent} CloseMe={()=>setOpenEditNoPlannif(false)}/>:<p>Bad</p>
        }
      </MbjModal2>
    </div>
  )
}

export const FormationPlannification = styled(FormationPlannificationCtrl)`
  padding: 1% 2%;
  .wrap_list_formation_no_planif{
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
  }
  .wrappy_list_formation_no_planif{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .wrap_plann{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`
