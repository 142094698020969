import * as React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { MbjNavPath } from "@mbj-front-monorepo/ui";

interface FormationsSessionsCtrlProps {
  className?: string;
}

function FormationsSessionsCtrl(props: FormationsSessionsCtrlProps) {
  const location = useLocation();
  return (
    <div className={"FormationsSessions" + props.className}>
      <MbjNavPath routePath={location.pathname} />
    </div>
  );
}
export const FormationsSessions = styled(FormationsSessionsCtrl)((props) => ({
  padding: "1% 2%",
}));
