import * as React from "react";
import styled from "styled-components";
import { MbjFlex, MbjNavPath } from "@mbj-front-monorepo/ui";
import { useLocation } from "react-router-dom";
import { InfosGeneralesCard } from "./InfosGeneralesCard/InfosGeneralesCard";
import { ResumeCard } from "./ResumeCard/ResumeCard";
import { EffectifsCard } from "./EffectifsCard/EffectifsCard";

interface EntrepriseInfosCtrlProps {
  className?: string;
}
function EntrepriseInfosCtrl(props: EntrepriseInfosCtrlProps) {
  const location = useLocation();
  return (
    <div className={"EntrepriseInfos" + props.className}>
      <MbjNavPath routePath={location.pathname} />
      <MbjFlex>
        <InfosGeneralesCard className="infos" />
        <MbjFlex direction="column" basis={"74%"}>
          <ResumeCard />
          <EffectifsCard />
        </MbjFlex>
      </MbjFlex>
    </div>
  );
}
export const EntrepriseInfos = styled(EntrepriseInfosCtrl)((props) => ({
  padding: "1% 2%",
}));
