import * as React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { MessageItemCard } from "../MessageItemCard/MessageItemCard";
import {
  MbjButton,
  MbjFlex,
  MbjLoading,
  MbjNavPath,
  MbjScrollArea,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import { NouveauMessageModal } from "../NouveauMessageModal/NouveauMessageModal";
import { useGetMessagesOutbox } from "@mbj-front-monorepo/data-access";
import { GoPlus } from "react-icons/go";

interface MessageEnvoyesCtrlProps {
  className?: string;
}

function MessageEnvoyesCtrl(props: MessageEnvoyesCtrlProps) {
  const location = useLocation();
  const messagesOutboxQuery = useGetMessagesOutbox();

  const messagesFiltered = useMemo(() => {
    if (
      messagesOutboxQuery === undefined ||
      messagesOutboxQuery.data === null ||
      messagesOutboxQuery.data === undefined
    ) {
      return [];
    }
    return messagesOutboxQuery.data?.filter((message) => !message.isTrashFrom && !message.isVeryTrashFrom);
  }, [messagesOutboxQuery.data]);

  const { isShowing: isShowing, toggle: toggle } = useModal();

  return (
    <div className={"MessageEnvoyes " + props.className}>
      <div className={"top-container"}>
        <MbjNavPath routePath={location.pathname} />
        <MbjButton onClick={toggle} leftIcon={<GoPlus />}>
          Nouveau message
        </MbjButton>
        <NouveauMessageModal isShowing={isShowing} hide={toggle} />
      </div>
      <MbjWidgetClassic bodyDirection={"column"} title={"Messages envoyés"}>
        {messagesOutboxQuery.isLoading ? (
          <MbjLoading />
        ) : messagesOutboxQuery.isError ? (
          <p>Erreur ...</p>
        ) : messagesFiltered.length >= 0 ? (
          <>
            <div>
              {messagesFiltered.length + " " + (messagesFiltered.length === 1 ? "message envoyé" : "messages envoyés")}
            </div>
            <MbjScrollArea maxHeight={"550px"}>
              <MbjFlex direction={"column"} gap={"12px"}>
                {messagesFiltered.map((message, idx) => (
                  <MessageItemCard type="outbox" message={message} key={message.id} />
                ))}
              </MbjFlex>
            </MbjScrollArea>
          </>
        ) : (
          <div>Aucun message envoyé...</div>
        )}
      </MbjWidgetClassic>
    </div>
  );
}
export const MessageEnvoyes = styled(MessageEnvoyesCtrl)((props) => ({
  padding: "1% 2%",

  ".top-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));
