import * as React from "react";
import { MbjAvatar, MbjCardClassic, MbjFlex, MbjLoading, MbjStaticInfo } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useGetMemberMe } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos générales de l'entreprise :
 */
interface InfosGeneralesCardCtrlProps {
  /**
   * Classe html de la carte :
   */
  className?: string;
  /**
   * Largeur que doit prendre cette carte :
   */
  grow?: number;
  /**
   * les données sont-elles en train de se charger ?
   */
  isLoading?: boolean;
}

function InfosGeneralesCardCtrl(props: InfosGeneralesCardCtrlProps) {
  const memberQuery = useGetMemberMe();

  function shortDesc(desc: string, size = 50) {
    if (desc.length > size) {
      return desc.substring(0, size) + " ...";
    }
    return desc.substring(0, size);
  }

  return (
    <MbjCardClassic title="Informations" className={props.className} grow={props.grow} to={"../informations"}>
      {memberQuery.isLoading ? (
        <MbjLoading />
      ) : memberQuery.error ? (
        <p>Erreur ...</p>
      ) : (
        <MbjFlex direction={"column"}>
          <div style={{marginTop:"10px", textAlign:"center"}}>
            <MbjAvatar
              className={"profilImage"}
              src={`${process.env.REACT_APP_API_URL_ROOT}/logos/members/${memberQuery.data.logo}`}
              objectFit={"contain"}
              bg={"#ffffff"}
              size={"xl"}
              withShadow
              name={memberQuery.data.name}
            />
          </div>
          <MbjFlex direction={"column"}>
            <MbjStaticInfo
              label="Résumé"
              info={memberQuery.data.description ? shortDesc(memberQuery.data.description) : "Résumé non renseigné"}
            />
            <MbjStaticInfo label="Raison sociale" info={memberQuery.data.name} />
            <MbjStaticInfo label="E-mail" info={memberQuery.data.email || "inconnu"} />
            <MbjStaticInfo label="Siret" info={memberQuery.data.siret || "inconnu"} />
          </MbjFlex>
        </MbjFlex>
      )}
    </MbjCardClassic>
  );
}
export const InfosGeneralesCard = styled(InfosGeneralesCardCtrl)((props) => ({
  ".profilImage": {
    alignSelf: "center",
  },
}));
