import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import {
  MbjAvatar,
  MbjButton,
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjEmailSimpleInput,
  MbjFlex,
  MbjLoading,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjTooltip,
  MbjTooltipArrow,
  MbjTooltipContent,
  MbjTooltipTrigger,
  useModal,
} from "@mbj-front-monorepo/ui";
import { Operation, useGetMemberMe, useMutateMember } from "@mbj-front-monorepo/data-access";
import { ChangeImageProfilModal } from "./ChangeImageProfilModal/ChangeImageProfilModal";
import { useForm } from "react-hook-form";
import { getDateEN } from "@mbj-front-monorepo/utils";

/**
 * Carte contenant des infos générales de l'entreprise :
 */
interface InfosGeneralesCardCtrlProps extends MbjCardClassicCtrlProps {
  /**
   * Classe html de la carte :
   */
  className?: string;
  /**
   * les données sont-elles en train de se charger ?
   */
  isLoading?: boolean;
}

function parseDate(date: string) {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
}

function InfosGeneralesCardCtrl(props: InfosGeneralesCardCtrlProps) {
  const memberQuery = useGetMemberMe();
  const mutation = useMutateMember();
  const { isShowing: isShowing, toggle: toggle } = useModal();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<Operation<"putMemberItem", "requestBody">>({
    mode: "onChange",
  });

  useEffect(() => {
    const newData = {
      ...memberQuery.data,
      creationEntrepriseAt: memberQuery.data?.creationEntrepriseAt
        ? getDateEN(new Date(memberQuery.data?.creationEntrepriseAt))
        : undefined,
    };
    reset(newData);
  }, [memberQuery.data]);

  const onSubmit = (data: Operation<"putMemberItem", "requestBody">) => {
    console.log(data);
    mutation.mutate(data);
  };

  return (
    <MbjCardClassic title="Informations générales" className={props.className} {...props}>
      {memberQuery.isLoading ? (
        <MbjLoading />
      ) : memberQuery.error ? (
        <p>Erreur ...</p>
      ) : (
        <MbjFlex direction={"column"}>
          <MbjTooltip delayDuration={0}>
            <MbjTooltipTrigger onClick={toggle}>
              <div style={{textAlign:"center"}}>
                <MbjAvatar
                  className={"profilImage"}
                  src={`${process.env.REACT_APP_API_URL_ROOT}/logos/members/${memberQuery.data.logo}`}
                  objectFit={"contain"}
                  bg={"#ffffff"}
                  size={"xl"}
                  withShadow
                  name={memberQuery.data.name}
                />
              </div>
            </MbjTooltipTrigger>
            <MbjTooltipContent sideOffset={5}>
              Modifier l'image de profil
              <MbjTooltipArrow />
            </MbjTooltipContent>
          </MbjTooltip>
          <ChangeImageProfilModal isShowing={isShowing} hide={toggle} />
          <form onSubmit={handleSubmit(onSubmit)} className="Infos" style={{display:"flex", gap:"15px", flexDirection:"column"}}>
            <MbjSimpleInputContainer errors={errors} label={"Dénomination *"}>
              <MbjSimpleInput
                name={"name"}
                id={"name"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Dénomination requise",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={errors} label={"Siret *"}>
              <MbjSimpleInput
                name={"siret"}
                id={"siret"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Siret requis",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={errors} label={"Date de création *"}>
              <MbjSimpleInput
                name={"creationEntrepriseAt"}
                id={"creationEntrepriseAt"}
                type={"date"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Date de création requise",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={errors} label={"E-mail *"}>
              <MbjEmailSimpleInput
                name={"email"}
                id={"email"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Email requis",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={errors} label={"Adresse *"}>
              <MbjSimpleInput
                name={"adresse"}
                id={"adresse"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Adresse requise",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={errors} label={"Code postal *"}>
              <MbjSimpleInput
                name={"codePostal"}
                id={"codePostal"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Code postal requis",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={errors} label={"Ville *"}>
              <MbjSimpleInput
                name={"ville"}
                id={"ville"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Ville requise",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjButton isPending={mutation.isLoading}>Confirmer</MbjButton>
          </form>
        </MbjFlex>
      )}
    </MbjCardClassic>
  );
}
export const InfosGeneralesCard = styled(InfosGeneralesCardCtrl)((props) => ({
  ".profilImage": {
    margin: "1rem auto",
    cursor: "pointer",
  },
  "& .Infos": {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    gap: "8px",
    flexGrow: 1,
    ".label": {
      color: props.theme.lightText,
    },
    ".data": {
      color: props.theme.primary,
      fontWeight: 600,
    },
    button: {
      marginLeft: "auto",
      marginTop: "25px",
    },
  },
}));
