import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  MbjButton,
  MbjDragDropFile,
  MbjFlex,
  MbjModal,
  MbjModalCtrlProps,
  MbjStaticInfo,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";

interface ScanCVModalCtrlProps extends MbjModalCtrlProps {
  className?: string;
}

function ScanCVModalCtrl(props: ScanCVModalCtrlProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [cv, setCV] = useState<File | null>(null);

  function onSubmit(data: any) {
    console.log(cv);
    //mutationLogo.mutate(data.file[0]);
  }

  return (
    <MbjModal {...props} maxWidth={"70%"} title={"Scanner un CV"}>
      <form onSubmit={handleSubmit(onSubmit)} className="ImageChooseForm">
        <MbjFlex direction={"column"}>
          <MbjDragDropFile
            accept={"image/png, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document "}
            onUpload={(files) => {
              setCV(files[0]);
              console.log(files[0]);
            }}
            count={1}
            formats={["png", "jpeg", "jpg", "pdf", 'doc', 'docx']}
          />
          {cv && <MbjStaticInfo label={"Nom du fichier :"} info={cv.name} />}
          <MbjButton alignSelf={"flex-end"}>Enregistrer</MbjButton>
        </MbjFlex>
      </form>
    </MbjModal>
  );
}
export const ScanCVModal = styled(ScanCVModalCtrl)((props) => ({}));
