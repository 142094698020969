import styled from "styled-components";
import { useGetAllSearchByMember } from "@mbj-front-monorepo/data-access";
import { useMemo } from "react";
import { MbjLoading, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import { RechercheUserMetierCard } from "./RechercheUserMetierCard";
import {RechercheMetierCard} from "./RechercheMetierCard";

interface DashboardCtrlProps {
  className?: string;
}

const DashboardCtrl = (props: DashboardCtrlProps) => {
  const SeachQuery = useGetAllSearchByMember();
  const listSearchUserMetier = useMemo(() => {
    if (!SeachQuery.data) {
      return [];
    } else {
      return SeachQuery.data.filter((s) => !!s.UserMetier);
    }
  }, [SeachQuery.data]);
  const listSearchMetier = useMemo(() => {
    if (!SeachQuery.data) {
      return [];
    } else {
      return SeachQuery.data.filter((s) => s.Metier);
    }
  }, [SeachQuery.data]);
  return (
    <div className={"Dashboard" + props.className}>
      {SeachQuery.isLoading ? (
        <MbjLoading />
      ) : SeachQuery.isError ? (
        "Error data"
      ) : (
        <>
          {listSearchUserMetier.length > 0 && (
            <MbjWidgetClassic
              title={`Recherches sur les métiers internes (${listSearchUserMetier.length})`}
              bodyDirection={"column"}
              bodyNoWrap
            >
              <div className={`wrap_search_internes`}>
                <div className="wrappy_search_internes">
                  {listSearchUserMetier.map((item) => (
                    <RechercheUserMetierCard Search={item} key={`oneSearchUM${item.id}`} />
                  ))}
                </div>
              </div>
            </MbjWidgetClassic>
          )}
          {listSearchMetier.length > 0 && (
            <MbjWidgetClassic
              title={`Recherches sur les autres métiers (${listSearchMetier.length})`}
              bodyGrow={1}
              grow={1}
              bodyDirection={"column"}
              bodyNoWrap
            >
              <div className={`wrap_search_internes`}>
                <div className="wrappy_search_internes">
                  {listSearchMetier.map((item) => (
                    <RechercheMetierCard Search={item} key={`oneSearchUMExt${item.id}`} />
                  ))}
                </div>
              </div>
            </MbjWidgetClassic>
          )}
        </>
      )}
    </div>
  );
};
export const Dashboard = styled(DashboardCtrl)`
  padding: 1% 2%;
  .wrap_search_internes {
    max-height: 350px;
    overflow-y: auto;
    padding: 5px 0 35px 0;
    .wrappy_search_internes {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
`;
