import styled from "styled-components";
import {Operation, useGetEmployees} from "@mbj-front-monorepo/data-access";
import * as React from "react";
import {useMemo} from "react";
import {useNavigate} from "react-router-dom";

interface FormatCibleCardCtrlProps{
  className?:string;
  UserMetier:Operation<"getUserMetierItem">
}

const FormatCibleCardCtrl = (props:FormatCibleCardCtrlProps)=>{
  const navigate = useNavigate();
  const EmployeesQuery = useGetEmployees();
  const EmployeeIn = useMemo(()=>{
    const list:Operation<"getEmployeeItem">[] = [];
    if(EmployeesQuery.data && props.UserMetier){
        const EmployeeFilter = EmployeesQuery.data.filter(e=>e.UserMetier && e.UserMetier.id === props.UserMetier.id)
        EmployeeFilter.map(e=>{
          list.push(e);
          return e;
        })
    }
    return list;
  }, [props.UserMetier, EmployeesQuery.data])
  const ClickOn = ()=>{
    navigate(`${props.UserMetier.id}`);
  }
  return (
    <div className={`format_cible_card ${props.className}`} onClick={ClickOn}>
      <div className={"in_format"}>
          <div className={`name_metier`}>{props.UserMetier.namePerso}</div>
          <div className={`cible`}>Format Cible : {props.UserMetier.formatCible}</div>
          <div className={`Format_actuel`}>Format Actuel : {EmployeeIn.length}</div>
           {props.UserMetier.formatCibleAt &&
            <div className={`cible_at`}>Date Cible : {new Date(props.UserMetier.formatCibleAt).toLocaleDateString()}</div>
           }
      </div>
    </div>
  )
}

export const FormatCibleCard = styled(FormatCibleCardCtrl)`
  padding: 0.5rem;
  width: 33.33%;
  .in_format{
    background: white;
    height: 100%;
    padding: 0.5rem;
    border-radius: 6px;
    .name_metier{
      font-weight: bold;
      color: ${props => props.theme.primary};
      font-size: 20px;
    }
    &:hover{
      cursor: pointer;
      box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    }
  }
`
