import React from "react";
import styled from "styled-components";
import { MbjLink } from "../MbjLink/MbjLink";

interface MbjListItemCtrlProps {
  /**
   * Classe HTML :
   */
  className?: string;
  /**
   * Contenu
   */
  children?: React.ReactNode;
  /**
   *
   */
  padding?: string | number;
  /**
   * Doit à avoir le tag html li ? :
   */
  tagged?: boolean;
  /**
   * bordure ?
   */
  noBorder?: boolean;
  /**
   * L'element est-il clickable ?
   */
  //clickable?: boolean;
  /**
   * onClick
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * L'item doit il redirigé vers un autre url ?
   */
  to?: any;
}
function MbjListItemCtrl(props: MbjListItemCtrlProps) {
  const className = props.className + (props.onClick || props.to ? " clickable" : "");

  if (props.to) {
    return (
      <MbjLink to={props.to} themeColor={"dark"} isBold={true}>
        <div className={className} onClick={props.onClick}>
          {props.tagged ? <li>{props.children}</li> : props.children}
        </div>
      </MbjLink>
    );
  }
  return (
    <div className={className} onClick={props.onClick}>
      {props.tagged ? <li>{props.children}</li> : props.children}
    </div>
  );
}

/**
 * Composant représentant un item dans une liste
 */
export const MbjListItem = styled(MbjListItemCtrl)((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "&:not(:last-child)": {
    borderBottom: props.noBorder ? "0px" : "1px solid",
    borderColor: "#f3eded",
  },
  padding: props.tagged ? "0.3rem 1.6rem" : props.padding,

  "&.clickable": {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: props.theme.greyPastel,
    },
  },
}));
MbjListItem.defaultProps = {
  padding: "0.5rem 0.9rem",
  tagged: false,
  noBorder: false,
};
