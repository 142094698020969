import styled from "styled-components";
export interface ItemMenuResult{
  id:number;
  libelle:string;
}

interface MbjChoixMenuCtrlProps{
  className?:string;
  choices:ItemMenuResult[];
  current:ItemMenuResult|null;
  setChoice:(i:ItemMenuResult)=>void;
  getNbResult?:(i:number)=>number;
  noNbResult?:boolean;
}

const MbjChoixMenuCtrl = (props:MbjChoixMenuCtrlProps)=>{
  return (
    <div className={"MenuChoix" + props.className}>
      {props.choices.map((item)=>(
        <div onClick={()=>props.setChoice(item)} className={`one_menu_choice ${item.id === props.current?.id ? 'current' : ''}`}  key={`omc${item.id}`}>{item.libelle} {!props.noNbResult && props.getNbResult && `(${props.getNbResult(item.id)})`}</div>
      ))}
    </div>
  )
}

export const MbjChoixMenu = styled(MbjChoixMenuCtrl)`
  margin: 15px auto;
  display: flex;
  justify-content: flex-start;
  .one_menu_choice{
    padding: 0.5rem 1rem;
    &.current{
      border-bottom: solid ${props => props.theme.primary} 4px;
      font-weight: bold;
    }
    &:hover{
      font-weight: bold;
      cursor: pointer;
    }
  }
`
