import styled from "styled-components";
import {MbjModal, MbjModalCtrlProps} from "@mbj-front-monorepo/ui";
import {AjoutFDPoste} from "../../AjoutCV/AjoutFDPoste";


interface AjoutMetierByScanModalCtrlProps extends MbjModalCtrlProps {}

const AjoutMetierByScanModalCtrl = (props:AjoutMetierByScanModalCtrlProps)=>{
  return (
    <MbjModal {...props} title="Ajout d'une fiche de poste" fitContent maxWidth={"80%"}>
      <AjoutFDPoste CloseMe={props.hide}/>
    </MbjModal>
  )
}
export const AjoutMetierByScanModal = styled(AjoutMetierByScanModalCtrl)``
