import styled from "styled-components";
import {
  useGetEmployee,
  useGetEmployeesCompetences,
  useGetFI,
  useGetParticulier,
  useGetXp
} from "@mbj-front-monorepo/data-access";
import {
  AvatarEmp,
  MbjAccordion,
  MbjAccordionItem,
  MbjFlex,
  MbjLoading,
  MbjScrollArea,
  MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import {MbjEmployeeFiCard, MbjEmployeeLineCompetence, MbjEmployeeXpCard} from "@mbj-front-monorepo/data-ui";
import * as React from "react";
interface InformationsEmployeeCtrlProps{
  className?:string;
  idEmpl:number;
  isShort?:boolean
}

const InformationsEmployeeCtrl = (props:InformationsEmployeeCtrlProps)=>{
  const EmployeeQuery = useGetEmployee(props.idEmpl)
  const xpQuery = useGetXp(parseInt(props.idEmpl+""));
  const FisQuery = useGetFI(parseInt(props.idEmpl+""));
  const queryCompetencesEmployee = useGetEmployeesCompetences(props.idEmpl);
  return (
    <div className={`infos_part ${props.className}`}>
      <div className={"wrap_infos_line"}>
        <div className={"Avatar_place"}><img src={AvatarEmp} alt={"avatar_part"}/></div>
        <div className={"wrap_infos"}>
          {EmployeeQuery.isLoading ? (<MbjLoading/>) : EmployeeQuery.isError ? 'Erreur datas' :
            <>
              <div className={"name_place"}>{EmployeeQuery.data.prenom} {EmployeeQuery.data.nom}</div>
              <div className={"Entreprise info_2"}><span>Entreprise Actuelle : </span><span>{EmployeeQuery.data.MemberMaster ? EmployeeQuery.data.MemberMaster.name : 'inconnu'}</span></div>
              <div className={"Poste info_2"}><span>Poste Actuel : </span><span>{EmployeeQuery.data.UserMetier ? EmployeeQuery.data.UserMetier.namePerso : 'inconnu'}</span></div>
            </>
          }
        </div>
      </div>
      {!props.isShort &&
        <>
          <MbjFlex direction={"row"} gap={"24px"}>
            <MbjWidgetClassic title={`Parcours professionnel`} basis={"calc(50% - 12px)"} bodyGrow={1}>
              <MbjFlex direction={"column"} width={"100%"}>
                {xpQuery.isLoading ? (
                  <MbjLoading />
                ) : xpQuery.isError ? (
                  <p>Error...</p>
                ) : (
                  xpQuery.data?.map((xp) => <MbjEmployeeXpCard EmployeeXp={xp} key={xp.id} isReadOnly/>)
                )}
              </MbjFlex>
            </MbjWidgetClassic>
            <MbjWidgetClassic title={`Formations`} basis={"calc(50% - 12px)"}>
              <MbjFlex direction={"column"} gap={"20px"}>
                {FisQuery.isLoading ? (
                  <MbjLoading />
                ) : FisQuery.isError ? (
                  <p>Error...</p>
                ) : (
                  FisQuery.data?.map((fi, idx: number) => <MbjEmployeeFiCard EmployeeFi={fi} key={fi.id} isReadOnly/>)
                )}
              </MbjFlex>
            </MbjWidgetClassic>
          </MbjFlex>
          <MbjWidgetClassic title={"Compétences"}>
            {queryCompetencesEmployee.isLoading ? (
              <MbjLoading />
            ) : queryCompetencesEmployee.isError ? (
              <p>Erreur ...</p>
            ) : (
              <MbjAccordion type="multiple" defaultValue={["item-1"]} className={"accordéon"}>
                {queryCompetencesEmployee.data?.map((CE, idx) => (
                  <MbjAccordionItem value={`item-${idx}`} key={CE?.Competence?.id}>
                    <MbjEmployeeLineCompetence
                      CompetencesEmployee={CE}
                      idEmployee={props.idEmpl || -1}
                      isLoading={queryCompetencesEmployee.isLoading}
                      isError={queryCompetencesEmployee.isError}
                      EmployeeActivites={CE.EmployeeActivites}
                      isEditable={false}
                    />
                  </MbjAccordionItem>
                ))}
              </MbjAccordion>
            )}
          </MbjWidgetClassic>
        </>
      }

    </div>
  )
}

export const InformationsEmployee = styled(InformationsEmployeeCtrl)`
  padding: 0.5rem 1.5rem;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  .info_2{
    span{
      font-size: 16px;
      &:first-child{
        font-weight: bold;
      }
      &:last-child{
        color:${props => props.theme.primary};
      }
    }
  }
  .name_place{
    font-size: 18px;
    color:${props => props.theme.primary};
    font-weight: bold;
  }
  .wrap_infos_line{
    display: flex;
    justify-content: flex-start;
    .Avatar_place{
      width: 160px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
       img{
         width: 130px;

       }
    }
    .wrap_infos{
      flex-grow: 1;
    }
  }
`
