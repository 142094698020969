import * as React from "react";
import styled from "styled-components";
import { MbjAvatar, MbjButton, MbjIconButton, MbjModal, MbjModalCtrlProps, useModal } from "@mbj-front-monorepo/ui";
import { FaRecycle } from "react-icons/fa";
import { getDateTimeFR } from "@mbj-front-monorepo/utils";
import { BsTrashFill } from "react-icons/bs";
import { ConfirmSuppModal } from "../ConfirmSuppModal/ConfirmSuppModal";
import { useNavigate } from "react-router-dom";
import { NouveauMessageModal } from "../../NouveauMessageModal/NouveauMessageModal";
import { components, useUnTrashMessage } from "@mbj-front-monorepo/data-access";

interface MessageDetailModalCtrlProps extends MbjModalCtrlProps {
  className?: string;
  type: "inbox" | "outbox";
  message: components["schemas"]["Messagerie-read.Messagerie_read.Member"];
}

function MessageDetailModalCtrl(props: MessageDetailModalCtrlProps) {
  const navigate = useNavigate();
  const unTrashMutation = useUnTrashMessage();

  const { isShowing: isShowing, toggle: toggle } = useModal();

  function createMarkup() {
    return { __html: props.message.content || "" };
  }
  const logo = props.type === "inbox" ? props.message.MemberFrom?.logo : props.message.MemberTo?.logo;
  const { isShowing: deleteIsShowing, toggle: deleteToggle } = useModal();

  function handleUnTrashClick() {
    unTrashMutation.mutate({ message: props.message, type: props.type }, {});
  }

  const isTrashMessage =
    (props.type === "inbox" && props.message.isTrashTo) || (props.type === "outbox" && props.message.isTrashFrom);

  return (
    <MbjModal
      title={props.type === "inbox" ? "Vous avez reçu" : "Vous avez envoyé"}
      {...props}
      maxWidth={"70%"}
      fitContent
    >
      <div className={"header"}>
        <div className={"subject"}>{props.message.subject}</div>
        <div className={"member"}>
          <MbjAvatar src={`${process.env.REACT_APP_API_URL_ROOT}/logos/members/${logo}`} objectFit={"contain"} />
          <div>
            <div>
              {props.type === "inbox"
                ? "De : " + props.message.MemberFrom?.name
                : "À : " + props.message.MemberTo?.name}
            </div>
            <div>{props.type === "inbox" ? "À : moi" : ""}</div>
          </div>
          <div className={"right"}>
            <div>{props.message.creationAt ? getDateTimeFR(new Date(props.message.creationAt)) : ""}</div>
            {isTrashMessage && (
              <MbjIconButton
                size={"sm"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUnTrashClick();
                  toggle();
                }}
                themeColor={"secondary"}
                isPending={unTrashMutation.isLoading}
                icon={<FaRecycle />}
                ariaLabel={"Restaurer le message"}
              />
            )}
            <MbjIconButton
              size={"sm"}
              onClick={(e) => {
                deleteToggle();
              }}
              themeColor={"warning"}
              icon={<BsTrashFill />}
              ariaLabel={"Supprimer le message"}
            />
            <ConfirmSuppModal
              type={props.type}
              isShowing={deleteIsShowing}
              hide={deleteToggle}
              message={props.message}
            />
          </div>
        </div>
      </div>
      <div className={"main"} dangerouslySetInnerHTML={createMarkup()} />
      {props.type === "inbox" && (
        <>
          <MbjButton onClick={toggle} className={"repButton"}>
            Répondre
          </MbjButton>
          <NouveauMessageModal
            isShowing={isShowing}
            hide={toggle}
            defaultDest={undefined}
            defaultSubject={"RE : " + props.message.subject}
          />
        </>
      )}
    </MbjModal>
  );
}
export const MessageDetailModal = styled(MessageDetailModalCtrl)((props) => ({
  ".header": {
    ".subject": {
      fontSize: "1.5rem",
      fontWeight: "bold",
      paddingLeft: "50px",
    },
    ".member": {
      display: "flex",
      flexDIrection: "row",
      gap: "10px",
      marginTop: "12px",

      ".right": {
        marginLeft: "auto",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
      },
    },
  },
  ".main": {
    border: "1px solid " + props.theme.dark + "25",
    backgroundColor: "white",
    marginTop: "15px",
  },
  ".repButton": { marginTop: "10px", marginLeft: "auto" },
}));
