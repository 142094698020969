import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {
  MbjButton,
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjFlex,
  MbjIconButton,
  MbjListItem,
  MbjLoading,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { useParams } from "react-router-dom";
import {
  useGetMetier,
  useGetMobilites,
  useGetMobTime,
  useGetXp,
  useRefreshMobilites
} from "@mbj-front-monorepo/data-access";
import { getDateTimeFR } from "@mbj-front-monorepo/utils";
import { MbjMobiliteListItem } from "@mbj-front-monorepo/data-ui";
import { FiRefreshCw } from "react-icons/fi";

interface MobilitesByMbjCardCtrlProps extends MbjCardClassicCtrlProps {}

const limit = 0.6;

interface TrancheSalaire{
  id:number;
  libelle:string;
  borneInf:number;
  borneSup:number;
}

const Tranches:TrancheSalaire[]=[
  {id:0, borneInf:0, borneSup:9999999, libelle:'Tous les salaires'},
  {id:1, borneInf:0, borneSup:10000, libelle:'0 - 10K€'},
  {id:2, borneInf:10001, borneSup:40000, libelle:'10K€ - 40K€'},
  {id:3, borneInf:40001, borneSup:80000, libelle:'40K€ - 80K€'},
  {id:4, borneInf:80001, borneSup:9999999, libelle:'80K€ et plus'},
]

const MobilitesByMbjCardCtrl = (props: MobilitesByMbjCardCtrlProps) => {
  const { id } = useParams();
  const XpQuery = useGetXp(parseInt(id||"-1"))
  const [lastIdMetier, setLastIdMetier] = useState<number>(0)
  const MetierQuery = useGetMetier(lastIdMetier === 0 ? undefined : lastIdMetier)
  const refTranche = useRef<HTMLSelectElement>(null)
  const mobTimeQuery = useGetMobTime(id ? parseInt(id) : -1);
  const mobilitesQuery = useGetMobilites(id ? parseInt(id) : -1);
  const [idTranche, setIdTranche] = useState<number>(0)
  const refreshMutation = useRefreshMobilites(1, id ? parseInt(id) : 0);
  useEffect(()=>{
    console.log('iiii')
    if(XpQuery.data){
      const XpSorted = XpQuery.data.sort((a, b) => {
        return (new Date(a.startAt || "0") > (new Date(b.startAt || "0")|| 0) ? 1 : -1);
      })
      console.log(XpSorted);
      const Metier = XpSorted[0].Metier
      setLastIdMetier(Metier ? Metier.id||0 : 0);
    }
  }, [XpQuery.data])
  useEffect(()=>{
    console.log('ici')
    if(MetierQuery.data && MetierQuery.data.salaire){
      Tranches.map(t=>{
        if(t.borneSup > (MetierQuery.data.salaire||0) && t.borneInf < (MetierQuery.data.salaire||0)){
          setIdTranche(t.id)
        }
        return t;
      })
    }
  }, [MetierQuery.data])
  const HandleChangeTranche = ()=>{
    if(refTranche.current){
      setIdTranche(parseInt(refTranche.current.value));
    }
  }
  const mobilitesFiltered = useMemo(() => {
    if (mobilitesQuery.isError || mobilitesQuery.isLoading || !mobilitesQuery.data) {
      return [];
    } else {
      const MyTranche = Tranches.find(t=>t.id === idTranche);
      return mobilitesQuery.data.filter((m) => {
        const CondLimit = m.score && m.score > limit;
        let CondTranche = true;
        if(MyTranche && m.Metier?.salaire){
          CondTranche = (m.Metier?.salaire>=MyTranche.borneInf && m.Metier?.salaire<=MyTranche.borneSup) || m.Metier?.salaire === -1;
        }
        return CondLimit && CondTranche;
      });
    }
  }, [mobilitesQuery.data, mobilitesQuery.isLoading, mobilitesQuery.isError, limit]);

  return (
    <MbjCardClassic title="Mobilités les plus favorables selon My-Better-Job" noPadding {...props}>
      <MbjFlex direction={"column"} height={"100%"} gap={"0px"}>
        {mobTimeQuery.isLoading || mobTimeQuery.isLoading ? (
          <React.Fragment />
        ) : mobTimeQuery.isError ? (
          "Error Time"
        ) : !mobTimeQuery.data[0]?.calculAt ? (
          <div style={{padding:"48px", textAlign:"center"}}>
            <MbjButton size={"md"} onClick={(e) => refreshMutation.mutate()} isPending={refreshMutation.isLoading}>
              Générer le premier calcul
            </MbjButton>
          </div>
        ) : (
          <MbjFlex className={"top"} justify={"space-between"} align={"center"}>
            <span>
              Dernière maj le{" "}
              {mobTimeQuery.data[0]?.calculAt ? getDateTimeFR(new Date(mobTimeQuery.data[0]?.calculAt)) : "--"}
            </span>
            <MbjIconButton
              size={"sm"}
              onClick={(e) => refreshMutation.mutate()}
              isPending={refreshMutation.isLoading}
              icon={<FiRefreshCw />}
              ariaLabel={"Raffraichir les mobilités"}
            />
          </MbjFlex>
        )}
        <div className={`filtre_tranche`}>
          <label>Filtre sur les salaires</label>
          <select ref={refTranche} value={idTranche} onChange={HandleChangeTranche}>
            {Tranches.map((t:TrancheSalaire)=>(
              <option value={t.id} key={`one_tranche${t.id}`}>{t.libelle}</option>
            ))}
          </select>
        </div>
        <MbjScrollArea grow={1}>
          {mobilitesQuery.isLoading ? (
            <MbjLoading />
          ) : mobilitesQuery.isError ? (
            "Errors"
          ) : (
            mobilitesFiltered
              .sort(function (a, b) {
                return (b.score || 0) - (a.score || 0);
              })
              .map((mobilite, idx: number) => (
                <MbjListItem key={mobilite.id} to={`${mobilite.Metier?.id}`}>
                  <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
                </MbjListItem>
              ))
          )}
        </MbjScrollArea>
      </MbjFlex>
    </MbjCardClassic>
  );
};

export const MobilitesByMbjCard = styled(MobilitesByMbjCardCtrl)((props) => ({
  ".top": {
    padding: "5px",
    borderBottom: `1px solid ${props.theme.primaryLighten}`,
  },
  ".filtre_tranche":{
    width:"100%",
    padding:"0.5rem 0.25rem",
    borderBottom: `1px solid ${props.theme.primaryLighten}`,
    "select":{
      marginLeft:"10px",
      height:"38px",
      borderRadius:"8px",
      border:`1px solid ${props.theme.primaryLighten}`,
    }
  }
}));
