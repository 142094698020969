import styled from "styled-components";
import {useChangeDiffusion, useGetMemberAlliances, useGetParticulier} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useState} from "react";
import {MbjButton, MbjLoading} from "@mbj-front-monorepo/ui";

interface DiffusionAllianceCVCtrlProps{
    className?:string;
    idParticulier:number;
    closeMe:()=>void;
}
interface CheckThereProps{
    isChecked:boolean;
    id:number;
    setMe:(n:number)=>void;
    label:string;
}

const CheckBoxThere = ({isChecked,id, setMe, label}:CheckThereProps)=>{
    const [isC, setC] = useState(isChecked);
    useEffect(() => {
        setC(isChecked);
    }, [isChecked]);
    const changing = ()=>{
        setMe(id);
    }
    return (
        <label>
            <input type={"checkbox"} checked={isC} onChange={changing}/>
            <span>{label}</span>
        </label>
    )
}

const DiffusionAllianceCVCtrl = (props:DiffusionAllianceCVCtrlProps)=>{
    const ParticulierQuery = useGetParticulier(props.idParticulier);
    const mutation = useChangeDiffusion(props.idParticulier);
    const alliancesQuery = useGetMemberAlliances();
    const [alliancesSelected, setAlliancesSelected] = useState<number[]>([])
    const alliancesFiltered = useMemo(() => {
        if (!alliancesQuery.data) {
            return [];
        }
        return alliancesQuery.data.filter((alliance) => {
            return alliance.isActive;
        });
    }, [alliancesQuery.data]);
    const tabAlliancePart = useMemo(()=>{
        if(!ParticulierQuery.data || !ParticulierQuery.data.alliances){
            return [];
        } else {
            return ParticulierQuery.data.alliances.map(a=>parseInt(a))
        }
    }, [ParticulierQuery.data])
    useEffect(() => {
        setAlliancesSelected(tabAlliancePart);
    }, [tabAlliancePart]);
    const switchAlliance = (idA:number)=>{
        if(alliancesSelected.indexOf(idA)===-1){
            setAlliancesSelected(tab=>[...tab, idA]);
        } else {
            setAlliancesSelected(tab=>[...tab].filter(i=>i!==idA))
        }
    }
    const submit = ()=>{
        mutation.mutate(alliancesSelected.join('_'), {
            onSuccess:()=>{
                props.closeMe();
            }
        })
    }
    return (
        <div className={`diffusion_alliance ${props.className}`}>
            {ParticulierQuery.isLoading || alliancesQuery.isLoading ? <MbjLoading/> :
                <>
                    <p className={"introduction"}>Cocher les cases des Alliances dans lesquelles vous souhaitez diffuser ce cv</p>
                    {alliancesFiltered.map(item=>(
                        <div className={`one_choice_alliance ${alliancesSelected.indexOf(item.Alliance?.id||0)!==-1 ? "selected" : ""}`} key={`choiceA${item.Alliance?.id||0}`}>
                            <CheckBoxThere isChecked={alliancesSelected.indexOf(item.Alliance?.id||0)!==-1} id={item.Alliance?.id||0} setMe={switchAlliance} label={item.Alliance?.name||''}/>
                        </div>
                    ))}
                    <div className={"sender_wrap"}>
                        <MbjButton size={"md"} themeColor={"primary"} onClick={submit} isPending={mutation.isLoading}>Enregistrer la diffusion</MbjButton>
                    </div>
                </>
            }
        </div>
    )
}

export const DiffusionAllianceCV = styled(DiffusionAllianceCVCtrl)`
  padding: 1% 2%;
  .introduction{
    font-size: 15px;
    margin-bottom: 15px;
  }
  .one_choice_alliance{

    padding: 0.25rem;
    label{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap:15px;
      &:hover{
        cursor: pointer;
      }
    }
    span{
      display: block;
      font-size: 15px;
    }
  }
  .sender_wrap{
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
  }
`
