import React from "react";
import styled from "styled-components";
import { MbjFlex, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import { EffectifCard } from "../Entreprise/Résumé/EffectifCard/EffectifCard";
import { EffectifsCard } from "../Entreprise/Informations/EffectifsCard/EffectifsCard";
import { CompetencesToDevTable } from "./CompetencesToDevTable/CompetenceToDevTable";
import { AlliancesTable } from "../Alliances/MesAlliances/AlliancesTable/AlliancesTable";
import { MetiersCard } from "../Entreprise/Résumé/MetiersCard/MetiersCard";
import { RepartitionMetiersCard } from "../Entreprise/Résumé/RepartitionMetiersCard/RepartitionMetiersCard";
import { CompetencesPrincipalesCard } from "../Entreprise/Résumé/CompetencesPrincipalesCard/CompetencesPrincipalesCard";
import { CompetencesTransmissiblesTable } from "./CompetencesTransmissiblesTable/CompetencesTransmissiblesTable";

interface DashboardCtrlProps {
  className?: string;
}

function DashboardCtrl(props: DashboardCtrlProps) {
  return (
    <div className={"Dashboard " + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Tableau de bord"} bodyDirection={"column"}>
          <EffectifCard />
          <MbjFlex>
            <RepartitionMetiersCard basis={"50%"} />
            <CompetencesPrincipalesCard basis={"47%"} />
          </MbjFlex>
          {/*
                    <MbjWidgetClassic flexBasis={"30%"}>
                        <ExpertiseCard memberQuery={memberQuery} flexBasis={"20%"} />
                    </MbjWidgetClassic>*/}
        </MbjWidgetClassic>
        <MbjFlex>
          <EffectifsCard basis={"40%"} />
          <MbjFlex direction={"column"} basis={"48%"}>
            <MbjWidgetClassic title={"Compétences transmissibles par vos collaborateurs"}>
              <CompetencesTransmissiblesTable />
            </MbjWidgetClassic>
            <MbjWidgetClassic title={"Compétences à développer chez vos collaborateurs"}>
              <CompetencesToDevTable />
            </MbjWidgetClassic>
          </MbjFlex>
        </MbjFlex>
        <MbjFlex>
          <MbjWidgetClassic title={"Métiers de l'entreprise"} basis={"35%"}>
            <MetiersCard to={"../entreprise/metiers"} />
          </MbjWidgetClassic>
          <MbjWidgetClassic
            basis={"62%"}
            title={"Alliances Actives"}
            textInfo={
              "Les alliances actives représentent les alliances dont vous faites parti et dans lesquelles vous souhaitez partager des données."
            }
          >
            <AlliancesTable redirectBaseUrl={"../alliances"} />
          </MbjWidgetClassic>
        </MbjFlex>
      </MbjFlex>
    </div>
  );
}
export const Dashboard = styled(DashboardCtrl)((props) => ({
  overflowY: "auto",
  padding: "1% 2%",
}));
