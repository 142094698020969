import styled from "styled-components";
import { MbjButton, MbjFlex, MbjNavPath, useModal } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ConversationsListe } from "./ConversationsListe/ConversationsListe";
import { FaPlus } from "react-icons/fa";
import { NewConversationModal } from "./NewConversationModal/NewConversationModal";
import { ConversationDetail } from "./ConversationDetail/ConversationDetail";

interface Messagerie2CtrlProps {
  className?: string;
}

const Messagerie2Ctrl = (props: Messagerie2CtrlProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { isShowing: showNewConversation, toggle: toggleNewConversation } = useModal();

  return (
    <div className={props.className}>
      <MbjFlex className={"top-container"} justify={"space-between"}>
        <MbjNavPath routePath={location.pathname} />
        <MbjButton leftIcon={<FaPlus />} onClick={toggleNewConversation}>
          Nouvelle conversation
        </MbjButton>
      </MbjFlex>
      <MbjFlex height={"100%"} wrap={"nowrap"}>
        <ConversationsListe width={"calc(25% - 5px)"} />
        {searchParams.get("id") && <ConversationDetail width={"calc(75% - 5px)"} />}
      </MbjFlex>
      <NewConversationModal isShowing={showNewConversation} hide={toggleNewConversation}></NewConversationModal>
    </div>
  );
};

export const Messagerie2 = styled(Messagerie2Ctrl)`
  padding: 1% 1%;
  .top-container{
    margin-bottom: 8px;
  }
`
