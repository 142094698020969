import { useQuery } from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { fetchApi } from "../../services/fetchApi";

export const useGetTopCompetences = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/competences_top_flops", { method: "get", query: { Member: memberId, sort: "Top" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member", memberId, "topCompetences"], promise, {
    enabled: !!memberId,
  });
};
export const useGetFlopCompetences = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/competences_top_flops", { method: "get", query: { Member: memberId, sort: "Flop" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member", memberId, "flopCompetences"], promise, {
    enabled: !!memberId,
  });
};
