import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { BsFillTriangleFill, BsSearch } from "react-icons/bs";
import { UseQueryResult } from "@tanstack/react-query";
import { MbjListItem } from "../../atoms/MbjListItem/MbjListItem";
import { MbjLoading } from "../../atoms/MbjLoading/MbjLoading";
import { MbjScrollArea } from "../MbjScrollArea/MbjScrollArea";
import {BiChevronUp} from "react-icons/bi";

interface MbjAutocompleteCtrlProps<T> {
  /**
   * Couleur utilisée pour représenter l'ordre d'importance de l'élément sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Doit-il y avoir des marges intérieures ?
   */
  noPadding?: boolean;
  /**
   * Fonction réagissant a un changement
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * Doit-il être désactivé ou non ?
   */
  readOnly?: boolean;
  /**
   * Classe HTML de l'élément ?
   */
  className?: string;
  /**
   * id HTML de l'élément ?
   */
  id?: string;
  /**
   * Placholder
   */
  placeholder?: string;
  /**
   * Valeur par défaut :
   */
  defaultValue?: string;
  /**
   * Requete contenant les données (permettant à l'utilisateur d'autocompléter le résultat)
   */
  suggestionsQuery: UseQueryResult<T[], Error>;
  /**
   * Clée de l'objet à utiliser comme libellé de la liste des propositions
   */
  labelKey: keyof T;
  /**
   * Hooks permettant de sélectionner une option
   */
  autocompleteHooks: {
    selectValue: (selectedValue: any) => void;
    selectedValue: any | undefined;
    clearValue: () => void;
  };
  /**
   * On ferme la liste des propositions après en avoir choisit une ?
   */
  foldAfterChoose?: boolean;
  /**
   * Nom de l'attribut correspondant à l'input dans l'objet data (lors de la soumission du formulaire)
   */
  name: string;

  /**
   * L'input est-il obligatoire ?
   */
  required?: boolean;
  /**
   * L'utilisateur doit entrer obligatoirement une valeur parmi la liste de suggestion.
   */
  strict?: boolean;

  /**
   * ajoute un comportement sur le click d'une suggestion :
   */
  onClickSugg?: () => void;

  register?: any;
  setValue?: any;
  trigger?: any;
}
function MbjAutocompleteCtrl<T>(props: MbjAutocompleteCtrlProps<T>) {
  function byString(o: any, s: any) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    const a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  const [isTyping, setIsTyping] = useState<boolean>(props.autocompleteHooks.selectedValue ? false : true);
  const [input, setInput] = useState(
    props.autocompleteHooks.selectedValue ? byString(props.autocompleteHooks.selectedValue, props.labelKey) : ""
  );
  useEffect(() => {
    if (props.autocompleteHooks.selectedValue) {
      setInput(byString(props.autocompleteHooks.selectedValue, props.labelKey));
      props.setValue?.(props.name, byString(props.autocompleteHooks.selectedValue, props.labelKey));
    } else {
      setInput("");
    }
  }, [props.autocompleteHooks.selectedValue]);

  function strictFunction(input: string) {
    if (!props.suggestionsQuery.data) return false;
    for (let i = 0; i < props.suggestionsQuery.data.length; i++) {
      if (input === (byString(props.suggestionsQuery.data[i], props.labelKey) as unknown as string)) {
        return true;
      }
    }
    return "Veuillez entrer un " + props.name + " valide";
  }

  function handleClickSuggestion(suggestion: T) {
    console.log(byString(suggestion, props.labelKey));
    props.autocompleteHooks.selectValue(suggestion);
    setInput(byString(suggestion, props.labelKey) as unknown as string);
    props.setValue?.(props.name, byString(suggestion, props.labelKey));
    props.trigger?.(props.name);
    setIsTyping(false);

    if (props.onClickSugg) {
      props.onClickSugg();
    }
  }
  function handleInput(e: FormEvent<HTMLInputElement>) {
    console.log("On input");
    const target = e.target as HTMLInputElement;
    setInput(target.value);
    setIsTyping(true);
    props.autocompleteHooks.selectValue(undefined);
  }

  return (
    <div className={props.className}>
      <BsSearch className={"icon-search"} />
      <input
        type="text"
        id={props.id}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        value={input}
        onInput={handleInput}
        list="suggestions"
        {...props.register?.(props.name, {
          required: props.required ? "Ce champ est requis" : false,
          validate: strictFunction,
        })}
        name={props.name}
        autoComplete={"off"}
      />
      {props.suggestionsQuery ? <BiChevronUp className={"icon-triangle"} style={{fontSize:"20px"}} /> : <React.Fragment />}
      {props.suggestionsQuery ? (
        <div className={"suggestions-container"}>
          <MbjScrollArea maxHeight={"150px"}>
            {props.suggestionsQuery.isLoading ? (
              <MbjLoading />
            ) : props.suggestionsQuery.isError ? (
              <p>Erreur ...</p>
            ) : (
              props.suggestionsQuery.data
                .filter((suggestion: T) => {
                  if (!isTyping) {
                    return suggestion;
                  } else if (
                    (byString(suggestion, props.labelKey) as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  ) {
                    return suggestion;
                  }
                  return undefined;
                })
                .map((suggestion: T, key: number) => {
                  return (
                    <MbjListItem
                      key={key}
                      onClick={() => handleClickSuggestion(suggestion)}
                      className={props.autocompleteHooks.selectedValue === suggestion ? "selected" : ""}
                    >
                      {byString(suggestion, props.labelKey)}
                    </MbjListItem>
                  );
                })
            )}
          </MbjScrollArea>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}

/**
 * Composant principal d'interaction utilisateur :
 */
export const MbjAutocomplete = styled(MbjAutocompleteCtrl)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  border:"solid 1px "+props.theme.greyLighten,
  backgroundColor: props.theme.background2,
  padding: "7px 10px",
  //boxShadow: "2px 1px 10px 2px #98989821",
  position: "relative",
  cursor: "text",

  "&:focus-within": {
    border: "2px solid",
    borderColor: props.theme.primaryPastel,

    ".icon-triangle": {
      transform: "rotate(0deg)",
    },

    ".suggestions-container": {
      visibility: "visible",
    },
  },

  ".icon-search": {
    marginRight: "15px",
    fill: props.theme.primary,
  },
  ".icon-triangle": {
    marginLeft: "8px",
    fill: props.theme.primary,
    transform: "rotate(180deg)",
    transition: "all 200ms",
  },
  input: {
    fontSize: "15px",
    border: "none",
    background: "none",
    flexGrow: 1,
  },
  ".suggestions-container": {
    visibility: "hidden",
    position: "absolute",
    top: "105%",
    width: "95%",
    backgroundColor: props.theme.background2,
    borderRadius: "0px 0px 7px 7px",
    boxShadow: "0px 7px 10px 2px #a0a0a0d6",
    transition: "all 200ms",
    zIndex: 2,

    ".suggestions-list": {
      fontSize: "calc(8px + 0.85vmin)",
      maxHeight: "0px",
      transition: "all 200ms",

      ".selected": {
        backgroundColor: props.theme.primary,
        color: "white",
      },
    },

    "&:hover": {
      //visibility: "visible",

      ".suggestions-list": {
        maxHeight: "150px",
      },
    },
  },
}));
MbjAutocomplete.defaultProps = {
  themeColor: "primary",
  noPadding: false,
  foldAfterChoose: false,
  strict: false,
};
