import styled from "styled-components";
import {Operation, useGetBadgesEmployee} from "@mbj-front-monorepo/data-access";
import {ExpertOne} from "./ExpertSuiviOneCompetenceWidget";
import {FaUserCircle} from "react-icons/fa";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface ExpertCardCtrlProps{
  className?:string;
  ExpertOne: ExpertOne;
  niveauActuel:number;
}

const ExpertCardCtrl = (props:ExpertCardCtrlProps)=>{
  const employeeBadgesQuery = useGetBadgesEmployee(props.ExpertOne.employee.id);
  return (
    <div className={`Expert_card ${props.className}`}>
        <div className={`in_card`}>
          <div className={`user_face`}><FaUserCircle/></div>
          <div className={`up_niveau`}>
            <div className={"slider_niveau"}>
              <div className={`slidy_niveau`} style={{width:`${(props.ExpertOne.niveau/5)*100}%`}}/>
            </div>
          </div>
          <div className={`badges`}>
            <h4>Badges :</h4>
            <div className={`in-badges`}>
              {employeeBadgesQuery.isLoading ? (
                <MbjLoading />
              ) : employeeBadgesQuery.isError ? (
                <p>Erreur ...</p>
              ) : (
                <ul>
                {employeeBadgesQuery.data?.map((BE, idx) => (
                  <li className={`badges`} key={`b_${BE.id}`}>{BE.Badge?.libelle}</li>
                ))}
                </ul>
              )}
            </div>
          </div>
          <div className={`name_user`}>{props.ExpertOne.employee.prenom} {props.ExpertOne.employee.nom}</div>
        </div>
    </div>
  )
}

export const ExpertCard = styled(ExpertCardCtrl)`
  padding: 0.5rem;
  width: 25%;
  .badges{
    flex-grow: 1;
    padding: 0.5rem 0;
    li{
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  .slider_niveau{
    width: 100%;
    height: 12px;
    border-radius: 7px;
    position: relative;
    background: white;
    overflow: hidden;
    .slidy_niveau{
      position: absolute;
      left: 0;
      top:0;
      height: 100%;
      transition:width 300ms;
      background: ${props => props.theme.thirdLighten};
    }
  }
  .name_user{
    padding: 0.5rem;
    text-align: center;
    font-weight: bold;
  }
  .in_card{
    height: 100%;
    background: #f5f1f1;
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    .user_face{
      font-size: 38px;
      text-align: center;
    }
  }
`

