import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  MbjAutocomplete,
  MbjButton,
  MbjCardClassic,
  MbjFlex,
  MbjLabel,
  MbjNavPath,
  MbjRadioGroup,
  MbjRadioGroupIndicator,
  MbjRadioGroupItem,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  useAutocomplete,
} from "@mbj-front-monorepo/ui";

import { ResultatRecherche } from "./ResultatRecherche";
import { useForm } from "react-hook-form";
import { useGetMetiers, useGetUserMetiers, usePostRecrutementSaved } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface RechercheCtrlProps {
  className?: string;
}

function RechercheCtrl(props: RechercheCtrlProps) {
  const location = useLocation();
  const metiersQuery = useGetMetiers();
  const userMetiersQuery = useGetUserMetiers();
  const mutation = usePostRecrutementSaved();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refChoice, setRefChoice] = useState("standard");

  const useAutoCompleteHook = useAutocomplete<
    GetElementType<typeof userMetiersQuery.data> | GetElementType<typeof metiersQuery.data>
  >();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data: { metier: string } | any) => {
    if (useAutoCompleteHook.selectedValue) {
      const data =
        refChoice === "perso"
          ? { UserMetier: "/api/user_metiers/" + useAutoCompleteHook.selectedValue.id }
          : { Metier: "/api/metiers/" + useAutoCompleteHook.selectedValue.id };
      mutation.mutate(data);
      setIsSubmitting(true);
      return;
    }
  };

  return (
    <div className={"ProfilsRetenus" + props.className}>
      <MbjNavPath routePath={location.pathname} />
      <MbjFlex direction={"column"}>
        <MbjCardClassic className={"searchCard"} title={"Recherche"}>
          <div className={"searchContent"}>
            <MbjFlex align={"center"}>
              <span>Référentiels utilisés :</span>
              <MbjRadioGroup
                defaultValue="standard"
                aria-label="Choix des référentiels à chercher"
                onValueChange={(value) => {
                  setRefChoice(value);
                  useAutoCompleteHook.clearValue();
                }}
                className={"radioContainer"}
              >
                <MbjFlex wrap={"nowrap"} align={"center"}>
                  <MbjRadioGroupItem value="perso" id="r1">
                    <MbjRadioGroupIndicator />
                  </MbjRadioGroupItem>
                  <MbjLabel htmlFor="r1" themeColor={"dark"} size={"very-small"}>
                    Métiers personnalisés
                  </MbjLabel>
                </MbjFlex>
                <MbjFlex wrap={"nowrap"} align={"center"}>
                  <MbjRadioGroupItem value="standard" id="r2">
                    <MbjRadioGroupIndicator />
                  </MbjRadioGroupItem>
                  <MbjLabel htmlFor="r2" themeColor={"dark"} size={"very-small"}>
                    Métiers standards
                  </MbjLabel>
                </MbjFlex>
              </MbjRadioGroup>
            </MbjFlex>
            <form
              onSubmit={handleSubmit((data) => {
                if (useAutoCompleteHook.selectedValue) {
                  const newData =
                    refChoice === "perso"
                      ? {
                          UserMetier: "/api/user_metiers/" + useAutoCompleteHook.selectedValue.id,
                          pcLimit: data.pcLimit / 100,
                        }
                      : { Metier: "/api/metiers/" + useAutoCompleteHook.selectedValue.id, pcLimit: data.pcLimit / 100 };
                  console.log(newData);
                  mutation.mutate(newData);
                  setIsSubmitting(true);
                  return;
                }
              })}
            >
              <MbjFlex direction={"column"}>
                <MbjSimpleInputContainer errors={errors} direction={"row"} label={"Filtrer la proximité"}>
                  <MbjSimpleInput
                    name={"pcLimit"}
                    register={register}
                    required
                    type={"number"}
                    width={"70px"}
                    defaultValue={70}
                    registerOptions={{
                      min: { value: 0, message: "Le taux de proximité minimal ne peut être négatif" },
                      max: { value: 100, message: "Le taux de proximité maximal ne peut être supérieur à 100" },
                      valueAsNumber: true,
                    }}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors}>
                  <MbjAutocomplete
                    placeholder={
                      refChoice === "perso"
                        ? "Choisissez un métier parmi vos fiches personnalisées"
                        : "Choisissez un métier parmi les fiches de my-better-job"
                    }
                    className={"SearchBar"}
                    suggestionsQuery={refChoice === "perso" ? userMetiersQuery : metiersQuery}
                    autocompleteHooks={useAutoCompleteHook}
                    foldAfterChoose={true}
                    name={"metier"}
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    labelKey={refChoice === "perso" ? ("namePerso" as keyof unknown) : ("libelle" as keyof unknown)}
                    required
                  />
                </MbjSimpleInputContainer>
                <MbjButton isPending={mutation.isLoading} alignSelf={"flex-end"}>
                  Lancer la recherche
                </MbjButton>
              </MbjFlex>
            </form>
          </div>
        </MbjCardClassic>
        {!isSubmitting ? (
          <React.Fragment />
        ) : (
          <ResultatRecherche
            recrutementSavedResult={mutation.data}
            isLoading={mutation.isLoading}
            isError={mutation.isError}
          />
        )}
      </MbjFlex>
    </div>
  );
}
export const Recherche = styled(RechercheCtrl)((props) => ({
  padding: "1% 2%",

  ".searchCard": {
    ".searchContent": {
      ".radioContainer": {
        display: "flex",
        alignItem: "center",
        gap: "5%",
        margin: "10px 0px",
        flexGrow: 1,
      },
    },
  },
}));
