import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface MbjSquareChartCtrlProps {
  className?: string;
  number: number;
  total: number;
  legend: string;
  color?: string;
}

const MbjSquareChartCtrl = (props: MbjSquareChartCtrlProps) => {
  return (
    <div className={`oneRepart ${props.className}`}>
      <div className="legende">
        <span className="nbIn me-2">{props.number}</span>
        <span>{props.legend}</span>
      </div>
      <div className="bloc-rising">
        <div className={"sur-couche"}>
          <div className="bloc-in-rising" style={{width:((props.number/props.total)*100)+"%"}}/>
        </div>
      </div>
    </div>
  );
};
const Progress = () => keyframes`
    from {
        width: 0%;
    }

`;
export const MbjSquareChart = styled(MbjSquareChartCtrl)`
  width: 100%;
  .legende{
    margin-bottom: 4px;
    color: ${props=>props.theme.primary};
    display: flex;
    gap:8px;
    .nbIn{
      font-weight: bold;
    }
  }
  .bloc-rising{
    width: 100%;
    height: 18px;
    position: relative;
    overflow: hidden;
    border: solid transparent 1px;
    background: #f2f1f1;
    border-radius: 6px;
    box-shadow: 1px 1px 2px rgba(0,0,0,.14) inset;
    .sur-couche{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 1px -1px 2px rgba(0,0,0,.14) inset;
    }
    .bloc-in-rising{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      animation: ${Progress} 1.5s ease-out;
      background: ${props=>props.color};
      box-shadow: 1px 0 5px rgba(0,0,0,0.3);
    }
  }
`

// export const MbjSquareChart = styled(MbjSquareChartCtrl)(
//   (props) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'start',
//     '.nbIn': {
//       fontSize: '18px',
//       fontWeight: 'bold',
//       marginRight:"8px"
//     },
//     '.bloc-rising': {
//       height: '120px',
//       border: 'solid 1px #ccc',
//       position: 'relative',
//       borderTopLeftRadius:"6px",
//       borderTopRightRadius:"6px",
//       '.bloc-in-rising': {
//         width: '100%',
//         position: 'absolute',
//         bottom: '0',
//         left: '0',
//         height: (props.number / props.total) * 100 + '%',
//         backgroundColor: props.color,
//       },
//     },
//     '.legende': {
//       width: '100%',
//       padding: '0.25rem',
//       textAlign: 'center',
//
//       '.nbIn': {
//         color: props.color,
//       },
//     },
//   }),
//   css`
//     .bloc-in-rising {
//       animation: ${(props: MbjSquareChartCtrlProps) => Progress} 1.5s ease-out;
//     }
//   `
// );
MbjSquareChart.defaultProps = {
  color: 'green',
};
