import * as React from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { MbjDivider, MbjFlex, MbjHeading, MbjLoading, MbjNavPath, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import { CompareProfilPosteWidget } from "./CompareProfilPosteWidget/CompareProfilPosteWidget";
import { FormationCourteCard } from "../Mobilites/MobiliteDetails/FormationsToWidget/FormationCourteCard/FormationCourteCard";
import { useGetEmployee } from "@mbj-front-monorepo/data-access";
import {ExpertSuiviWidget} from "./ExpertSuivi/ExpertSuiviWidget";
import {ModalSuiviOneCompetence} from "./Components/ModalSuiviOneCompetence";
import {EmployeeAutoFormation} from "./AutoFormation/EmployeeAutoFormation";
import {MetrixCompareSuivi} from "./MetrixCompareSuivi";

interface SuiviCtrlProps {
  className?: string;
}

function SuiviCtrl(props: SuiviCtrlProps) {
  const location = useLocation();
  const { id } = useParams();
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));
  const [nbMissed, setNbMissed] = useState<number>(0);
  const [nbRised, setNbRised] = useState<number>(0);
  const [openD, setOpenD] = useState(false);
  const [actiCurr, setActiCurr] = useState<{id:number, libelle:string, niveau:number}|null>(null)
  const SwitchOpen = ()=>{
    setOpenD(o=>!o);
  }
  const OpenDwith = (item:{id:number, libelle:string, niveau:number})=>{
    setActiCurr(item);
    SwitchOpen();
  }
  useEffect(()=>{
    console.log(openD);
  }, [openD])
  const SetMeNBMissed = (nb: number) => {
    setNbMissed(nb);
  };
  const SetMeNBRised = (nb: number) => {
    setNbRised(nb);
  };
  return (
    <div className={"Suivi " + props.className} >
      {employeeQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <div style={{padding:"0 2%"}}>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom,
            }}
          />
          </div>
          <div className={`wrap_central`} style={{height:"95%", flexGrow:1, overflowY:"auto", padding:"0 2%"}}>
            <MbjFlex direction={"column"}>
              <MbjFlex>
                <MbjHeading withLine={false}>
                  {"Suivi de " + employeeQuery.data.prenom + " " + employeeQuery.data.nom}
                </MbjHeading>
                <MbjDivider orientation={"vertical"} isFlexChild />
                <MbjHeading withLine={false}>{"Poste actuel : " + (employeeQuery.data.UserMetier?.namePerso||"Métier non précisé")}</MbjHeading>
              </MbjFlex>
              {employeeQuery.data.UserMetier ?
                <>
              <MetrixCompareSuivi employee={employeeQuery.data}/>
              <CompareProfilPosteWidget
                employee={employeeQuery.data}
                setNbMissed={SetMeNBMissed}
                setNbRised={SetMeNBRised}
                SwitchOpen={OpenDwith}
              />
              <ExpertSuiviWidget
                employee={employeeQuery.data}
               />
              <EmployeeAutoFormation
                employee={employeeQuery.data}
              />
              {nbMissed > 0 ||
                (nbRised > 0 && (
                  <MbjWidgetClassic title="Formations">
                    <FormationCourteCard
                      employeeId={employeeQuery.data.id || -1}
                      metierId={employeeQuery.data.UserMetier?.id || -1}
                      basis={"48%"}
                    />
                    {
                      //Fonctionnalité à finir :
                      /*<EmployeesExpertsCard metierId={employeeQuery.data.Metier.id} flexBasis={"48%"} />*/
                    }
                  </MbjWidgetClassic>
                ))}
                </>:
                <p style={{fontSize:"18px"}}>Le collaborateur n'a pas de métier précisé, le suivi sera disponible au moment de la sélection d'un métier</p>
              }
            </MbjFlex>
          </div>
          <ModalSuiviOneCompetence
            open={openD}
            closeMe={SwitchOpen}
            activite={actiCurr ? actiCurr : {id:0, libelle:'nc', niveau:0}}
            employee={employeeQuery.data}
          />
        </>
      )}

    </div>
  );
}
export const Suivi = styled(SuiviCtrl)((props) => ({
  padding: "1% 0",
  position:'relative',
  overflow:"hidden",
  height:"99%"
}));
