import styled from "styled-components";
import {BlockPlannif} from "../FormationPlannification";
import {BiBulb} from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import {useDrag} from "react-dnd";
import {ItemType} from "./ItemType";

interface OnePlannifCtrlProps{
  className?:string;
  BlockPlannif:BlockPlannif
}

const OnePlannifCtrl = (props:OnePlannifCtrlProps)=>{

  const [{isDragging}, drag, preview] = useDrag(()=>({
    type:ItemType.FORM,
    item:{PlForm:props.BlockPlannif},
    canDrag:()=>true,
    collect:(monitor)=>({
      isDragging: !!monitor.isDragging()
    })
  }))

  return (
    <div className={`block_plannif ${props.className}`} ref={drag} style={{opacity:isDragging ? 0.5 : 1}}>
      <div className="content_block">
        <div className={`activite_place`}>{props.BlockPlannif.Activite.libelle}</div>
        <div className={`nb_pers_place`}>{props.BlockPlannif.list.length}<FiUsers/></div>
      </div>
    </div>
  )
}
export const OnePlannif = styled(OnePlannifCtrl)`
  padding: 0.2rem;
  &:hover{
    cursor: grab;
  }
  .content_block{
    border-radius: 6px;
    padding: 0.25rem;
    background: ${props => props.theme.third};
    color:white;
    .activite_place{
      font-size: 14px;
      font-weight: bold;
    }
    .nb_pers_place{
      display: flex;
      justify-content: flex-start;
      gap: 4px;
      align-items: center;
    }
  }
`
