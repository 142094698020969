import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useGetMemberMe } from "../member";
import { fetchApi, Operation } from "../../services/fetchApi";
import { components } from "../../openApi-schema";

export const useGetMessagesInbox = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/members/{id}/messagerie_inboxes", { method: "get", params: { id: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["messages_inbox", { memberId }], promise, {
    enabled: !!memberId,
  });
};
export const useGetMessagesOutbox = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/members/{id}/messagerie_outboxes", { method: "get", params: { id: memberId + "" } });
  return useQuery(["messages_outbox", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useSendMessage = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();

  return useMutation(
    (data: Operation<"postMessagerieCollection", "requestBody">) => {
      return fetchApi("/api/messageries", {
        method: "post",
        json: { ...data, MemberFrom: "api/members/" + memberQuery.data?.id },
      });
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(["messages_outbox"]);
        toast.success("Message envoyé !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
export const useDeleteMessage = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { message: components["schemas"]["Messagerie-read.Messagerie_read.Member"]; type: "inbox" | "outbox" }) => {
      const json = {
        isTrashFrom: data.type === "outbox" ? true : undefined,
        isVeryTrashFrom: data.message.isTrashFrom && data.type === "outbox" ? true : undefined,
        isTrashTo: data.type === "inbox" ? true : undefined,
        isVeryTrashTo: data.message.isTrashTo && data.type === "inbox" ? true : undefined,
      };
      return fetchApi("/api/messageries/{id}", { method: "put", params: { id: data.message.id + "" }, json: json });
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(["messages_outbox"]);
        queryClient.invalidateQueries(["messages_inbox"]);
        toast.success("Message supprimé !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
export const useUnTrashMessage = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { message: components["schemas"]["Messagerie-read.Messagerie_read.Member"]; type: "inbox" | "outbox" }) => {
      const json = {
        isTrashFrom: data.type === "outbox" ? false : undefined,
        isVeryTrashFrom: data.message.isTrashFrom && data.type === "outbox" ? false : undefined,
        isTrashTo: data.type === "inbox" ? true : undefined,
        isVeryTrashTo: data.message.isTrashTo && data.type === "inbox" ? true : undefined,
      };
      return fetchApi("/api/messageries/{id}", { method: "put", params: { id: data.message.id + "" }, json: json });
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(["messages_outbox"]);
        queryClient.invalidateQueries(["messages_inbox"]);
        toast.success("Message retiré de la corbeille !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
