import * as React from "react";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjLoading, MbjStatsNumber } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useGetStatsEffectifs } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos sur l'effectif de l'entreprise :
 */
interface EffectifCardCtrlProps extends MbjCardClassicCtrlProps {}

function EffectifCardCtrl(props: EffectifCardCtrlProps) {
  const statsEffectifQuery = useGetStatsEffectifs();

  return (
    <MbjCardClassic {...props} title="Effectif" className={props.className} grow={props.grow}>
      {statsEffectifQuery.isLoading ? (
        <MbjLoading />
      ) : statsEffectifQuery.error ? (
        <p>Erreur ...</p>
      ) : (
        <div className="bigNumbers">
          <MbjStatsNumber
            className={"BigNumberDash"}
            label={"Métiers différents"}
            value={statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].nbMetiers : 0}
            themeColor={"third"}
          />
          <MbjStatsNumber
            label={"Effectif \n Total"}
            value={statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].effectif : 0}
            themeColor={"third"}
            className={"BigNumberDash"}
          />
          <MbjStatsNumber
            className={"BigNumberDash"}
            label={"Salaire moyen"}
            value={statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].salaireAvg : 0}
            themeColor={"third"}
          />
        </div>
      )}
    </MbjCardClassic>
  );
}
export const EffectifCard = styled(EffectifCardCtrl)((props) => ({
  "& .bigNumbers": {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    borderColor: props.theme.lightText,
    paddingBottom: "20px",
    flexWrap: "wrap",
    ".BigNumberDash": {
      flexBasis: "20%",
    },
    "& label": {
      whiteSpace: "pre-line",
      textAlign: "center",
    },
  },
  "& .repartition": {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid",
    borderColor: props.theme.lightText,
  },
  "& .mainCompetences": {
    display: "flex",
    flexDirection: "column",

    button: {
      alignSelf: "end",
      marginTop: "12px",
    },
  },
}));
