import * as React from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import {InternDesktopLayout} from "@mbj-front-monorepo/ui";
import {NotFound} from "../NotFound/NotFound";
import {Effectif} from "./Effectif";
import {FormatCibleDetail} from "./FormatCibleDetail";

interface EffectifRootCtrlProps{}

const EffectifRootCtrl = (props:EffectifRootCtrlProps)=>{
  return (
    <div className="Collaborateurs">
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              /*
                            mbjSectionMenu={
                                <MbjSectionMenu
                                    otherContent={
                                        <React.Fragment>
                                            <MbjButton themeColor="secondary" size="small">
                                                Ajouter à une session de formation
                                            </MbjButton>
                                        </React.Fragment>
                                    }
                                />
                            }
                            */
            />
          }
        >
          <Route path="" element={<Effectif />} />
        </Route>
        <Route path="/:id/*" element={<FormatCibleDetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export const EffectifRoot = styled(EffectifRootCtrl)``
