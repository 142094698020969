import React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { MbjLoading, MbjNavPath } from "@mbj-front-monorepo/ui";
import { EntretienAnnuelNewForm } from "./EntretienAnnuelNewForm";
import { useGetEmployee } from "@mbj-front-monorepo/data-access";

interface EntretienAnnuelNewCtrlProps {
  className?: string;
}

const EntretienAnnuelNewCtrl = ({ className }: EntretienAnnuelNewCtrlProps) => {
  const location = useLocation();
  const { id } = useParams();
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));

  return (
    <div className={`wrapNewEntretien ${className}`}>
      {employeeQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom,
              },
              {
                index: 3,
                label: "Entretien",
              },
              {
                index: 4,
                label: "Nouveau",
              },
            ]}
          />
          {employeeQuery.isLoading ? <MbjLoading/> :
            !employeeQuery.data.UserMetier ?
              <p style={{fontSize:"18px"}}>Le collaborateur n'a pas de métier précisé, l'entretien sera disponible au moment de la sélection d'un métier</p>:
              <EntretienAnnuelNewForm Employee={employeeQuery.data} />
          }
        </>
      )}
    </div>
  );
};

export const EntretienAnnuelNew = styled(EntretienAnnuelNewCtrl)((props) => ({
  padding: "1% 2%",
}));
