import styled from "styled-components";
import {BlockPlannif, OneMonth, Plannif} from "../FormationPlannification";
import {useEffect, useState} from "react";
import {OnePlannif} from "./OnePlannif";
import {useDrop} from "react-dnd";
import {ItemType} from "./ItemType";
import {Operation, useModifyFormationPlanning} from "@mbj-front-monorepo/data-access";
import {OnePlannifRO} from "./OnePlannifRO";


interface OneMonthPlCtrlProps{
  className?:string;
  Month:OneMonth;
  date:Date,
  list:Plannif[]
  setPlannifs:(pl:Plannif[])=>void;
  SaveFormationPlDate:(id:number, date:Date)=>void;
  readonly?:boolean;
}

const OneMonthPlCtrl = (props:OneMonthPlCtrlProps)=>{
  const {mutate} = useModifyFormationPlanning();
  const [myList, setMyList] = useState<BlockPlannif[]>([])
  const [PlannifConcerned, setPlannifConcerned] = useState<Plannif|null>(null)

  useEffect(()=>{
    const Concerned = props.list.find(pl=>pl.date.getMonth() === props.Month.num)
    if(Concerned) {
      setPlannifConcerned(Concerned)
      setMyList(Concerned.list);
    }
  },  [props.list, props.Month])

  const DropMe = (PlForm:BlockPlannif)=>{
    const PlannifConcerned2 = props.list.find(pl=>pl.mois === PlForm.mois)
    if(PlannifConcerned2 && PlannifConcerned){
      const BlocksPlannifConcerned = PlannifConcerned2.list;
      const NewBlocksPlannif = [...BlocksPlannifConcerned.filter(fp=>fp.id !== PlForm.id )]
      const ThisBlockPlannif = PlannifConcerned.list;
      const NewThisBlockPlannif = [...ThisBlockPlannif, {...PlForm, mois:PlannifConcerned.mois}]
      const NewPlannifOld:Plannif = {...PlannifConcerned2, list:NewBlocksPlannif}
      const NewThisPlannif:Plannif = {...PlannifConcerned, list:NewThisBlockPlannif}
      const NewPlannifs = props.list.map(pl=>{
        if(pl.date === NewPlannifOld.date){
          return NewPlannifOld
        } else if(pl.date === NewThisPlannif.date){
          return NewThisPlannif
        } else {
          return pl
        }
      })
      props.setPlannifs(NewPlannifs)
      const MyNewDate = props.date.setHours(12,0,0)
      PlForm.list.map(fp=>{
        const data:Operation<"putFormationPlanningItem", "requestBody">={
          id:fp.id,
          souhaitAt:props.date.toISOString().slice(0,10)
        }
        mutate(data)
      })
    }
  }

  const [{isOver}, drop] = useDrop(()=>({
    accept:[ItemType.FORM],
    canDrop: () =>true,
    drop:(item:{PlForm:BlockPlannif}, monitor)=> {
      if(monitor.didDrop()){
        return
      } else {
        DropMe(item.PlForm)
      }

    },
    collect:(monitor)=>({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({shallow:true}),
      canDrop: monitor.canDrop()
    })
  }), [props.list, PlannifConcerned])
  return (
    <div className={`one_month_pl ${props.className}`}>
      <div className={`content_one_month_pl`}>
        <div className={`lib_month`}>{props.Month.libShort} {props.date.getFullYear()}</div>
        <div className={`wrap_form  ${isOver ? 'dropping' : ''}`} ref={drop}>
          {props.readonly ?
            myList.map((item, idx:number)=>(
                <OnePlannifRO BlockPlannif={item} key={`OBP${idx}`}/>
              )):
            myList.map((item, idx:number)=>(
                <OnePlannif BlockPlannif={item} key={`OBP${idx}`}/>
              ))
          }
        </div>
      </div>
    </div>
  )
}

export const OneMonthPl = styled(OneMonthPlCtrl)`
  width: 25%;
  padding: 3px;
  .content_one_month_pl{
    background: white;
    border-radius: 4px;

    .lib_month{
      padding: 0.25rem;
      text-align: center;
      width: 100%;
      font-weight: bold;
      border-bottom: solid 1px ${props => props.theme.primaryPastel};
    }
    .wrap_form{
      height: 150px;
      overflow-y: auto;
      &.dropping{
        background: ${props=>props.theme.thirdPastel};
      }
    }
  }
`
