import React from "react";
import styled from "styled-components";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjListItem,
  MbjLoading,
  MbjRangeStatic,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEmployeesCompetences } from "@mbj-front-monorepo/data-access";

interface CompetencesCardCtrlProps extends MbjCardClassicCtrlProps {
  /**
   * Classe html de la carte :
   */
  className?: string;
  /**
   * Données à utiliser :
   */
  employeeId: string;
}

export const CompetencesCardCtrl = (props: CompetencesCardCtrlProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryCompetencesEmployee = useGetEmployeesCompetences(id ? parseInt(id) : undefined);
  return (
    <MbjCardClassic className={props.className} title={"Compétences"} noPadding {...props}>
      {queryCompetencesEmployee.isLoading ? (
        <MbjLoading />
      ) : queryCompetencesEmployee.isError ? (
        <p>Erreur</p>
      ) : (
        <MbjScrollArea maxHeight={"250px"}>
          {queryCompetencesEmployee.data?.map((competenceEmploye, idx: number) => (
            <MbjListItem key={competenceEmploye.Competence?.id} className={"lineCompet"}>
              <div className="libelle">{competenceEmploye.Competence?.libelle}</div>
              <MbjRangeStatic nbPoints={5} currentPoint={competenceEmploye.Niveau || 0} themeColor={"third"} />
            </MbjListItem>
          ))}
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
};

export const CompetencesCard = styled(CompetencesCardCtrl)((props) => ({
  ".contentCard": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",

    button: { alignSelf: "self-end" },
  },

  ".lineCompet": {
    fontSize: "15px",
    justifyContent: "space-between",

    libelle: {
      flexBasis: "1 1 175px",
    },
  },
}));
