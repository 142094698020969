import * as React from "react";
import { MbjListItem, MbjRank } from "@mbj-front-monorepo/ui";

import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

/**
 * Carte contenant des infos sur l'effectif de l'entreprise :
 */
interface MainCompetenceItemProps {
  /**
   * Classe html de la carte :
   */
  className?: string;
  /**
   * Numéro de l'item :
   */

  /**
   * données à utiliser :
   */
  competence: GetElementType<Operation<"getCompetencesTopFlopCollection">>;
}

function MainCompetenceItemCtrl(props: MainCompetenceItemProps) {
  return (
    <MbjListItem className={props.className}>
      <span>{props.competence.Activite?.libelle}</span>
      <MbjRank maxRank={5} rank={props.competence.niveau} themeColor={"third"} isStar />
    </MbjListItem>
  );
}
export const MainCompetenceItem = styled(MainCompetenceItemCtrl)((props) => ({
  display: "flex",
  flexDirection: "row",
  color: props.theme.dark,
  justifyContent: "space-between",
  "span":{
    paddingRight:"15px",
  }
}));
