import styled from "styled-components";
import {MbjLoading, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {useParams} from "react-router-dom";
import {
  components,
  useGetResultsSearchParticulierBySearch,
  useGetResultsSearchReclassementBySearch
} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useState} from "react";
import {ResultReclassementCard} from "./ResultReclassementCard";
import {ResultParticulierCard} from "./ResultParticulierCard";

interface ResultParticulierCtrlProps{
  className?:string;
  min:number;
  max:number;
  clickCompare:(result:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"])=>void;
}

const ResultParticulierCtrl = (props:ResultParticulierCtrlProps)=>{
  const {id} = useParams();
  const ResultsQuery = useGetResultsSearchParticulierBySearch(id ? parseInt(id) : 0)
  const listResult = useMemo(()=>{
    if(!ResultsQuery.data){
      return [];
    } else {
      return ResultsQuery.data.filter(r=>{
        const CondMin = r.score && r.score >= props.min;
        const CondMax = r.score && r.score <= props.max;
        return CondMax && CondMin;
      })
    }
  }, [ResultsQuery.data, props.min, props.max])
  return (
    <div className={"result_emp "+props.className}>
      <div className={"wrap_results"}>
        {ResultsQuery.isLoading ? (<MbjLoading/>) : ResultsQuery.isError ? 'Error data' :
          (
            <div className={"wrap_result"}>
              {listResult.map((item)=>(
                <ResultParticulierCard key={`r${item.id}`} Search={item} clickToCompare={props.clickCompare}/>
              ))}
            </div>
          )
        }
      </div>
    </div>
  )
}

export const ResultParticulier = styled(ResultParticulierCtrl)`
  .wrap_result{
    display: flex;
    flex-wrap: wrap;
  }
`
