import styled from "styled-components";

interface OneBlockMetrixSuiviCtrlProps{
  className?:string;
  nombre:number;
  titre:string;
}

const OneBlockMetrixSuiviCtrl = (props:OneBlockMetrixSuiviCtrlProps)=>{
  return (
    <div className={`block_metrix ${props.className}`}>
      <div className={`titre`}>{props.titre}</div>
      <div className={`content`}>
        <div className={`nombre`}>{props.nombre}</div>
      </div>
    </div>
  )
}

export const OneBlockMetrixSuivi = styled(OneBlockMetrixSuiviCtrl)`
  background: white;
  border-radius: 8px;
  flex-grow: 1;
  .titre{
    text-align: center;
    padding: 0.25rem 0.5rem;
  }
  .content{
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .nombre{
    font-size: 32px;
  }

`
