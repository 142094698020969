import styled from "styled-components";
import {useGetBesoinsFormationEntreprise} from "@mbj-front-monorepo/data-access";
import {MbjCardClassic, MbjCardClassicCtrlProps, MbjLoading} from "@mbj-front-monorepo/ui";
import React from "react";

interface BesoinDashCardCtrlProps extends MbjCardClassicCtrlProps {}

const BesoinDashCardCtrl = (props:BesoinDashCardCtrlProps)=>{
  const BesoinsQuery = useGetBesoinsFormationEntreprise();
  return (
    <MbjCardClassic {...props} title="Compétences Nécessitant une formation" className={props.className} grow={props.grow}>
      {BesoinsQuery.isLoading ? (
        <MbjLoading />
      ) : BesoinsQuery.error ? (
        <p>Erreur ...</p>
      ) : (
          <div className={"BigNumber"}>{BesoinsQuery.data.length}</div>
        )
      }
    </MbjCardClassic>
  )
}

export const BesoinDashCard = styled(BesoinDashCardCtrl)`
  .BigNumber{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 38px;
    text-align: center;
    font-weight: bold;
    color: ${props => props.theme.third};
  }
`
