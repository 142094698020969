import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DefaultTheme } from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";

interface EntrepriseInfoPdfProps {
  entrepriseData?: Operation<"meMemberCollection">;
  theme: DefaultTheme;
}
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
// Create styles
const styles = StyleSheet.create({
  entrepriseInfoContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
  },
  title: {
    fontSize: 12,
    fontWeight: 900,
    marginBottom: 7,
    borderBottom: "1px solid grey",
  },
  entrepriseFieldContainer: {
    display: "flex",
    flexDirection: "row",
    width: "35%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  data: {
    fontSize: 10,
    fontWeight: "bold",
    maxLines: 4,
  },
  label: {
    fontSize: 10,
  },
});

// Create Document Component
export const EntrepriseInfoPdf = (props: EntrepriseInfoPdfProps) => {
  return (
    <View style={styles.entrepriseInfoContainer}>
      <Text style={[styles.title, styles.entrepriseFieldContainer]}>Entreprise concernée :</Text>
      {props.entrepriseData?.name && (
        <View style={styles.entrepriseFieldContainer}>
          <Text style={styles.label}>Raison sociale : </Text>
          <Text style={styles.data}>{props.entrepriseData.name}</Text>
        </View>
      )}
      {props.entrepriseData?.name && (
        <View style={styles.entrepriseFieldContainer}>
          <Text style={styles.label}>Siret : </Text>
          <Text style={styles.data}>{props.entrepriseData?.siret}</Text>
        </View>
      )}
      {props.entrepriseData?.email && (
        <View style={styles.entrepriseFieldContainer}>
          <Text style={styles.label}>Email : </Text>
          <Text style={styles.data}>{props.entrepriseData?.email}</Text>
        </View>
      )}
      {props.entrepriseData?.adresse && (
        <View style={styles.entrepriseFieldContainer}>
          <Text style={styles.label}>Adresse : </Text>
          <Text style={styles.data}>
            {props.entrepriseData?.adresse} {props.entrepriseData?.codePostal} {props.entrepriseData?.ville}
          </Text>
        </View>
      )}
    </View>
  );
};
