import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { MbjFlex, MbjLoading, MbjScrollArea } from "@mbj-front-monorepo/ui";
import {CSSProperties, useEffect, useRef, useState} from "react";
import {components, useEventSourceQuery, useGetConversation, useGetMemberMe} from "@mbj-front-monorepo/data-access";
import { Message } from "./Message";
import { SendMessageForm } from "./SendMessageForm";
import { useQueryClient } from "@tanstack/react-query";

interface ConversationDetailCtrlProps {
  className?: string;
  width?: CSSProperties["width"];
}

const ConversationDetailCtrl = (props: ConversationDetailCtrlProps) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("id") as string;
  const conversationsQuery = useGetConversation(roomId);
  const me = useGetMemberMe();
  const el = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<(components["schemas"]["Conversation-conversation.read_read.Member"])[]>([])
  useEffect(()=>{
    if(conversationsQuery.data) {
      setMessages(conversationsQuery.data)
    }
  }, [conversationsQuery.data])
  useEffect(()=>{
    console.log('icicicic');
    if(el.current) {
      const scroll =
        el.current.scrollHeight -
        el.current.clientHeight;
      console.log(scroll);
      el.current.scrollTo(0, scroll);
    }
  }, [conversationsQuery.data])
  useEventSourceQuery(
    new URL(process.env["REACT_APP_MERCURE_URL"] || ""),
    ["test", "https://localhost:8000/api/conversations"],
    {
      eventName: "message",
      callback: (event) => {
        console.log(event.data);
        queryClient.invalidateQueries(["conversation", { roomId: roomId }]);

        return event.data;
      },
    },
    ["last_message"]
  );

  if (!searchParams.get("id")) return <div>Selectionner une conversation</div>;

  if (conversationsQuery.isLoading) return <MbjLoading />;

  if (conversationsQuery.isError) return <div>Erreur</div>;
  const AddMess = (m:components["schemas"]["Conversation-conversation.read_read.Member"])=> {
    setMessages(m0 => [...m0, {...m, created_at:new Date().toISOString(), member:{id:me.data?.id}}])
  }
  return (
    <div className={`converstion_details ${props.className}`}>
      <div className={"wrap_scroll"}  ref={el}>
        {messages.map((conversation, idx:number) => (
          <Message message={conversation} key={`${conversation.id}${idx}`} mine={conversation.member?.id === me.data?.id} />
        ))}
      </div>
      <div className={"wrap_sender"}>
        <SendMessageForm roomId={roomId} AddMess={AddMess}/>
      </div>
    </div>
  );
};

export const ConversationDetail = styled(ConversationDetailCtrl)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .wrap_sender{
    padding:0.5rem;
  }
  .wrap_scroll{
    padding: 1rem 1rem 0 1rem;
    flex-grow: 1;
    height: 0;
    overflow-y: scroll;
  }
   width: ${props => props.width ? props.width : "70%"};
  background: white;
  border-radius: 6px;
`
