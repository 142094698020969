
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import { useGetMemberMe } from "../member";
import { fetchApi, Operation } from "../../services/fetchApi";
import {toast} from "react-toastify";

export const useGetAllSearchByMember = ()=>{
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/searchs", { method: "get", query: { Member: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["search", { memberId }], promise, {
    enabled: !!memberId,
  });
}

export const useGetSearchOne = (id:number)=>{
  const promise = () => fetchApi("/api/searchs/{id}", {method:'get', params:{id:id+""}});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["search_one", { id }], promise, {
    enabled: id!==0,
  });
}

export const useGetAllSearchByUM = (idUM:number|null)=>{
  const promise = () => fetchApi("/api/searchs", { method: "get", query: { UserMetier: (idUM||-1) + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["search_um", { idUM }], promise, {
    enabled: !!idUM,
  });
}

export const usePostSearch = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  return useMutation(
    (data: { Metier: string } | { UserMetier: string }) => {
      return fetchApi("/api/searchs", {
        method: "post",
        json: { ...data, Member: "/api/members/" + memberQuery.data?.id },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["search", { memberId: memberQuery.data?.id }]);
        toast.success("Recherche enregistrée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useTrashSearch = ()=>{
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  return useMutation(
    (id:number)=>{
      return fetchApi("/api/searchs/{id}", {method:"delete", params:{id:id+""}})
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["search", { memberId: memberQuery.data?.id }]);
        toast.success("Recherche supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
}
