import * as React from "react";
import styled from "styled-components";
import {Navigate, Route, Routes} from "react-router-dom";
import {InternDesktopLayout, MbjLink, MbjSectionMenu} from "@mbj-front-monorepo/ui";
import {NotFound} from "../NotFound/NotFound";
import {FormationEntrepriseDash} from "./FormationEntrepriseDash";
import {FormationEntreprise} from "./FormationEntreprise";
import {FormationPlannification} from "./FormationPlannification";
import {FormationPlannificationAlliance} from "./FormationPlannificationAlliance";

interface FormationEntrepriseRootCtrlProps{}

const FormationEntrepriseRootCtrl = (props:FormationEntrepriseRootCtrlProps)=>{
  return (
    <div className="Collaborateurs">
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              mbjSectionMenu={
                <MbjSectionMenu>
                  <MbjLink to="dashboard">Tableau de bord</MbjLink>
                  <MbjLink to="besoin_formation">Besoins en formations</MbjLink>
                  <MbjLink to="plannification_formation">Planification formation</MbjLink>
                  <MbjLink to="plannification_alliance">Planification formation Alliances</MbjLink>
                </MbjSectionMenu>
              }
            />
          }
        >
          <Route path="" element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<FormationEntrepriseDash />} />
          <Route path="/besoin_formation" element={<FormationEntreprise />} />
          <Route path="/plannification_formation" element={<FormationPlannification />} />
          <Route path="/plannification_alliance" element={<FormationPlannificationAlliance />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export const FormationEntrepriseRoot = styled(FormationEntrepriseRootCtrl)``
