import styled from "styled-components";
import {
  components,
  Operation,
  useDeleteFormationPlanning,
  useModifyFormationPlanning
} from "@mbj-front-monorepo/data-access";
import {OneMonthForIn, TabMois} from "../FormationPlannification";
import {useRef, useState} from "react";
import {MbjButton} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface FormModifyNoPlCtrlProps{
  className?:string;
  FormationPl:components["schemas"]["FormationPlanning.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"]
  CloseMe?:()=>void;
}

const FormModifyNoPlCtrl = (props:FormModifyNoPlCtrlProps)=>{
  const mutationDelete = useDeleteFormationPlanning();
  const mutationChange = useModifyFormationPlanning();
  const TabMoisCpnt:OneMonthForIn[] = [];
  const today = new Date();
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  for(let i=0; i<12; i++) {
    const DateDecale = new Date(firstDate.getTime() + 86400000 * i * 32);
    const ThisDate = new Date(DateDecale.getFullYear(), DateDecale.getMonth(), 1);
    const monthThis = DateDecale.getMonth();
    const MyMonth = TabMois.find(m=>m.num === monthThis);
    if(MyMonth){
      TabMoisCpnt.push({
        month:MyMonth,
        date:ThisDate
      })
    }
  }
  const [moisChoix, setMoisChoix] = useState<OneMonthForIn|null>(null)
  const refSelect = useRef<HTMLSelectElement>(null)
  const handleChangeSelect = ()=>{
    if(refSelect.current){
      const myREfy = refSelect.current ? refSelect.current.value : '';
      const my = TabMoisCpnt.find(tm=>tm.date.toISOString() === myREfy)
      if(my) {
        setMoisChoix(my)
      }
    }
  }
  const handleSubmitPl = ()=>{
    if(moisChoix) {
      const MyNewDate = moisChoix.date.setHours(12, 0, 0);
      const data: Operation<"putFormationPlanningItem", "requestBody"> = {
        id: props.FormationPl.id,
        souhaitAt: moisChoix.date.toISOString().slice(0,10)
      }
      mutationChange.mutate(data, {
        onSuccess:()=>{
          if(props.CloseMe){
            props.CloseMe();
          }

        }
      })
    }
  }
  const handleDelete = ()=>{
    mutationDelete.mutate(props.FormationPl.id||0,  {
      onSuccess:()=>{
        if(props.CloseMe){
          props.CloseMe();
        }

      }
    })
  }
  return (
    <div className={`form_modify_pl ${props.className}`}>
      <div className={`wrap_plannif`}>
        <h4>Plannifier la formation</h4>
        <div className={"wrap_select"}>
          <select ref={refSelect} value={moisChoix ? moisChoix.date.toISOString() : ''} onChange={handleChangeSelect}>
            {TabMoisCpnt.map((item, idx)=>(
              <option value={item.date.toISOString()} key={`choice_date_nopl${idx}`}>{item.month.libShort} {item.date.getFullYear()}</option>
            ))}
          </select>
        </div>
        <MbjButton size={"sm"} onClick={handleSubmitPl} isPending={mutationChange.isLoading}>
          Plannifier
        </MbjButton>
      </div>
      <div className={"wrap_delete"}>
        <MbjButton size={"sm"} themeColor={"warning"} onClick={handleDelete} isPending={mutationDelete.isLoading}>
          Supprimer la formation
        </MbjButton>
      </div>
    </div>
  )
}

export const FormModifyNoPl = styled(FormModifyNoPlCtrl)`
  padding: 1rem;
  .wrap_delete{
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
  }
  .wrap_plannif{
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0.5rem 0;
    overflow: visible;
    .wrap_select{
      flex-grow: 1;
      height: 30px;
      select{
        border-radius: 4px;
        height: 100%;
      }
    }
  }
`
