import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DefaultTheme } from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { EntretienAnnuelFormData } from "../../EntretienAnnuelNewForm";

interface BilanCompetencesPdfProps {
  formData?: EntretienAnnuelFormData;
  activitesEntretiens?: Operation<"getEmployeeEntretienActiviteCollection">;
  theme: DefaultTheme;
}

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create Document Component
export const BilanCompetencesPdf = (props: BilanCompetencesPdfProps) => {
  function getActiviteEntretien(id: number): GetElementType<typeof props.activitesEntretiens> | undefined {
    if (!props.activitesEntretiens) {
      console.log("no actvitesEntretiens", props.activitesEntretiens);
      return;
    }
    return props.activitesEntretiens.find(
      (activiteEntretien) => activiteEntretien.EmployeeActivite?.Activite?.id === id
    );
  }

  // Create styles
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      borderWidth: 1,
      borderColor: props.theme.primary,
    },
    headerContainer: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      backgroundColor: props.theme.primaryLighten,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
      fontSize: 10,
      //verticalAlign: "middle",
    },

    libelle: {
      width: "30%",
    },
    niveauAct: {
      width: "7%",
    },
    niveauReq: {
      width: "7%",
    },
    detail: {
      width: "30%",
    },
    apprResp: {
      width: "13%",
    },
    apprSalarie: {
      width: "13%",
    },
    row: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      fontSize: 10,
      fontStyle: "bold",
      textAlign: "center",
    },
    cell: {
      borderRightColor: props.theme.primary,
      borderRightWidth: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  });

  if (!props.formData || !props.formData.Acti || !props.activitesEntretiens) {
    return <React.Fragment />;
  }
  return (
    <View style={styles.tableContainer}>
      <View style={styles.headerContainer}>
        <View style={[styles.cell, styles.libelle]}>
          <Text>Compétence</Text>
        </View>
        <View style={[styles.cell, styles.niveauAct]}>
          <Text>Niveau actuel</Text>
        </View>
        <View style={[styles.cell, styles.niveauReq]}>
          <Text>Niveau requis</Text>
        </View>
        <View style={[styles.cell, styles.detail]}>
          <Text>Commentaire</Text>
        </View>
        <View style={[styles.cell, styles.apprResp]}>
          <Text>Appréciation du responsable</Text>
        </View>
        <View style={[styles.cell, styles.apprSalarie]}>
          <Text>Appréciation du salarié</Text>
        </View>
      </View>
      {props.formData.Acti.map((activite, index) => {
        const activiteEntretien = getActiviteEntretien(parseInt(activite.id));

        if (!activiteEntretien) {
          return <React.Fragment key={activite.id} />;
        }
        //console.log(index, activite, activiteEntretien);
        return (
          <View style={styles.row} key={activite.id} wrap={false}>
            <View style={[styles.cell, styles.libelle]}>
              <Text>{activiteEntretien.EmployeeActivite?.Activite?.libelle}</Text>
            </View>
            <View style={[styles.cell, styles.niveauAct]}>
              <Text>{activiteEntretien.niveauActuel}</Text>
            </View>
            <View style={[styles.cell, styles.niveauReq]}>
              <Text>{activiteEntretien.niveauRequis}</Text>
            </View>
            <View style={[styles.cell, styles.detail]}>
              <Text>{activite.ill}</Text>
            </View>
            <View style={[styles.cell, styles.apprResp]}>
              <Text>{activite.appreciationResp}</Text>
            </View>
            <View style={[styles.cell, styles.apprSalarie]}>
              <Text>{activite.appreciationEmp}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};
