import styled from "styled-components";
import {useLocation, useParams} from "react-router-dom";
import {useGetAllSearchByUM, useGetUserMetier} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjNavPath} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {SearchDid} from "./SearchDid";

interface EffectifRecrutementCtrlProps{
  className?:string;
}

const EffectifRecrutementCtrl = (props:EffectifRecrutementCtrlProps)=>{
  const location = useLocation();
  const { id } = useParams();
  const UserMetierQuery = useGetUserMetier(id ? parseInt(id) : undefined)
  const SearchQuery = useGetAllSearchByUM(id ? parseInt(id) : null)
  return (
    <div className={"RecrEffectif " + props.className}>
      {UserMetierQuery.isLoading || SearchQuery.isLoading ? (
        <MbjLoading />
      ) : UserMetierQuery.isError || SearchQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: UserMetierQuery.data?.namePerso?.toUpperCase(),
            }}
          />
          {SearchQuery.data.length >0 ?
            <SearchDid Search={SearchQuery.data[0]}/>:
            <p>No Search</p>
          }
        </>
      )}
    </div>
  );
}

export const EffectifRecrutement = styled(EffectifRecrutementCtrl)`
  padding: 1% 2%;
`
