import * as React from "react";
import styled from "styled-components";
import { MbjLoading, MbjTable } from "@mbj-front-monorepo/ui";
import { useGetFlopCompetences } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos sur les à développer :
 */
interface CompetencesToDevTableCtrlProps {}

const Titles = [
  { libelle: "Compétences à développer", Align: "isLeft" },
  { libelle: "Effectif à former", Align: "isCenter" },
];

function CompetencesToDevTableCtrl(props: CompetencesToDevTableCtrlProps) {
  const flopCompetencesQuery = useGetFlopCompetences();

  return (
    <MbjTable titles={Titles} scrollable maxHeight={"200px"}>
      {flopCompetencesQuery.isLoading ? (
        <tr>
          <td colSpan={Titles.length}>
            <MbjLoading />
          </td>
        </tr>
      ) : flopCompetencesQuery.isError ? (
        <tr>
          <td colSpan={Titles.length}>
            <p>Erreur...</p>
          </td>
        </tr>
      ) : (
        flopCompetencesQuery.data?.map((competence, idx: number) => (
          <tr key={competence.id}>
            <td>{competence.Activite?.libelle}</td>
            <td className="isCenter">{competence.effectif}</td>
          </tr>
        ))
      )}
    </MbjTable>
  );
}
export const CompetencesToDevTable = styled(CompetencesToDevTableCtrl)((props) => ({}));
