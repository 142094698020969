import * as React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import { NotFound } from "../NotFound/NotFound";
import { MesAlliances } from "./MesAlliances/MesAlliances";
import { ReclassementsAlliances } from "./ReclassementsAlliances/ReclassementsAlliances";
import { FormationsAlliances } from "./FormationsAlliances/FormationsAlliances";
import styled from "styled-components";
import { InternDesktopLayout, MbjLink, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { AllianceDetail } from "./AllianceDetail/AllianceDetail";
import {GestionAlliance} from "./GestionAlliance/GestionAlliance";

interface AlliancesCtrlProps {}

function AlliancesCtrl(props: AlliancesCtrlProps) {
  return (
    <div className="Alliances">
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              mbjSectionMenu={
                <MbjSectionMenu isNavMenu={false}>
                  <MbjLink to="./Dashboard" isNavLink={false}>
                    Tableau de bord
                  </MbjLink>
                  <MbjLink to="./nouvelle_alliance">Créer une nouvelle alliance</MbjLink>
                </MbjSectionMenu>
              }
            />
          }
        >
          <Route path="" element={<Navigate to="./Dashboard" />} />
          <Route path="/Dashboard" element={<GestionAlliance />} />
          {/*<Route path="/reclassements-alliances" element={<ReclassementsAlliances />} />*/}
          {/*<Route path="/formation-alliance" element={<FormationsAlliances />} />*/}
          {/*<Route path="/:idAlliance/*" element={<AllianceDetail />} />*/}
          {/*<Route path="*" element={<NotFound />} />*/}
        </Route>
      </Routes>
    </div>
  );
}
export const Alliances = styled(AlliancesCtrl)((props) => ({}));
