import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  MbjAutocomplete,
  MbjCardClassic,
  MbjFlex,
  MbjLabel,
  MbjLoading,
  MbjNavPath,
  MbjSimpleInputContainer,
  MbjWidgetClassic,
  useAutocomplete,
} from "@mbj-front-monorepo/ui";

import { useForm } from "react-hook-form";

import styled from "styled-components";
import {
  Operation,
  useGetMemberMetierReclassement,
  useGetMemberMetierReclassements,
  useGetMetrixMetier,
} from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface ReclassementStatistiquesCtrlProps {
  className?: string;
}

function ReclassementStatistiquesCtrl(props: ReclassementStatistiquesCtrlProps) {
  const location = useLocation();
  const YearToday = new Date().getFullYear();

  const reclassementQuery = useGetMemberMetierReclassements({
    onSuccess: (data: Operation<"getMemberMetierReclassementCollection">) => {
      setDefaultValue(data?.[0].UserMetier?.namePerso);
      useAutoCompleteHook.selectValue(data[0]);
    },
  });

  const [defaultValue, setDefaultValue] = useState<string | undefined>(
    reclassementQuery.data && reclassementQuery.data.length > 0
      ? reclassementQuery.data[0].UserMetier?.namePerso
        ? reclassementQuery.data[0].UserMetier.namePerso
        : undefined
      : undefined
  );
  const useAutoCompleteHook = useAutocomplete<GetElementType<typeof reclassementQuery.data>>(
    reclassementQuery.data && reclassementQuery.data.length > 0 ? reclassementQuery.data[0] : undefined
  );

  const memberMetierReclassementDetailQuery = useGetMemberMetierReclassement(
    useAutoCompleteHook.selectedValue?.id,
    true
  );
  const metrixMetierQuery = useGetMetrixMetier(useAutoCompleteHook.selectedValue?.UserMetier?.Metier?.id);

  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useForm({
    mode: "onChange",
  });

  return (
    <div className={"NouveauReclassement" + props.className}>
      <MbjNavPath routePath={location.pathname} />
      {!(reclassementQuery.data && reclassementQuery.data.length > 0) ? (
        <p>Vous n'avez pas de reclassement en cours ...</p>
      ) : (
        <MbjFlex direction={"column"}>
          <MbjWidgetClassic title={"Choix du reclassement"}>
            <MbjCardClassic title={"Recherche"}>
              {reclassementQuery.isLoading ? (
                <MbjLoading />
              ) : reclassementQuery.isError ? (
                <p>Erreur...</p>
              ) : (
                <form className="Infos">
                  <MbjSimpleInputContainer errors={errors}>
                    <MbjAutocomplete
                      placeholder={"Choisissez un reclassement"}
                      className={"SearchBar"}
                      suggestionsQuery={reclassementQuery}
                      autocompleteHooks={useAutoCompleteHook}
                      foldAfterChoose={true}
                      name={"reclassement"}
                      register={register}
                      setValue={setValue}
                      trigger={trigger}
                      defaultValue={defaultValue}
                      labelKey={"UserMetier.namePerso" as keyof unknown}
                    />
                  </MbjSimpleInputContainer>
                </form>
              )}
            </MbjCardClassic>
          </MbjWidgetClassic>
          <MbjWidgetClassic title={"Statistiques du reclassement"}>
            <MbjCardClassic title={"Informations"} basis={"10%"}>
              {memberMetierReclassementDetailQuery.isLoading ? (
                <MbjLoading />
              ) : memberMetierReclassementDetailQuery.isError ? (
                <p>Erreur...</p>
              ) : (
                <div className={"InformationsCardContent"}>
                  <div className="Nom">
                    <MbjLabel>Reclassement</MbjLabel>
                    <div className="data">{useAutoCompleteHook.selectedValue?.UserMetier?.namePerso}</div>
                  </div>
                  <div className="Nombre d'apparition">
                    <MbjLabel>Apparition</MbjLabel>
                    <div className="data">
                      Ce reclassement est apparu dans {memberMetierReclassementDetailQuery.data.Matching} recherches !
                    </div>
                  </div>
                </div>
              )}
            </MbjCardClassic>
            <MbjFlex>
              {metrixMetierQuery.isLoading ? (
                <MbjLoading />
              ) : metrixMetierQuery.isError ? (
                <p>Erreur...</p>
              ) : (
                <>
                  <MbjCardClassic title="Salaire (moy. régionale)">
                    <div className="metrixContent">{metrixMetierQuery.data.salaire}€</div>
                  </MbjCardClassic>
                  <MbjCardClassic title="Code Rome">
                    <div className="metrixContent">
                      {useAutoCompleteHook.selectedValue?.UserMetier?.Metier?.codeRome}
                    </div>
                  </MbjCardClassic>
                  <MbjCardClassic title="Champ professionnel">
                    <div className="metrixContent">
                      {useAutoCompleteHook.selectedValue?.UserMetier?.Metier?.champPro}
                    </div>
                  </MbjCardClassic>
                </>
              )}
            </MbjFlex>
          </MbjWidgetClassic>
          <MbjWidgetClassic title={"Situation du marché du travail"}>
            {metrixMetierQuery.isLoading ? (
              <MbjLoading />
            ) : metrixMetierQuery.isError ? (
              <p>Erreur ...</p>
            ) : (
              <>
                <MbjCardClassic title="Equilibre National" basis={"24%"}>
                  <div
                    className="centerCard"
                    style={{
                      color: `${
                        metrixMetierQuery.data.InformationNationale?.chomage === "fort"
                          ? "red"
                          : metrixMetierQuery.data.InformationNationale?.chomage === "moyen"
                          ? "orange"
                          : "green"
                      }`,
                    }}
                  >
                    {!metrixMetierQuery.data || !metrixMetierQuery.data.InformationNationale || metrixMetierQuery.data.InformationNationale.chomage ?
                      'Information non disponible':
                      `Chômage ${metrixMetierQuery.data.InformationNationale?.chomage}`
                    }

                  </div>
                </MbjCardClassic>
                <MbjCardClassic title="Equilibre Local" basis={"24%"}>
                  <div
                    className="centerCard"
                    style={{
                      color: `${
                        metrixMetierQuery.data.InformationLocale?.chomage === "fort"
                          ? "red"
                          : metrixMetierQuery.data.InformationLocale?.chomage === "moyen"
                          ? "orange"
                          : "green"
                      }`,
                    }}
                  >
                    {!metrixMetierQuery.data || !metrixMetierQuery.data.InformationLocale || metrixMetierQuery.data.InformationLocale.chomage ?
                      'Information non disponible':
                      `Chômage ${metrixMetierQuery.data.InformationLocale?.chomage}`
                    }

                  </div>
                </MbjCardClassic>
                <MbjCardClassic title={`Recrutements prévu en ${YearToday}`} basis={"24%"}>
                  <div className="centerCard">{metrixMetierQuery.data.r1}</div>
                </MbjCardClassic>
                <MbjCardClassic title={`Recrutements prévu en ${YearToday + 1}`} basis={"24%"}>
                  <div className="centerCard">{metrixMetierQuery.data.r2}</div>
                </MbjCardClassic>
              </>
            )}
          </MbjWidgetClassic>
        </MbjFlex>
      )}
    </div>
  );
}
export const ReclassementStatistiques = styled(ReclassementStatistiquesCtrl)((props) => ({
  padding: "1% 2%",

  ".Infos": {
    padding: "0.5em 1em",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
