import React from "react";
import { GiPathDistance } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";
import { IoLocationSharp, IoSchoolSharp } from "react-icons/io5";
import { MbjOpenCloseDetails } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface LineFormationCourteCtrlProps {
  FormationCourte: GetElementType<GetElementType<Operation<"getFormationMetierCollection">>["FormationsCourtesMetier"]>;
  className?: string;
}

const LineFormationCourteCtrl = ({ className, FormationCourte }: LineFormationCourteCtrlProps) => {
  return (
    <div className={`LFM ${className}`}>
      <MbjOpenCloseDetails hidePrefixe={true} fontSize={"13px"} text={FormationCourte.libelle || ""}>
        <ul className="list-unstyled">
          {FormationCourte.OrganismeFormationDistants?.map((item, idx: number) => (
            <li key={item.id}>
              <div className="one-OF">
                <div className={"name-of"}>
                  <IoSchoolSharp />
                  <span className={"ms-2"}>{item.rs}</span>
                </div>
                <div className={"infos-of"}>
                  <div className="one-info">
                    <GiPathDistance />
                    <span>{item.distance ? Math.round(item.distance) : ""}km</span>
                  </div>
                  {item.siteWeb !== null && (
                    <div className="one-info">
                      <CgWebsite />
                      <a href={item.siteWeb}>{item.siteWeb}</a>
                    </div>
                  )}
                  <div className="one-info">
                    <IoLocationSharp />
                    <span>{item.adresse ? item.adresse : item.ville}</span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </MbjOpenCloseDetails>
    </div>
  );
};
export const LineFormationCourte = styled(LineFormationCourteCtrl)((props) => ({
  //marginBottom:"10px",
  padding: "0.5rem",
  borderRadius: "0.25rem",

  ".one-OF": {
    marginBottom: "10px",
    marginLeft: "15px",
    fontSize: "14px",
    ".name-of": {
      fontWeight: "bold",
      textDecoration: "underline",
      marginBottom: "5px",
    },
    ".one-info": {
      marginLeft: "10px",
      span: {
        marginLeft: "10px",
      },
    },
  },
}));
