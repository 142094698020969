import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {ItemSaveCard} from "./ItemSaveCard";
import {AvatarPart} from "@mbj-front-monorepo/ui";

interface ProfilRetenuParticulierCtrlProps{
  className?:string;
  ResultPart:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"];
  clickCompare:(type:"Emp"|"Part"|"Recl", result:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>void;
}

const ProfilRetenuParticulierCtrl = (props:ProfilRetenuParticulierCtrlProps)=>{
  const ClickOnIt = ()=>{
    props.clickCompare("Part", props.ResultPart)
  }
  const infos:React.ReactNode =
    <div className={"wrap_infos_base"}>
      <div className={"Avatar_place"}><img src={AvatarPart} alt={"avatar_empl"}/></div>
      <div className={"Name_place"}>{props.ResultPart.Particulier?.prenom} {props.ResultPart.Particulier?.nom}</div>
    </div>
  return (
    <div className={`fiche_emp_retenu ${props.className}`}>
      <ItemSaveCard
        statutLib={props.ResultPart.Statut?.libelle||'nc'}
        satutID={props.ResultPart.Statut?.id||0}
        infos={infos}
        Pc={props.ResultPart.score||0}
        Reaction={ClickOnIt}
      />
    </div>
  )
}

export const ProfilRetenuParticulier = styled(ProfilRetenuParticulierCtrl)`
  .wrap_infos_base{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
  }
  .Avatar_place{
    width: 90px;
    height: 90px;
    img{
      width: 100%;
      height: auto;
    }
  }
  .Name_place{
    font-weight: bold;
  }
`
