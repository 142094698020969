import * as React from "react";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  MbjAutocomplete,
  MbjButton,
  MbjCardClassic,
  MbjFlex,
  MbjNavPath,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjWidgetClassic,
  useAutocomplete,
} from "@mbj-front-monorepo/ui";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useAddMemberMetierReclassement, useGetEmployees, useGetUserMetiers } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface NouveauReclassementCtrlProps {
  className?: string;
}

function NouveauReclassementCtrl(props: NouveauReclassementCtrlProps) {
  const userMetiersQuery = useGetUserMetiers();
  const employeesQuery = useGetEmployees();
  const mutationAdd = useAddMemberMetierReclassement();

  const location = useLocation();
  const useAutoCompleteHook = useAutocomplete<GetElementType<typeof userMetiersQuery.data>>();

  const volume = useMemo(() => {
    return employeesQuery.data?.filter((employee) => employee.UserMetier?.id === useAutoCompleteHook.selectedValue?.id)
      .length;
  }, [useAutoCompleteHook.selectedValue, employeesQuery.data]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      volume: useAutoCompleteHook.selectedValue ? volume : undefined,
    },
  });

  useEffect(() => {
    setValue("volume", volume || 0);
  }, [useAutoCompleteHook.selectedValue]);

  return (
    <div className={"NouveauReclassement" + props.className}>
      <MbjNavPath routePath={location.pathname} />
      <MbjWidgetClassic title={"Créer un reclassement"}>
        <MbjCardClassic title={"Recherche"}>
          <form
            onSubmit={handleSubmit((data) => {
              const myVolume = data.volume ? parseInt(data.volume.toString()) : 0
              console.log('est bien là')
              if (useAutoCompleteHook.selectedValue) {
                mutationAdd.mutate(
                  {
                    UserMetier: "/api/user_metiers/" + useAutoCompleteHook.selectedValue.id,
                    volume: myVolume,
                  },
                  {}
                );

                return;
              }
            })}
            className="Infos"
          >
            <MbjFlex direction={"column"} gap={"10px"} wrap={"nowrap"}>
              <MbjSimpleInputContainer errors={errors}>
                <MbjAutocomplete
                  placeholder={"Choisissez un métier parmi vos fiches"}
                  className={"SearchBar"}
                  suggestionsQuery={userMetiersQuery}
                  autocompleteHooks={useAutoCompleteHook}
                  foldAfterChoose={true}
                  name={"metier"}
                  register={register}
                  setValue={setValue}
                  trigger={trigger}
                  labelKey={"namePerso" as keyof unknown}
                  required
                />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={errors} label={"Nombre d'employé concernés :"} direction={"row"}>
                <MbjSimpleInput
                  name={"volume"}
                  id={"volume"}
                  type={"number"}
                  noPadding
                  register={register}
                  registerOptions={{
                    min: {
                      value: volume || 0,
                      message: "Ne peut pas être inférieur au nombre d'employés exercant ce métier",
                    },
                  }}
                />
              </MbjSimpleInputContainer>
              <MbjButton className={"newButton"} isPending={mutationAdd.isLoading} alignSelf={"flex-end"}>
                Créer un nouveau reclassement
              </MbjButton>
            </MbjFlex>
          </form>
        </MbjCardClassic>
      </MbjWidgetClassic>
    </div>
  );
}
export const NouveauReclassement = styled(NouveauReclassementCtrl)((props) => ({
  padding: "1% 2%",
}));
