import { useState } from "react";
import { Suggestion } from "./Suggestion";

export const useSearchBar = <T = Suggestion>(maxOptions: number, valueKey: string | undefined = "value") => {
  const [options, setOptions] = useState<T[]>([]);
  function addOption(optionToAdd: T) {
    if (options.includes(optionToAdd) || options.length >= maxOptions) {
      return;
    }
    setOptions([...options, optionToAdd]);
  }
  function removeOption(optionToDel: T) {
    const newState = options.filter((option: T) => (option as any)?.[valueKey] !== (optionToDel as any)?.[valueKey]);
    setOptions(newState);
  }
  function clearOptions() {
    setOptions([]);
  }

  return {
    options,
    addOption,
    removeOption,
    clearOptions,
  };
};
