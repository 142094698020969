import React from "react";
import styled from "styled-components";
import { MbjAvatar, MbjLoading } from "@mbj-front-monorepo/ui";
import { useGetEmployee, useGetEmployeesCompetences } from "@mbj-front-monorepo/data-access";

interface MbjCollabProfilCardCtrlProps {
  EmployeeId: number;
  className?: string;
  children?: React.ReactNode;
  scoring?: string;
}

const MbjCollabProfilCardCtrl = ({ EmployeeId, className, children, scoring }: MbjCollabProfilCardCtrlProps) => {
  const { isLoading: loadCompet, isError: errorCompet, data: competences } = useGetEmployeesCompetences(EmployeeId);
  const employeeQuery = useGetEmployee(EmployeeId);

  return (
    <div className={`ProfilCollabCard ${className}`}>
      <div className="card-profil">
        <div className="avatar">
          <MbjAvatar
            size={"xl"}
            name={employeeQuery.data ? `${employeeQuery.data?.prenom} ${employeeQuery.data?.nom}` : undefined}
          />
        </div>
        {employeeQuery.isLoading ? (
          <MbjLoading />
        ) : employeeQuery.isError ? (
          <div>Erreur</div>
        ) : (
          <div className="infos-job">
            <div className={"name-profil"}>
              {employeeQuery.data?.prenom} {employeeQuery.data?.nom}
            </div>
            <div className="line-infos-job">
              <div className="infos-lib">Métier</div>
              <div className="infos-content">{employeeQuery.data?.UserMetier?.namePerso}</div>
            </div>
            <div className="line-infos-job">
              <div className="infos-lib">Entreprise</div>
              <div className="infos-content">{employeeQuery.data?.UserMetier?.Member?.name}</div>
            </div>
          </div>
        )}
        <div className="infos-competence">
          {loadCompet ? (
            <MbjLoading />
          ) : errorCompet ? (
            "impossible de charger les compétences"
          ) : (
            <ul className="listComp">
              {competences?.slice(0, 5).map((competenceEmploye, idx: number) => (
                <li className={"one-comp"} key={competenceEmploye.Competence?.id}>
                  {competenceEmploye.Competence?.libelle}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {scoring && <div className="card-score">{scoring}</div>}
      {children && <div className="card-right">{children}</div>}
    </div>
  );
};

export const MbjCollabProfil = styled(MbjCollabProfilCardCtrl)((props) => ({
  width: "100%",
  background: "white",
  padding: "0.5rem",
  borderRadius: "0.4rem",
  boxShadow: "2px 1px 4px 1px #bbbbbb21",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  gap: "15px",
  fontSize: "14px",
  ".card-right": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "180px",
    button: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: "10px",
      },
    },
  },
  ".card-score": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "30px",
    padding: "0 0.5rem",
  },
  ".card-profil": {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    gap: "10px",
    ".avatar": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      svg: {
        fontSize: "50px",
      },
    },
    ".infos-job": {
      width: "25%",
      ".name-profil": {
        fontWeight: "bold",
        fontSize: "16px",
        color: props.theme.primary,
      },
      ".line-infos-job": {
        ".infos-lib": {
          fontWeight: "bold",
        },
        ".infos-content": {
          marginLeft: "3px",
        },
      },
    },
    ".infos-competence": {
      padding: "0 1.5rem",
      ul: {
        margin: 0,
        padding: 0,
        li: {
          fontSize: "12px",
          listStyle: "none",
          padding: "0.2rem 0.5rem",
          marginBottom: "5px",
          background: props.theme.secondaryPastel,
          borderRadius: "0.25rem",
        },
      },
    },
  },
}));
