import React from "react";
import styled from "styled-components";
import { MbjLoading, useModal } from "@mbj-front-monorepo/ui";
import {
  useDeleteActiviteUserMetiers,
  useGetActivitesUserMetiers,
  useUpdateNiveauActiviteUserMetiers,
} from "@mbj-front-monorepo/data-access";
import { useParams } from "react-router-dom";
import { MbjLineActivite } from "@mbj-front-monorepo/data-ui";

interface ActiviteItemCtrlProps {
  className?: string;
  competenceId: number;
}

function ActiviteItemCtrl(props: ActiviteItemCtrlProps) {
  const { id } = useParams();
  const trashModal = useModal();
  const mutationActivite = useUpdateNiveauActiviteUserMetiers(parseInt(id || "-1"));
  const mutationDeleteActivite = useDeleteActiviteUserMetiers(parseInt(id || "-1"));
  const queryActivitesUserMetiers = useGetActivitesUserMetiers(parseInt(id || "-1"));

  const handleChangeNiveau = (idActivite: number, niveau: number) => {
    mutationActivite.mutate({ idUMActivite: idActivite, niveau });
  };
  const handleDelete = (idActivite: number) => {
    mutationDeleteActivite.mutate(idActivite, {
      onSettled: () => {
        trashModal.toggle();
      },
    });
  };

  return (
    <div className={props.className}>
      {queryActivitesUserMetiers.isLoading ? (
        <MbjLoading />
      ) : queryActivitesUserMetiers.isError ? (
        "Errors"
      ) : (
        queryActivitesUserMetiers.data.map(
          (a, idx) =>
            a.Activite?.Competence?.id === props.competenceId && (
              <MbjLineActivite
                key={a.id}
                isEditable
                niveau={a.niveau || 0}
                libelle={a.Activite.libelle || ""}
                changeLevelFunction={(level) => handleChangeNiveau(a.id || -1, level)}
                trashFunction={() => handleDelete(a.id || -1)}
                modalTrash={trashModal}
                trashLoading={mutationDeleteActivite.isLoading}
              />
            )
        )
      )}
    </div>
  );
}

export const ActiviteItem = styled(ActiviteItemCtrl)((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "10px",
}));
