import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {AvatarEmp} from "@mbj-front-monorepo/ui";

interface ResultEmployeeCardCtrlProps{
  className?:string;
  Search:components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"];
  clickCompare:(result:components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>void;
}

const ResultEmployeeCardCtrl = (props:ResultEmployeeCardCtrlProps)=>{
  const ClickOnMe = ()=>{
    props.clickCompare(props.Search);
  }
  return (
    <div className={`result_emp_card ${props.className}`} onClick={ClickOnMe}>
      <div className={"content_card"}>
        <div className={"wrap_infos_base"}>
          <div className={"Avatar_place"}><img src={AvatarEmp} alt={"avatar_empl"}/></div>
          <div className={"Name_place"}>{props.Search.Employee?.prenom} {props.Search.Employee?.nom}</div>
        </div>
        <div className={`Pc_place ${(props.Search.score||0) > 0.8 ? 'good' : (props.Search.score||0) > 0.7 ? 'medium' : 'bad'}`}>{Math.round((props.Search.score||0)*100)}%</div>
      </div>
    </div>
  )
}

export const ResultEmployeeCard = styled(ResultEmployeeCardCtrl)`
  padding: 1rem;
  width: 33.33%;
  &:hover{
    .content_card{
      box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);
      cursor: pointer;
      transform: translateY(-5px);
      transition: 0.3s;
    }
  }
  .wrap_infos_base{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
  }
  .Pc_place{
    position: absolute;
    right: 5px;
    top: -5px;
    font-size: 40px;
    &.good{
      color: ${props => props.theme.thirdDarken};
    }
    &.medium{
      color: ${props => props.theme.warningPastel};
    }
    &.bad{
      color: ${props => props.theme.warningDarken};
    }
  }
  .content_card{
    background: white;
    padding: 0.5rem;
    border-radius: 8px;
    position: relative;
  }
  .Avatar_place{
    width: 90px;
    height: 90px;
    img{
      width: 100%;
      height: auto;
    }
  }
  .Name_place{
    font-weight: bold;
  }
`
