import * as React from "react";
import styled from "styled-components";
import { MbjWidget, MbjWidgetCtrlProps } from "./MbjWidget";
import { MbjWidgetHeader } from "./MbjWidgetParts/MbjWidgetHeader";
import { MbjHeading } from "../../atoms/MbjHeading/MbjHeading";
import { MbjDivider } from "../../atoms/MbjDivider/MbjDivider";
import { MbjInfo } from "../../atoms/MbjInfo/MbjInfo";
import { MbjWidgetBody } from "./MbjWidgetParts/MbjWidgetBody";

interface MbjWidgetClassicCtrlProps extends MbjWidgetCtrlProps {
  /**
   * Classe HTML de l'élément?
   */
  className?: string;

  title: string;

  textInfo?: string;

  bodyDirection?: React.CSSProperties["flexDirection"];

  bodyGrow?: React.CSSProperties["flexGrow"];

  bodyGap?: React.CSSProperties["gap"];

  bodyNoWrap?: boolean;
}

function MbjWidgetClassicCtrl(props: MbjWidgetClassicCtrlProps) {
  return (
    <MbjWidget className={"Widget classic "+props.className} {...props}>
      <MbjWidgetHeader>
        <MbjHeading themeColor="primary" size={"sm"}>
          {props.title}
        </MbjHeading>
        <MbjDivider isFlexChild />
        {props.textInfo && <MbjInfo>{props.textInfo}</MbjInfo>}
      </MbjWidgetHeader>
      <MbjWidgetBody>{props.children}</MbjWidgetBody>
    </MbjWidget>
  );
}

export const MbjWidgetClassic = styled(MbjWidgetClassicCtrl)((props) => ({
  "& > .MbjWidgetHeader": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",

    "& > h1, h2, h3, h4": {
      flexShrink: 1,
    },
    "& > hr": {
      flexShrink: 3,
    },
  },
  "& > .MbjWidgetBody": {
    display: "flex",
    flexDirection: props.bodyDirection,
    gap: props.bodyGap,
    flexGrow: props.bodyGrow,
    flexWrap: !props.bodyNoWrap ? "wrap" : "nowrap",
  },
}));
MbjWidgetClassic.defaultProps = {
  direction: "column",
  bodyDirection: "row",
  bodyGap: "24px",
};
