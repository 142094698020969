import { fetchApi, Operation } from "../../services/fetchApi";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useGetMemberMe} from "../member";


export const useGetFormationPlanningOne = (idEmp:number, idActivite:number)=>{
  const promise = () => fetchApi("/api/formation_plannings", { method: "get", query: { Employee: idEmp, Activite:idActivite } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_planning", idEmp, idActivite], promise);
}

export const useGetFormationPlanningMember = ()=>{
  const memberQuery = useGetMemberMe();
  const promise = () => fetchApi("/api/formation_plannings", { method: "get", query: { "Employee.MemberMaster": memberQuery.data?.id||0 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_planning_m"], promise, {
    enabled: !!memberQuery.data,
  });
}

export const useGetFormationPlanningAlliances = (ids:number[])=>{
  //const partms = {"Employee.MemberMaster[]":ids[0], "Employee.MemberMaster[]":ids[1]}
  const promise = () => fetchApi("/api/formation_plannings", { method: "get"});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_planning_a"], promise, {
    enabled: ids.length>0,
  });
}

export const useAddFormationPlanning = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postFormationPlanningCollection", "requestBody">) => {
      return fetchApi("/api/formation_plannings", {
        method: "post",
        json: data,
      });
    },
    {
      onSuccess: (newFormationPl) => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        queryClient.setQueryData(["formation_planning", newFormationPl.Employee ? newFormationPl.Employee.id||0 : 0, newFormationPl.Activite ? newFormationPl.Activite.id||0 : 0], newFormationPl);
        toast.success("Formation ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useModifyFormationPlanning = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putFormationPlanningItem", "requestBody">) => {
      return fetchApi("/api/formation_plannings/{id}", {
        method: "put",
        params:{id:data.id+""},
        json: data,
      });
    },
    {
      onSuccess: (newFormationPl) => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        queryClient.setQueryData(["formation_planning_m", newFormationPl.Employee ? newFormationPl.Employee.id||0 : 0, newFormationPl.Activite ? newFormationPl.Activite.id||0 : 0], newFormationPl);
        toast.success("Formation modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const useDeleteFormationPlanning = ()=>{
  const queryClient = useQueryClient();
  return useMutation((id:number
    ) => {
      return fetchApi("/api/formation_plannings/{id}", {
        method: "delete",
        params:{id:id+""}
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        toast.success("Formation supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
