import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";

export const useGetObjectifEntretiens = (employeeId?: number) => {
  const promise = () =>
    fetchApi("/api/objectifs_entretiens", {
      method: "get",
      query: { "EntretienAnnuel.Employee": employeeId + "", "exists[resultat]": false },
    });
  return useQuery(["objectif_entretiens", { employeeId }], promise, {
    enabled: !!employeeId,
  });
};
