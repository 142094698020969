import * as React from "react";
import { useMemo, useState } from "react";
import styled from "styled-components";
import {
  MbjButton,
  MbjLoading,
  MbjNavPath,
  MbjNoResult,
  MbjSearchBar,
  MbjTable,
  useModal,
} from "@mbj-front-monorepo/ui";
import { useLocation } from "react-router-dom";
import { ModalAddService } from "./ModalAddService";
import { useGetMemberServices } from "@mbj-front-monorepo/data-access";
import { ServiceItem } from "./ServiceItem/ServiceItem";
import { GoPlus } from "react-icons/go";

const Titles = [
  { libelle: "Nom du service", Align: "isLeft" },
  { libelle: "Effectif", Align: "isCenter" },
  { libelle: "Date" },
];
interface EntrepriseServicesCtrlProps {
  className?: string;
}

function EntrepriseServiceCtrl(props: EntrepriseServicesCtrlProps) {
  const location = useLocation();
  const [query, setQuery] = useState("");
  const { isShowing, toggle } = useModal();
  const userServicesQuery = useGetMemberServices();
  const ListFiltered = useMemo(() => {
    if (userServicesQuery === undefined || userServicesQuery.data === null || userServicesQuery.data === undefined) {
      return [];
    }
    return userServicesQuery.data?.filter((Service) => {
      if (query === "") {
        return Service;
      } else if (Service.libelle?.toLowerCase().includes(query.toLowerCase())) {
        return Service;
      }
      return undefined;
    });
  }, [query, userServicesQuery]);
  return (
    <div className={"EntrepriseMetiers" + props.className}>
      <div className="top">
        <MbjNavPath routePath={location.pathname} noMargin />
        <MbjButton onClick={toggle} leftIcon={<GoPlus />}>
          Ajouter un service
        </MbjButton>
        <ModalAddService isShowing={isShowing} hide={toggle} />
      </div>
      <MbjSearchBar
        placeholder={"Rechercher un service"}
        className={"SearchBar"}
        onChange={(event) => setQuery(event.target.value)}
      />
      <MbjTable titles={Titles} scrollable>
        {userServicesQuery.isLoading ? (
          <tr>
            <td colSpan={Titles.length}>
              <MbjLoading />
            </td>
          </tr>
        ) : userServicesQuery.isError ? (
          <tr>
            <td colSpan={Titles.length}>
              <p>Error: {userServicesQuery.error.message}</p>
            </td>
          </tr>
        ) : ListFiltered.length > 0 ? (
          ListFiltered?.map((Service, i: number) => <ServiceItem Service={Service} key={Service.id} />)
        ) : (
          <MbjNoResult text={"Aucun services"} />
        )}
      </MbjTable>
    </div>
  );
}

export const EntrepriseService = styled(EntrepriseServiceCtrl)((props) => ({
  ".top": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  ".SearchBar": {
    width: "100%",
    marginBottom: "15px",
  },
  ".col1": {
    flexBasis: "20%",
  },

  padding: "1% 2%",
}));
