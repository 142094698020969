import styled from "styled-components";
import {
  components,
  useDeleteNewNotReclassement,
  usePostNewNotReclassement,
  usePutNewNotReclassement
} from "@mbj-front-monorepo/data-access";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {MbjButton, MbjSimpleInputContainer, MbjSimpleTextArea} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface FormNoteReclassementCtrlProps{
  className?:string;
  Note:components["schemas"]["NotesResultsReclassement-notesresultsrecl.read"]|null;
  idResult:number,
}

const FormNoteReclassementCtrl = (props:FormNoteReclassementCtrlProps)=>{
  const mutationAdd = usePostNewNotReclassement();
  const mutationTrash = useDeleteNewNotReclassement();
  const mutationPut = usePutNewNotReclassement();
  const methods = useForm({
    mode:'onChange', defaultValues:{comment:''}
  })
  useEffect(()=>{
    if(props.Note){
      methods.reset({comment:props.Note.comment || ''})
    }
  }, [props.Note])
  const onSubmit = (data:any)=>{
    console.log(data);
    if(!props.Note) {
      mutationAdd.mutate({
        comment: data.comment,
        ResultsSearch: "/api/results_search_reclassements/" + props.idResult
      }, {
        onSuccess: () => {
          methods.reset();
        }
      })
    } else {
      mutationPut.mutate({id:props.Note && props.Note.id ? props.Note.id : 0, datas:{comment: data.comment}})
    }
  }

  const TrashMe = (e:any)=>{
    e.preventDefault();
    if(props.Note){
      mutationTrash.mutate(props.Note.id||0)
    }
  }

  return (
    <div className={"Form_note_part" + props.className}>
      {props.Note && props.Note.creationAt &&
        <div className={`date_note`} style={{marginBottom:"6px", fontWeight:"bold"}}>{new Date(props.Note.creationAt).toLocaleDateString()}</div>
      }
      <form onSubmit={methods.handleSubmit(onSubmit)}>
      <MbjSimpleInputContainer errors={methods.formState.errors}>
        <MbjSimpleTextArea
          name={"comment"}
          id={"comment"}
          height={"80px"}
          noPadding
          register={methods.register}
          resize={"none"}
          registerOptions={{
            maxLength: {
              value: 750,
              message: "Note trop longue",
            },
          }}
        />
      </MbjSimpleInputContainer>
      <div className={"wrap_sender"}>
        <MbjButton size={"xs"} themeColor={"primary"} onClick={methods.handleSubmit(onSubmit)} disabled={mutationAdd.isLoading || mutationTrash.isLoading} isPending={mutationAdd.isLoading || mutationTrash.isLoading}>
          Enregistrer
        </MbjButton>
        {props.Note &&
          <MbjButton size={"xs"} themeColor={"warning"} onClick={TrashMe} disabled={mutationAdd.isLoading || mutationTrash.isLoading} isPending={mutationAdd.isLoading || mutationTrash.isLoading}>
            Supprimer
          </MbjButton>
        }
      </div>
      </form>
    </div>
  )
}

export const FormNoteReclassement = styled(FormNoteReclassementCtrl)`
  .wrap_sender{
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    gap: 6px;
  }
`
