import styled from "styled-components";
import {Operation, useGetOFAutoFormation, useGetProximiteReferentiel} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {OneBlockMetrixSuivi} from "./Components/OneBlockMetrixSuivi";


interface MetrixCompareSuiviCtrlProps{
  className?:string;
  employee: Operation<"getEmployeeItem">;
}

const MetrixCompareSuiviCtrl = (props:MetrixCompareSuiviCtrlProps)=>{
  if (!props.employee.id) throw new TypeError("employee.id is undefined");
  const useGetMobBilanQuery = useGetProximiteReferentiel("EUM", props.employee.id, props.employee?.UserMetier?.id);
  const OfQuery = useGetOFAutoFormation(props.employee.id)

  return (
    <MbjWidgetClassic title="Bilan Suivi" className={props.className}>
      {useGetMobBilanQuery.isLoading || OfQuery.isLoading ? (
        <MbjLoading />
      ) : useGetMobBilanQuery.isError || OfQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <div className={`line_metrix`}>
            <OneBlockMetrixSuivi titre={"Nombre de compétences acquises"} nombre={useGetMobBilanQuery.data?.Shared?.length||0}/>
            <OneBlockMetrixSuivi titre={"Nombre de compétences à améliorer"} nombre={useGetMobBilanQuery.data?.ToRised?.length||0}/>
            <OneBlockMetrixSuivi titre={"Nombre de compétences à acquérir"} nombre={useGetMobBilanQuery.data?.Missed?.length||0}/>
          </div>
          <div className={`line_metrix`}>
            <OneBlockMetrixSuivi titre={"Nombre Experts internes mobilisables"} nombre={useGetMobBilanQuery.data?.Experts?.length||0}/>
            <OneBlockMetrixSuivi titre={"Nombre Organisme de formation"} nombre={OfQuery.data?.OFActivites?.length||0}/>
          </div>
        </>
      )}
    </MbjWidgetClassic>
  )

}

export const MetrixCompareSuivi = styled(MetrixCompareSuiviCtrl)`
  .line_metrix{
    width: 100%;
    display: flex;
    gap: 24px;
    justify-content: space-between;
  }
`
