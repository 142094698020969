import * as React from "react";
import { getDateTimeFR, useHover } from "@mbj-front-monorepo/utils";
import { MbjIconButton, useModal } from "@mbj-front-monorepo/ui";
import { BsTrashFill } from "react-icons/bs";
import styled from "styled-components";
import { ConfirmSuppModal } from "./ConfirmSuppModal/ConfirmSuppModal";
import { MessageDetailModal } from "./MessageDetailModal/MessageDetailModal";
import { FaRecycle } from "react-icons/fa";
import { components, useUnTrashMessage } from "@mbj-front-monorepo/data-access";

interface MessageItemCardCtrlProps {
  className?: string;
  type: "inbox" | "outbox";
  message: components["schemas"]["Messagerie-read.Messagerie_read.Member"];
}

function MessageItemCardCtrl(props: MessageItemCardCtrlProps) {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const { isShowing: detailIsShowing, toggle: detailToggle } = useModal();
  const { isShowing: deleteIsShowing, toggle: deleteToggle } = useModal();
  const unTrashMutation = useUnTrashMessage();

  const isTrashMessage =
    (props.type === "inbox" && props.message.isTrashTo) || (props.type === "outbox" && props.message.isTrashFrom);

  function handleUnTrashClick() {
    unTrashMutation.mutate({ message: props.message, type: props.type });
  }

  return (
    <>
      <div className={props.className} ref={hoverRef} onClick={detailToggle}>
        <div className={"from"}>
          {props.type === "inbox" ? "De : " + props.message.MemberFrom?.name : "A :" + props.message.MemberTo?.name}
        </div>
        <div className={"subject-content-container"}>
          <div className={"subject"}>{props.message.subject}</div>
          <div className={"message"}>{props.message.content?.replace(/(<([^>]+)>)/gi, "")}</div>
        </div>
        <div className={"right-container"}>
          {!isHovered ? (
            <div className={"date"}>
              {props.message.creationAt ? getDateTimeFR(new Date(props.message.creationAt)) : ""}
            </div>
          ) : (
            <>
              {isTrashMessage && (
                <MbjIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUnTrashClick();
                  }}
                  themeColor={"secondary"}
                  isPending={unTrashMutation.isLoading}
                  icon={<FaRecycle />}
                  ariaLabel={"Restaurer le message"}
                  size={"sm"}
                />
              )}
              <MbjIconButton
                onClick={(e) => {
                  e.stopPropagation();
                  deleteToggle();
                }}
                themeColor={"warning"}
                size={"sm"}
                icon={<BsTrashFill />}
                ariaLabel={"Supprimer le message"}
              />
            </>
          )}
        </div>
      </div>
      <ConfirmSuppModal type={props.type} isShowing={deleteIsShowing} hide={deleteToggle} message={props.message} />
      <MessageDetailModal type={props.type} isShowing={detailIsShowing} hide={detailToggle} message={props.message} />
    </>
  );
}
export const MessageItemCard = styled(MessageItemCardCtrl)((props) => ({
  padding: "0.6em 0.6em",
  borderRadius: "0.4em",
  backgroundColor: props.theme.background2,
  height: "51px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",

  "&:hover, &:focus": {
    boxShadow: "0px 0px 2px 2px inset" + props.theme.primary,
  },

  ".from": {
    fontWeight: 600,
    color: props.theme.primary,
  },
  ".subject-content-container": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    width: "52%",

    ".subject": {
      fontWeight: 600,
      color: props.theme.dark,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      flexShrink: 0,
      maxWidth: "60%",
    },
    ".message": {
      fontSize: "0.8rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  ".right-container": {
    width: "20%",
    display: "flex",
    justifyContent: "end",
    gap: "15px",
  },
}));
