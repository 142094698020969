import styled from "styled-components";
import {MbjFlex, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {BesoinDashCard} from "./Components/BesoinDashCard";
import {StatsPlDashCard} from "./Components/StatsPlDashCard";

interface FormationEntrepriseDashCtrlProps{
  className?:string;
}

const FormationEntrepriseDashCtrl = (props:FormationEntrepriseDashCtrlProps)=>{

  return (
    <div className={`formation_entreprise_dash ${props.className}`}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Tableau de bord"} bodyDirection={"column"}>
            <MbjFlex>
              <BesoinDashCard basis={"calc(50% - 20px)"}/>
              <StatsPlDashCard basis={"calc(50% - 20px)"}/>
            </MbjFlex>
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  )
}

export const FormationEntrepriseDash = styled(FormationEntrepriseDashCtrl)`
  padding: 1% 2%;
`
