import * as React from "react";
import styled from "styled-components";
import {
  MbjSwitch,
  MbjSwitchThumb,
  MbjTooltip,
  MbjTooltipArrow,
  MbjTooltipContent,
  MbjTooltipTrigger,
} from "@mbj-front-monorepo/ui";

import { getDateFR } from "@mbj-front-monorepo/utils";
import {
  Operation,
  useMutateAcceptReclassementEmployee,
  useMutateProposeReclassementEmployee,
} from "@mbj-front-monorepo/data-access";

interface CollaborateurReclasseItemCtrlProps {
  className?: string;

  reclassementEmployee: NonNullable<Operation<"getMemberMetierReclassementCollection">[0]["ReclassementEmployees"]>[0];
}

function CollaborateurReclasseItemCtrl(props: CollaborateurReclasseItemCtrlProps) {
  const mutationPropose = useMutateProposeReclassementEmployee(props.reclassementEmployee.id || -1);
  const mutationAccept = useMutateAcceptReclassementEmployee(props.reclassementEmployee.id || -1);

  return (
    props.reclassementEmployee && (
      <tr>
        <td className="isCenter">
          {props.reclassementEmployee.Employee?.nom?.toUpperCase()} {props.reclassementEmployee.Employee?.prenom}
        </td>
        <td className="isCenter">{props.reclassementEmployee.Employee?.UserMetier?.namePerso}</td>
        <td className="isCenter">
          {props.reclassementEmployee.Employee?.entryAt
            ? getDateFR(new Date(props.reclassementEmployee.Employee?.entryAt))
            : "Non renseignée"}
        </td>
        <td className="isCenter">{props.reclassementEmployee.Employee?.isMobilite ? "✅" : "❌"}</td>
        <td className="isCenter"></td>
        <td className="isCenter">
          <MbjSwitch
            defaultChecked={props.reclassementEmployee.isProposed}
            onCheckedChange={() => mutationPropose.mutate(!props.reclassementEmployee.isProposed)}
          >
            <MbjSwitchThumb />
          </MbjSwitch>
        </td>
        <td className="isCenter">
          <MbjTooltip delayDuration={150}>
            <MbjTooltipTrigger asChild>
              <MbjSwitch
                defaultChecked={props.reclassementEmployee.isAccepted}
                onCheckedChange={() => mutationAccept.mutate(!props.reclassementEmployee.isAccepted)}
              >
                <MbjSwitchThumb />
              </MbjSwitch>
            </MbjTooltipTrigger>

            <MbjTooltipContent sideOffset={5}>
              {props.reclassementEmployee.isAccepted
                ? "Ce profil ne sera plus visible auprès des membres de vos alliances une fois décoché."
                : "Ce profil sera visible auprès des membres de vos alliances une fois coché."}
              <MbjTooltipArrow />
            </MbjTooltipContent>
          </MbjTooltip>
        </td>
      </tr>
    )
  );
}
export const CollaborateurReclasseItem = styled(CollaborateurReclasseItemCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
