import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";

export const useGetAlliance = (allianceId: number) => {
  const promise = () => fetchApi("/api/alliances/{id}", { method: "get", params: { id: allianceId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["alliances", { allianceId }], promise, {
    enabled: !!allianceId,
  });
};

export const useGetAllianceNeedFormation = (allianceId: number) => {
  const promise = () => fetchApi("/api/alliance_need_formations", { method: "get", query: { Alliance: allianceId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["alliances_need_formations", { allianceId }],
    promise,
    {}
  );
};

export const useGetAllianceMembers = (allianceId: number) => {
  const promise = () => fetchApi("/api/alliance_members", { method: "get", query: { Alliance: allianceId + "" } });
  return useQuery(["alliance_members", { allianceId }], promise, {
    enabled: !!allianceId && allianceId!==0,
  });
};
