import styled from "styled-components";
import {useLocation, useParams} from "react-router-dom";
import {useGetParticulier} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjNavPath} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {MbjParticulierInformationsCard} from "@mbj-front-monorepo/data-ui";

interface InformationsCvThequeCtrlProps{
    className?:string;
}

const InformationsCvThequeCtrl= (props:InformationsCvThequeCtrlProps)=>{
    const { id } = useParams();
    const particulierQuery = useGetParticulier(parseInt(id || "-1"));
    const location = useLocation()
    return (
        <div className={"informations_cv_theque " + props.className}>
            <MbjNavPath
                routePath={location.pathname}
                changeLabelTab={[
                    {
                        index: 2,
                        label: particulierQuery.data?.nom?.toUpperCase() + " " + particulierQuery.data?.prenom,
                    },
                    { index: 3, label: "Informations" },
                ]}
            />
            {particulierQuery.isLoading || particulierQuery.isError ? (
                <MbjLoading />
            ) : (
                <MbjParticulierInformationsCard particulierId={particulierQuery.data?.id || -1} />
            )}
        </div>
    )
}

export const InformationsCvTheque = styled(InformationsCvThequeCtrl)`
    padding: 1% 2%;
`

