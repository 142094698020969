import * as React from "react";
import styled from "styled-components";
import {
  MbjAutocomplete,
  MbjButton,
  MbjEmailSimpleInput,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  useAutocomplete,
} from "@mbj-front-monorepo/ui";
import { useForm } from "react-hook-form";
import { useAddEmployee, useGetUserMetiers } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface AjoutCollaborateurModalCtrlProps extends MbjModalCtrlProps {}

function AjoutCollaborateurModalCtrl(props: AjoutCollaborateurModalCtrlProps) {
  const mutation = useAddEmployee();

  const userMetiersQuery = useGetUserMetiers();
  const useAutoCompleteHook = useAutocomplete<GetElementType<typeof userMetiersQuery.data>>();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (useAutoCompleteHook.selectedValue) {
      mutation.mutate(
        {
          ...data,
          UserMetier: "/api/user_metiers/" + useAutoCompleteHook.selectedValue.id,
        },
        {
          onSettled: (data, error, variables, context) => {
            props.hide();
          },
        }
      );
      return;
    }
  };

  return (
    <MbjModal {...props} title="Ajout d'un collaborateur" fitContent>
      <form onSubmit={handleSubmit(onSubmit)} className="Infos">
        <div className={"rowContainer"}>
          <MbjSimpleInputContainer errors={errors} label={"Prénom *"}>
            <MbjSimpleInput
              name={"prenom"}
              id={"prenom"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Prénom requis",
                },
                minLength: {
                  value: 2,
                  message: "Prénom trop court",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={errors} label={"Nom *"}>
            <MbjSimpleInput
              name={"nom"}
              id={"nom"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Nom requis",
                },
                minLength: {
                  value: 2,
                  message: "Nom trop court",
                },
              }}
            />
          </MbjSimpleInputContainer>
        </div>
        <MbjSimpleInputContainer errors={errors} label={"Métier *"}>
          <MbjAutocomplete
            placeholder={"Choisissez un métier parmi vos fiches"}
            suggestionsQuery={userMetiersQuery}
            autocompleteHooks={useAutoCompleteHook}
            foldAfterChoose={true}
            name={"userMetier"}
            id={"userMetier"}
            register={register}
            setValue={setValue}
            trigger={trigger}
            labelKey={"namePerso" as keyof unknown}
            required
          />
        </MbjSimpleInputContainer>
        <div className={"rowContainer"}>
          <MbjSimpleInputContainer errors={errors} label={"Email *"}>
            <MbjEmailSimpleInput
              name={"email"}
              id={"email"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Email requis",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={errors} label={"Adresse"}>
            <MbjSimpleInput name={"adresse"} id={"adresse"} type={"text"} noPadding register={register} />
          </MbjSimpleInputContainer>
        </div>
        <div className={"rowContainer"}>
          <MbjSimpleInputContainer errors={errors} label={"Code postal"}>
            <MbjSimpleInput name={"codePostal"} id={"codePostal"} type={"text"} noPadding register={register} />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={errors} label={"Ville"}>
            <MbjSimpleInput name={"ville"} id={"ville"} type={"text"} noPadding register={register} />
          </MbjSimpleInputContainer>
        </div>
        <span>Les champs notés * sont obligatoires</span>
        <MbjButton isPending={mutation.isLoading} alignSelf={"flex-end"}>
          Ajouter le collaborateur
        </MbjButton>
      </form>
    </MbjModal>
  );
}
export const AjoutCollaborateurModal = styled(AjoutCollaborateurModalCtrl)((props) => ({
  form: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    ".rowContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "start",
      gap: "20px",
    },
  },
}));
