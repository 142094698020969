import styled from "styled-components";
import * as React from "react";
import {
  useGetFiPart, useGetMemberMe,
  useGetParticulier,
  useGetParticulierCompetences,
  useGetXpPart
} from "@mbj-front-monorepo/data-access";
import {
  AvatarEmp, MbjButton,
  MbjCardClassic,
  MbjFlex,
  MbjListItem,
  MbjLoading, MbjModal2, MbjNavPath,
  MbjRangeStatic,
  MbjScrollArea, MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import {useLocation, useParams} from "react-router-dom";
import {MbjEmployeeFiCard, MbjEmployeeXpCard} from "@mbj-front-monorepo/data-ui";
import {useEffect, useState} from "react";
import {DiffusionAllianceCV} from "../DiffusionAlliance/DiffusionAllianceCV";


interface DashboardOneCvThequeCtrlProps{
  className?:string;
}

const DashboardOneCvThequeCtrl = (props:DashboardOneCvThequeCtrlProps)=>{
  const { id } = useParams();
  const particulierQuery = useGetParticulier(parseInt(id || "-1"));
  const CompetQuery = useGetParticulierCompetences(parseInt(id || "-1"))
  const XpQuery = useGetXpPart(parseInt(id || "-1"));
  const FisQuery = useGetFiPart(parseInt(id || "-1"));
  const location = useLocation();
  const [isMine, setIsMine] = useState(false);
  const memberQuery = useGetMemberMe();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if(memberQuery.data && particulierQuery.data){
      console.log(particulierQuery.data.MemberId === memberQuery.data.id);
      setIsMine(particulierQuery.data.MemberId === memberQuery.data.id)
    }
  }, [memberQuery.data, particulierQuery.data]);
  return (
    <div className={`dash_cv_theque ${props.className}`}>
      <MbjNavPath
          routePath={location.pathname}
          changeLabelTab={[
            {
              index: 2,
              label: particulierQuery.data?.nom?.toUpperCase() + " " + particulierQuery.data?.prenom,
            },
            { index: 3, label: "Tableau de bord" },
          ]}
      />
      <MbjFlex>
        <MbjCardClassic title={`Informations`} basis={"30%"}>
          {particulierQuery.isLoading ? <MbjLoading/> :
            <div className={"wrap_infos"}>
              <div className={"Avatar_place"}><img src={AvatarEmp} alt={"avatar_empl"}/></div>
              <div className={"infos_ind"}>
                <div className={`one_info`}>
                  {particulierQuery.data?.prenom} {particulierQuery.data?.nom}
                </div>
                <div className={`one_info`}>
                  {particulierQuery.data?.adresse} {particulierQuery.data?.codepostal} {particulierQuery.data?.ville}
                </div>
                {isMine &&
                <div className={"Infos_alliances"}>
                    <MbjButton size={"sm"} themeColor={"primary"} onClick={()=>setOpen(o=>!o)}>Gérer la diffusion alliance</MbjButton>
                </div>
                }
              </div>

            </div>
          }
        </MbjCardClassic>
        <MbjCardClassic title={`Principales compétences`} basis={"60%"}>
          {CompetQuery.isLoading ? (
            <MbjLoading />
          ) : CompetQuery.isError ? (
            <p>Erreur</p>
          ) : (
            <MbjScrollArea maxHeight={"250px"}>
              {CompetQuery.data?.map((compet, idx: number) => (
                <MbjListItem key={compet.Competence?.id} className={"lineCompet"}>
                  <div className="libelle">{compet.Competence?.libelle}</div>
                  <MbjRangeStatic nbPoints={5} currentPoint={compet.Niveau || 0} themeColor={"third"} />
                </MbjListItem>
              ))}
            </MbjScrollArea>
          )}
        </MbjCardClassic>
      </MbjFlex>
      <MbjFlex direction={"row"} gap={"24px"}>
        <MbjWidgetClassic title={`Parcours professionnel`} basis={"calc(50% - 12px)"} bodyGrow={1}>
          <MbjFlex direction={"column"} width={"100%"}>
            {XpQuery.isLoading ? (
              <MbjLoading />
            ) : XpQuery.isError ? (
              <p>Error...</p>
            ) : (
              XpQuery.data?.map((xp) => <MbjEmployeeXpCard EmployeeXp={xp} key={xp.id} isReadOnly/>)
            )}
          </MbjFlex>
        </MbjWidgetClassic>
        <MbjWidgetClassic title={`Formations`} basis={"calc(50% - 12px)"}>
          <MbjFlex direction={"column"} gap={"20px"}>
            {FisQuery.isLoading ? (
              <MbjLoading />
            ) : FisQuery.isError ? (
              <p>Error...</p>
            ) : (
              FisQuery.data?.map((fi) => <MbjEmployeeFiCard EmployeeFi={fi} key={fi.id} isReadOnly/>)
            )}
          </MbjFlex>
        </MbjWidgetClassic>
      </MbjFlex>
      <MbjModal2 open={open} closeMe={()=>setOpen(false)} titre={"Gestion Diffusion alliance"} Wd={"30%"}>
        <DiffusionAllianceCV idParticulier={id ? parseInt(id) : 0} closeMe={()=>setOpen(false)}/>
      </MbjModal2>
    </div>
  )
}

export const DashboardOneCvTheque = styled(DashboardOneCvThequeCtrl)`
  padding: 1% 2%;
  .wrap_line{
    display: flex;
    flex-wrap: wrap;
  }
  .Infos_alliances{
    margin: 8px 0;
  }
  .one_info{
    font-size: 16px;
    font-weight: bold;
  }
  .wrap_infos{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .Avatar_place{
    width: 150px;
    img{
      width: 120px;
    }
  }
  .contentCard{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .lineCompet{
  font-size: 15px;
  justify-content: space-between;
},
`
