import styled from "styled-components";
import {MbjLoading, MbjNavPath, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useGetParticulier, useGetProxiCvThequeUseMetier} from "@mbj-front-monorepo/data-access";
import * as React from "react";
import {ProximitePartUserMetierCard} from "../Compatibilite/ProximitePartUserMetierCard";


interface CompatibiliteOneCvThequeCtrlProps{
  className?:string;
}

const CompatibiliteOneCvThequeCtrl = (props:CompatibiliteOneCvThequeCtrlProps)=>{
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const particulierQuery = useGetParticulier(parseInt(id || "-1"));
  const ProxiQuery = useGetProxiCvThequeUseMetier(id ? parseInt(id) : -1)
  const GoMe = (id:number)=>{
    navigate('../comparateur/'+id)
  }
  return (
    <div className={`dash_cv_theque ${props.className}`}>
      {particulierQuery.isLoading ? (
        <MbjLoading />
        ) : particulierQuery.isError ? (
        <p>Erreur ...</p>
        ) : (
        <MbjNavPath routePath={location.pathname}
                    changeLabel={{
                      index: 2,
                      label: particulierQuery.data?.nom?.toUpperCase() + " " + particulierQuery.data?.prenom,
                    }}
        />
        )
      }
        <MbjWidgetClassic title={"Compatibilité avec les métiers de l'entreprise"} basis={"100%"} className={`bl ${props.className}`}>
          {ProxiQuery.isLoading ? (
            <MbjLoading />
          ) : ProxiQuery.isError ? (
            <p>Erreur ...</p>
          ) : (
            <div className={`wrap_results`}>
              {ProxiQuery.data.sort((a, b)=>(a.score||0)> (b.score||0) ? -1 : 1).map(p=>(
                <ProximitePartUserMetierCard key={`Proxy${p.id}`} Proximite={p} ClickOnIt={GoMe}/>
              ))}
            </div>
          )}
        </MbjWidgetClassic>

    </div>
  )
}

export const CompatibiliteOneCvTheque = styled(CompatibiliteOneCvThequeCtrl)`
  padding: 1% 2%;
  .wrap_results{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`
