import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";

export const useGetFormationEmployees = (employeeId: number, year?: number) => {
  const stopDate = `${year}-12-31`;
  const startDate = `${year}-01-01`;

  const promise = () =>
    fetchApi("/api/formation_employees", {
      method: "get",
      query: {
        Employee: employeeId + "",
        "formationAt[before]": year ? stopDate : undefined,
        "formationAt[after]": year ? startDate : undefined,
      },
    });

  return useQuery(["formation_employee", { employeeId, year }], promise, {
    enabled: !!employeeId,
  });
};
