import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { MbjCardClassic, MbjFlex, MbjLoading, MbjNavPath, MbjNoResult } from "@mbj-front-monorepo/ui";
import { CompetencesCard } from "./CompetencesCard";
import { BadgesCard } from "./BadgesCard";
import styled from "styled-components";
import { useGetEmployee } from "@mbj-front-monorepo/data-access";
import { MbjEmployeeInformationsCard } from "@mbj-front-monorepo/data-ui";

interface DashboardCtrlProps {
  className?: string;
}

function DashboardCtrl(props: DashboardCtrlProps) {
  const location = useLocation();
  const { id } = useParams();
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));

  return (
    <div className={"Informations " + props.className}>
      {employeeQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom,
            }}
          />
          <MbjFlex gap={"24px"}>
            <MbjEmployeeInformationsCard
              title="Informations"
              employeeId={id || "-1"}
              flex={"1 1 25%"}
              to="../informations"
            />
            <MbjFlex direction="column" flex={"1 1 70%"}>
              <CompetencesCard employeeId={id || "-1"} to={"../competences"} />
              <MbjFlex>
                <BadgesCard title="Badges" flex={"1 1 45%"} />
                <MbjFlex basis="45%">
                  <MbjCardClassic title="Formations">
                    <div className={`wrap_no_form`}>
                      <MbjNoResult text={"Aucune formation pour le moment"} />
                    </div>
                  </MbjCardClassic>
                </MbjFlex>
              </MbjFlex>
            </MbjFlex>
          </MbjFlex>
        </>
      )}
    </div>
  );
}

export const Dashboard = styled(DashboardCtrl)((props) => ({
  padding: "1% 2%",
  ".wrap_no_form":{
    display:"flex",
    height:"100%",
  }
}));
