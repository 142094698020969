import {fetchApi, Operation} from "@mbj-front-monorepo/data-access";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";


export const useGetResultsSearchParticulierBySearch = (idSearch:number|null)=>{
  const promise = () => fetchApi("/api/results_search_particuliers", { method: "get", query: { RecrutementSaved: (idSearch||-1) + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["result_search_part", { idSearch }], promise, {
    enabled: !!idSearch,
  });
}


export const useModifyStatutSearchPart = (idResult:number)=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putResultsSearchParticulierItem", "requestBody">) =>
      fetchApi("/api/results_search_particuliers/{id}", { method: "put", params: { id: idResult + "" }, json: data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["result_search_part"]);
        toast.success("Resultat modifié !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
