import * as React from "react";
import styled from "styled-components";

export interface MbjCardBodyCtrlProps {
  /**
   * Classe HTML de la card?
   */
  className?: string;
  /**
   * Contenu de la card ?
   */
  children?: React.ReactNode;

  noPadding?: boolean;
  grow?:number;
}

function MbjCardBodyCtrl(props: MbjCardBodyCtrlProps) {
  return <div className={`MbjCardBody ${props.className}`}>{props.children}</div>;
}
/**
 * Composant layout card :
 */
export const MbjCardBody = styled(MbjCardBodyCtrl)<MbjCardBodyCtrlProps>((props) => ({
  padding: props.noPadding ? "0 !important" : "initial",
  flexGrow:props.grow,
}));
MbjCardBody.defaultProps = {
  noPadding: false,
  grow:1,
};
