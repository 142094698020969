import styled from "styled-components";
import {MbjFlex, MbjLoading, MbjModal, MbjNavPath, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {useLocation, useParams} from "react-router-dom";
import {
  useGetEmployee,
  useGetEntretienAnnuels,
  useGetMobilitesInternes,
  useGetUserMetiers
} from "@mbj-front-monorepo/data-access";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import {useMemo, useState} from "react";

interface MobilitesInterneCtrlProps{
  className?:string;
}
interface ForProxi{
  idCompareA:number;
  idCompareB:number;
}

interface LineForEntretien{
  name:string;
  score:number;
  id:number;
  userMetier:{id:number, libelle:string}
}

const MobilitesInterneCtrl = ({className}:MobilitesInterneCtrlProps)=>{
  const location = useLocation();
  const { id } = useParams();
  const EntretiensAnnuelsQuery = useGetEntretienAnnuels(id ? parseInt(id) : undefined)
  const MobilitesQuery = useGetMobilitesInternes(id ? parseInt(id) : undefined);
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));
  const { isShowing, toggle } = useModal();
  const [forP, setForP] = useState<ForProxi|null>(null);
  const listVsEntretien=useMemo(()=>{
    const list:LineForEntretien[] = [];
    if(EntretiensAnnuelsQuery.data && MobilitesQuery.data){
      const idsAn:number[] = [];
      EntretiensAnnuelsQuery.data.map(ea=>{
        const AnThis = ea.EvoAnInterne;
        const Trithis = ea.EvoTriInterne;
        if(AnThis){
          const Tab = AnThis.split('/');
          const Last = Tab[3];
          if(idsAn.indexOf(parseInt(Last))===-1) idsAn.push(parseInt(Last));
        }
        if(Trithis){
          const Tabtri = Trithis.split('/');
          const Lasttri = Tabtri[3];
          if(idsAn.indexOf(parseInt(Lasttri))===-1) idsAn.push(parseInt(Lasttri));
        }
        return ea;
      })
      idsAn.map(id=>{
        const MyMobilite = MobilitesQuery.data.find(m=>m.userMetier?.id === id)
        if(MyMobilite){
          list.push({userMetier:{id:MyMobilite.userMetier?.id||0, libelle:MyMobilite.userMetier?.namePerso||'nc'}, id:MyMobilite.id||0, name:MyMobilite.userMetier?.namePerso||'nc', score:MyMobilite.score||0})
        }
        return id;
      })
    }
    return list;
  }, [EntretiensAnnuelsQuery.data, MobilitesQuery.data]);
  const setForPTrigger = (item:any)=>{
    console.log(item);
    console.log({idCompareA:item.id, idCompareB:item.userMetier.id});
    setForP({idCompareA:item.id, idCompareB:item.userMetier.id})
    toggle();
  }
  return (
    <div className={`Mobilités_interne ${className}`}>
      {employeeQuery.isLoading || MobilitesQuery.isLoading || EntretiensAnnuelsQuery.isLoading ? (
        <MbjLoading />
      ) : employeeQuery.isError || MobilitesQuery.isError || EntretiensAnnuelsQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              {
                index: 2,
                label: employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom,
              },
              { index: 3, label: "Mobilités Internes" },
            ]}
          />
          <MbjFlex gap={"24px"}>
            <MbjWidgetClassic title={"Mobilité vers un métier souhaité lors des entretiens périodiques"} bodyDirection={"column"} basis={"100%"}>
              {listVsEntretien.map((item, idx)=>(
                <div className={`one_mobilite_interne`} key={`one_mobilite_interneEA${idx}`} onClick={()=>setForPTrigger(item)}>
                  <div className={`libelle`}>{item.name}</div>
                  <div className={`score`}>{Math.round((item.score||0)*10000)/100}%</div>
                </div>
              ))}
              {listVsEntretien.length === 0 &&
                <p>Aucun souhait de mobilité interne pour cette personne</p>
              }
            </MbjWidgetClassic>
            <MbjWidgetClassic title={"Mobilité vers un métier interne à l'entreprise"} bodyDirection={"row"}>
              {MobilitesQuery.data?.map((item, idx)=>(
                <div className={`one_mobilite_interne`} key={`one_mobilite_interne${idx}`} onClick={()=>setForPTrigger(item)}>
                  <div className={`libelle`}>{item.userMetier?.namePerso}</div>
                  <div className={`score`}>{Math.round((item.score||0)*10000)/100}%</div>
                </div>
              ))}
            </MbjWidgetClassic>
          </MbjFlex>
        </>
        )}
      <MbjModal isShowing={isShowing} hide={toggle} maxWidth={"90%"}>
        <div>
          <MbjCompareReferentiel
            idToCompare1={forP?.idCompareA||0}
            idToCompare2={forP?.idCompareB||0}
            typeCompared={"EUM"}
          />
        </div>
      </MbjModal>
    </div>
  )
}

export const MobilitesInterne = styled(MobilitesInterneCtrl)`
  padding: 1% 2%;
  .one_mobilite_interne{
    width: 75%;
    display: flex;
    justify-content: flex-start;
    padding: 0.25rem 0;
    border-bottom: solid ${props => props.theme.primaryLighten} 1px;
    &:hover{
      cursor: pointer;
      font-weight: bold;
    }
    .libelle{
      width: 75%;
    }
    .score{
      flex-grow: 1;
      text-align: right;
      font-weight: bold;
      color:${props => props.theme.primaryLighten}
    }
  }
`
