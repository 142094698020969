import styled from "styled-components";
import {Route, Routes} from "react-router-dom";
import {InternDesktopLayout} from "@mbj-front-monorepo/ui";
import {NotFound} from "../NotFound/NotFound";
import * as React from "react";
import {CvThequeDetail} from "./CvThequeDetails/CvThequeDetails";
import {CvThequeRoot} from "./CvThequeRoot";

interface CvThequeCtrlProps{
  className?:string;
}

const CvThequeCtrl = (props:CvThequeCtrlProps)=>{
  return (
    <div className={`CvTheque`}>
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              /*
                            mbjSectionMenu={
                                <MbjSectionMenu
                                    otherContent={
                                        <React.Fragment>
                                            <MbjButton themeColor="secondary" size="small">
                                                Ajouter à une session de formation
                                            </MbjButton>
                                        </React.Fragment>
                                    }
                                />
                            }
                            */
            />
          }
        >
          <Route path="" element={<CvThequeRoot />} />
        </Route>
        <Route path="/:id/*" element={<CvThequeDetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  )
}

export const CvTheque = styled(CvThequeCtrl)``
