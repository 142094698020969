import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {AvatarFact} from "@mbj-front-monorepo/ui";
import {ItemSaveCard} from "./ItemSaveCard";
import React from "react";

interface ProfilRetenuReclCtrlProps{
  className?:string;
  ResultRecl:components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"];
  clickCompare:(type:"Emp"|"Part"|"Recl", result:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>void;
}

const ProfilRetenuReclCtrl = (props:ProfilRetenuReclCtrlProps)=>{
  const handleError = (e:any)=>{
    e.target.src = AvatarFact;
  }
  const ClickOnIt = ()=>{
    props.clickCompare("Recl", props.ResultRecl)
  }
  const infos:React.ReactNode =
    <div className={`wrap_infos_base`}>
      <div className={"Avatar_place"}>
        <img
          src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${props.ResultRecl.Reclassement?.UserMetier?.Member?.logo}`}
          alt={"avatar_logo"}
          onError={handleError}
        />
      </div>
      <div className={"Name_place"}>
        <div className={`ent_name`}>{props.ResultRecl.Reclassement?.UserMetier?.Member?.name}</div>
        {props.ResultRecl.Reclassement?.UserMetier?.namePerso}
      </div>
    </div>

  return (
    <div className={`fiche_emp_retenu ${props.className}`}>
      <ItemSaveCard
        statutLib={props.ResultRecl.statut?.libelle||'nc'}
        satutID={props.ResultRecl.statut?.id||0}
        infos={infos}
        Pc={props.ResultRecl.score||0}
        Reaction={ClickOnIt}
      />
    </div>
  )
}

export const ProfilRetenuRecl = styled(ProfilRetenuReclCtrl)`

  .wrap_infos_base{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 69%;
  }
  .Pc_place{
    position: absolute;
    right: 5px;
    top: -5px;
    font-size: 40px;
    &.good{
      color: ${props => props.theme.thirdDarken};
    }
    &.medium{
      color: ${props => props.theme.warningPastel};
    }
    &.bad{
      color: ${props => props.theme.warningDarken};
    }
  }
  .Avatar_place{
    width: 90px;
    height: 90px;
    margin-right: 8px;
    flex-shrink: 0;
    img{
      width: 100%;
      height: auto;
    }
  }
  .Name_place{
    font-weight: bold;
    .ent_name{
      color: ${props=>props.theme.primary};
    }
  }
`
