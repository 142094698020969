import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { fetchApi, Operation } from "../../services/fetchApi";
import {toast} from "react-toastify";

export const useGetResultsSearchEmployeeBySearch = (idSearch:number|null)=>{
  const promise = () => fetchApi("/api/results_search_employees", { method: "get", query: { RecrutementSaved: (idSearch||-1) + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["result_search_emp", { idSearch }], promise, {
    enabled: !!idSearch,
  });
}

export const useModifyStatutSearchEmp = (idResult:number)=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putResultsSearchEmployeeItem", "requestBody">) =>
      fetchApi("/api/results_search_employees/{id}", { method: "put", params: { id: idResult + "" }, json: data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["result_search_emp"]);
        toast.success("Resultat modifié !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
