import styled from "styled-components";

interface TitleProfilRetenuCtrlProps{
  className?:string;
  libelle:string;
}

const TitleProfilRetenuCtrl = (props:TitleProfilRetenuCtrlProps)=>{
  return (
    <div className={`titre_profil_retenu ${props.className}`}>{props.libelle}</div>
  )
}

export const TitleProfilRetenu = styled(TitleProfilRetenuCtrl)`
  font-size: 17px;
  font-weight: bold;
`
