import * as React from "react";
import styled, { useTheme } from "styled-components";
import { MbjAvatar } from "../../../atoms/MbjAvatar";
import {AlertNotifications} from "./AlertNotifications";

interface MbjHeadBarDesktopCtrlProps {
  /**
   * Classe HTML de la HeadBar ?
   */
  className?: string;
  /**
   * Hauteur de la headbar
   */
  height?: string;
  /**
   * Largeur de la headbar
   */
  width?: string;
  /**
   * Logo de l'utilisateur
   */
  userLogo?: React.ReactElement;
}

function MbjHeadBarDesktopCtrl(props: MbjHeadBarDesktopCtrlProps) {
  const theme = useTheme();

  return (
    <div className={props.className}>
      <img src={theme.logo} alt="logo mbj" className={"logo"} />
      <div className={`wrap_right`}>
        <AlertNotifications/>
        <MbjAvatar icon={props.userLogo} objectFit={"contain"} bg={"#ffffff"} />
      </div>
    </div>
  );
}

export const MbjHeadBarDesktop = styled(MbjHeadBarDesktopCtrl)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  background: props.theme.primary,
  alignItems: "center",
  paddingLeft: "1.2vw",
  paddingRight: "2.5vw",
  height: props.height,
  width: props.width,
  ".logo": {
    height: "50%",
  },
  ".wrap_right": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
}));
MbjHeadBarDesktop.defaultProps = {
  width: "100vw",
  height: "9vh",
};
