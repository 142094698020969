import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
  MbjAutocomplete,
  MbjButton,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  useAutocomplete,
} from "@mbj-front-monorepo/ui";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import { useLocation } from "react-router-dom";
import { Operation, useGetAllMember, useSendMessage } from "@mbj-front-monorepo/data-access";

interface NouveauMessageModalCtrlProps extends MbjModalCtrlProps {
  className?: string;
  defaultDest?: Operation<"getMemberItem">;
  defaultSubject?: string;
  defaultText?: string;
}

function NouveauMessageModalCtrl(props: NouveauMessageModalCtrlProps) {
  const usersQuery = useGetAllMember();
  const location = useLocation();
  const state: any = location.state;

  const [defaultSubject, setDefaultSubject] = useState("");

  const useAutoCompleteHook = useAutocomplete<Operation<"getMemberItem">>(props.defaultDest);
  const editorRef = useRef<any>(null);

  const mutationSendMessage = useSendMessage();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      subject: props.defaultSubject ? props.defaultSubject : state ? state.subject : "",
    },
  });

  useEffect(() => {
    if (location.state) {
      useAutoCompleteHook.selectValue(props.defaultDest || state.dest);
      setDefaultSubject(state.subject);
    }
  }, [props.hide]);

  function onSubmit(data: any) {
    if (useAutoCompleteHook.selectedValue) {
      mutationSendMessage.mutate(
        {
          MemberTo: "/api/members/" + useAutoCompleteHook.selectedValue.id,
          subject: data.subject,
          content: editorRef.current.getContent(),
        },
        {
          onSuccess: () => {
            props.hide();
          },
        }
      );
    }
  }

  return (
    <MbjModal title={"Nouveau message"} {...props} maxWidth={"65%"} fitContent>
      <form onSubmit={handleSubmit(onSubmit)} className="Infos">
        <MbjSimpleInputContainer errors={errors} label={"Destinataire"}>
          <MbjAutocomplete
            placeholder={"Choisissez un destinataire"}
            className={"SearchBar"}
            suggestionsQuery={usersQuery}
            autocompleteHooks={useAutoCompleteHook}
            foldAfterChoose={true}
            id={"destinataire"}
            defaultValue={defaultSubject}
            name={"destinataire"}
            register={register}
            setValue={setValue}
            trigger={trigger}
            labelKey={"name" as keyof unknown}
            required
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={errors} label={"Sujet"}>
          <MbjSimpleInput
            name={"subject"}
            id={"subject"}
            type={"text"}
            noPadding
            defaultValue={defaultSubject}
            register={register}
            registerOptions={{
              maxLength: {
                value: 90,
                message: "Ne peut pas contenir plus de 90 caractères",
              },
            }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={errors} label={"Corps du message"}>
          <Editor
            apiKey="dyashvxtrn0l64scdt6b11wmfjj9txwbg3qj3zmt40jkzwjy"
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={props.defaultText ? props.defaultText : ""}
            init={{
              height: 250,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "preview",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </MbjSimpleInputContainer>
        <MbjButton className={"sendButton"} isPending={mutationSendMessage.isLoading}>
          Envoyer
        </MbjButton>
      </form>
    </MbjModal>
  );
}
export const NouveauMessageModal = styled(NouveauMessageModalCtrl)((props) => ({
  form: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
    ".sendButton": {
      marginTop: "10px",
      marginLeft: "auto",
    },
  },
}));
