import styled from "styled-components";
import {Operation, useGetOFAutoFormation} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjModal, MbjScrollArea, MbjTableV2, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import React, {useState} from "react";
import {CoordOF} from "../Components/CoordOF";

interface EmployeeAutoFormationCtrlProps{
  className?:string;
  employee: Operation<"getEmployeeItem">;
}
export interface orgaFormation{
  siret:string;
  email:string;
  adresse:string;
  codePostal:string;
  tel:string;
  contact:string;
  nom:string;
  ville:string;
}
const EmployeeAutoFormationCtrl = (props:EmployeeAutoFormationCtrlProps)=>{
  if (!props.employee.id) throw new TypeError("employee.id is undefined");
  const OfQuery = useGetOFAutoFormation(props.employee.id)
  const { isShowing, toggle } = useModal();
  const [ofCurr, setOfCurr] = useState<orgaFormation|null>(null)
  const ClickOF = (OF:any)=>{
    setOfCurr({
      siret:OF.siret||'nc',
      email:OF.email||'nc',
      adresse:OF.adresse||'nc',
      codePostal:OF.Zipcode||'nc',
      tel:OF.tel||'nc',
      contact:OF.contact||'nc',
      nom:OF.rs,
      ville:OF.ville
    })
    toggle();
  }
  return (
    <MbjWidgetClassic title="Organismes de formation pouvant former" className={props.className}>
      {OfQuery.isLoading ? (
        <MbjLoading />
      ) : OfQuery.isError || !OfQuery.data ? (
        <p>Erreur ...</p>
      ) : (
        <>
          {OfQuery.data.OFActivites &&
            OfQuery.data.OFActivites.map((item, idx)=>(
              <div className={`one_of_form`} key={`one_of_form${idx}`}>
                <div className={`titre_of`} onClick={()=>ClickOF(item.organismeFormation)}>{item.organismeFormation ? (item.organismeFormation.rs || 'nc') : 'nc' } Distance {item.distance}km</div>
                <div className={`wrap-list-fc`}>
                  {item.formationsCourtesForm?.map((fcc, idxFFC:number)=>(
                    <div className={`one_formation_fcc`} key={`Form_${idx}_${idxFFC}`}>
                      <div className={`titre_fcc`}>{fcc.formationContinuCourtes?.libelle}</div>
                      <div className={`wrap_list_activite_form`}>
                        {fcc.activites?.map((activite, idxA)=>(
                          <div className={`one_acti_form`} key={`acti_form_${idx}_${idxFFC}_${idxA}`}>{activite.libelle}</div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          }
          <MbjModal hide={toggle} isShowing={isShowing} title="Coordonnées organisme formation">
            <div className={`wrap_coord`}>
              <CoordOF titre={"Nom"} infos={<span>{ofCurr?.nom}</span>}/>
              <CoordOF titre={"Adresse"} infos={<span>{ofCurr?.codePostal} {ofCurr?.codePostal}</span>}/>
              <CoordOF titre={"Téléphone"} infos={<span>{ofCurr?.tel}</span>}/>
              {ofCurr && ofCurr.email!=='nc' &&
                <CoordOF titre={"Email"} infos={<div className={`link_mail`} onClick={(e) => {window.location.href ='mailto:'+ofCurr?.email;}}>{ofCurr.email}</div>}/>
              }
            </div>
          </MbjModal>
        </>
      )
      }

    </MbjWidgetClassic>
  )
}

export const EmployeeAutoFormation = styled(EmployeeAutoFormationCtrl)`
  .line_coord{
    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  .one_of_form{
    width: 100%;
    padding: 0.5rem;
    background: white;
    border-radius: 6px;
    margin-bottom: 10px;
    .titre_of{
      font-weight: bold;
      border-bottom: solid black 1px;
      margin-bottom: 10px;
    }
    .wrap-list-fc{
      padding-left: 1.5rem;
      .titre_fcc{
        color:${props => props.theme.primary};
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 5px;
      }
      .wrap_list_activite_form{
        padding-left: 1rem;
      }
    }
  }
`
