import * as React from "react";
import { useLocation } from "react-router-dom";
import { MbjFlex, MbjNavPath, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import { EffectifCard } from "./EffectifCard/EffectifCard";
import { InfosGeneralesCard } from "./InfosGeneralesCard/InfosGeneralesCard";
import { MetiersCard } from "./MetiersCard/MetiersCard";
import { CompetencesToDevCard } from "./CompetencesToDevCard/CompetencesToDevCard";
import styled from "styled-components";
import { RepartitionMetiersCard } from "./RepartitionMetiersCard/RepartitionMetiersCard";
import { CompetencesPrincipalesCard } from "./CompetencesPrincipalesCard/CompetencesPrincipalesCard";

interface EntrepriseResumeCtrlProps {
  className?: string;
}

function EntrepriseResumeCtrl(props: EntrepriseResumeCtrlProps) {
  const location = useLocation();

  return (
    <div className={"EntrepriseResume " + props.className}>
      <MbjNavPath
        routePath={location.pathname}
        changeLabel={{
          index: 2,
          label: "Résumé",
        }}
      />
      <MbjFlex direction={"column"}>
        <MbjFlex>
          <MbjWidgetClassic className="profilContainer" title="Profil" basis={"20%"}>
            <InfosGeneralesCard grow={1} />
          </MbjWidgetClassic>
          <MbjWidgetClassic className="statsContainer" title="Statistiques" basis={"calc(80% - 24px)"} bodyDirection={"column"}>
            <EffectifCard />
            <RepartitionMetiersCard />
            <CompetencesPrincipalesCard />
          </MbjWidgetClassic>
        </MbjFlex>
        <MbjFlex className="content2">
          <MbjWidgetClassic className="metiersContainer" title="Métiers de l'entreprise" basis={"calc(50% - 12px)"}>
            <MetiersCard to="../metiers" />
          </MbjWidgetClassic>
          <MbjWidgetClassic className="competencesContainer" title="Compétences internes" basis={"calc(50% - 12px)"}>
            <CompetencesToDevCard />
          </MbjWidgetClassic>
        </MbjFlex>
      </MbjFlex>
    </div>
  );
}
export const EntrepriseResume = styled(EntrepriseResumeCtrl)((props) => ({
  padding: "1% 2%",
}));
