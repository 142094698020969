import styled from "styled-components";
import {components, Operation, useModifyStatutSearchEmp} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjCirclePercent, useModal} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {
  ExternalProfilConsultModal
} from "../Recrutements/MesRecherches/CollabProfilItem/ExternalProfilConsultModal/ExternalProfilConsultModal";
import {useParams} from "react-router-dom";

interface CardProfilFindCtrlProps{
  className?:string;
  idSearch:number;
  Employee:components["schemas"]["Employee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"]|undefined,
  score:number|undefined;
  idStatut:number;
}

const CardProfilFindCtrl = (props:CardProfilFindCtrlProps)=>{
  const { id } = useParams();
  const { isShowing: isShowing, toggle: toggle } = useModal();
  const mutation = useModifyStatutSearchEmp(props.idSearch)
  const ModifyMe = (idStatut:number)=>{
    const datas:Operation<"putResultsSearchEmployeeItem", "requestBody">={
      statut:`/api/statut_results_searches/${idStatut}`
    }
    mutation.mutate((datas))
  }
  return (
    <div className={`card_profil ${props.className}`}>
      {props.Employee && props.score &&
        <>
          <div className={`score`}>
            <MbjCirclePercent
              percent={Math.round(props.score*100)}
            />
          </div>
          <div className={`infos_profil`}>
            <div className={`name is_infos`}><span>Prénom Nom</span><span>{props.Employee.prenom} {props.Employee.nom}</span></div>
            <div className={`useMetier is_infos`}><span>Métier actuel</span><span>{props.Employee.UserMetier?.namePerso}</span></div>
            <div className={`Entreprise is_infos`}><span>Entreprise actuelle</span><span>{props.Employee.UserMetier?.Member?.name}</span></div>
          </div>
          <div className={`wrap_actions`}>
            <div className={`wrap_btn`}>
                <MbjButton size={"sm"} grow={1} isPending={mutation.isLoading} onClick={toggle}>
                  Consulter
                </MbjButton>
            </div>
            <div className={`wrap_btn`}>
              <MbjButton size={"sm"} themeColor={"warning"} grow={1} isPending={mutation.isLoading} onClick={()=>ModifyMe(3)}>
                Rejeter
              </MbjButton>
            </div>
            {props.idStatut !== 2 &&
              <div className={`wrap_btn`}>
                <MbjButton size={"sm"} themeColor={"third"} grow={1} isPending={mutation.isLoading} onClick={()=>ModifyMe(2)}>
                  Sauvegarder
                </MbjButton>
              </div>
            }
          </div>
        </>
      }
      {isShowing &&
        <ExternalProfilConsultModal
          isShowing={isShowing}
          hide={toggle}
          externalProfil={props.Employee}
          externalMember={props.Employee?.UserMetier?.Member}
          type={"EUM"}
          refMet={id ? parseInt(id) : 0}
        />
      }
    </div>
  )
}

export const CardProfilFind = styled(CardProfilFindCtrl)`
  width: 100%;
  padding: 0.5rem;
  background: white;
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 12px;
  .wrap_actions{
    .wrap_btn{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 4px;
    }
  }
  .is_infos{
    span{
      display: block;
      &:first-child{
        font-weight: bold;
      }
      &:last-child{
        color:${props => props.theme.primary}
      }
    }
    display: flex;
    justify-content: flex-start;
    gap: 10px;

  }
  .infos_profil{
    flex-grow: 1;
  }
  .score{
    width: 90px;
    height: 90px;
  }
`
