import React, {useEffect, useMemo} from "react";
import {
  createSelectColumn,
  MbjLink,
  MbjLoading,
  MbjScrollArea,
  MbjTableV2,
  MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import { DetailCompareCompCard } from "./CompareProfilPosteCard";
import styled from "styled-components";
import { Operation, useGetProximiteReferentiel } from "@mbj-front-monorepo/data-access";
import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";

interface CompareProfilPosteWidgetCtrlProps {
  employee: Operation<"getEmployeeItem">;
  className?: string;
  setNbMissed: (nb: number) => void;
  setNbRised: (nb: number) => void;
  SwitchOpen:(item:{id:number, libelle:string, niveau:number})=>void;
}

interface OneLineLearn{
  id:number,
  libelle:string,
  niveau:number,
  niveauCible:number
}

const CompareProfilPosteWidgetCtrl = (props: CompareProfilPosteWidgetCtrlProps) => {
  if (!props.employee.id) throw new TypeError("employee.id is undefined");
  const useGetMobBilanQuery = useGetProximiteReferentiel("EUM", props.employee.id, props.employee?.UserMetier?.id);

  const listToLearn = useMemo(()=>{
    if(!useGetMobBilanQuery.data){
      return []
    } else {
      const myList:OneLineLearn[] = [];
      if(useGetMobBilanQuery.data.Missed){
        useGetMobBilanQuery.data.Missed.map(l=>{
          myList.push({
            id:l.id || 0,
            libelle:l.libelle || '',
            niveau:l.niveauActuel || 0,
            niveauCible:l.niveauAttendu || 0
          })
        })
      }
      if(useGetMobBilanQuery.data.ToRised){
        useGetMobBilanQuery.data.ToRised.map(l=>{
          myList.push({
            id:l.id || 0,
            libelle:l.libelle || '',
            niveau:l.niveauActuel || 0,
            niveauCible:l.niveauAttendu || 0
          })
        })
      }
      console.log(myList);
      return myList;
    }
  }, [useGetMobBilanQuery.data])

  const columnHelper = createColumnHelper<OneLineLearn>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.group({
      header: "Information",
      columns: [
        columnHelper.accessor((row) => row.libelle, {
          header: "Libelle",
          cell: (info) => {
            const ShowTruc = (id:number, libelle:string, niveau:number)=>{
              props.SwitchOpen({id:id, libelle:libelle, niveau});
            }
            return <div className={`name_acti`} onClick={()=>ShowTruc(info.row.original.id, info.getValue(), info.row.original.niveau)}>{info.getValue()}</div>
          },
        }),
        columnHelper.accessor((row) => row.niveau, {
          header: "Niveau Actuel",
        }),
        columnHelper.accessor((row) => row.niveauCible, {
          header: "Niveau Attendu",
        }),
      ],
    }),

  ];

  const table = useReactTable({
    data: listToLearn,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Libelle", desc: false }],
    },
  });

  const totalComp: number =
    (useGetMobBilanQuery.data?.Shared?.length || 0) +
    (useGetMobBilanQuery.data?.Missed?.length || 0) +
    (useGetMobBilanQuery.data?.ToRised?.length || 0);
  useEffect(() => {
    if (useGetMobBilanQuery.data) {
      props.setNbMissed(useGetMobBilanQuery.data.Missed ? useGetMobBilanQuery.data.Missed.length : 0);
      props.setNbRised(useGetMobBilanQuery.data.ToRised ? useGetMobBilanQuery.data.ToRised.length : 0);
    }
  }, [useGetMobBilanQuery.data, props.setNbMissed, props.setNbRised]);
  return (

    <MbjWidgetClassic title="Compétences à améliorer" className={props.className}>
      <p style={{width:"100%"}} className={`introduction`}>Afin de mieux maîtriser les compétences attendues, selon My-Better-Job, {props.employee.prenom} devrait améliorer les compétences suivantes :</p>
      {useGetMobBilanQuery.isLoading ? (
        <MbjLoading />
      ) : useGetMobBilanQuery.isError || !useGetMobBilanQuery.data ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <div style={{height:"500px", width:"100%"}}>
            <MbjScrollArea overflowX={"auto"}>
              <MbjTableV2 table={table} />
            </MbjScrollArea>
          </div>
        </>
      )}
    </MbjWidgetClassic>
  );
};

export const CompareProfilPosteWidget = styled(CompareProfilPosteWidgetCtrl)`
  .name_acti{
    color:${props => props.theme.primary}
    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .introduction{
    font-size: 16px;
  }
`
