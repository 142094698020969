import React from "react";
import "./App.scss";
import GlobalStyle from "../assets/styles/global";
import { ThemeProvider } from "styled-components/macro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Navigate, Route, Routes } from "react-router-dom";
import { Registration } from "../pages/Registration/Registration";
import { Login } from "../pages/Login/Login";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { PrivateRouteCtrl } from "../components/PrivateRouteCtrl";
import { EntrepriseApp } from "./apps/entreprise/EntrepriseApp";
import { PublicRouteCtrl } from "../components/PublicRouteCtrl";
import { NotFound } from "../pages/NotFound/NotFound";
import { ConseillerApp } from "./apps/conseiller/ConseillerApp";
import { LegalMentions } from "../pages/LegalMentions/LegalMentions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {mbjTheme, pauTheme, sqyTheme, orlyTheme} from "@mbj-front-monorepo/ui";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      //cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});
//const persister = createSyncStoragePersister({
//  storage: window.localStorage,
//});

function App() {
  return (
    <ThemeProvider theme={process.env["REACT_APP_DEFAULT_THEME"] === 'pauTheme' ? pauTheme : process.env["REACT_APP_DEFAULT_THEME"] === 'orlyTheme' ? orlyTheme : process.env["REACT_APP_DEFAULT_THEME"] === 'sqyTheme' ? sqyTheme : mbjTheme}>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Routes>
            <Route element={<PrivateRouteCtrl from={"App"} />}>
              <Route path="/entreprise/*" element={<EntrepriseApp />} />
              <Route path="/conseiller/*" element={<ConseillerApp />} />
            </Route>
            <Route element={<PublicRouteCtrl />}>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>
            <Route path="/mentions" element={<LegalMentions />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
