import styled from "styled-components";
import {MbjLoading, MbjNavPath, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {useLocation} from "react-router-dom";
import {useGetEmployees, useGetUserMetiers} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import {useGetAllSearchByMember} from "@mbj-front-monorepo/data-access";
import {FormatCibleCard} from "./FormatCibleCard";


interface EffectifCtrlProps{
  className?:string;
}

const EffectifCtrl = (props:EffectifCtrlProps)=>{
  const UserMetiersQuery = useGetUserMetiers();
  const SearchsQuery = useGetAllSearchByMember();
  const EmployeesQuery = useGetEmployees();
  const location = useLocation();
  return (
    <div className={"Effectif" + props.className}>
      <MbjNavPath routePath={location.pathname} />
      <MbjWidgetClassic title="Gestion des effectifs" bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {UserMetiersQuery.isLoading ? (
          <MbjLoading />
        ) : UserMetiersQuery.isError ? (
          <p>Error !</p>
        ) : (
          <div className={`wrap_formats_cible`}>
            {UserMetiersQuery.data?.filter(um=>um.formatCible).length === 0 &&
              <div className={"no_result"}>
                <p>Tous les métiers de l'entreprise ont un effectif correspondant à l'objectif</p>
                <p>Si vous souhaitez recruter sur un métier de l'entreprise, vous pouvez augmenter le format cible depuis <a href={"/entreprise/entreprise/metiers"}>Les métiers de l'entreprise</a> </p>
              </div>
            }
            {UserMetiersQuery.data?.filter(um=>um.formatCible).map((item, idx)=>(
              <FormatCibleCard UserMetier={item} key={`OmC${idx}`}/>
            ))}
          </div>
        )}
      </MbjWidgetClassic>

    </div>
  )
}

export const Effectif = styled(EffectifCtrl)`
  padding: 1% 2%;
  .wrap_formats_cible{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .no_result{
    font-size: 18px;
  }
`
