import * as React from "react";
import styled from "styled-components";
import {WaitingZone} from "../AjoutCV";
import {useEffect, useState} from "react";

interface InfoWaitCtrlProps{
  className?:string;
  WZ:WaitingZone;
  index:number;
  currentIndex:number;
  setFinish:(index:number)=>void;
}

const InfoWaitCtrl = ({className, WZ, setFinish, index, currentIndex}:InfoWaitCtrlProps)=>{
  const [targetTime, setTargetTime] = useState(0)
  useEffect(()=>{
    console.log(index, currentIndex);
  }, [index, currentIndex])
  useEffect(()=>{
      const timzeI = setInterval(() => {
        if (targetTime < WZ.timeToStop && index <= currentIndex) {
          setTargetTime(targetTime => targetTime + 1)
        }
      }, 1000)

    return ()=>{
      clearInterval(timzeI);
    }
  }, [targetTime, WZ.timeToStop, currentIndex, index])
  useEffect(()=>{
    if(targetTime >= WZ.timeToStop){
      setFinish(index+1);
    }
  }, [targetTime, WZ.timeToStop])

  return (
    <div className={`InfoWait ${className} ${index <= currentIndex ? 'visible' : 'invisible' }`}>
      <div className={`libelle`}>{WZ.libelle}</div>
      <div className={`slider`}>
        <div className={"progress"}>{Math.round((targetTime/WZ.timeToStop)*100)}%</div>
        <div className={`slidy`} style={{width:(targetTime/WZ.timeToStop)*100+'%'}}/>
      </div>
    </div>
  )
}

export const InfoWait = styled(InfoWaitCtrl)`
  width: 100%;
  margin-bottom: 12px;

  .libelle {
    font-weight: bold;
  }

  &.invisible {
    display: none;
  }

  .slider {
    width: 100%;
    margin: 8px auto;
    border: solid #ccc 1px;
    border-radius: 4px;
    height: 24px;
    position: relative;
    overflow: hidden;
    background: #ebe3e3;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .1) inset, -1px -1px 3px rgba(0, 0, 0, .1) inset;

    .progress {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      font-size: 13px;
      font-weight: bold;
    }

    .slidy {
      position: absolute;
      height: 100%;
      background: #a5e1a5;
      border-radius: 4px;
      left: 0;
      top: 0;
      transition: width 0.3s;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, .2)
    }
  }
`
