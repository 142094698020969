import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { fetchApi, Operation } from "../../services/fetchApi";
import {toast} from "react-toastify";


export const useGetResultsSearchReclassementBySearch = (idSearch:number|null)=>{
  const promise = () => fetchApi("/api/results_search_reclassements", { method: "get", query: { RecrutementSaved: (idSearch||-1) + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["result_search_recl", { idSearch }], promise, {
    enabled: !!idSearch,
  });
}

export const useGetResultsSearchReclassementOne = (id:number)=>{
  const promise = ()=> fetchApi("/api/results_search_reclassements/{id}", {method:"get", params:{id:id+""}});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["result_search_recl", { id }], promise, {
    enabled: id !== 0,
  });
}

export const useModifyStatutSearchRecl = (idResult:number)=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putResultsSearchReclassementItem", "requestBody">) =>
      fetchApi("/api/results_search_reclassements/{id}", { method: "put", params: { id: idResult + "" }, json: data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["result_search_recl"]);
        toast.success("Resultat modifié !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
