import * as React from "react";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MbjAccordion,
  MbjAccordionContent,
  MbjAccordionItem,
  MbjAccordionTrigger,
  MbjButton,
  MbjCardClassic,
  MbjConfirmModal,
  MbjFlex,
  MbjLabel,
  MbjLoading,
  MbjNavPath,
  MbjRadioGroup,
  MbjRadioGroupIndicator,
  MbjRadioGroupItem,
  MbjSearchBar,
  MbjTable,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";

import { CollaborateurReclasseItem } from "./CollaborateurReclasséItem/CollaborateurReclasséItem";
import styled from "styled-components";
import { useDeleteMemberMetierReclassement, useGetMemberMetierReclassements } from "@mbj-front-monorepo/data-access";
import { getDateFR } from "@mbj-front-monorepo/utils";

interface MesReclassementsCtrlProps {
  className?: string;
}
const Titles = [
  { libelle: "Collaborateur", Align: "isLeft" },
  { libelle: "Poste", Align: "isCenter" },
  //{ libelle: "Service", Align: "isCenter" },
  { libelle: "Date d'entrée", Align: "isCenter" },
  { libelle: "Mobilité exprimée", Align: "isCenter" },
  { libelle: "Retiré", Align: "isCenter" },
  { libelle: "Proposé", Align: "isCenter" },
  { libelle: "Accepté", Align: "isCenter" },
];

function MesReclassementsCtrl(props: MesReclassementsCtrlProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const myReclassementsQuery = useGetMemberMetierReclassements();
  const [refChoice, setRefChoice] = useState("metier");
  const [query, setQuery] = useState("");
  const { isShowing, toggle } = useModal();
  const [isTrashing, setIsTrashing] = useState(false);
  const [idReclSelected, setIdReclSelected] = useState<number | null>(null);
  const mutation = useDeleteMemberMetierReclassement();
  const ConfirmTrash = () => {
    if (idReclSelected) {
      setIsTrashing(true);
      mutation.mutate(idReclSelected, {
        onSuccess: () => {
          setIsTrashing(false);
          toggle();
        },
      });
    }
  };
  const MemberMetierReclassementFiltered = useMemo(() => {
    if (
      myReclassementsQuery === undefined ||
      myReclassementsQuery.data === null ||
      myReclassementsQuery.data === undefined
    ) {
      return [];
    }
    return myReclassementsQuery.data?.filter((MMRItem) => {
      if (refChoice === "metier") {
        if (query === "") {
          return MMRItem;
        } else if (MMRItem.UserMetier?.namePerso?.toLowerCase().includes(query.toLowerCase())) {
          return MMRItem;
        }
      } else if (refChoice === "collaborateur") {
        if (!MMRItem.ReclassementEmployees) {
          return;
        }
        if (query === "") {
          return MMRItem;
        }
        for (let i = 0; i < MMRItem.ReclassementEmployees.length; i++) {
          const fullname =
            MMRItem.ReclassementEmployees[i].Employee?.nom?.toLowerCase() +
            " " +
            MMRItem.ReclassementEmployees[i].Employee?.prenom?.toLowerCase();
          if (fullname.toLowerCase().includes(query.toLowerCase())) {
            return MMRItem;
          }
        }
      }
      return undefined;
    });
  }, [query, myReclassementsQuery]);

  function filtrerEmployees(employee: any) {
    if (refChoice === "collaborateur") {
      const fullname = employee.Employee.nom.toLowerCase() + " " + employee.Employee.prenom.toLowerCase();
      return fullname.toLowerCase().includes(query.toLowerCase());
    }
    return true;
  }
  const handleClickTrash = (e: any, id: number) => {
    e.stopPropagation();
    setIdReclSelected(id);
    toggle();
  };
  return (
    <div className={"MesReclassements" + props.className}>
      <div className="top">
        <MbjNavPath routePath={location.pathname} noMargin />
        <div className="right">
          <MbjButton size={"sm"} onClick={() => navigate("./../nouveau-reclassement")}>
            Ajouter un nouveau reclassement
          </MbjButton>
        </div>
      </div>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Mes reclassements"}>
          <MbjCardClassic className={"searchCard"} title={"Recherche"}>
            <div className={"searchContent"}>
              <div className={"refChoiceContainer"}>
                <span>Recherche sur :</span>
                <MbjRadioGroup
                  defaultValue="metier"
                  aria-label="Choix du type de recherche"
                  onValueChange={(value) => setRefChoice(value)}
                  className={"radioContainer"}
                >
                  <div>
                    <MbjRadioGroupItem value="metier" id="r1">
                      <MbjRadioGroupIndicator />
                    </MbjRadioGroupItem>
                    <MbjLabel htmlFor="r1" themeColor={"dark"} size={"very-small"}>
                      métier
                    </MbjLabel>
                  </div>
                  <div>
                    <MbjRadioGroupItem value="collaborateur" id="r2">
                      <MbjRadioGroupIndicator />
                    </MbjRadioGroupItem>
                    <MbjLabel htmlFor="r2" themeColor={"dark"} size={"very-small"}>
                      collaborateur
                    </MbjLabel>
                  </div>
                </MbjRadioGroup>
              </div>
              <MbjSearchBar
                placeholder={"Rechercher un " + (refChoice === "metier" ? "métier" : "collaborateur")}
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
          </MbjCardClassic>
        </MbjWidgetClassic>
        <MbjWidgetClassic title={"Détails des reclassements"}>
          <MbjAccordion type="multiple" defaultValue={["item-0"]} className={"accordéon"}>
            {myReclassementsQuery.isLoading ? (
              <MbjLoading />
            ) : myReclassementsQuery.isError ? (
              <p>Erreur...</p>
            ) : (
              MemberMetierReclassementFiltered.map((MMRItem, idx: number) => (
                <MbjAccordionItem value={`item-${idx}`} key={MMRItem.id}>
                  <MbjAccordionTrigger>
                    <div className="accordion-trigger-content">
                      <span>
                        <span>{MMRItem.UserMetier?.namePerso || "--"}</span>
                        <span>
                          - Reclassement créée le{" "}
                          {MMRItem.reclassementAt ? getDateFR(new Date(MMRItem.reclassementAt)) : "--"}
                        </span>
                      </span>
                      <span>
                        <span>
                          Volume : <strong>{MMRItem.volume}</strong> dont {MMRItem.ReclassementEmployees?.length || 0}{" "}
                          profils référencé(s)
                        </span>
                      </span>
                      <div className={"fake-btn-trash"} onClick={(e) => handleClickTrash(e, MMRItem.id || -1)}>
                        Cloturer
                      </div>
                    </div>
                  </MbjAccordionTrigger>
                  <MbjAccordionContent>
                    <MbjTable titles={Titles} themeColor={"white"} noBorderRadius>
                      {MMRItem.ReclassementEmployees?.filter(filtrerEmployees).map(
                        (reclassementEmployee, idx: number) => (
                          <CollaborateurReclasseItem
                            reclassementEmployee={reclassementEmployee}
                            key={reclassementEmployee.id}
                          />
                        )
                      )}
                    </MbjTable>
                  </MbjAccordionContent>
                </MbjAccordionItem>
              ))
            )}
          </MbjAccordion>
        </MbjWidgetClassic>
      </MbjFlex>
      <MbjConfirmModal
        isShowing={isShowing}
        hide={toggle}
        title="Confirmation de clôture"
        confirmText={"Voulez-vous vraiment clôturer ce reclassement ?"}
        confirmFunction={ConfirmTrash}
        isLoading={isTrashing}
      />
    </div>
  );
}
export const MesReclassements = styled(MesReclassementsCtrl)((props) => ({
  padding: "1% 2%",

  ".top": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",

    ".right": {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  },
  ".searchCard": {
    overflow: "visible",

    ".searchContent": {
      padding: "0.5em 1em",
      ".refChoiceContainer": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",

        ".radioContainer": {
          display: "flex",
          alignItem: "center",
          gap: "5%",
          margin: "10px 0px",

          "& > div": {
            display: "flex",
            alignItems: "center",
            gap: "8px",

            label: { whiteSpace: "nowrap" },
          },
        },
      },
    },
  },
  ".accordion-trigger-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    paddingRight: "25px",

    "span:first-child": {
      marginRight: "15px",
    },
  },
  ".accordéon": {},
  ".fake-btn-trash": {
    "&.hover": {
      cursor: "pointer",
    },
    padding: "0.3rem 1.2rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "0.5rem",
    background: "#FF0000",
    color: "white",
    border: "solid 1px transparent",
    fontSize: "16px",
  },
}));
