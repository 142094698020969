import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {AvatarEmp} from "@mbj-front-monorepo/ui";
import { BiBulb } from "react-icons/bi";

interface FormNoPlanCardCtrlProps{
  className?:string;
  FormationPl:components["schemas"]["FormationPlanning.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"]
  ClickOnIt:(fp:components["schemas"]["FormationPlanning.jsonld-formationpl.read_read.Employee.simple_read.Activite_read.Competence_read.Famille_read.Domaine"])=>void;
}

const FormNoPlanCardCtrl = (props:FormNoPlanCardCtrlProps)=>{
  return (
    <div className={`form_no_plCard ${props.className}`} onClick={()=>props.ClickOnIt(props.FormationPl)}>
      <div className={"content_card"}>
        <div className={"wrap_infos"}>
          <div className={"Avatar_place"}><img src={AvatarEmp} alt={"avatar_empl"}/></div>
          <div className={"Name_place"}>{props.FormationPl.Employee?.prenom} {props.FormationPl.Employee?.nom}</div>
        </div>
        <div className={'wrap_infos'}>
          <div className={"round_bulb"}><BiBulb/></div>
          <div className={"activite_name"}>{props.FormationPl.Activite?.libelle}</div>
        </div>
      </div>
    </div>
  )
}
export const FormNoPlanCard = styled(FormNoPlanCardCtrl)`
  padding: 1rem;
  width: 33.33%;
  .Name_place{
    font-size: 22px;
    font-weight: bold;
    color: ${props => props.theme.primary};
  }
  &:hover{
    .content_card{
      box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);
      cursor: pointer;
      transform: translateY(-5px);
      transition: 0.3s;
    }
  }
  .Avatar_place{
    width: 45px;
    height: 45px;
    img{
      width: 100%;
    }
  }
  .round_bulb{
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
    display: flex;
    justify-content: center;
    flex-direction: column;
    svg{
      font-size: 28px;
      margin: auto;
    }
  }
  .content_card{
    width: 100%;
    background: white;
    padding: 0.5rem;
    border-radius: 8px;
    position: relative;
  }
  .wrap_infos{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
  }
`
