import styled from "styled-components";
import {useLocation, useParams} from "react-router-dom";
import {useGetParticulier, useGetUserMetier} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjNavPath} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {FormChoiceMetier} from "./FormChoiceUserMetier";
import {DetailComparaison} from "./DetailComparaison";

interface ComparateurPartUserMetierCtrlProps{
  className?:string;
}

const ComparateurPartUserMetierCtrl = (props:ComparateurPartUserMetierCtrlProps)=>{
  const location = useLocation();
  const { id, idUserMetier } = useParams();
  const particulierQuery = useGetParticulier(parseInt(id || "-1"));
  const UserMetierQuery = useGetUserMetier(idUserMetier ? parseInt(idUserMetier) : undefined)
  return (
    <div className={`comparateur ${props.className}`}>
      {particulierQuery.isLoading || UserMetierQuery.isLoading ? (
        <MbjLoading />
      ) : particulierQuery.isError || UserMetierQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <MbjNavPath routePath={location.pathname}
                    changeLabelTab={[{
                      index: 2,
                      label: particulierQuery.data?.nom?.toUpperCase() + " " + particulierQuery.data?.prenom,
                    }, {index:4, label : idUserMetier === "0" ? 'Tous' : UserMetierQuery.data.namePerso }]}
        />
      )
      }
      <div className={"wrap_search"}>
        <FormChoiceMetier idUserMetierCurrent={parseInt(idUserMetier||"0")}/>
      </div>
      {idUserMetier !== "0" &&
        <div className={`wrap_infos`}>
          <DetailComparaison/>
        </div>
      }
    </div>
  )
}

export const ComparateurPartUserMetier = styled(ComparateurPartUserMetierCtrl)`
  padding: 1% 2%;
  .wrap_search{
    margin: 25px auto;
  }
`
