import styled from "styled-components";
import {GrTrash} from "react-icons/gr";
import {useTrashSearch} from "@mbj-front-monorepo/data-access";
import {MbjConfirmModal, useModal} from "@mbj-front-monorepo/ui";
import React from "react";

interface TrashSearchCtrl{
  className?:string;
  idSearch:number;
}
const TrashSearchCtrl = (props:TrashSearchCtrl)=>{
  const mutationTrash = useTrashSearch();
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const ClickTrash = ()=>{
    toggleConfirm();
  }
  const HandleConfirmDelete = ()=>{
      mutationTrash.mutate(props.idSearch, {
        onSuccess:()=>{
          toggleConfirm();
        }
      })
  }
  return (
    <>
      <button className={`trash_search ${props.className}`} onClick={ClickTrash}>
        <GrTrash/>
      </button>
      <MbjConfirmModal
        isShowing={ShowConfirm}
        hide={toggleConfirm}
        title="Confirmation suppression recherche"
        confirmText={"Voulez-vous vraiment supprimer cette recherche ?"}
        confirmFunction={HandleConfirmDelete}
        isLoading={mutationTrash.isLoading}
      />
    </>
  )
}
export const TrashSearch = styled(TrashSearchCtrl)`
  width: 46px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.warning};
  border-radius: 8px;
  border: none;
  &:hover{
    cursor: pointer;
  }
  svg{
    color: white;
    path{
      color: white;
      stroke: white;
    }
    margin: auto;
  }

  &:active{
    transform: scale(0.9);
    border: none;
    box-shadow: 0 0 4px 4px ${props=>props.theme.warningDarken};
    background-color: ${props=>props.theme.warningLighten};
  }
`
