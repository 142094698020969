import * as React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { MbjNavPath } from "@mbj-front-monorepo/ui";

interface FormationsFindCtrlProps {
  className?: string;
}

function FormationsFindCtrl(props: FormationsFindCtrlProps) {
  const location = useLocation();
  return (
    <div className={"FormationsFind" + props.className}>
      <MbjNavPath routePath={location.pathname} />
    </div>
  );
}
export const FormationsFind = styled(FormationsFindCtrl)((props) => ({
  padding: "1% 2%",
}));
