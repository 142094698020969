import styled from "styled-components";
import {useGetBesoinsFormationEntreprise, useGetFormationPlanningMember} from "@mbj-front-monorepo/data-access";
import {MbjCardClassic, MbjCardClassicCtrlProps, MbjLoading, MbjStatsNumber} from "@mbj-front-monorepo/ui";
import React from "react";

interface StatsPlDashCardCtrlProps extends MbjCardClassicCtrlProps {}

const StatsPlDashCardCtrl = (props:StatsPlDashCardCtrlProps)=>{
  const PlannQuery = useGetFormationPlanningMember();
  return (
    <MbjCardClassic {...props} title="Formations prévues" className={props.className} grow={props.grow}>
      {PlannQuery.isLoading ? (
        <MbjLoading />
      ) : PlannQuery.error ? (
        <p>Erreur ...</p>
      ) : (

          <div className="bigNumbers">
            <MbjStatsNumber
              className={"BigNumberDash"}
              label={"Total"}
              value={PlannQuery.data.length}
              themeColor={"third"}
            />
            <MbjStatsNumber
              className={"BigNumberDash"}
              label={"Non plannifiées"}
              value={PlannQuery.data.filter(p=>!p.souhaitAt).length}
              themeColor={"third"}
            />
            <MbjStatsNumber
              className={"BigNumberDash"}
              label={"Plannifiées"}
              value={PlannQuery.data.filter(p=>p.souhaitAt).length}
              themeColor={"third"}
            />
        </div>
        )
      }
    </MbjCardClassic>
  )
}

export const StatsPlDashCard = styled(StatsPlDashCardCtrl)`
  .bigNumbers{
  display:flex;
  flex-direction:row;
  align-items: stretch;
  justify-content: space-between;
  border-color: ${props=>props.theme.lightText};
  padding-bottom: 20px;
  flex-wrap: wrap;
    .BigNumberDash{
      flex-basis: 20%;
    }
    label{
      white-space: pre-line;
      text-align: center;
    }
  }
`
