import styled from "styled-components";
import {useQuery} from "@tanstack/react-query";

interface IogaWidgetCtrlProps{
  className?:string;
}

interface PromiseIOGA{
  id:string,
  accessName:string;
  accessLink:string;
  accessKeyExpireAt:string;
}

async function GetSrcIOGA():Promise<PromiseIOGA>{
  const token = window.localStorage.getItem("__user_token__");
  const body = {id:0}
  const response = await fetch(`${process.env["REACT_APP_API_URL"]}/ioga/geturl`, {
    method: 'GET',
    redirect: 'follow'
  })

  return await response.json()
}

const useGetMyIOGA = ()=>{
  return useQuery(["url_ioga"], ()=>GetSrcIOGA())
}

const IogaWidgetCtrl = (props:IogaWidgetCtrlProps)=>{
  const urlQuery = useGetMyIOGA();
  return (
    <div className={`ioga_wid ${props.className}`}>
      <h2>IOGA</h2>
      {urlQuery.isLoading ? 'chargement...' : urlQuery.isError ? 'erreur' :
        <iframe style={{display:"block", width:"100%"}} src={urlQuery.data.accessLink+"&hb=true"} width="850px" height="880px" id="results"  title={"ioga_frame"}/>
      }
    </div>
  )
}

export const IogaWidget = styled(IogaWidgetCtrl)`
  width: 100%;
`
