import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Operation, useGetMemberMe, useSavingMachEmployee } from "@mbj-front-monorepo/data-access";
import { MbjCollabProfil } from "../../../../components/organisms/MbjCollabProfil/MbjCollabProfilCard";
import { MbjButton, useModal } from "@mbj-front-monorepo/ui";
import { ExternalProfilConsultModal } from "./ExternalProfilConsultModal/ExternalProfilConsultModal";
import { NouveauMessageModal } from "../../../Messagerie/NouveauMessageModal/NouveauMessageModal";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface CollabProfilItemCtrlProps {
  className?: string;
  type?: string;
  MatchEmployee: GetElementType<GetElementType<Operation<"getRecrutementSavedCollection">>["MatchEmployees"]>;
  refMet?: number | null;
}

function CollabProfilItemCtrl(props: CollabProfilItemCtrlProps) {
  const [result, setResult] = useState<typeof props.MatchEmployee | null>(null);
  const [userToContact, setUserToContact] = useState<{ id: number; name: string } | null>(null);
  const [subjectDefault, setSubjectDefault] = useState("");
  const [textDefault, setTextDefault] = useState("");

  const memberMeQuery = useGetMemberMe();
  const mutationSwitchProfil = useSavingMachEmployee(props.MatchEmployee.id || -1);
  const { isShowing: isShowing, toggle: toggle } = useModal();
  const { isShowing: isShowingContact, toggle: toggleContact } = useModal();
  const handleContact = (Result: typeof props.MatchEmployee) => {
    const MyName = memberMeQuery.data ? memberMeQuery.data.name : "";
    const Emp = Result.Employee?.prenom + " " + Result.Employee?.nom;

    const subjectText = `Demande de contact à propos du profil ${Emp} de ${Result.Employee?.UserMetier?.Member?.name}`;
    setSubjectDefault(subjectText);
    const TextDefault = `<p>Bonjour je suis recruteur/recruteuse chez ${MyName}</p></br><p>J'ai identifié le profil <strong>${Emp}</strong> de ${Result.Employee?.UserMetier?.Member?.name}, à qui nous pourrions proposer des opportunités de recrutement intéressantes.</p><p>Pouvons-nous échanger à ce sujet prochainement ?</p></br><p>Bien cordialement</p>`;
    setResult(Result);
    setTextDefault(TextDefault);
    setUserToContact({
      id: Result.Employee?.UserMetier?.Member?.id || -1,
      name: Result.Employee?.UserMetier?.Member?.name || "",
    });
    toggleContact();
  };
  return (
    <MbjCollabProfil
      EmployeeId={props.MatchEmployee?.Employee?.id || 0}
      scoring={props.MatchEmployee.score ? `${Math.round(props.MatchEmployee.score * 10000) / 100}%` : ""}
    >
      <div className={"wrap-actions"}>
        {props.MatchEmployee.isSaved && (
          <MbjButton themeColor={"primary"} size={"xs"} onClick={() => handleContact(props.MatchEmployee)}>
            Contacter l'employeur
          </MbjButton>
        )}
        <MbjButton themeColor={"grey"} size={"xs"} onClick={toggle}>
          Consulter sa fiche
        </MbjButton>
        <MbjButton
          size={"xs"}
          themeColor={props.MatchEmployee.isSaved ? "warning" : "primary"}
          isPending={mutationSwitchProfil.isLoading}
          onClick={() => mutationSwitchProfil.mutate(!props.MatchEmployee.isSaved)}
        >
          {props.MatchEmployee.isSaved ? "Supprimer de la sélection" : "Retenir ce profil"}
        </MbjButton>
        {isShowing &&
          <ExternalProfilConsultModal
            isShowing={isShowing}
            hide={toggle}
            externalProfil={props.MatchEmployee.Employee}
            externalMember={props.MatchEmployee?.Employee?.UserMetier?.Member}
            type={props.type}
            refMet={props.refMet}
          />
        }
      </div>
      {result && userToContact && (
        <NouveauMessageModal
          isShowing={isShowingContact}
          hide={toggleContact}
          defaultDest={userToContact}
          defaultText={textDefault}
          defaultSubject={subjectDefault}
        />
      )}
    </MbjCollabProfil>
  );
}
export const CollabProfilItem = styled(CollabProfilItemCtrl)((props) => ({}));
