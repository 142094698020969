import { useQuery } from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { fetchApi } from "../../services/fetchApi";

export const useGetStatsEffectifs = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/member_effectif_statistiques", { method: "get", query: { Member: memberId || -1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member", memberId, "statsEffectif"], promise, {
    enabled: !!memberId,
  });
};

export const useGetStatsEffectifsUM = (idUM:number|null) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/member_effectif_statistiques", { method: "get", query: { Member: memberId || -1, UserMetier:idUM||-1 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member", idUM, "statsEffectif_um"], promise, {
    enabled: !!memberId && !!idUM,
  });
};
