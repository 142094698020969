import styled from "styled-components";
import {Operation, useGetProximiteReferentiel} from "@mbj-front-monorepo/data-access";
import React, {useMemo} from "react";
import {MbjLoading, MbjScrollArea, MbjTableV2, MbjWidgetClassic} from "@mbj-front-monorepo/ui";

interface ExpertSuiviWidgetCtrlProps{
  employee: Operation<"getEmployeeItem">;
  className?: string;
}
interface EmployeeExpert{
  employee: { prenom:string, nom:string, id:number },
  activites:{activite:Operation<"getActiviteItem">, niveau:number, id:number}[]
}

const ExpertSuiviWidgetCtrl = (props:ExpertSuiviWidgetCtrlProps)=>{
  if (!props.employee.id) throw new TypeError("employee.id is undefined");
  const useGetMobBilanQuery = useGetProximiteReferentiel("EUM", props.employee.id, props.employee?.UserMetier?.id);
  const myList = useMemo(()=>{
    if(!useGetMobBilanQuery.data){
      return [];
    } else {
      const myList:EmployeeExpert[] = []
      const ids:number[] = [];
      if(useGetMobBilanQuery.data.Missed){
        useGetMobBilanQuery.data.Missed.map(l=>{
          if(l.id){
            ids.push(l.id)
          }
          return l
        })
      }
      if(useGetMobBilanQuery.data.ToRised){
        useGetMobBilanQuery.data.ToRised.map(l=>{
          if(l.id){
            ids.push(l.id)
          }
          return l
        })
      }
      if(useGetMobBilanQuery.data.Experts?.map(e=>{
        const ActivitesCompare = e.activites;
        const ActivitesOk:{activite:Operation<"getActiviteItem">, niveau:number, id:number}[] = []
        ActivitesCompare?.map(a=>{
          if(ids.indexOf(a.activite?.id || 0)!==-1 && a.activite && a.niveau && a.id){
            ActivitesOk.push({activite: {...a.activite, Competence:undefined}, niveau:a.niveau, id:a.id})
          }
        })
        if(ActivitesOk.length >0 && e.employee){
          myList.push({employee: {prenom:e.employee.prenom || '', nom:e.employee.nom || '', id:e.employee.id || 0}, activites:ActivitesOk})
        }
      }))

      return myList.sort((a, b)=>a.activites.length > b.activites.length ? -1 : 1);

    }
  }, [useGetMobBilanQuery.data])

  return (
    <MbjWidgetClassic title="Expert pouvant former" className={props.className}>
      {useGetMobBilanQuery.isLoading ? (
        <MbjLoading />
      ) : useGetMobBilanQuery.isError || !useGetMobBilanQuery.data ? (
        <p>Erreur ...</p>
      ) : (
        <>
          {myList?.map((item, idx:number)=>(
            <div className={`oneExpert`} key={`OneExpert${idx}`}>
              <div className={"name_expert"}>{item.employee.prenom} {item.employee.nom}</div>
              <div className={`list_expertise`}>
                <ul>
                  {item.activites.map((acti, idxA:number)=>(
                    <li className={`one_expertise_acti`} key={`oea${idxA}`}>{acti.activite.libelle}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </>
      )}
    </MbjWidgetClassic>
  )
}


export const ExpertSuiviWidget = styled(ExpertSuiviWidgetCtrl)`
  .oneExpert{
    width: 100%;
    padding: 0.5rem;
    border-radius: 6px;
    background: white;
    .name_expert{
      font-weight: bold;
      font-size: 16px;
      border-bottom: solid black 1px;
      margin-bottom: 10px;
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style: none;
      }
    }
  }
`
