import React from "react";
import styled from "styled-components";
import {
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjLoading,
  MbjNoResult,
  MbjScrollArea,
} from "@mbj-front-monorepo/ui";
import { LineFormationCourte } from "./LineFormationCourte";
import { useGetEmployee, useGetFormationToMetier } from "@mbj-front-monorepo/data-access";

interface FormationCourteCardCtrlProps extends MbjCardClassicCtrlProps {
  className?: string;

  employeeId?: number;
  metierId?: number;
}

const FormationCourteCardCtrl = (props: FormationCourteCardCtrlProps) => {
  const employeeQuery = useGetEmployee(props.employeeId);
  const formationToQuery = useGetFormationToMetier(props.employeeId, props.metierId);

  if (employeeQuery.data && !employeeQuery.data.codePostal) {
    return (
      <MbjCardClassic
        className={`cardFC ${props.className}`}
        title={"Formations courtes pour les compétences spécifiques"}
        {...props}
      >
        <p>Pas de code postal renseigné</p>
      </MbjCardClassic>
    );
  }

  return (
    <MbjCardClassic
      className={`cardFC ${props.className}`}
      title={"Formations courtes pour les compétences spécifiques"}
      {...props}
    >
      {formationToQuery.isLoading ? (
        <MbjLoading />
      ) : formationToQuery.isError ? (
        <p>Informations non disponible</p>
      ) : (
        <MbjScrollArea maxHeight={"450px"}>
          {formationToQuery.data.FormationsCourtesMetier?.length === 0 && (
            <MbjNoResult text="Aucune formation courte" />
          )}
          {formationToQuery.data.FormationsCourtesMetier &&
            formationToQuery.data.FormationsCourtesMetier?.map((FC, idx: number) => (
              <LineFormationCourte key={FC.id} FormationCourte={FC} />
            ))}
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
};

export const FormationCourteCard = styled(FormationCourteCardCtrl)((props) => ({}));
