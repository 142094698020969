import styled from "styled-components";
import { useForm } from "react-hook-form";
import { MbjFlex, MbjIconButton, MbjSimpleInput } from "@mbj-front-monorepo/ui";
import { IoSend } from "react-icons/io5";
import {components, useAddConversation} from "@mbj-front-monorepo/data-access";

interface SendMessageFormProps {
  className?: string;
  roomId: string;
  AddMess:(m:components["schemas"]["Conversation-conversation.read_read.Member"])=>void;
}

const SendMessageFormCtrl = (props: SendMessageFormProps) => {
  const addConversationMutation = useAddConversation(props.roomId);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <form
      className={props.className}
      onSubmit={handleSubmit((data) => {
        props.AddMess(data)
        addConversationMutation.mutate(data);
      })}
    >
      <MbjFlex align={"center"}>
        <MbjSimpleInput
          name={"content"}
          register={register}
          className={"content_input"}
          registerOptions={{ required: true }}
        />
        <MbjIconButton
          type={"submit"}
          icon={<IoSend />}
          ariaLabel={"Envoyer"}
          isRound
          themeColor={"grey"}
          isPending={addConversationMutation.isLoading}
        />
      </MbjFlex>
    </form>
  );
};

export const SendMessageForm = styled(SendMessageFormCtrl)((props) => ({
  ".content_input": {
    flexGrow: 1,
  },
}));
