import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {BsFillArrowLeftSquareFill} from "react-icons/bs";
import {useGetFormations} from "@mbj-front-monorepo/data-access";
import {ExpertOne} from "../../Collaborateurs/CollaborateurDetail/Suivi/ExpertSuivi/ExpertSuiviOneCompetenceWidget";
import {
  OneResultOF
} from "../../Collaborateurs/CollaborateurDetail/Suivi/AutoFormation/EmployeeAutoFormationOneCompetence";
import {MbjLoading, MbjModal, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import {ExpertCard} from "../../Collaborateurs/CollaborateurDetail/Suivi/ExpertSuivi/ExpertCard";
import {ExpertWidget} from "./ExpertWidget";
import {CoordOF} from "../../Collaborateurs/CollaborateurDetail/Suivi/Components/CoordOF";
import {orgaFormation} from "../../Collaborateurs/CollaborateurDetail/Suivi/AutoFormation/EmployeeAutoFormation";
import {IogaWidget} from "./IogaWidget";

interface ModalFormationActiviteCtrlProps{
  className?:string;
  activite:{id:number, libelle:string, niveau:number}
  open:boolean;
  closeMe:()=>void;
  Experts?:ExpertOne[];
}

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const ModalFormationActiviteCtrl = (props:ModalFormationActiviteCtrlProps)=>{
  const FormationsQuery = useGetFormations(props.activite.id);
  const [state, setState] = useState(props.open ? VISIBLE : HIDDEN);
  const { isShowing, toggle } = useModal();
  const [ofCurr, setOfCurr] = useState<orgaFormation|null>(null)
  const ClickOF = (OF:any)=>{
    if(OF) {
      setOfCurr({
        siret: OF.siret || 'nc',
        email: OF.email || 'nc',
        adresse: OF.adresse || 'nc',
        codePostal: OF.Zipcode || 'nc',
        tel: OF.tel || 'nc',
        contact: OF.contact || 'nc',
        nom: OF.rs,
        ville: OF.ville
      })
      toggle();
    } else {
      alert("impossible de récupérer OF")
    }
  }
  const myList = useMemo(()=>{
    const list:OneResultOF[] = [];
    if(FormationsQuery.data){
      FormationsQuery.data.OFActivite?.map(item=>{
        const Formation:string[] = []
        item.formationsCourtesForm?.map(fcc=>{
          const ActivitesDispense = fcc.activites?.map(a=>a.id);
          if(ActivitesDispense && ActivitesDispense.indexOf(props.activite.id)!==-1){
            Formation.push(fcc.formationContinuCourtes?.libelle || 'nom inconnu')
          }
        })
        if(Formation.length>0){
          list.push({
            name:item.organismeFormation?.rs||'nom inconnu',
            distance:`${item.distance||'nc'}`,
            formation:Formation,
            OF:item.organismeFormation
          })
        }
      })
    }
    return list;
  }, [FormationsQuery.data, props.activite.id])
  useEffect(()=>{
    if(!props.open){
      setState(LEAVING)
    } else{
      setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
    }
  }, [props.open])
  useEffect(()=>{
    if(state === LEAVING){
      const timer = setTimeout(()=>{
        setState(HIDDEN)
      }, 300)
      return ()=>{
        clearTimeout(timer);
      }
    } else if (state === ENTERING){
      const sf = document.body.offsetHeight
      setState(VISIBLE);
    }
  }, [state])


  const styleModal = {transitionDuration: `300ms`, transitionProperty:"width", width:"100%"}
  if(state!==VISIBLE){
    styleModal.width = "0";
  }

  if(state === HIDDEN){
    return null;
  } else {
    return (
      <div className={`Modal_suivi_competence ${props.className}`} style={styleModal}>
        <div className={`header`}>
          <div className={"Close"} onClick={()=>props.closeMe()}>
            <div className={`wrap_close`}><BsFillArrowLeftSquareFill/></div>
          </div>
          <div className={"lib"}>Former à {props.activite.libelle}</div>
        </div>
        <div className={`body`}>
          <MbjWidgetClassic title={`Organismes de formation pouvant former à cette compétence`}>
            {FormationsQuery.isLoading ? (
              <MbjLoading />
            ) : FormationsQuery.isError || !FormationsQuery.data ? (
              <p>Erreur ...</p>
            ) : (
              myList.length === 0 ?
                <div className={`no_result`}>Aucune formation trouvée pour cette compétence</div>:
                myList.map((item, idx:number)=>(
                  <div className={`one_of_competence`} key={`of_one_${idx}`}>
                    <div className={"titre_of"} onClick={()=>ClickOF(item.OF)}>{item.name} ({item.distance}km)</div>
                    <div className={`list_formation`}>
                      {item.formation.map((f, idxF:number)=>(
                        <div className={`one_form_of`} key={`O_f${idx}${idxF}`}>{f}</div>
                      ))}
                    </div>
                  </div>
                ))
            )}
          </MbjWidgetClassic>
          {props.Experts &&
            <MbjWidgetClassic title={`Experts pouvant former à cette compétence`}>
              {props.Experts.length == 0 &&
                <p>Aucun expert trouvé pour cette compétence</p>
              }
              {props.Experts.map((item, idx)=>(
                <ExpertWidget key={`on_expert_one${idx}`} Expert={item} idActivite={props.activite.id}/>
              ))}
            </MbjWidgetClassic>
          }
          <MbjWidgetClassic title={"formation vidéos IOGA"}>
            <IogaWidget/>
          </MbjWidgetClassic>
        </div>
        <MbjModal hide={toggle} isShowing={isShowing} title="Coordonnées organisme formation">
          <div className={`wrap_coord`}>
            <CoordOF titre={"Nom"} infos={<span>{ofCurr?.nom}</span>}/>
            <CoordOF titre={"Adresse"} infos={<span>{ofCurr?.codePostal} {ofCurr?.codePostal}</span>}/>
            <CoordOF titre={"Téléphone"} infos={<span>{ofCurr?.tel}</span>}/>
            {ofCurr && ofCurr.email!=='nc' &&
              <CoordOF titre={"Email"} infos={<div className={`link_mail`} onClick={(e) => {window.location.href ='mailto:'+ofCurr?.email;}}>{ofCurr.email}</div>}/>
            }
          </div>
        </MbjModal>
      </div>
    )
  }
}

export const ModalFormationActivite = styled(ModalFormationActiviteCtrl)`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .one_of_competence{
    width: 100%;
    background: #f5f1f1;
    padding: 1rem;
    margin-bottom: 10px;
    border-radius: 6px;
    .titre_of{
      font-weight: bold;
      margin-bottom: 10px;
      border-bottom: solid black 1px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .header{
    width: 100%;
    display: flex;
    padding: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .Close, .wrap_close{
      font-size: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .wrap_close{
      &:hover{
        cursor: pointer;
      }
    }
    .lib{
      flex-grow: 1;
      font-size: 22px;
      color:${props=>props.theme.primary};
    }
  }
  .body{
    flex-grow: 1;
    padding: 1rem;
    height: 0;
    overflow-y: auto;
  }
`
