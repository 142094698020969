import styled from "styled-components";
import {components, Operation, useGetBesoinsFormationEntreprise} from "@mbj-front-monorepo/data-access";
import {useLocation} from "react-router-dom";
import {useMemo, useRef, useState} from "react";
import {ExpertOne} from "../Collaborateurs/CollaborateurDetail/Suivi/ExpertSuivi/ExpertSuiviOneCompetenceWidget";
import {MbjLoading, MbjNavPath, MbjScrollArea, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {GrClose} from "react-icons/gr";
import {OneBlockCompetence} from "./Components/OneBlockCompetence";
import {ModalFormationActivite} from "./Components/ModalFormationActivite";
import * as React from "react";
import {
  EmployeesExpertsCard
} from "../Collaborateurs/CollaborateurDetail/Suivi/EmployeesExpertsCard/EmployeesExpertsCard";
import {OneBlockService} from "./Components/OneBlockService";
import {OneItemByService} from "./BesoinsParServices";
import {OneBlockUserMetier} from "./Components/OneBlockUserMetier";

interface BesoinsParUserMetierCtrlProps{
  className?:string;
}


export interface OneEmployeeNeed{
  Employee:{id:number, name:string},
  niveau:number;
  niveauTarget:number,
  idUserMetier:number,
  Activite:{id:number, libelle:string, idCompetence:number}
  Competence:{id:number, libelle:string},
}

export interface OneActiviteNeed{
  Activite:{id:number, libelle:string, idCompetence:number}
  EmployeesInActi:OneEmployeeNeed[]
}

export interface OneItemByUserMetier{
  Competence:{id:number, libelle:string},
  Activites:OneActiviteNeed[]
}

export interface UserMetierBlock{
  UserMetier:{id:number, libelle:string},
  listCompetence:OneItemByUserMetier[],
  nb:number
}

const BesoinsParUserMetierCtrl = (props:BesoinsParUserMetierCtrlProps)=>{
  const location = useLocation();
  const refSearch = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>('')
  const BesoinsQuery = useGetBesoinsFormationEntreprise();
  const [openD, setOpenD] = useState(false);
  const [actiCurr, setActiCurr] = useState<{id:number, libelle:string, niveau:number}|null>(null)
  const [ExpertsCurr, setExpertsCurr] = useState<ExpertOne[]>([])
  const myList = useMemo(()=>{
    const list:UserMetierBlock[] = [];
    if(BesoinsQuery.data){
      const regex = new RegExp(""+search+"", 'gi');
      const Employees:(components["schemas"]["Employee-read.BesoinFormationEntreprise_read.Activite_read.employeExpert_read.Employee.detailed_read.EmployeeNeedsForm_read.Competence_read.Famille_read.Domaine_read.Services.Detailed_read.UserMetier_read.Metier"])[] = [];
      const EmployesNF:OneEmployeeNeed[] = [];
      BesoinsQuery.data.forEach(b=>{
        const Emps = b.EmployeeNeedFormation;
        if(Emps) {
          Emps.forEach(e => {
            if(e.Employees && e.Employees.UserMetier) {
              EmployesNF.push({
                Employee: {id: e.Employees?.id || 0, name: `${e.Employees.prenom} ${e.Employees.nom}`},
                niveau:e.niveau||0, niveauTarget:e.cible||0,
                idUserMetier:e.Employees?.UserMetier?.id||0, Activite: {
                  id:e.Activite?.id || 0,
                  libelle:e.Activite?.libelle||'',
                  idCompetence:e.Activite?.Competence?.id||0
                },
                Competence:{id:e.Activite?.Competence?.id||0, libelle:e.Activite?.Competence?.libelle||''}
              })
              Employees.push(e.Employees)
            }
          })
        }
      })
      const UserMetier:{id:number, libelle:string}[] = []
      Employees.forEach(e=> {
        if(e.UserMetier && e.UserMetier.id && e.UserMetier.namePerso){
          UserMetier.push({id : e.UserMetier.id, libelle:e.UserMetier.namePerso})
        }
      })
      const UserMetierSSD = UserMetier.reduce((acc:{id:number, libelle:string}[], item:{id:number, libelle:string})=>acc.map(a=>a.id).indexOf(item.id) === -1 ? [...acc, item] : acc, [])
      UserMetierSSD.forEach(s=>{
        let nb = 0;
        if(search === '' || s.libelle.match(search)) {
          const EmloyeesConcernedUserMetier: OneEmployeeNeed[] = EmployesNF.filter(e => e.idUserMetier === s.id)
          const CompetencesConcerned = EmloyeesConcernedUserMetier.map(e=>e.Competence);
          const listCompetence = CompetencesConcerned.reduce((acc:{id:number, libelle:string}[], item:{id:number, libelle:string})=>acc.map(a=>a.id).indexOf(item.id) === -1 ? [...acc, item] : acc, [])
          const TabComp:OneItemByUserMetier[] = [];
          listCompetence.forEach(c=>{
            const EmloyeesConcernedCompetence: OneEmployeeNeed[] = EmloyeesConcernedUserMetier.filter(e => e.Competence.id === c.id)
            const ActiviteConcerned = EmloyeesConcernedCompetence.map(e=>e.Activite);
            const listActivite = ActiviteConcerned.reduce((acc:{id:number, libelle:string}[], item:{id:number, libelle:string})=>acc.map(a=>a.id).indexOf(item.id) === -1 ? [...acc, item] : acc, [])
            const TabActivites:OneActiviteNeed[] = [];
            listActivite.forEach(a=>{
              const EmployeesConcernedActivite: OneEmployeeNeed[] = EmloyeesConcernedCompetence.filter(e => e.Activite.id === a.id)
              nb +=EmployeesConcernedActivite.length;
              TabActivites.push(
                {Activite:{id:a.id, libelle:a.libelle, idCompetence:c.id}, EmployeesInActi:EmployeesConcernedActivite},
              )
            })
            TabComp.push({Competence:{id:c.id, libelle:c.libelle}, Activites:TabActivites})
          })
          list.push({UserMetier:{id:s.id, libelle:s.libelle}, listCompetence:TabComp, nb:nb})
        }
      })
    }
    return list;
  }, [BesoinsQuery.data, search])
  const SwitchOpen = ()=>{
    setOpenD(o=>!o);
  }
  const ClickActi = (item:{id:number, libelle:string, niveau:number})=>{
    const Besoins = BesoinsQuery.data?.find(b=>b.activite?.id === item.id);
    if(Besoins){
      const ExpertsThis = Besoins.Experts;
      if(ExpertsThis) {
        setExpertsCurr(ExpertsThis.map(e => {
          return {
            employee: {id: (e.employee?.id||0), prenom: (e.employee?.prenom||''), nom:  (e.employee?.nom||''), service: ''},
            niveau: 0
          }
        }))
      }
    }
    setActiCurr(item);
    SwitchOpen();
  }
  const handleChangeSearch = ()=>{
    if(refSearch.current){
      setSearch(refSearch.current.value);
    }
  }
  return (
    <div className={`formation_entreprise ${props.className}`}>
      <MbjWidgetClassic title="Recherche de compétences" className={props.className}>
        {BesoinsQuery.isLoading ? (
          <MbjLoading />
        ) : BesoinsQuery.isError || !BesoinsQuery.data ? (
          <p>Erreur ...</p>
        ) : (
          <>
            <div style={{height:"530px", width:"100%"}}>
              <div className={"Zone_search"}>
                <input value={search} onChange={handleChangeSearch} ref={refSearch} placeholder={"Recherche de métier interne"}/>
                {search!=='' &&
                  <div className={`wrap_close_search`} onClick={()=>setSearch('')}>
                    <GrClose/>
                  </div>
                }
              </div>
              <MbjScrollArea overflowX={"auto"}>
                {myList.map(item=>(
                  <OneBlockUserMetier key={`one_bsoiSev${item.UserMetier.id}`} BlockUserMetier={item} ClickActi={ClickActi}/>
                ))}
              </MbjScrollArea>
            </div>
          </>
        )}
      </MbjWidgetClassic>
      <ModalFormationActivite
        open={openD}
        closeMe={SwitchOpen}
        activite={actiCurr ? actiCurr : {id:0, libelle:'nc', niveau:0}}
        Experts={ExpertsCurr}
      />
    </div>
  )
}

export const BesoinsParUserMetier = styled(BesoinsParUserMetierCtrl)`
  .Zone_search{
    width: 100%;
    padding: 0.5rem 0;
    position: relative;
    input{
      width: 100%;
      padding: 0 0.5rem;
      border: solid #ccc 1px;
      border-radius: 4px;
      height: 36px;
      font-size: 17px;
    }
    .wrap_close_search{
      position: absolute;
      right: 10px;
      top:50%;
      transform: translateY(-50%);
      background: red;
      color:white;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
      svg{
        margin: auto;
        stroke: white;
        color:white;
        fill:white;
        path{
          stroke: white;
        }
      }
    }
  }
  .Employee_need{
    padding: 0.5rem;
  }
  .details{
    padding: 0.25rem 0.5rem;
    background: ${props=>props.theme.primaryPastel};
    border-radius: 4px;
    &:hover{
      cursor: pointer;
    }
  }
`
