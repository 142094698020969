import styled from "styled-components";
import {
  components,
  Operation,
  useGetMemberMetierReclassement,
  useModifyStatutSearchRecl
} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjCirclePercent, MbjLoading, MbjMemberCard, MbjModal, useModal} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import {useParams} from "react-router-dom";

interface CardReclassementFindCtrlProps{
  className?:string;
  idSearch:number;
  idStatut:number;
  score:number|undefined;
  scoreWhenUp:number|null|undefined;
  MemberMetierReclassement:components["schemas"]["MemberMetierReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|undefined,
}

const CardReclassementFindCtrl = (props:CardReclassementFindCtrlProps)=>{
  const { id } = useParams();
  const mutation = useModifyStatutSearchRecl(props.idSearch)
  const { isShowing, toggle } = useModal();
  const memberMetierReclassementQuery = useGetMemberMetierReclassement(props.MemberMetierReclassement?.id||undefined);
  const concernedMember = memberMetierReclassementQuery.data?.UserMetier?.Member;
  const ModifyMe = (idStatut:number)=>{
    const datas:Operation<"putResultsSearchEmployeeItem", "requestBody">={
      statut:`/api/statut_results_searches/${idStatut}`
    }
    mutation.mutate((datas))
  }
  return (
    <div className={`card_reclassement_find ${props.className}`}>
      {props.MemberMetierReclassement && props.score &&
        <>
          <div className={`score`}>
            <MbjCirclePercent
              percent={Math.round(props.score*100)}
            />
          </div>
          <div className={`infos_reclassement`}>
            {memberMetierReclassementQuery.isLoading || memberMetierReclassementQuery.isLoading ? (
              <MbjLoading />
            ) : memberMetierReclassementQuery.isError || memberMetierReclassementQuery.isError ? (
              <p>Erreur ...</p>
            ) : (
              <MbjMemberCard logo={concernedMember?.logo || ""} name={concernedMember?.name}>
                <div className={"metierReclasse"}>
                  <span className="infos-name me-2">Métier reclassé :</span>
                  {memberMetierReclassementQuery.data?.UserMetier?.namePerso}
                </div>
                <div className={"metierReclasse"}>
                  <span className="infos-name me-2">Nombre :</span>
                  {memberMetierReclassementQuery.data?.volume}
                </div>
              </MbjMemberCard>
              )}
          </div>
          <div className={`wrap_actions`}>
            <div className={`wrap_btn`}>
              <MbjButton size={"sm"} grow={1} isPending={mutation.isLoading} onClick={toggle}>
                Consulter
              </MbjButton>
            </div>
            <div className={`wrap_btn`}>
              <MbjButton size={"sm"} themeColor={"warning"} grow={1} isPending={mutation.isLoading} onClick={()=>ModifyMe(3)}>
                Rejeter
              </MbjButton>
            </div>
            {props.idStatut !== 2 &&
              <div className={`wrap_btn`}>
                <MbjButton size={"sm"} themeColor={"third"} grow={1} isPending={mutation.isLoading} onClick={()=>ModifyMe(2)}>
                  Sauvegarder
                </MbjButton>
              </div>
            }
          </div>
        </>
      }
      {isShowing && props.score && memberMetierReclassementQuery.data && (
        <MbjModal isShowing={isShowing} hide={toggle} maxWidth={"65%"} title={"Comparaison des compétences"}>
          <MbjCompareReferentiel
            idToCompare1={memberMetierReclassementQuery.data.UserMetier?.id || -1}
            idToCompare2={id ? parseInt(id) : 0}
            typeCompared={"UMUM"}
          />
        </MbjModal>
      )}
    </div>
  )
}

export const CardReclassementFind = styled(CardReclassementFindCtrl)`
  width: 100%;
  padding: 0.5rem;
  background: white;
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 12px;
  .score{
    width: 90px;
    height: 90px;
  }
  .infos_reclassement{
    flex-grow: 1;
  }
  .wrap_actions{
    .wrap_btn{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 4px;
    }
  }
`
