import styled from "styled-components";
import {useGetMemberAlliances} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {AllianceCard} from "./AllianceCard";

interface AlliancesGroupCtrlProps{
  className?:string;
}

const AlliancesGroupCtrl = (props:AlliancesGroupCtrlProps)=>{
  const alliancesQuery = useGetMemberAlliances();
  const AlliancesFiltered = useMemo(()=>{
    if(alliancesQuery.data){
      return alliancesQuery.data.filter(a=>a.isActive)
    } else {
      return [];
    }
  },  [alliancesQuery.data])
  return (
    <div className={`alliance_group ${props.className}`}>
      {alliancesQuery.isLoading ? <MbjLoading/>:
        AlliancesFiltered.map(item=>(
            <AllianceCard key={`One_alliance_card${item.Alliance?.id||0}`} Alliance={item}/>
        ))
      }
    </div>
  )
}
export const AlliancesGroup = styled(AlliancesGroupCtrl)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
`
