import { GetElementType } from "@mbj-front-monorepo/utils";
import { Operation, useDeleteService, useGetMemberMe, useMutateService } from "@mbj-front-monorepo/data-access";
import {
  MbjButton,
  MbjCardClassic,
  MbjFlex,
  MbjListItem,
  MbjModal,
  MbjModalCtrlProps,
  MbjNoResult,
  MbjScrollArea,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import * as React from "react";

interface ModifierServiceModalCtrlProps extends MbjModalCtrlProps {
  Service: GetElementType<Operation<"getServicesCollection">>;
}

const ModifierServiceModalCtrl = (props: ModifierServiceModalCtrlProps) => {
  const memberQuery = useGetMemberMe();

  const mutationService = useMutateService(props.Service.id || -1, memberQuery.data?.id);
  const deleteService = useDeleteService(memberQuery.data?.id || -1);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Operation<"putServicesItem", "requestBody">>({
    mode: "onChange",
    defaultValues: props.Service,
  });

  const onSubmit = (data: Operation<"putServicesItem", "requestBody">) => {
    mutationService.mutate(data);
  };

  return (
    <MbjModal {...props} title={"Modification du service"} fitContent>
      <MbjFlex direction={"column"} gap={"12px"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MbjSimpleInputContainer errors={errors} label={"Libellé"}>
            <MbjSimpleInput
              name={"libelle"}
              id={"libelle"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Libellé requis",
                },
                minLength: {
                  value: 4,
                  message: "Nécessite au moins 4 caractères",
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjButton isPending={mutationService.isLoading}>Enregistrer</MbjButton>
        </form>
        <MbjCardClassic title={"Employés : "+props.Service?.Employees?.length} themeColor={"secondary"} noPadding>
          {props.Service?.Employees && props.Service?.Employees?.length > 0 ? (
            <MbjScrollArea maxHeight={"200px"}>
              {props.Service.Employees?.map((employee, index) => (
                <MbjListItem key={employee.id} to={"../../collaborateurs/" + employee.id}>
                  <span>
                    {employee.nom?.toUpperCase()} {employee.prenom}
                  </span>
                </MbjListItem>
              ))}
            </MbjScrollArea>
          ) : (
            <MbjNoResult text={"Aucun employé"} />
          )}
        </MbjCardClassic>
        {props.Service?.Employees?.length === 0 ?
          <MbjButton
            onClick={() => deleteService.mutate(props.Service.id || -1)}
            isPending={deleteService.isLoading}
            themeColor={"warning"}
            className={"deleteMetier"}
          >
            Supprimer
          </MbjButton>:
          <p className={"alerte_no_empty"}>Le service n'est pas vide, il n'est pas possible de le supprimer</p>
        }
      </MbjFlex>
    </MbjModal>
  );
};

export const ModifierServiceModal = styled(ModifierServiceModalCtrl)((props) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "9px",
  },
  button: {
    alignSelf: "flex-end",
  },
  ".alerte_no_empty":{
    color:"red",
    textAlign:"center"
  }
}));
