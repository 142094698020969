import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";

export const useSearchActivites = (search: string) => {
  const promise = () => fetchApi("/api/activites", { method: "get", query: { libelle: search } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["search_activites", search], promise, {
    enabled: search !== "",
  });
};

export const useGetFormations = (id:number)=>{
  const promise = ()=> fetchApi("/api/activite_formations/{id}", {method:"get", params: { id: id + "" }})
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["activite_formation", id], promise, {
    enabled: id !== 0,
  });
}
