import * as React from "react";
import styled from "styled-components";
import { MbjCardClassic, MbjCardClassicCtrlProps, MbjLoading, MbjScrollArea } from "@mbj-front-monorepo/ui";
import { MainCompetenceItem } from "./mainCompetenceItem/mainCompetenceItem";
import { useGetTopCompetences } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos sur les principales compétences de l'entreprise :
 */
interface CompetencesPrincipalesCardCtrlProps extends MbjCardClassicCtrlProps {}

function CompetencesPrincipalesCardCtrl(props: CompetencesPrincipalesCardCtrlProps) {
  const topCompetencesQuery = useGetTopCompetences();

  return (
    <MbjCardClassic title="Principales compétences de l'entreprise" noPadding {...props}>
      {topCompetencesQuery.isLoading ? (
        <MbjLoading />
      ) : topCompetencesQuery.isError ? (
        <p>Error !</p>
      ) : (
        <MbjScrollArea maxHeight={"100%"}>
          {topCompetencesQuery.data?.map((competence, i: number) =>
            i > 4 ? (
              <React.Fragment key={competence.id} />
            ) : (
              <MainCompetenceItem competence={competence} key={competence.id} />
            )
          )}
        </MbjScrollArea>
      )}
    </MbjCardClassic>
  );
}
export const CompetencesPrincipalesCard = styled(CompetencesPrincipalesCardCtrl)((props) => ({}));
