import { useState } from 'react';

export const useAutocomplete = <T>(defaultValue?: T) => {
  const [selectedValue, setValue] = useState<T | undefined>(defaultValue);

  function selectValue(selectValue: T) {
    setValue(selectValue);
  }
  function clearValue() {
    setValue(undefined);
  }
  return {
    selectedValue,
    selectValue,
    clearValue,
  };
};
