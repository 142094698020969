import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi } from "../../services/fetchApi";
import { useGetMemberMe } from "../member";

export const useMutateProposeReclassementEmployee = (relassementEmployeeId: number) => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;

  return useMutation(
    (isProposed: boolean) => {
      return fetchApi("/api/reclassement_employees/{id}", {
        method: "put",
        params: { id: relassementEmployeeId + "" },
        json: { isProposed },
      });
    },
    {
      onSuccess: (newReclassementEmployee) => {
        queryClient.invalidateQueries(["member_metier_reclassements", { memberId }]);
        toast.success(
          newReclassementEmployee.isProposed ? "Profil proposé !" : "Profil supprimé des propositions !",
          {}
        );
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useMutateAcceptReclassementEmployee = (relassementEmployeeId: number) => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;

  return useMutation(
    (isAccepted: boolean) => {
      return fetchApi("/api/reclassement_employees/{id}", {
        method: "put",
        params: { id: relassementEmployeeId + "" },
        json: { isAccepted },
      });
    },
    {
      onSuccess: (newReclassementEmployee) => {
        queryClient.invalidateQueries(["member_metier_reclassements", { memberId }]);
        toast.success(
          newReclassementEmployee.isAccepted
            ? "Profil accepté. Le profil sera désormais visible auprès des membres de vos alliances !"
            : "Le profil n'est plus visible auprès des membres de vos alliances !",
          {}
        );
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
