import {components, fetchApi} from "@mbj-front-monorepo/data-access";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";

export const useGetAllNoteResPartBySearch = (id:number)=>{
  const promise = () => fetchApi("/api/notes_results_particuliers", {method:'get', query:{ResultSearch:id+""}});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["notes_results_part", { id }], promise, {
    enabled: id!==0,
  });
}

export const usePostNewNotParticulier = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data:components["schemas"]["NotesResultsParticulier-notesresultpart.post"]) => {
      return fetchApi("/api/notes_results_particuliers", {
        method: "post",
        json: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notes_results_part"]);
        toast.success("Note enregistrée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const usePutNewNotParticulier = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: {id:number, datas:components["schemas"]["NotesResultsParticulier-notesresultpart.write"] }) => {
      return fetchApi("/api/notes_results_particuliers/{id}", {
        method: "put",
        params:{id:data.id+""},
        json: data.datas,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notes_results_part"]);
        toast.success("Note modifiée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const useDeleteNewNotParticulier = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (id:number) => {
      return fetchApi("/api/notes_results_particuliers/{id}", {
        method: "delete",
        params: {id:id+""},
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notes_results_part"]);
        toast.success("Note supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}
