import React from "react";
import styled from "styled-components";
import { GiPathDistance } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";
import { IoLocationSharp, IoSchoolSharp } from "react-icons/io5";
import { MbjOpenCloseDetails } from "@mbj-front-monorepo/ui";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface LineFormationContinuMetierCtrlProps {
  FormationContinuMetier: GetElementType<
    GetElementType<Operation<"getFormationMetierCollection">>["FormationContinusMetier"]
  >;
  className?: string;
}

const LineFormationContinuMetierCtrl = ({ className, FormationContinuMetier }: LineFormationContinuMetierCtrlProps) => {
  return (
    <div className={`LFM ${className}`}>
      <MbjOpenCloseDetails hidePrefixe={true} fontSize={"13px"} text={FormationContinuMetier.libelle || ""}>
        <ul className="list-unstyled">
          {FormationContinuMetier.OrganismeFormationDistants?.map((item, idx: number) => (
            <li key={item.id}>
              <div className="one-OF">
                <div className={"name-of"}>
                  <IoSchoolSharp />
                  <span className={"ms-2"}>{item.rs}</span>
                </div>
                <div className={"infos-of"}>
                  <div className="one-info">
                    <GiPathDistance />
                    <span>{item.distance ? Math.round(item.distance) : ""}km</span>
                  </div>
                  {item.siteWeb !== null && (
                    <div className="one-info">
                      <CgWebsite />
                      <a target={"_blank"} href={item.siteWeb}>{item.siteWeb}</a>
                    </div>
                  )}
                  <div className="one-info">
                    <IoLocationSharp />
                    <span>{item.adresse ? item.adresse : item.codePostal + " " + item.ville}</span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </MbjOpenCloseDetails>
    </div>
  );
};
export const LineFormationContinuMetier = styled(LineFormationContinuMetierCtrl)((props) => ({
  //marginBottom:"10px",
  padding: "0.5rem",
  borderRadius: "0.25rem",
  "&.opened": {
    background: "#ccc",
  },
  ".one-OF": {
    marginBottom: "10px",
    marginLeft: "15px",
    fontSize: "14px",
    ".name-of": {
      fontWeight: "bold",
      textDecoration: "underline",
      marginBottom: "5px",
    },
    ".one-info": {
      marginLeft: "10px",
      "span, a": {
        marginLeft: "10px",
      },
    },
  },
}));
