import React from "react";
import styled from "styled-components";
import { MbjLoading } from "../MbjLoading/MbjLoading";
import { BaseColorsTheme } from "../../../assets/styles/ThemeType";
import { BaseButonOptions } from "./button-types";
import { FlexItemOptions, omitFlexProps, useFlexItem } from "../../flex-utils";

interface MbjButtonCtrlProps extends FlexItemOptions, BaseButonOptions {
  children: React.ReactNode;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance du bouton sur la page :
   */
  themeColor?: BaseColorsTheme;
  /**
   * De quelle taille le bouton doit être ?
   */
  size?: "xs" | "sm" | "md" | "lg";
  /**
   * En train de travailler ?
   */
  isPending?: boolean;

  leftIcon?: React.ReactNode;

  rightIcon?: React.ReactNode;
  margin?:string;
  isfull?:boolean;
}
function MbjButtonCtrl(props: MbjButtonCtrlProps) {
  const { themeColor, size, children, isPending, leftIcon, rightIcon, ...rest } = omitFlexProps(props);
  return (
    <button {...rest} disabled={props.isPending ? props.isPending : props.disabled}>
      {props.leftIcon}
      <span>{props.children}</span>
      {props.isPending ? <MbjLoading isSpinner height={"100%"} className={"spinner"} /> : <React.Fragment />}
      {props.rightIcon}
    </button>
  );
}

/**
 * Composant principal d'interaction utilisateur :
 */
export const MbjButton = styled(MbjButtonCtrl)((props) => ({
  cursor: "pointer",
  width:props.isfull ? "100%" : "max-content",
  display: props.isfull ? "block" : "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "0.4em",
  transition: "transform ease-in 0.09s",
  gap: "0.3rem",
  height: "fit-content",
  fontWeight: "600",
  border: "1px solid transparent",
  color: props.theme.background2,
  padding: props.size === "xs" ? "0.35rem" : props.size === "sm" ? "0.4rem" : props.size === "md" ? "0.5rem" : "0.7rem",
  backgroundColor: props.themeColor ? props.theme[props.themeColor] : "none",
  fontSize: props.size ? props.theme.fontSize[props.size] : "none",
  position: "relative",
  margin:props.margin ? props.margin : "0",
  "& > :not(.spinner)": {
    visibility: props.isPending ? "hidden" : "visible",
  },
  "& > .spinner": {
    position: "absolute",
    padding: "inherit",
  },

  "&:focus-visible": {
    outline: "2px solid blue",
  },

  "&:hover, &:focus": {
    backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Darken`] : "none",
    border: "1px solid " + (props.themeColor ? props.theme[props.themeColor] : "none"),
  },

  "&:active": {
    transform: "scale(0.9)",
    border: "none",
    boxShadow: "0px 0px 4px 4px " + (props.themeColor ? props.theme[`${props.themeColor}Darken`] : "none"),
    backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Lighten`] : "none",
  },
  "&:disabled": {
    transform: "none",
    border: "1px solid transparent",
    boxShadow: "none",
    backgroundColor: props.themeColor ? props.theme[`${props.themeColor}Pastel`] : "none",
  },

  ...useFlexItem(props),
}));
MbjButton.defaultProps = {
  themeColor: "primary",
  size: "md",
  isPending: false,
};
