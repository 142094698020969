import { getDateTimeFR, GetElementType } from "@mbj-front-monorepo/utils";
import { Operation } from "@mbj-front-monorepo/data-access";
import styled from "styled-components";
import { MbjAvatar, MbjFlex } from "@mbj-front-monorepo/ui";
import React from "react";

interface MessageCtrlProps {
  className?: string;
  message: GetElementType<Operation<"getConversationCollection">>;
  mine?: boolean;
}

const MessageCtrl = (props: MessageCtrlProps) => {
  return (
    <div className={`one_message ${props.className} ${props.mine ? 'mine' : 'yours'}`}>
      <div className={"wrapper_message"}>
        <MbjAvatar
          name={props.message.member?.name}
          bg={"white"}
          src={`${process.env.REACT_APP_API_URL_ROOT}/${props.message.member?.logoUrl}`}
          size={"md"}
          border={"solid 1px #ccc"}
        />
        <div className={"message_content"}>
          <div className={"name"}>{props.message.member?.name}</div>
          <div className={"content"}>{props.message.content}</div>
          <div className={"date"}>{getDateTimeFR(new Date(props.message.created_at || ""))}</div>
        </div>
      </div>
    </div>
  );
};

export const Message = styled(MessageCtrl)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  &.mine{
    justify-content: flex-end;
  }
  .wrapper_message{
    width: 40%;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
  }
  .message_content{
    flex-grow: 1;
    .content{
      padding: 0.5rem;
      background: rgb(123,207,217);
      background: linear-gradient(180deg, rgba(123,207,217,1) 0%, rgba(100,171,180,1) 100%);
      border-radius: 12px;
      color: black;
    }
  }
`
