import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DefaultTheme } from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { EntretienAnnuelFormData } from "../../EntretienAnnuelNewForm";

interface AnalyseCompetencesPdfProps {
  formData?: EntretienAnnuelFormData;
  formPasts?: Operation<"getFormationEmployeeCollection">;
  theme: DefaultTheme;
}

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create Document Component
export const AnalyseCompetencesPdf = (props: AnalyseCompetencesPdfProps) => {
  function getFormationPast(id: number) {
    if (!props.formPasts) {
      console.log("no formPasts", props.formPasts);
      return;
    }
    return props.formPasts.find((formPast) => formPast.id === id);
  }

  // Create styles
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      borderWidth: 1,
      borderColor: props.theme.primary,
    },
    headerContainer: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      backgroundColor: props.theme.primaryLighten,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
      fontSize: 10,
      // verticalAlign: "middle",
    },

    formPastLibelle: {
      width: "40%",
    },
    formPastDate: {
      width: "20%",
    },
    formPastEval: {
      width: "40%",
    },

    label: {
      marginLeft: 8,
      marginBottom: 8,
      fontSize: 14,
      color: props.theme.lightText,
    },
    row: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      fontSize: 10,
      fontStyle: "bold",
      textAlign: "center",
    },
    cell: {
      borderRightColor: props.theme.primary,
      borderRightWidth: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    listItem: {
      fontSize: 10,
    },
  });

  if (!props.formData?.FormPast && !props.formData && !props.formPasts) {
    return <React.Fragment />;
  }
  return (
    <>
      {props.formData?.FormPast && (
        <>
          <Text style={styles.label}>Formation(s) suivie(s) au cours de l'année écoulée</Text>
          <View style={styles.tableContainer} wrap={false}>
            <View style={styles.headerContainer}>
              <View style={[styles.cell, styles.formPastLibelle]}>
                <Text>Libellé</Text>
              </View>
              <View style={[styles.cell, styles.formPastDate]}>
                <Text>Date</Text>
              </View>
              <View style={[styles.cell, styles.formPastEval]}>
                <Text>Évaluation de la formation</Text>
              </View>
            </View>
            {props.formData.FormPast.map((formationPastform, index) => {
              const formationEmployee = getFormationPast(parseInt(formationPastform.id));

              if (!formationEmployee) {
                return <React.Fragment key={formationPastform.id} />;
              }
              //console.log(index, activite, activiteEntretien);
              return (
                <View style={styles.row} key={index} wrap={false}>
                  <View style={[styles.cell, styles.formPastLibelle]}>
                    <Text>{formationEmployee.nomPerso}</Text>
                  </View>
                  <View style={[styles.cell, styles.formPastDate]}>
                    <Text>
                      {formationEmployee.formationAt
                        ? new Date(formationEmployee.formationAt).toLocaleDateString()
                        : ""}
                    </Text>
                  </View>
                  <View style={[styles.cell, styles.formPastEval]}>
                    <Text>{formationPastform.appreciation}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </>
      )}
      {props.formData?.Skill && props.formData.Skill.length > 0 && (
        <>
          <Text style={styles.label}>Points forts du salarié</Text>
          {props.formData.Skill.map((skill, index) => (
            <Text style={styles.listItem} key={index}>
              - {skill.coment}
            </Text>
          ))}
        </>
      )}
      {props.formData?.ToRise && props.formData.ToRise.length > 0 && (
        <>
          <Text style={styles.label}>Axes d'améliorations du salarié</Text>
          {props.formData.ToRise.map((toRise, index) => (
            <Text style={styles.listItem} key={index}>
              - {toRise.coment}
            </Text>
          ))}
        </>
      )}
    </>
  );
};
