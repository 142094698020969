import React from "react";
import { MbjConfirmModal, MbjIconButton, MbjRange, MbjRangeStatic, useModalHook } from "@mbj-front-monorepo/ui";
import { BsTrash } from "react-icons/bs";
import styled from "styled-components";

interface MbjLineActiviteCtrlProps {
  className?: string;
  libelle: string;
  niveau: number;
  isEditable?: boolean;
  /**
   * Fonction de callback pour la modification du niveau d'une activite
   *
   */
  changeLevelFunction?: (level: number) => void;
  /**
   * Fonction de callback pour la confirmation
   */
  trashFunction?: any;
  modalTrash?: useModalHook;
  modifyLoading?: boolean;
  trashLoading?: boolean;
}

const MbjLineActiviteCtrl = (props: MbjLineActiviteCtrlProps) => {
  return (
    <>
      <div className={props.className}>
        <span className={"libelle"}>{props.libelle}</span>
        {props.isEditable ? (
          <span className={"interactContainer"}>
            <MbjRange
              nbPoints={5}
              currentPoint={props.niveau}
              onClick={props.changeLevelFunction}
              interactIsLoading={props.modifyLoading}
            />
            {props.modalTrash && (
              <MbjIconButton
                onClick={props.modalTrash.toggle}
                ariaLabel={"Supprimer cette compétence"}
                icon={<BsTrash />}
                themeColor={"warning"}
                size={"sm"}
                isRound={true}
              />
            )}
          </span>
        ) : (
          <MbjRangeStatic nbPoints={5} currentPoint={props.niveau} />
        )}
      </div>
      {props.modalTrash && (
        <MbjConfirmModal
          isShowing={props.modalTrash.isShowing}
          hide={props.modalTrash.toggle}
          title="Confirmation suppression compétence"
          confirmText={"Voulez-vous vraiment supprimer cette compétence ?"}
          confirmFunction={props.trashFunction}
          isLoading={props.trashLoading}
        />
      )}
    </>
  );
};

export const MbjLineActivite = styled(MbjLineActiviteCtrl)((props) => ({
  display: "flex",
  padding:"10px",
  justifyContent: "space-between",
  alignItems:"center",
  fontSize: "0.9em",
  flexWrap: "wrap",
  ":hover":{
    backgroundColor:"#fdf9f9",
  },
  ".libelle": {
    flex: "1 1 175px",
    color:props.theme.dark,
    paddingLeft:"64px",
    fontSize:"15px"
  },

  ".interactContainer": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "30px",
  },
}));
