import * as React from "react";
import styled from "styled-components";
import {
  MbjAutocomplete,
  MbjButton,
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjFlex,
  MbjLoading,
  MbjOption,
  MbjScrollArea,
  MbjTooltip,
  MbjTooltipArrow,
  MbjTooltipContent,
  MbjTooltipTrigger,
  useAutocomplete,
} from "@mbj-front-monorepo/ui";
import { useParams } from "react-router-dom";
import {
  useAddBadgeEmployee,
  useDeleteBadge,
  useGetBadges,
  useGetBadgesEmployee,
  useGetMemberMe,
} from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface BadgesCardCtrlProps extends MbjCardClassicCtrlProps {
  className?: string;
}

export const BadgesCardCtrl = (props: BadgesCardCtrlProps) => {
  const { id } = useParams();
  const employeeBadgesQuery = useGetBadgesEmployee(id ? parseInt(id) : -1);
  const memberQuery = useGetMemberMe();
  const badgesQuery = useGetBadges();
  const addBadgeMutation = useAddBadgeEmployee(id ? parseInt(id) : -1);
  const deleteBadgeMutation = useDeleteBadge(id ? parseInt(id) : -1);
  const useAutoCompleteHook = useAutocomplete<GetElementType<typeof badgesQuery.data>>();

  const HandleClickBadge = () => {
    const idBadge = useAutoCompleteHook.selectedValue?.id;
    const idMemberCreate = memberQuery.data?.id;
    if (idBadge && idMemberCreate) {
      addBadgeMutation.mutate({ idBadge, idMemberCreate });
    }
  };

  return (
    <MbjCardClassic title={props.title} className={props.className}>
      {employeeBadgesQuery.isLoading ? (
        <MbjLoading />
      ) : employeeBadgesQuery.isError ? (
        <p>Error...</p>
      ) : (
        <MbjFlex direction={"column"} gap={"10px"}>
          <div className={`wrap_add_badge`}>
            <div style={{flexGrow:1}}>
              <MbjAutocomplete
                placeholder={"Sélectionnez un badge à ajouter"}
                className={"SearchBar"}
                suggestionsQuery={badgesQuery}
                autocompleteHooks={useAutoCompleteHook}
                foldAfterChoose={true}
                name={"badge"}
                labelKey={"libelle" as keyof unknown}
              />
            </div>
            {useAutoCompleteHook.selectedValue &&
              <MbjButton onClick={HandleClickBadge} isPending={addBadgeMutation.isLoading} alignSelf={"flex-end"}>
                Ajouter
              </MbjButton>
            }
          </div>
          <MbjScrollArea maxHeight={"150px"}>
            <MbjFlex direction={"column"} gap={"6px"} wrap={"nowrap"}>
              {employeeBadgesQuery.data?.map((BE, idx) => (
                <MbjTooltip delayDuration={75} key={BE.id}>
                  <MbjTooltipTrigger>
                    <MbjOption onClick={(e) => deleteBadgeMutation.mutate(BE?.id || -1)}>{BE.Badge?.libelle}</MbjOption>
                  </MbjTooltipTrigger>
                  <MbjTooltipContent sideOffset={5}>
                    Supprimer ce badge
                    <MbjTooltipArrow />
                  </MbjTooltipContent>
                </MbjTooltip>
              ))}
            </MbjFlex>
          </MbjScrollArea>
        </MbjFlex>
      )}
    </MbjCardClassic>
  );
};

export const BadgesCard = styled(BadgesCardCtrl)((props) => ({
  ".wrap_add_badge":{
    padding:"20px 0",
    display:"flex",
    justifyContent:"flex-start",
    gap:"5px",
    borderBottom:`solid 1px ${props.theme.greyPastel}`,
    alignItems:"center"
  },
  ".contentCard": {
    paddingTop: "5px",

    ".SearchBar": {
      marginTop: "8px",
      marginBottom: "5px",
    },
    ".containerButton": {
      display: "flex",
      justifyContent: "end",
      marginBottom: "8px",
    },
  },
}));
