
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {GrClose} from "react-icons/gr";

interface MbjModal2CtrlProps{
  className?:string;
  open:boolean
  children:React.ReactNode
  closeMe:()=>void;
  titre:string;
  Wd?:string;
}

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const MbjModal2Ctrl = ({className, open, children, closeMe, titre, Wd}:MbjModal2CtrlProps)=>{
  // const childrenRef = useRef<ReactNode>(children)
  const [state, setState] = useState(open ? VISIBLE : HIDDEN);
  // if(open){
  //     childrenRef.current = children
  // }
  useEffect(()=>{
    if(!open){
      setState(LEAVING)
    } else{
      setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
    }
  }, [open])
  useEffect(()=>{
    if(state === LEAVING){
      const timer = setTimeout(()=>{
        setState(HIDDEN)
      }, 300)
      return ()=>{
        clearTimeout(timer);
      }
    } else if (state === ENTERING){
      const xx = document.body.offsetHeight
      setState(VISIBLE);
    }
    return;
  }, [state])

  const style = {transitionDuration: `300ms`, transitionProperty:"opacity", opacity:1}
  const styleModal = {transitionDuration: `300ms`, transitionProperty:"width", width:Wd ? Wd : "90%"}
  if(state!==VISIBLE){
    style.opacity = 0;
    styleModal.width = "0";
  }


  if(state === HIDDEN){
    return null;
  } else {
    return ReactDOM.createPortal(
      <div className={`timed_modal ${className}`}>
        <div style={style} className={`back-drop`}/>
        <div className={`modal_content`} style={styleModal}>
          <div className={`header_modal`}>
            <div className={`titre_modal`}>{titre}</div>
            <div className="close_modal" onClick={()=>closeMe()}>
              <GrClose/>
            </div>
          </div>
          <div className={`body_modal`}>
            {children}
          </div>
        </div>
      </div>, document.body
    )
  }

}

export const MbjModal2 = styled(MbjModal2Ctrl)`
    .back-drop{
      position:fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.7);
      z-index: 9;
    }
  .modal_content{
    height: auto;
    max-height: 92vh;
    background: white;
    width: ${props=>props.Wd ? props.Wd : "90%"};
    border-radius: 12px;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .body_modal{
      overflow: hidden;
      flex-grow: 1;
      //height: 0;
      max-height: calc(100% - 33px);
      overflow-y: auto;
    }
    .header_modal{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0.5rem;
      align-content: center;
      .titre_modal{
        flex-grow: 1;
        font-weight: bold;
      }
      .close_modal{
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
`
