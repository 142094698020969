import * as React from "react";
import styled from "styled-components";
import { MbjNavPath } from "@mbj-front-monorepo/ui";
import { useLocation } from "react-router-dom";

interface FormationsIogaCtrlProps {
  className?: string;
}

function FormationsIogaCtrl(props: FormationsIogaCtrlProps) {
  const location = useLocation();
  return (
    <div className={"FormationsIoga" + props.className}>
      <MbjNavPath routePath={location.pathname} />
    </div>
  );
}
export const FormationsIoga = styled(FormationsIogaCtrl)((props) => ({
  padding: "1% 2%",
}));
