import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import { DefaultTheme } from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import { EntretienAnnuelFormData } from "../../EntretienAnnuelNewForm";

interface RealisedAndFixedObjectifsPdfProps {
  formData?: EntretienAnnuelFormData;
  objectifsEntretien?: Operation<"getObjectifsEntretienCollection">;
  theme: DefaultTheme;
}

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create Document Component
export const RealisedAndFixedObjectifsPdf = (props: RealisedAndFixedObjectifsPdfProps) => {
  function getObjectifEntretien(id: number) {
    if (!props.objectifsEntretien) {
      console.log("no objectifsEntretien", props.objectifsEntretien);
      return;
    }
    return props.objectifsEntretien.find((objectifEntretien) => objectifEntretien.id === id);
  }

  // Create styles
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      borderWidth: 1,
      borderColor: props.theme.primary,
    },
    headerContainer: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      backgroundColor: props.theme.primaryLighten,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
      fontSize: 10,
      //verticalAlign: "middle",
    },

    libelle: {
      width: "35%",
    },
    niveauReal: {
      width: "15%",
    },
    commentaire: {
      width: "50%",
    },
    NewObjLibelle: { width: "33%" },
    NewObjProgression: { width: "33%" },
    NewObjMoyen: { width: "34%" },

    label: {
      marginLeft: 8,
      marginBottom: 8,
      fontSize: 14,
      color: props.theme.lightText,
    },
    row: {
      flexDirection: "row",
      borderBottomColor: props.theme.primary,
      borderBottomWidth: 1,
      alignItems: "stretch",
      minHeight: 24,
      fontSize: 10,
      fontStyle: "bold",
      textAlign: "center",
    },
    cell: {
      borderRightColor: props.theme.primary,
      borderRightWidth: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    listItem: {
      fontSize: 10,
    },
  });

  if (!props.formData?.ObjPast && !props.formData && !props.objectifsEntretien) {
    return <React.Fragment />;
  }
  return (
    <>
      {props.formData?.ObjPast && (
        <>
          <Text style={styles.label}>Objectifs pour la période évaluée</Text>
          <View style={styles.tableContainer}>
            <View style={styles.headerContainer}>
              <View style={[styles.cell, styles.libelle]}>
                <Text>Libellé</Text>
              </View>
              <View style={[styles.cell, styles.niveauReal]}>
                <Text>Réalisation</Text>
              </View>
              <View style={[styles.cell, styles.commentaire]}>
                <Text>Commentaire</Text>
              </View>
            </View>
            {props.formData.ObjPast.map((ObjPastForm, index) => {
              const objectifEmployee = getObjectifEntretien(parseInt(ObjPastForm.id));

              if (!objectifEmployee) {
                return <React.Fragment key={ObjPastForm.id} />;
              }
              //console.log(index, activite, activiteEntretien);
              return (
                <View style={styles.row} key={ObjPastForm.id} wrap={false}>
                  <View style={[styles.cell, styles.libelle]}>
                    <Text>{objectifEmployee.libelle}</Text>
                  </View>
                  <View style={[styles.cell, styles.niveauReal]}>
                    <Text>{ObjPastForm.resultat}</Text>
                  </View>
                  <View style={[styles.cell, styles.commentaire]}>
                    <Text>{ObjPastForm.coment}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </>
      )}
      {props.formData?.NewObj && props.formData.NewObj.length > 0 && (
        <>
          <Text style={styles.label}>Objectifs pour la période suivante</Text>
          <View style={styles.tableContainer}>
            <View style={styles.headerContainer}>
              <View style={[styles.cell, styles.NewObjLibelle]}>
                <Text>Libellé</Text>
              </View>
              <View style={[styles.cell, styles.NewObjProgression]}>
                <Text>Progression envisagée</Text>
              </View>
              <View style={[styles.cell, styles.NewObjMoyen]}>
                <Text>Moyens matériels</Text>
              </View>
            </View>
            {props.formData.NewObj.map((NewObj, index) => {
              return (
                <View style={styles.row} key={index} wrap={false}>
                  <View style={[styles.cell, styles.NewObjLibelle]}>
                    <Text>{NewObj.libelle}</Text>
                  </View>
                  <View style={[styles.cell, styles.NewObjProgression]}>
                    <Text>{NewObj.progression}</Text>
                  </View>
                  <View style={[styles.cell, styles.NewObjMoyen]}>
                    <Text>{NewObj.moyens}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </>
      )}
    </>
  );
};
