import * as React from "react";
import { MbjLoading, MbjTable } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useGetTopCompetences } from "@mbj-front-monorepo/data-access";

/**
 * Carte contenant des infos sur les à développer :
 */
interface CompetencesTransmissiblesTableCtrlProps {}

const Titles = [
  { libelle: "Compétences à transmettre", Align: "isLeft" },
  { libelle: "Effectif expert", Align: "isCenter" },
];

function CompetencesTransmissiblesTableCtrl(props: CompetencesTransmissiblesTableCtrlProps) {
  const topCompetencesQuery = useGetTopCompetences();

  return (
    <MbjTable titles={Titles} scrollable maxHeight={"200px"}>
      {topCompetencesQuery.isLoading ? (
        <tr>
          <td colSpan={Titles.length}>
            <MbjLoading />
          </td>
        </tr>
      ) : topCompetencesQuery.isError ? (
        <tr>
          <td colSpan={Titles.length}>
            <p>Erreur...</p>
          </td>
        </tr>
      ) : (
        topCompetencesQuery.data?.map((competence, idx: number) => (
          <tr key={competence.id}>
            <td>{competence.Activite?.libelle}</td>
            <td className="isCenter">{competence.effectif}</td>
          </tr>
        ))
      )}
    </MbjTable>
  );
}
export const CompetencesTransmissiblesTable = styled(CompetencesTransmissiblesTableCtrl)((props) => ({}));
