import styled, {useTheme} from "styled-components";
import {
  MbjCardClassic,
  MbjFlex,
  MbjLabel,
  MbjLoading,
  MbjNavPath,
  MbjNoResult,
  MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import {MbjEmployeeInformationsCard} from "@mbj-front-monorepo/data-ui";
import {CompetencesCard} from "../Collaborateurs/CollaborateurDetail/Dashboard/CompetencesCard";
import {BadgesCard} from "../Collaborateurs/CollaborateurDetail/Dashboard/BadgesCard";
import * as React from "react";
import {useLocation, useParams} from "react-router-dom";
import {Operation, useGetEmployees, useGetStatsEffectifsUM, useGetUserMetier} from "@mbj-front-monorepo/data-access";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useMemo} from "react";

interface EffectifDashboardCtrlProps{
  className?:string;
}

const EffectifDashboardCtrl = (props:EffectifDashboardCtrlProps)=>{
  const location = useLocation();
  const { id } = useParams();
  const UserMetierQuery = useGetUserMetier(id ? parseInt(id) : undefined)
  const statsEffectifQuery = useGetStatsEffectifsUM(id ? parseInt(id) : null)
  const EmployeesQuery = useGetEmployees();
  const EmployeeIn = useMemo(()=>{
    const list:Operation<"getEmployeeItem">[] = [];
    if(EmployeesQuery.data && UserMetierQuery.data){
      const EmployeeFilter = EmployeesQuery.data.filter(e=>e.UserMetier && e.UserMetier.id === UserMetierQuery.data.id)
      EmployeeFilter.map(e=>{
        list.push(e);
        return e;
      })
    }
    return list;
  }, [UserMetierQuery.data, EmployeesQuery.data])
  const theme = useTheme();
  return (
    <div className={"DashEffectif " + props.className}>
      {UserMetierQuery.isLoading || statsEffectifQuery.isLoading ? (
        <MbjLoading />
      ) : UserMetierQuery.isError || statsEffectifQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 2,
              label: UserMetierQuery.data?.namePerso?.toUpperCase(),
            }}
          />
          <MbjWidgetClassic title="Information sur le métier" bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
              <div className={`wrap_infos`}>
                <div className={`name_metier`}>Nom du métier : {UserMetierQuery.data.namePerso}</div>
                <div className={`cible`}>Format Cible : {UserMetierQuery.data.formatCible}</div>
                <div className={`Format_actuel`}>Format Actuel : {EmployeeIn.length}</div>
                {UserMetierQuery.data.formatCibleAt &&
                  <div className={`cible_at`}>Date Cible : {new Date(UserMetierQuery.data.formatCibleAt).toLocaleDateString()}</div>
                }
              </div>
          </MbjWidgetClassic>
          <MbjWidgetClassic title="Evolution des effectifs" bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
            <div className="graphContainer">
              <MbjLabel>Evolution des effectifs</MbjLabel>
              <ResponsiveContainer width="94%" height={250}>
                <LineChart data={statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].evolutions : []}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip />
                  {/*
                                    <Legend />
                                    */}
                  <Line type="monotone" dataKey="effectif" stroke={theme.complementary} />
                </LineChart>
              </ResponsiveContainer>
            </div>

          </MbjWidgetClassic>
        </>
      )}
    </div>
  );
}

export const EffectifDashboard = styled(EffectifDashboardCtrl)`
  padding: 1% 2%;
  .graphContainer{
    padding: 1rem;
    background: white;
    border-radius: 12px;
  }
`
