import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { NotFound } from "../../NotFound/NotFound";
import { InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { FaUserCircle } from "react-icons/fa";
import {useGetMemberAlliances, useGetMemberMe, useGetParticulier} from "@mbj-front-monorepo/data-access";
import {DashboardOneCvTheque} from "./Dashboard/DashboardOneCvTheque";
import {ComparateurPartUserMetier} from "./Comparateur/ComparateurPartUserMetier";
import {CompatibiliteOneCvTheque} from "./Compatibilite/CompatibiliteOneCvTheque";
import {useEffect, useState} from "react";
import {InformationsCvTheque} from "../MaCvTheque/InformationsCvTheque";
import {CvCvTheque} from "../MaCvTheque/CvCvTheque";

interface CvThequeDetailCtrlProps {
  className?: string;
}

function CvThequeDetailCtrl(props: CvThequeDetailCtrlProps) {
  const { id } = useParams();
  const particulierQuery = useGetParticulier(parseInt(id || "-1"));
  const [isMine, setIsMine] = useState(false);
  const memberQuery = useGetMemberMe();
    useEffect(() => {
        if(memberQuery.data && particulierQuery.data){
            console.log(particulierQuery.data.MemberId === memberQuery.data.id);
            setIsMine(particulierQuery.data.MemberId === memberQuery.data.id)
        }
    }, [memberQuery.data, particulierQuery.data]);
  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="Entreprise"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + props.className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {particulierQuery.data ? (
                      <>
                        <FaUserCircle className={"icon-profile-menu"} />
                        <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                          {particulierQuery.data?.nom?.toUpperCase() + " " + particulierQuery.data?.prenom}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                <MbjLink to="dashboard">Tableau de bord</MbjLink>
                {isMine && <MbjLink to="setting_info">Informations</MbjLink>}
                {isMine && <MbjLink to="cv">Cv</MbjLink>}
                <MbjLink to="compatibilite">Compatibilité</MbjLink>
                <MbjLink to="comparateur/0">Comparateur</MbjLink>
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path="" element={<Navigate to="dashboard" />} />
        <Route path={"/compatibilite"} element={<CompatibiliteOneCvTheque/>}/>
        <Route path={"/dashboard"} element={<DashboardOneCvTheque/>}/>
        <Route path={"/setting_info"} element={<InformationsCvTheque/>}/>
        <Route path={"/cv"} element={<CvCvTheque/>}/>
        <Route path={"/comparateur/:idUserMetier"} element={<ComparateurPartUserMetier/>}/>
        {/*<Route path="/dashboard" element={<Dashboard />} />*/}
        {/*<Route path="/informations" element={<Informations />} />*/}
        {/*<Route path="/competences" element={<Competences />} />*/}
        {/*<Route path="/cv" element={<CV />} />*/}
        {/*<Route path="/mobilites" element={<Mobilites />} />*/}
        {/*<Route path="/mobilites_internes" element={<MobilitesInterne />} />*/}
        {/*<Route path="/mobilites/:idMetier" element={<MobiliteDetails />} />*/}
        {/*{<Route path="/suivi" element={<Suivi />} />}*/}
        {/*{<Route path="/entretien" element={<EntretienAnnuel />} />}*/}
        {/*{<Route path="/entretien/new" element={<EntretienAnnuelNew />} />}*/}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
export const CvThequeDetail = styled(CvThequeDetailCtrl)((props) => ({
  minHeight: "fit-content",
  justifyContent: "space-between",

  ".custom-content-menu": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5em",

    ".icon-profile-menu": {
      fill: props.theme.primaryDarken,
      height: "25px",
      width: "25px",
    },
  },
}));
