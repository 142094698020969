import styled from "styled-components";
import {useGetMember, useGetMemberMetierReclassement} from "@mbj-front-monorepo/data-access";
import {AvatarFact, MbjLoading} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface InformationsReclassementCtrlProps{
  className?:string;
  idRecl:number;
}

const InformationsReclassementCtrl = (props:InformationsReclassementCtrlProps)=>{
  const ReclassementQuery = useGetMemberMetierReclassement(props.idRecl, true);
  const handleError = (e:any)=>{
    e.target.src = AvatarFact;
  }
  return (
    <div className={`infos_recl ${props.className}`}>
      <div className={"wrap_infos_line"}>
        {ReclassementQuery.isLoading ? (<MbjLoading/>) : ReclassementQuery.isError ? 'Erreur datas' :
          <>
            <div className={"Avatar_place"}>
              <img
                src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${ReclassementQuery.data.UserMetier?.Member?.logo}`}
                alt={"avatar_logo"}
                onError={handleError}
              />
            </div>
            <div className={"wrap_infos"}>
              <div className={"name_place"}>{ReclassementQuery.data.UserMetier?.Member?.name}</div>
              <div className={"Métier info_2"}><span>Métier reclassé : </span><span>{ReclassementQuery.data.UserMetier?.namePerso}</span></div>
              <div className={"Volume info_2"}><span>Volume : </span><span>{ReclassementQuery.data.volume}</span></div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export const InformationsReclassement = styled(InformationsReclassementCtrl)`
  padding: 0.5rem 1.5rem;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  .info_2{
    span{
      font-size: 16px;
      &:first-child{
        font-weight: bold;
      }
      &:last-child{
        color:${props => props.theme.primary};
      }
    }
  }
  .name_place{
    font-size: 18px;
    color:${props => props.theme.primary};
    font-weight: bold;
  }
  .wrap_infos_line{
    display: flex;
    justify-content: flex-start;
    .Avatar_place{
      width: 160px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      img{
        width: 130px;

      }
    }
    .wrap_infos{
      flex-grow: 1;
    }
  }
`
