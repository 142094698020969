import * as React from "react";
import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { FieldValues, Path, RegisterOptions, UseFormRegister } from "react-hook-form";

export interface MbjSimpleInputCtrlProps<TFormValues extends FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Classe HTML du conteneur de l'input
   */
  className?: string;
  /**
   * Identifiant HTML du champ d'input
   */
  id?: string;

  /**
   * Couleur utilisée pour représenter l'ordre d'importance de l'input :
   */
  themeColor?: "primary";
  /**
   * Nom de l'attribut correspondant à l'input dans l'objet data (lors de la soumission du formulaire)
   */
  name: Path<TFormValues>;
  /**
   * doit-il y avoir des marges internes ?
   */
  noPadding?: boolean;

  register: UseFormRegister<TFormValues>;
  setValue?: any;
  trigger?: any;

  registerOptions?: RegisterOptions;

  width?: string;
  height?: string;
}

function MbjSimpleInputCtrl<TFormValues extends FieldValues>(props: MbjSimpleInputCtrlProps<TFormValues>) {
  const { className, themeColor, register, name, noPadding, setValue, trigger, registerOptions, ...rest } = props;

  return <input className={props.className} {...props.register(props.name, registerOptions)} {...rest} />;
}

/**
 * Représente un input :
 */
export const MbjSimpleInput = styled(MbjSimpleInputCtrl)((props) => ({
  display: "block",
  border: `${props.theme.greyLighten} solid 1px`,
  borderRadius: "4px",
  padding: "0.5rem 1rem",
  fontSize: "1rem",
  width: `${props.width} !important`,
  height: `${props.height} !important`,

  "&:focus": {
    border: `${props.theme.greyLighten} solid 2px`,
  },
})) as typeof MbjSimpleInputCtrl;
//
// MbjSimpleInput.defaultProps = {
//   themeColor: "primary",
//   type: "text",
//   noPadding: false,
// };
