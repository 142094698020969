import * as React from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import {
  MbjButton,
  MbjCard,
  MbjCardBody,
  MbjFlex,
  MbjLoading,
  MbjNavPath,
  MbjNoResult,
  MbjScrollArea,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjStaticInfo,
  MbjWidgetClassic,
} from "@mbj-front-monorepo/ui";
import { getDateFR } from "@mbj-front-monorepo/utils";
import { CollabProfilItem } from "./CollabProfilItem/CollabProfilItem";
import { useGetRecrutementSaved, usePostRecrutementSaved } from "@mbj-front-monorepo/data-access";
import { ReclassementAllianceItemCard } from "../../Alliances/ReclassementsAlliances/ReclassementAllianceItemCard/ReclassementAllianceItemCard";
import { FiRefreshCw } from "react-icons/fi";
import { useForm } from "react-hook-form";

interface MesRecherchesCtrlProps {
  className?: string;
}

function MesRecherchesCtrl(props: MesRecherchesCtrlProps) {
  const { id } = useParams();
  const rechercheDetailQuery = useGetRecrutementSaved(parseInt(id as string));
  const location = useLocation();
  const mutationRefresh = usePostRecrutementSaved();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  return (
    <div className={"MesRecherches" + props.className}>
      {rechercheDetailQuery.isLoading ? (
        <MbjLoading />
      ) : rechercheDetailQuery.isError ? (
        "erreurs"
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabel={{
              index: 3,
              label: rechercheDetailQuery.data.Metier
                ? rechercheDetailQuery.data.Metier.libelle
                : rechercheDetailQuery.data.UserMetier
                ? rechercheDetailQuery.data.UserMetier.namePerso
                : "",
            }}
          />
          <MbjFlex basis={"100%"} gap={"10px"}>
            <MbjWidgetClassic title="Détail de votre recherche" basis={"100%"}>
              <MbjCard grow={1}>
                <MbjCardBody>
                  <MbjFlex justify={"space-between"}>
                    <MbjFlex direction={"column"}>
                      <MbjStaticInfo
                        label={"Métier recherché"}
                        info={
                          rechercheDetailQuery.data.Metier
                            ? rechercheDetailQuery.data.Metier.libelle
                            : rechercheDetailQuery.data.UserMetier
                            ? rechercheDetailQuery.data.UserMetier.namePerso
                            : ""
                        }
                      />
                      <MbjStaticInfo
                        label={"Date de la recherche"}
                        info={
                          rechercheDetailQuery.data.creationAt
                            ? getDateFR(new Date(rechercheDetailQuery.data.creationAt))
                            : ""
                        }
                      />
                    </MbjFlex>
                    <form
                      onSubmit={handleSubmit((data) => {
                        if (rechercheDetailQuery.data) {
                          const newData = rechercheDetailQuery.data.UserMetier
                            ? {
                                UserMetier: "/api/user_metiers/" + rechercheDetailQuery.data.UserMetier?.id,
                                pcLimit: data.pcLimit / 100,
                              }
                            : {
                                Metier: "/api/metiers/" + rechercheDetailQuery.data.Metier?.id,
                                pcLimit: data.pcLimit / 100,
                              };
                          console.log(newData);
                          mutationRefresh.mutate(newData);
                        }
                      })}
                    >
                      <MbjFlex direction={"column"}>
                        <MbjSimpleInputContainer errors={errors} direction={"row"} label={"Filtrer la proximité"}>
                          <MbjSimpleInput
                            name={"pcLimit"}
                            register={register}
                            required
                            type={"number"}
                            width={"70px"}
                            defaultValue={
                              rechercheDetailQuery.data.pcLimit ? rechercheDetailQuery.data.pcLimit * 100 : 70
                            }
                            registerOptions={{
                              min: { value: 0, message: "Le taux de proximité minimal ne peut être négatif" },
                              max: { value: 100, message: "Le taux de proximité maximal ne peut être supérieur à 100" },
                              valueAsNumber: true,
                            }}
                          />
                        </MbjSimpleInputContainer>
                        <MbjButton isPending={mutationRefresh.isLoading} leftIcon={<FiRefreshCw />}>
                          Rafraîchir la recherche
                        </MbjButton>
                      </MbjFlex>
                    </form>
                  </MbjFlex>
                </MbjCardBody>
              </MbjCard>
            </MbjWidgetClassic>

            <MbjWidgetClassic title="Reclassements correspondants" basis={"100%"}>
              {rechercheDetailQuery.data.MatchReclassements?.length === 0 ? (
                <MbjNoResult text="Aucun reclassement trouvé" />
              ) : (
                <MbjScrollArea maxHeight={"450px"}>
                  {rechercheDetailQuery.data.MatchReclassements?.sort((m1, m2) => {
                    if (m1.score && m2.score) return m1.score < m2.score ? 1 : m1.score > m2.score ? -1 : 0;
                    else return 0;
                  }).map((matchReclassement, idx: number) => (
                    <ReclassementAllianceItemCard
                      memberMetierReclassementId={matchReclassement.MemberMetierReclassement?.id || -1}
                      score={matchReclassement.score}
                      metierSearchedId={rechercheDetailQuery.data.Metier?.id}
                      userMetierSearchId={rechercheDetailQuery.data.UserMetier?.id}
                      MatchEmployees={matchReclassement.MatchEmployees}
                      key={matchReclassement.id}
                      contactTo={"./../../../messagerie"}
                    />
                  ))}
                </MbjScrollArea>
              )}
            </MbjWidgetClassic>
            <MbjWidgetClassic title="Profils trouvés" basis={"100%"}>
              {rechercheDetailQuery.data.MatchEmployees?.length === 0 ? (
                <MbjNoResult text="aucun profil trouvé" />
              ) : (
                <MbjScrollArea maxHeight={"350px"}>
                  <MbjFlex direction={"column"} gap={"10px"}>
                    {rechercheDetailQuery.data.MatchEmployees?.filter((matchEmployee) => !matchEmployee.isSaved)
                      .sort((m1, m2) => {
                        if (m1.score && m2.score) return m1.score < m2.score ? 1 : m1.score > m2.score ? -1 : 0;
                        else return 0;
                      })
                      .map((matchEmployee) => (
                        <CollabProfilItem
                          MatchEmployee={matchEmployee}
                          key={matchEmployee.id}
                          type={rechercheDetailQuery.data.Metier ? "Metier" : "UserMetier"}
                          refMet={
                            rechercheDetailQuery.data.Metier
                              ? rechercheDetailQuery.data.Metier.id
                              : rechercheDetailQuery.data.UserMetier
                              ? rechercheDetailQuery.data.UserMetier.id
                              : null
                          }
                        />
                      ))}
                  </MbjFlex>
                </MbjScrollArea>
              )}
            </MbjWidgetClassic>
            <MbjWidgetClassic title="Profils Retenus" basis={"100%"}>
              {rechercheDetailQuery.data.MatchEmployees?.length === 0 ? (
                <MbjNoResult text="aucun profil trouvé" />
              ) : (
                <MbjScrollArea maxHeight={"350px"}>
                  <MbjFlex direction={"column"} gap={"10px"}>
                    {rechercheDetailQuery.data.MatchEmployees?.filter((matchEmployee) => matchEmployee.isSaved).map(
                      (matchEmployee) => (
                        <CollabProfilItem
                          MatchEmployee={matchEmployee}
                          key={matchEmployee.id}
                          type={rechercheDetailQuery.data.Metier ? "Metier" : "UserMetier"}
                          refMet={
                            rechercheDetailQuery.data.Metier
                              ? rechercheDetailQuery.data.Metier.id
                              : rechercheDetailQuery.data.UserMetier
                              ? rechercheDetailQuery.data.UserMetier.id
                              : null
                          }
                        />
                      )
                    )}
                  </MbjFlex>
                </MbjScrollArea>
              )}
            </MbjWidgetClassic>
          </MbjFlex>
        </>
      )}
    </div>
  );
}
export const MesRecherches = styled(MesRecherchesCtrl)((props) => ({
  padding: "1% 2%",
}));
