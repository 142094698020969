import styled from "styled-components";
import {BsChevronDown} from "react-icons/bs";

interface MbjAccordionChevronCtrlProps {}

export const MbjAccordionChevron = styled(BsChevronDown)<MbjAccordionChevronCtrlProps>(({ ...rest }) => ({
  fill: "black",
  flexShrink:0,
  fontSize: "12px",
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
}));
MbjAccordionChevron.defaultProps = {};
