import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {useNavigate} from "react-router-dom";
import React from "react";
import {FaSuitcase} from "react-icons/fa";
import {AiOutlineUser} from "react-icons/ai";
import {BiBuildingHouse} from "react-icons/bi";
import {TrashSearch} from "./TrashSearch";

interface RechercheMetierCardCtrlProps{
  className?:string;
  Search: components["schemas"]["Searchs-searchs.read_read.Member_read.UserMetier_read.Metier"];
}

const RechercheMetierCardCtrl = (props:RechercheMetierCardCtrlProps)=>{
  const navigate = useNavigate();
  const ClickMe = ()=>{
    navigate('../resultats/'+props.Search.id)
  }
  return (
    <div className={`search_resume_card ${props.className}`}>
      <div className={`wrap_search_resume_card ${props.className}`} onClick={ClickMe}>
        <div className={`name info`}><span>{props.Search.Metier?.libelle}</span></div>
        <div className={`resume`}>
          <div className={`info resume_part`}>
            <div className={"Icon_resume"}>
              <AiOutlineUser/>
              <div className={`tool_tips`}>Particuliers</div>
            </div>
            <span>{props.Search.nbResultParticulier||0}</span></div>
          <div className={`info resume_part`}>
            <div className={"Icon_resume"}>
              <BiBuildingHouse/>
              <div className={`tool_tips`}>Reclassements</div>
            </div>
            <span>{props.Search.nbResultReclassement||0}</span>
          </div>
          <div className={`info resume_part`}>
            <div className={"Icon_resume"}>
              <FaSuitcase/>
              <div className={`tool_tips`}>Employées</div>
            </div>
            <span>{props.Search.nbResultEmployee||0}</span>
          </div>
        </div>
        <div className={`date_maj info`}><span style={{marginRight:"10px"}}>Mise à jour : </span><span>{props.Search.updateAt ? new Date(props.Search.updateAt).toLocaleDateString() + " " + new Date(props.Search.updateAt).toLocaleTimeString() : 'Pas encore  évaluée'}</span></div>
      </div>
      <TrashSearch idSearch={props.Search.id||0}/>
    </div>
  )
}

export const RechercheMetierCard = styled(RechercheMetierCardCtrl)`
  display: flex;
  justify-content: flex-start;
  gap:10px;
  .wrap_search_resume_card{
    width: 100%;
    background: white;
    border-radius: 8px;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    &:hover{
      //box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);
      box-shadow: 1px 1px 3px rgba(0,0,0,.2);
      cursor: pointer;
      transform: translateY(-5px);
      transition: 0.3s;
      z-index: 9;
    }
  }

  .date_maj{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  .resume{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-left: 30px;
  }
  .resume_part{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .Icon_resume{
      width: 32px;
      height: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 4px;
      background: #ebe8e8;
      position: relative;margin-right: 6px;
      svg{
        margin: auto;
      }
      &:hover{
        .tool_tips{display: block}
        cursor: pointer;
      }
      .tool_tips{
        display: none;
        padding: 0.5rem;
        font-size: 14px;
        position: absolute;
        left: 50%;
        top: calc(100% + 6px);
        width: max-content;
        background: black;
        color: white;
        border-radius: 4px;
        transform:translateX(-50%);
      }
    }
  }

  .info{
    &.name{
      font-size: 18px;
      span{
        color: ${props => props.theme.primary};
      }
    }
    span{
      font-weight: bold;
    }
  }
`
