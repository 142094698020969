import React from "react";
import styled from "styled-components";

interface Title {
  libelle: string;
  Align?: string;
}

interface MbjTableCtrlProps {
  className?: string;
  titles: Title[];
  children?: React.ReactNode;
  themeColor?: "primary" | "secondary" | "third" | "complementary" | "white";
  noBorderRadius?: boolean;
  padding?: string | number;
  scrollable?: boolean;
  maxHeight?: string;
}

export const MbjTableCtrl = (props: MbjTableCtrlProps) => {
  return (
    <div className={`TableMbj ${props.className}`}>
      <table>
        <thead>
          <tr>
            {props.titles.map((t, idx) => (
              <th key={`lineHead${idx} `} className={`${t.Align && t.Align}`}>
                {t.libelle}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
    </div>
  );
};

export const MbjTable = styled(MbjTableCtrl)((props) => ({
  borderRadius: !props.noBorderRadius ? "0.4rem" : 0,
  overflowY: "auto",
  table: {
    width: "100%",
    boxShadow: "2px 1px 4px 1px #bbbbbb21",
    //overflow:"hidden",
    borderCollapse: "collapse",
    "thead tr, tbody tr": {
      display: props.scrollable ? "table" : "revert",
      width: props.scrollable ? "100%" : "auto",
      tableLayout: props.scrollable ? "fixed" : "auto",
    },
    thead: {
      display: props.scrollable ? "block" : "revert",
      overflow: props.scrollable ? "auto" : "revert",
      scrollbarGutter: props.scrollable ? "stable" : "revert",
      fontSize: "16px",
      th:{
        fontWeight:"bold",
        padding: "0.6rem 0.9rem",
        fontSize:"15px",
        color:props.theme.dark,
      },
      tr: {
        "th:first-child": {
          borderTopLeftRadius: "0.4em",
        },
        borderTopRightRadius: "0.4em",
      },
      //color: !(props.themeColor === "white") ? props.theme.background2 : props.theme.dark,
      color: props.theme.dark,
      backgroundColor:
        props.themeColor === "primary"
          ? props.theme.primaryPastel
          : props.themeColor === "secondary"
          ? props.theme.secondaryPastel
          : props.themeColor === "third"
          ? props.theme.thirdPastel
          : props.themeColor === "complementary"
          ? props.theme.complementaryPastel
          : props.themeColor === "white"
          ? props.theme.background2
          : "none",
      boxShadow: props.themeColor === "white" ? "0px 3px 6px #00000029" : "none",
      position: props.themeColor === "white" ? "relative" : "revert",
    },
    tbody: {
      background: "white",
      display: props.scrollable ? "block" : "table-row-group",
      maxHeight: props.maxHeight,
      overflowY: props.scrollable ? "auto" : "visible",
      width: "100%",
      "tr.selectable": {
        "&:hover": {
          cursor: "pointer",
          background: props.theme.primaryPastel,
        },
      },
      "tr:not(:last-child)": {
        borderBottom: "solid #ccc 1px",
      },
    },
    th: {
      textAlign: "center",
      padding: "0.25rem 0.75rem",
      border: "none",
      fontWeight: "normal",
      "&.isLeft": {
        textAlign: "left",
      },
    },
    td: {
      border: "none",
      padding: "0.5rem",
      fontSize: "15px",
      color: props.theme.dark,
      overflow: props.scrollable ? "hidden" : "unset",
      textOverflow: props.scrollable ? "ellipsis" : "unset",
      "&.isCenter": {
        textAlign: "center",
      },
    },
  },
}));

MbjTable.defaultProps = {
  themeColor: "primary",
  padding: "0",
  noBorderRadius: false,
};
